import { httpSmartClient } from "../../config/httpClient";

const baseName = "PaypalTransactions"

const PaypalTransactionService = {
  add: async (data) => {
    const response = await httpSmartClient.post(`/${baseName}/`, { ...data });
    return response;
  },
  delete: async (id) => {
    const response = await httpSmartClient.delete(`/${baseName}/${id}`);
    return response;
  },
  getById: async (id) => {
    const response = await httpSmartClient.getById(`/${baseName}/${id}`);
    return response;
  },
  put: async (data) => {
    const response = await httpSmartClient.put(`/${baseName}/`, data);
    return response;
  },
  getIncomesPerMonths: async (from, to) => {
    var response = await httpSmartClient.get(`/${baseName}/GetIncomesPerMonths?from=${from}&to=${to}`);
    return response;
  },
}
export default PaypalTransactionService;