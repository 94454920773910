import { httpSmartClient } from "../../config/httpClient";

const baseName = "AccountToCreditCardTransactions"

const AccountToCreditCardTransactions = {
  add: async (data) => {
    const response = await httpSmartClient.post(`/${baseName}/`, { ...data });
    return response;
  },
  delete: async (id) => {
    const response = await httpSmartClient.delete(`/${baseName}/${id}`);
    return response;
  },
  getByParams: async (params) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return response;
  },
  getById: async (id) => {
    const response = await httpSmartClient.getById(`/${baseName}/${id}`);
    return response;
  },
  put: async (data) => {
    const response = await httpSmartClient.put(`/${baseName}/`, data);
    return response;
  },
  getIncomesPerMonths: async (from, to) => {
      var response = await httpSmartClient.get(`/${baseName}/GetIncomesPerMonths?from=${from}&to=${to}`);
      return response;
  },
  getTotalIncome: async () => {
    const response = await httpSmartClient.get(`/${baseName}/TotalIncome`);
    return response;
  },
}
export default AccountToCreditCardTransactions;