import React from 'react';
import { useTranslation } from 'react-i18next';

export default function InfoHeader() {
    const { t } = useTranslation()
    return (
        <div className='container'>
            <div className='ms-3 me-3 pe-2 ps-2'>
                <div className='text-center mt-5 pt-4'>
                    <h4 className='naslov' style={{ color: 'rgba(146, 15, 205, 1)',fontSize:"24px",fontStyle:"normal",fontWeight:"600"}}>{t("CHOSE_DRIVE")}</h4>
                    <h4 className='naslov mb-4'style={{ color: 'rgba(146, 15, 205, 1)',fontSize:"24px",fontStyle:"normal",fontWeight:"600"}}>{t("WHENEVER")}</h4>
                    <div className='row'>
                        <div className='col col-sm-12 mb-1 home-text' >
                            <p style={{fontSize:"16px",fontWeight:"500"}}>{t("ABOUT_VOZIME")} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
