export const reactSelectCustomStyle = {
  menu: (provided) => ({
    ...provided,
    borderRadius: 0, 
  }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'rgb(176, 32, 237)'
        : state.isHovered
        ? '#9400d3'
        : 'whitesmoke' , 
      color: state.isSelected ? 'white' : state.isHovered ? 'white' : '#4d4d4d',
      '&:hover': {
        backgroundColor: '#9400d3',
        color:"white",
      },
      borderRadius:  '0!important' 
    }),
   
  };