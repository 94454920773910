import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { toastify } from "utils/toastify";
import globalStore from "data/stores/GlobalStore";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getAppPagingSettings } from "config/paging";
import { PagingComponent } from "components/layout/components";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { ReportsService, ReportTypesService, UsersService, EnumsService } from "data/services";
import { useIsFirstRender } from "../../public/chat/hooks/useIsFirstRender";

export default function ReportsPage() {
  let pagingSettings = getAppPagingSettings();
  const [pagingData, setPagingData] = useState(pagingSettings);
  const [data, setData] = useState([]);
  const [reportStates, setReportStates] = useState();
  const [reportId, setReportId] = useState();
  const [reportTypes, setReportTypes] = useState();
  const [users, setUsers] = useState();
  const [currentId, setCurrentId] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [reportDetail, setReportDetail] = useState();
  const [searchParams, setSearchParams] = useState({
    reportTypeId: null,
    reportState: null,
    reporterUserId: null,
    reportedUserId: null
  });
  const [selectedReportStateFilter, setSelectedReportStateFilter] = useState(null);
  const [selectedReportTypeIdFilter, setSelectedReportTypeIdFilter] = useState(null);
  const [selectedReporterUserIdFilter, setSelectedReporterUserIdFilter] = useState(null);
  const [selectedReportedUserIdFilter, setSelectedReportedUserIdFilter] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isFirstRender = useIsFirstRender();
  const {
    register,
    formState: { errors },
    reset,
    getValues,
    handleSubmit
  } = useForm();

  const fetchReports = async (params) => {
    const reports = await ReportsService.getByParams({
      pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, ...params
    });
    setData(reports.data);
  };

  const onPageChange = (page) => {
    pagingData.pageNumber = page;
    setPagingData({ ...pagingData });
  }

  const handleDelete = (id) => {
    setShowConfirmationDialog(true);
    setCurrentId(id);
  };

  useEffect(() => {
    const fetchAndSetReportTypes = async () => {
      const reportTypesList = await ReportTypesService.getByParams({
        pageNumber: 1,
        pageSize: 9999
      });
      setReportTypes(() => {
        return reportTypesList.data.items.map(x => {
          return {
            value: x.id,
            label: x.name
          }
        })
      });
    };
    fetchAndSetReportTypes();

    const fetchAndSetReportStates = async () => {
      const reportStatesList = await EnumsService.getReportStates();
      setReportStates(() => {
        return reportStatesList.map(x => {
          return {
            value: x.key,
            label: x.value
          }
        })
      });
    };
    fetchAndSetReportStates();

    const fetchAndSetUsers = async () => {
      const usersList = await UsersService.getByParams({
        pageNumber: 1,
        pageSize: 9999
      });
      setUsers(() => {
        return  usersList && usersList.data && usersList.data.items.map(x => {
          return {
            value: x.id,
            label: x.firstName + " " + x.lastName
          }
        })
      });
    };
    fetchAndSetUsers()


    fetchReports({});
  }, []);

  useEffect(() => {
    const fetchReports = async () => {
      const reports = await ReportsService.getByParams({
        pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, ...searchParams
      });
      setData(reports.data);
    };
    fetchReports();
  }, [searchParams])

  const onReportTypeChange = async (data) => {
    setSearchParams({ ...searchParams, reportTypeId: data.value, reportTypeLabel: data.label })
  };
  const onReportStateChange = (data) => {
    setSearchParams({ ...searchParams, reportState: data.value, reportStateLabel: data.label })
  };
  const onReporterUserIdChange = (data) => {
    setSearchParams({ ...searchParams, reporterUserId: data.value, reporterUserLabel: data.label })
  };
  const onReportedUserIdChange = (data) => {
    setSearchParams({ ...searchParams, reportedUserId: data.value, reportedUserLabel: data.label })
  };
  const deleteReport = async (id) => {
    const deleted = await ReportsService.delete(id);
    if (deleted.isSuccess) {
      toastify.success(t("SUCCESSFULLY_DELETED_REPORT"),<img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png"/>);
    } else {
      toastify.error(t("ERROR_DELETING_REPORT"));
    }
  };
  const ShowDetailDialog = async (id) => {
    const reportCountDetail = await ReportsService.getDetail(id);
    if (reportCountDetail.isSuccess) {
      setReportDetail(reportCountDetail.data);
      setShowDetails(true);
    }
  }

  const StatusEdit = async (id, state) => {
    setShowStatus(true);
    setReportId(id);
  }
  const onSubmitForm = async (data) => {
    const apiModel = { id: reportId, reportState: data.reportState }
    const response = await ReportsService.changeStatus(apiModel);
    if (response.isSuccess) {
      window.location.reload(false);
    }
  }
  const ClearFilters = () => {
    setSearchParams({});
    setData({});
  }
  return (
    <>
      <div className="row">
        <div className="col-12 mb-4 mb-sm-4">
          <div className="d-sm-flex justify-content-between align-items-center ">
            <h1 className="h4 mb-3 mb-sm-0">{t("REPORTS")}</h1>
            <div className="d-grid">
              <a href="#" className="btn btn-primary-soft" onClick={() => navigate("create", { state: {} })}>
                + {t("NEW_REPORT")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-body">
          <div className="row  mb-4">
            <div className="col-md-3  col-sm-12 mt-3">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                  })
                }}
                className="basic-select"
                onChange={(e) => onReportStateChange(e)}
                placeholder={t("CHOOSE_REPORT_STATE")}
                options={reportStates}
                value={searchParams.reportStateLabel ? { label: searchParams.reportStateLabel } : null}
              />
            </div>

            <div className="col-md-3   col-sm-12 mt-3">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                  })
                }}
                className="basic-select"
                onChange={(e) => onReporterUserIdChange(e)}
                placeholder={t("CHOOSE_REPORTER_USER")}
                options={users}
                value={searchParams.reporterUserLabel ? { label: searchParams.reporterUserLabel } : null}
              />
            </div>

            <div className="col-md-3   col-sm-12 mt-3">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                  })
                }}
                className="basic-select"
                onChange={(e) => onReportedUserIdChange(e)}
                placeholder={t("CHOOSE_REPORTED_USER")}
                options={users}
                value={searchParams.reportedUserLabel ? { label: searchParams.reportedUserLabel } : null}
              />
            </div>
            <div className="col-md-2 col-sm-12 mt-3">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                  })
                }}
                className="basic-select"
                onChange={(e) => onReportTypeChange(e)}
                placeholder={t("CHOOSE_REPORT_TYPE")}
                options={reportTypes}
                value={searchParams.reportTypeLabel ? { label: searchParams.reportTypeLabel } : null}
              />
            </div>
            <div className='col-md col-sm-12 pt-3 ps-0 d-flex justify-content-end mt-1 mt-md-0'>
                            <button onClick={() => ClearFilters()} className="btn d-flex  justify-content-center w-sm-100 ms-3 align-items-center btn-sm btn-neutral-soft border border-danger " >
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
          </div>
          <div className="bg-light rounded p-3 d-none d-lg-block">
            <div className="row row-cols-7 g-4">
              <div className="col">
                <h6 className="mb-0">{t("DESTINATION_FROM")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("DESTINATION_TO")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("DRIVER")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("REPORT_TYPES")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("REPORTER")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("STATUS")}</h6>
            </div>
              <div className="col">
                <h6 className="float-end mb-0">{t("ACTION")}</h6>
              </div>
            </div>
          </div>
          {( data && !data.items || data.items.length === 0) && (
            <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col text-center">{selectedReportTypeIdFilter ? t("NO_RESULTS") : t("PLEASE_CHOOSE_REPORT_TYPE_TO_SHOW")}</div>
            </div>
          )}
          {data && data.items &&
            data.items.map((report, index) => (
              <div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
                <div className="col">
                  <small className="d-block d-lg-none">{t("DESTINATION_FROM")}:</small>
                  {report.ride.milestones && report.ride.milestones.map((ride, index) => (
                    ride.type == "Start" && <h6 className="mb-0 fw-normal">{ride.cityName}</h6>
                  ))}
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("DESTINATION_TO")}:</small>
                  {report.ride.milestones && report.ride.milestones.map((ride, index) => (
                    ride.type == "End" && <h6 className="mb-0 fw-normal">{ride.cityName}</h6>
                  ))}
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("DRIVER")}:</small>
                  <h6 className="mb-0 fw-normal">{report.reportedUser.firstName + " " + report.reportedUser.lastName}</h6>
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("REPORT_TYPES")}:</small>
                  <h6 className="mb-0 fw-normal">{report.reportType.name}</h6>
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("REPORTER")}:</small>
                  <h6 className="mb-0 fw-normal">{report.reporterUser.firstName + " " + report.reporterUser.lastName}</h6>
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("STATUS")}:</small>
                  <h6 className={`badge bg-opacity-10 bg-primary text-primary`} >
                    {report.reportState}
                  </h6>
                </div>
                <div className="col-auto ">
                  {/* <button className="btn btn-sm btn-danger float-end mb-0 me-2" onClick={() => handleDelete(report.id)}>
                    {t("DELETE")}
                  </button> */}
                  <button className="btn btn-sm btn-primary-soft mb-0 me-2" onClick={() => ShowDetailDialog(report.id)}>
                    {t("VIEW")}
                  </button>
                  <button className="btn btn-sm btn-primary-soft mb-0 me-2" onClick={() => StatusEdit(report.id, report.reportState)}>
                    {t("STATUS")}
                  </button>
                </div>
              </div>
            ))}
        </div>
        <PagingComponent
          className="pagination-bar"
          currentPage={data.pageNumber ?? 0}
          totalCount={data.totalCount ?? 0}
          pageSize={data.pageSize ?? 0}
          onPageChange={onPageChange}
        />
      </div>
      {showConfirmationDialog && <ConfirmationDialog
        onDiscard={() => setShowConfirmationDialog(false)}
        title={t("DELETE_REPORT_ITEM")}
        description={t("DELETE_REPORT")}
        confirmBtnLabel={t("DELETE")}
        onConfirm={() => deleteReport(currentId)}
      ></ConfirmationDialog>}

      {showStatus &&
        <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="card shadow">
              <div className="card-header border-bottom">
                <h5 className="card-header-title">{t("CHANGE_STATUS")}</h5>
              </div>
              <div className="card-body w-500" style={{ width: "400px" }} >
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="col-md col-sm-12">
                    <label className="form-label">{t("REPORT_STATE")}</label>
                    <select {...register("reportState", {
                      required: "Report state je obavezan",
                    })}
                      className="form-control">
                      <option value="">{t("CHOOSE_REPORT_STATE")}</option>
                      {reportStates &&
                        reportStates.map((reportState, index) => (
                          <option key={index} value={reportState.key}>
                            {reportState.value}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button className="btn text-secondary border-0 me-2 ${props.onDiscard" onClick={() => setShowStatus(false)}>
                      {t("DISCARD")}
                    </button>
                    <button type="submit" className="btn btn-danger">{t("CHANGE")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }

      {showDetails &&
        <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="card shadow">
              <div className="card-header border-bottom">
                <h5 className="card-header-title">{t("DETAILS_REPORT")}</h5>
              </div>
              <div className="card-body w-500" style={{ width: "800px" }} >
                <div className="row justify-content-around text-start mb-4">
                  <div className="col-lg-5">
                    <ul className="list-group list-group-borderless">
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-arrow-up-right  me-2"></i>{t("DESTINATION_FROM")}:</span>
                        <span className="h6 fw-normal mb-0">{reportDetail.rideStart}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-sign-stop me-2"></i>{t("DESTINATION_TO")}:</span>
                        <span className="h6 fw-normal mb-0">{reportDetail.rideEnd}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-calendar fa-fw me-2"></i>{t("DATE_REPORT")}:</span>
                        <span className="h6 fw-normal mb-0">{moment.utc(reportDetail.date).format("DD.MM.YYYY")}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-person me-2"></i>{t("DRIVER")}:</span>
                        <span
                          className="h6 fw-normal mb-0">{reportDetail.reportedUser}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-person me-2"></i>{t("REPORTER")}:</span>
                        <span
                          className="h6 fw-normal mb-0">{reportDetail.reporterUser}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-filetype-aac me-2"></i>{t("REPORT_TYPES")}:</span>
                        <span
                          className="h6 fw-normal mb-0">{reportDetail.reportType}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-pencil-square me-2"></i>{t("REPORT_NOTE")}:</span>
                        <span
                          className="h6 fw-normal mb-0">{reportDetail.note}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-5">
                    <ul className="list-group list-group-borderless">
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-123 me-2"></i>{t("COUNT_RIDE")}:</span>
                        <span className="h6 fw-normal mb-0">{reportDetail.countReportByRide}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-123 me-2"></i>{t("COUNT_RIDE_DRIVER")}:</span>
                        <span className="h6 fw-normal mb-0">{reportDetail.countDriverRide}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-123 me-2"></i>{t("COUNT_REPORT_SAME_DRIVER")}:</span>
                        <span className="h6 fw-normal mb-0">{reportDetail.countReportSameDriver}</span>
                      </li>
                      <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                        <span className="mb-0"><i className="bi bi-123 me-2"></i>{t("COUNT_REPORT_DRIVER")}:</span>
                        <span className="h6 fw-normal mb-0">{reportDetail.countReportByDriver}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button className="btn text-secondary border-0 me-2 ${props.onDiscard" onClick={() => setShowDetails(false)}>
                    {t("DISCARD")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
