import { httpSmartClient } from "../../config/httpClient";

const baseName = "RideMilestones";

const RideMilestonesService = {
  getById: async (id) => {
    const response = await httpSmartClient.getById(`/${baseName}/` + id);
    return response;
  },
  getTopLocations: async (count) => {

    const response = await httpSmartClient.get(`/${baseName}/GetTopLocations?count=${count}`);
    return response;
  },
  getByParams: async (params) => {
    const response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return response;
  },
  getFlags: async (searchObject) => {
    const response = await httpSmartClient.get(`/${baseName}/GetFlags`, { params: { ...searchObject } });
    return response;
  },
  add: async (travelPreference) => {
    const response = await httpSmartClient.post(`/${baseName}`, travelPreference);
    return response;
  },

  edit: async (travelPreference) => {
    const response = await httpSmartClient.put(`/${baseName}`, travelPreference);
    return response;
  },

  delete: async (id) => {
    const response = await httpSmartClient.delete(`/${baseName}/` + id);
    return response;
  },
};

export default RideMilestonesService;
