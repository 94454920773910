import React, { useEffect, useState } from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

export default function GoogleMapSubmitMobile({ width, height, formData }) {
    const [directions, setDirections] = useState(null);

    const containerStyle = {
        width: width,
        height: height,
        margin: 'auto',
        borderRadius: "20px",
    };

    useEffect(() => {
        const newArr = []
        formData && formData.rows && formData.rows.forEach((milestone, index) => {
            if (index !== 0 && index !== formData.rows.length - 1) {
                newArr.push({
                    location: { lat: milestone.lat, lng: milestone.lng }
                })
            }
        })

        const getDirections = async () => {
            try {
                const directionsOptions = {
                    origin: { lat: formData.rows[0].lat, lng: formData.rows[0].lng },
                    destination: { lat: formData.rows[formData.rows.length - 1].lat, lng: formData.rows[formData.rows.length - 1].lng },
                    waypoints: [...newArr],
                    travelMode: 'DRIVING',
                    provideRouteAlternatives: true,
                }

                const directionsService = new window.google.maps.DirectionsService();
                directionsService.route(directionsOptions, (result, status) => {
                    if (status === 'OK') {
                        setDirections(result);
                    }
                });
            } catch (error) {
                throw error;
            }
        };
        getDirections();
    }, []);

    return (
        <>
            <div className="ms-3 mt-3 me-3 googleMapWidthLastStep">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    className="card"
                    zoom={5}
                    options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}
                >
                    {directions && directions.routes && directions.routes.map((route, index) => {
                        return formData.summary === route.summary ? (
                            <DirectionsRenderer
                                key={index}
                                directions={directions}
                                routeIndex={index}
                                options={{
                                    polylineOptions: {
                                        strokeColor: "#039be5",
                                        strokeOpacity: 1.0,
                                        strokeWeight: 5
                                    },
                                }}
                            />
                        )
                            : (<></>)
                    }
                    )}
                </GoogleMap>
            </div>
        </>
    );
};
