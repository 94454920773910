import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/i18n";
import { LanguageSwitcher } from "components/layout/components";
import { useIsFirstRender } from "../chat/hooks/useIsFirstRender";
import globalStore from "data/stores/GlobalStore";
import Header from "../../../layout/components/Header";
import { AuthService } from "data/services";

export default function SignInPage() {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [serverError, setServerError] = useState("");
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [IsTablet, setIsTablet] = useState(window.innerWidth <= 1200);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth <= 1500);
  const [emailEntered, setEmail] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value !== '');
  };

  const IsFirstLoad = useIsFirstRender();

  const togglePasswordVisibility = (e) => {
    setPasswordVisibility((previousVisibility) => !previousVisibility);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1200);
      setIsDesktop(window.innerWidth <= 1500);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    //if (!IsFirstLoad) {
      //window.location.reload(false);
    //}
  }, [localStorage.getItem("i18nextLng")])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSuccessfullFormSubmit = async (data) => {
    setFormSubmitting(true);
    const response = await AuthService.signIn(data.username, data.password);
    if (response && response.token) {
      setServerError("");
      const decodedToken = jwt_decode(response.token);
      localStorage.setItem('i18nextLng', decodedToken.LanguageCode);
      if(decodedToken.LastSignInAt == "")
        globalStore.changeFirstLogin(true);
      i18n.changeLanguage(decodedToken.LanguageCode);
      globalStore.changeRenderConnection();

      if (decodedToken && decodedToken.Role === "Administrator") {
        navigate("/admin", { state: { signInToken: response.token } });
      } else if (searchParams.size !== 0) {
        navigate(`/rides/${currentParams.rideId}/${currentParams.rideMilestoneFromId}/${currentParams.rideMilestoneToId}`);
      } else {
        navigate("/");
      }
    } else {
      if (response.response.status == 401)
        setServerError(t("EMAIL_NOT_VERIFIED_ERROR"));

      if (response.response.status == 400)
        setServerError(t("WRONG_CREDENTIALS"));
    }

    setFormSubmitting(false);
  };

  return (
    <main>
      {!isMobile ? <div className="row justify-content-center align-items-center  " >
        <div className="col-12">
          <div className="row g-1">
            {!IsTablet ? <div className="col-5 vh-100" >
              {localStorage.getItem("i18nextLng") == "ba" && <img src="assets/images/prijava-ba.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                style={{ objectFit: "cover", maxHeight: "100%" }} />}
              {localStorage.getItem("i18nextLng") == "en" && <img src="assets/images/prijava-en.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                style={{ objectFit: "cover", maxHeight: "100%" }} />}
              {localStorage.getItem("i18nextLng") == "de" && <img src="assets/images/prijava-de.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                style={{ objectFit: "cover", maxHeight: "100%" }} />}
            </div> : <div className="col" >
              {localStorage.getItem("i18nextLng") == "ba" && <img src="assets/images/prijava-ba.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                style={{ objectFit: "cover", maxHeight: "100%" }} />}
              {localStorage.getItem("i18nextLng") == "en" && <img src="assets/images/prijava-en.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                style={{ objectFit: "cover", maxHeight: "100%" }} />}
              {localStorage.getItem("i18nextLng") == "de" && <img src="assets/images/prijava-de.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                style={{ objectFit: "cover", maxHeight: "100%" }} />}
            </div>}
            <div className="col-lg order-1 bg-white ">
              <div className="row" style={{ marginTop: "50px", marginBottom: "80px" }}>
                <div className="col"></div>
                <div className="col-auto d-flex flex-row">
                <div className="col d-flex justify-content-center align-items-center">
                  <svg width="16" onClick={() => navigate("/rides")} style={{ marginTop: "1px" }} className="cursor-pointer" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_202_1401)">
                      <path d="M8.14812 15.1852C12.0346 15.1852 15.1852 12.0346 15.1852 8.14816C15.1852 4.26171 12.0346 1.11113 8.14812 1.11113C4.26167 1.11113 1.11108 4.26171 1.11108 8.14816C1.11108 12.0346 4.26167 15.1852 8.14812 15.1852Z" stroke="black" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18.889 18.8889L13.5557 13.5555" stroke="black" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_202_1401">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p onClick={() => navigate("/rides")} className="cursor-pointer me-3" style={{ marginLeft: "10px", color: "black", fontSize: "14px" }}>{t("SEARCH")}</p>
                  <ul className="nav">
                    <LanguageSwitcher/>
                  </ul>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row" >
                {!isDesktop ? <div className="col"></div> : <div className="col-2"></div>}
                <div className={!isDesktop ? "col-5" : "col"}>
                  <h4 className="mb-2" style={{ fontWeight: "bold" }}>{t("LOGIN")}</h4>
                  <p className="mb-0" style={{ fontSize: "14px" }}>
                    {t("LOGIN_INFO")}
                  </p>
                  <form onSubmit={handleSubmit(handleSuccessfullFormSubmit)} className="mt-4 text-start">
                    <div className="mb-3">
                      <label className="form-label" style={{ fontSize: "14px" }} >{t("EMAIL")}:</label>
                      <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-5px" }}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.2917 19.6457H6.70841C3.83341 19.6457 1.91675 18.2082 1.91675 14.854V8.14567C1.91675 4.7915 3.83341 3.354 6.70841 3.354H16.2917C19.1667 3.354 21.0834 4.7915 21.0834 8.14567V14.854C21.0834 18.2082 19.1667 19.6457 16.2917 19.6457Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M16.2916 8.625L13.292 11.0208C12.3049 11.8067 10.6853 11.8067 9.69825 11.0208L6.70825 8.625" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </div>
                        <input placeholder={emailEntered ? '': t("EMAIL")} onChange={handleEmailChange} {...register("username", { required: t("EMAIL_IS_REQUIRED") })} style={{ border: "none", marginLeft: "-5px" }} className="form-control bg-light" />
                      </div>
                      {errors.username && <small className="text-danger">{errors.username.message}</small>}
                    </div>
                    <div className="mb-3 position-relative">
                      <label className="form-label" style={{ fontSize: "14px" }}>{t("PASSWORD")}:</label>
                      <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-5px" }}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.75 9.58317V7.6665C5.75 4.49442 6.70833 1.9165 11.5 1.9165C16.2917 1.9165 17.25 4.49442 17.25 7.6665V9.58317" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.5001 17.7292C12.8233 17.7292 13.8959 16.6565 13.8959 15.3333C13.8959 14.0102 12.8233 12.9375 11.5001 12.9375C10.1769 12.9375 9.10425 14.0102 9.10425 15.3333C9.10425 16.6565 10.1769 17.7292 11.5001 17.7292Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M16.2917 21.0835H6.70841C2.87508 21.0835 1.91675 20.1252 1.91675 16.2918V14.3752C1.91675 10.5418 2.87508 9.5835 6.70841 9.5835H16.2917C20.1251 9.5835 21.0834 10.5418 21.0834 14.3752V16.2918C21.0834 20.1252 20.1251 21.0835 16.2917 21.0835Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </div>
                        <input placeholder={t("PASSWORD")}
                          {...register("password", {
                            required: t("PASSWORD_IS_REQUIRED"),
                            minLength: {
                              value: 4,
                              message: t("PASSWORD_MIN_LENGTH", { length: 4 }),
                            },
                          })}
                          className="form-control fakepassword bg-light"
                          style={{ border: "none" }}
                          type={passwordVisibility ? "text" : "password"}
                        />
                        <i onClick={togglePasswordVisibility} style={{ color: "#9400D3" }} className={`fakepasswordicon fas ${passwordVisibility ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2 mt-1`}></i>
                      </div>
                      {errors.password && <small className="text-danger">{errors.password.message}</small>}
                    </div>
                    <div className="mb-3 d-sm-flex">
                      <a className="purple cursor-pointer" style={{ fontSize: "14px", fontWeight: "bold" }} onClick={() => navigate("/forgot-password")}>{t("FORGOT_PASSWORD")}</a>
                    </div>
                    {serverError && <small className="text-danger">{serverError}</small>}
                    <div>
                      <button disabled={isFormSubmitting} type="submit" className="btn btn-primary w-100 mb-0 mt-4">
                        <div className="row" style={{ height: "30px" }}>
                          <div className="col" style={{ textAlign: "left", marginTop: "2px" }}>
                            {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                            <span style={{ fontSize: "15px", fontWeight: "normal" }}> {t("LOGIN")}</span>
                          </div>
                          <div className="col-auto" style={{ marginTop: "2px" }}>
                            <i className="bi bi-chevron-right"></i>
                          </div>
                        </div>
                      </button>
                    </div>
                    <p className="mb-0 mt-3" style={{ textAlign: "center", fontSize: "14px" }}>
                      {t("NEW_HERE")}
                      <a style={{ fontWeight: "bold" }} className="purple cursor-pointer" onClick={() => navigate("/sign-up")}>
                        &nbsp; {t("CREATE_ACCOUNT")}
                      </a>
                    </p>
                  </form>
                </div>
                {!isDesktop ? <div className="col"></div> : <div className="col-2"></div>}
              </div>
              <div className="row mb-4" style={{ position:"absolute", bottom:"2%",marginLeft:"23%" }} >
                <div className="col"></div>
                <div className="col-auto">
                  <div className="col-md-12 text-center" style={{ height: "20px", fontFamily: "Inter-Medium, Helvetica", fontWeight: "500", color: "#ababab", fontSize: "14px" }}>
                    © 2024 VoziMe.ba
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div> :
        <div className="card">
          <div className="card bg-purple" style={{ height: "185px", top: "0", maxWidth: "100%", borderRadius: "0px", textAlign: "center" }}>
            <Header></Header>
            <span className="mb-1" style={{ color: "white", fontWeight: "500", fontSize: "17px" }}>{t("LOGIN")}</span>
            <p style={{ fontSize: "13px", color: "white" }}>
              {t("LOGIN_INFO")}
            </p></div>
          <div className="card bg-light" style={{ maxWidth: "100%", marginTop: "-10px", height: "570px" }}>
            <div className="card p-4" style={{ maxWidth: "100%", marginTop: "-15px", borderRadius: "20px", marginLeft: "7px", marginRight: "7px" }}>
              <form onSubmit={handleSubmit(handleSuccessfullFormSubmit)} className="text-start" style={{ marginTop: "-5px" }}>
                <div className="mb-3">
                  <label className="form-label" style={{ fontSize: "14px", marginLeft: "5px" }} >{t("EMAIL")}:</label>
                  <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-5px" }}>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.2917 19.6457H6.70841C3.83341 19.6457 1.91675 18.2082 1.91675 14.854V8.14567C1.91675 4.7915 3.83341 3.354 6.70841 3.354H16.2917C19.1667 3.354 21.0834 4.7915 21.0834 8.14567V14.854C21.0834 18.2082 19.1667 19.6457 16.2917 19.6457Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M16.2916 8.625L13.292 11.0208C12.3049 11.8067 10.6853 11.8067 9.69825 11.0208L6.70825 8.625" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </div>
                    <input placeholder={t("EMAIL")} onChange={handleEmailChange} {...register("username", { required: t("EMAIL_IS_REQUIRED") })} style={{ border: "none", marginLeft: "-5px" }} className="form-control bg-light" />
                  </div>
                  {errors.username && <small className="text-danger">{errors.username.message}</small>}
                </div>
                <div className="mb-3 position-relative">
                  <label className="form-label" style={{ fontSize: "14px", marginLeft: "5px" }}>{t("PASSWORD")}:</label>
                  <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-5px" }}>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.75 9.58317V7.6665C5.75 4.49442 6.70833 1.9165 11.5 1.9165C16.2917 1.9165 17.25 4.49442 17.25 7.6665V9.58317" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M11.5001 17.7292C12.8233 17.7292 13.8959 16.6565 13.8959 15.3333C13.8959 14.0102 12.8233 12.9375 11.5001 12.9375C10.1769 12.9375 9.10425 14.0102 9.10425 15.3333C9.10425 16.6565 10.1769 17.7292 11.5001 17.7292Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M16.2917 21.0835H6.70841C2.87508 21.0835 1.91675 20.1252 1.91675 16.2918V14.3752C1.91675 10.5418 2.87508 9.5835 6.70841 9.5835H16.2917C20.1251 9.5835 21.0834 10.5418 21.0834 14.3752V16.2918C21.0834 20.1252 20.1251 21.0835 16.2917 21.0835Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </div>
                    <input placeholder={t("PASSWORD")}
                      {...register("password", {
                        required: t("PASSWORD_IS_REQUIRED"),
                        minLength: {
                          value: 4,
                          message: t("PASSWORD_MIN_LENGTH", { length: 4 }),
                        },
                      })}
                      className="form-control fakepassword bg-light"
                      style={{ border: "none" }}
                      type={passwordVisibility ? "text" : "password"}
                    />
                    <i onClick={togglePasswordVisibility} style={{ color: "#9400D3" }} className={`fakepasswordicon fas ${passwordVisibility ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2 mt-1`}></i>
                  </div>
                  {errors.password && <small className="text-danger">{errors.password.message}</small>}
                </div>
                <div className="mb-3 d-sm-flex">
                  <a className="purple" style={{ fontSize: "14px", fontWeight: "bold" }} onClick={() => navigate("/forgot-password")}>{t("FORGOT_PASSWORD")}</a>
                </div>
                {serverError && <small className="text-danger">{serverError}</small>}
                <div>
                  <button disabled={isFormSubmitting} type="submit" className="btn btn-primary w-100 mb-0 mt-4" style={{ borderRadius: "10px" }}>
                    <div className="row" style={{ height: "30px" }}>
                      <div className="col" style={{ textAlign: "left", marginTop: "2px" }}>
                        {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                        <span style={{ fontSize: "15px", fontWeight: "normal" }}> {t("LOGIN")}</span>
                      </div>
                      <div className="col-auto" style={{ marginTop: "2px" }}>
                        <i className="bi bi-chevron-right"></i>
                      </div>
                    </div>
                  </button>
                </div>
                <p className="mb-0 mt-3" style={{ textAlign: "center", fontSize: "14px" }}>
                  {t("NEW_HERE")}
                  <a style={{ fontWeight: "bold" }} className="purple cursor-pointer" onClick={() => navigate("/sign-up")}>
                    &nbsp; {t("CREATE_ACCOUNT")}
                  </a>
                </p>
              </form>
            </div>
          </div>
          <img src="assets/images/registracija-footer.png" className="cursor-pointer bg-light  fade-in" alt=""
            style={{ objectFit: "cover", maxHeight: "100%", marginTop: "-6px" }} />
        </div>
      }
    </main>
  );
}