import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DateTimeDifference from 'components/common/dateTimeDifference/DateTimeDifference';
import { httpSmartClient } from 'config/httpClient';
import TodayMessage from './TodayMessage';
import chatStore from 'data/stores/ChatStore';

function ChatMessages({ data, currentUserId, prevMessage }) {
    const [showDate, setShowDate] = useState();
    let date1 = moment(prevMessage && prevMessage.createdAt);
    let date2 = moment(data.createdAt);
    let diffInMinutes = date2.diff(date1, 'minutes')
    const todayDate = new Date();
    const today = moment(todayDate);
    let dateProp = moment(data.createdAt);
    let prevMessageDate = moment(prevMessage && prevMessage.createdAt)
    let isThereDiffInDays = dateProp.diff(prevMessageDate, 'days');
    useEffect(() => {
        if (isThereDiffInDays > 0 || prevMessage == undefined) {
            setShowDate(true)
        } else {
            setShowDate(false)
        }
    }, [chatStore.render])
    return (
        <>
            {(today.format("DD MMM YYY") == moment(data.createdAt).format("DD MMM YYY")) == false &&
                showDate &&
                <DateTimeDifference
                    data={data}
                    prevMessage={prevMessage && prevMessage}
                    date={prevMessage && prevMessage.createdAt}
                    myMessage={true}
                    isThereDiffInDays={isThereDiffInDays}
                    diffInMinutes={diffInMinutes}

                />}
            {currentUserId == data.senderId ?
                <>
                    {prevMessage &&
                        (today.format("DD MMM YYY") == moment(data.createdAt).format("DD MMM YYY") && today.format("DD MMM YYY") != moment(prevMessage.createdAt).format("DD MMM YYY")) &&
                        <TodayMessage />
                    }
                    {prevMessage === undefined && (today.format("DD MMM YYY") == moment(data.createdAt).format("DD MMM YYY")) && <TodayMessage />}
                    <ul className='chatMessagesList'>
                        <li className={`mb-2 clearfix  d-flex justify-content-end`} >
                            <div className={`d-flex message-bubble max-width-bubble p-2 ${prevMessage && prevMessage.senderId == data.senderId && isThereDiffInDays < 1 ? 'my-message-no-image ' : ''} message my-message `}>
                                <div>
                                    <a className='text-of-message text-break' data-tooltip-id="my-tooltip" data-tooltip-content={`${moment(data.createdAt).format("HH:mm")}`}>{data.message}</a>
                                </div>
                                <span className='my-time d-flex align-items-end pt-1'>{moment(data.createdAt).format("HH:mm")}</span>
                            </div>
                            {prevMessage ? (prevMessage.senderId != data.senderId || isThereDiffInDays >= 1) && <img className='ms-1 image-custom' style={{width:"30px"}} src={data.sender?.profilePhotoId ? httpSmartClient.getPhotoUrl(data.sender?.profilePhotoId) : "/assets/images/avatar/01.jpg"} alt="Profile picture" /> : <img className='ms-1 image-custom' style={{width:"30px"}} src={data.sender?.profilePhotoId ? httpSmartClient.getPhotoUrl(data.sender?.profilePhotoId) : "/assets/images/avatar/01.jpg"} alt="Profile picture" />}
                        </li>
                    </ul>
                </>
                :
                <>
                    <div className={`message-data`}>
                        {prevMessage &&
                            (today.format("DD MMM YYY") == moment(data.createdAt).format("DD MMM YYY") && today.format("DD MMM YYY") != moment(prevMessage.createdAt).format("DD MMM YYY")) &&
                            <TodayMessage />
                        }
                        {prevMessage === undefined && (today.format("DD MMM YYY") == moment(data.createdAt).format("DD MMM YYY")) &&
                            <TodayMessage />
                        }
                    </div>
                    <ul className='chatMessagesList'>
                        <li className={`mb-0 clearfix d-flex  ${prevMessage && (prevMessage.senderId == data.senderId) && isThereDiffInDays >= 1 == false ? 'other-message-no-image other-message' : ''}`} >
                            {prevMessage ?
                                (prevMessage.senderId !== data.senderId || isThereDiffInDays >= 1 == true) &&
                                <img className='me-1 image-custom' style={{width:"30px"}} src={data.sender?.profilePhotoId ? httpSmartClient.getPhotoUrl(data.sender?.profilePhotoId) : "/assets/images/avatar/01.jpg"} alt="Profile picture" />
                                :
                                <img className='me-1 image-custom' style={{width:"30px"}} src={data.sender?.profilePhotoId ? httpSmartClient.getPhotoUrl(data.sender?.profilePhotoId) : "/assets/images/avatar/01.jpg"} alt="Profile picture" />}
                            <div className='d-flex align-items-start max-width-bubble message-bubble-other mb-2'>
                                <div className={` message ${currentUserId == data.senderId ? "float-right other-message" : "other-message"}`}>
                                    <a className='text-of-message-other text-break'>{data.message}</a>
                                </div>
                                <span className='other-time w-100 d-flex align-items-end pt-1'>{moment(data.createdAt).format("HH:mm")}</span>
                            </div>
                        </li>
                    </ul>
                </>
            }
        </>
    )
}
export default ChatMessages
