import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toastify } from "utils/toastify";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { getAppPagingSettings } from "config/paging";
import { PagingComponent } from "components/layout/components";
import { ReportTypesService } from "data/services";

export default function ReportTypesPage() {
  let pagingSettings = getAppPagingSettings();
  const [pagingData, setPagingData] = useState(pagingSettings);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [currentId, setCurrentId] = useState();

  const fetchReportTypes = async (params) => {
    const reportTypesList = await ReportTypesService.getByParams({ pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, ...params });
    setData(reportTypesList.data);
  }

  useEffect(() => {
    fetchReportTypes();
  }, [pagingData.pageNumber, pagingData.pageSize]);

  const onPageChange = (page) => {
    pagingData.pageNumber = page;
    setPagingData({ ...pagingData });
  }

  const handleDelete = (id) => {
    setShowConfirmationDialog(true);
    setCurrentId(id);
  };

  const deleteReportType = async (id) => {
    const deleted = await ReportTypesService.delete(id);
    if (deleted.isSuccess) {
      const index = data.items.findIndex(x => x.id == id)
      const Arr = data.items;
      Arr.splice(index, 1);
      setData((data) => {
        return {
          ...data,
          items: [...Arr]
        }
      })
      toastify.success(t("SUCCESSFULLY_DELETED_REPORT_TYPE"),<img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png"/>);
      setShowConfirmationDialog(false);
    } else {
      toastify.error(t("ERROR_DELETING_REPORT_TYPE"));
    }
  };

  const searchChange = (e) => fetchReportTypes({ name: e.target.value });

  return (
    <>
      <div className="row">
        <div className="col-12 mb-4 mb-sm-4">
          <div className="d-sm-flex justify-content-between align-items-center">
            <h1 className="h4 mb-3 mb-sm-0">{t("REPORT_TYPES")}</h1>
            <div className="d-grid">
              <a href="#" className="btn btn-primary-soft" onClick={() => navigate("create")}>
                + {t("NEW_REPORT_TYPE")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-body">
          <div className="mb-4 col col-md-4 col-sm-12">
            <form className="rounded position-relative">
              <input style={{height:"38px",borderRadius:"5px"}} className="form-control bg-transparent" type="text" placeholder={t("SEARCH")} aria-label="Search" onChange={debounce(searchChange, 500)} />
              <button className="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                <i className="fas fa-search fs-6"></i>
              </button>
            </form>
          </div>
          <div className="bg-light rounded p-3 d-none d-lg-block">
            <div className="row row-cols-7 g-4">
              <div className="col">
                <h6 className="mb-0">{t("NAME")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("ACTIVE")}</h6>
              </div>
              <div className="col">
                <h6 className="float-end mb-0">{t("ACTION")}</h6>
              </div>
            </div>
          </div>
          {(data && data.length === 0) && (
            <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col text-center">{t("NO_RESULTS")}</div>
            </div>
          )}
          {data && data.items &&
            data.items.map((r, index) => (
              <div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
                <div className="col">
                  <small className="d-block d-lg-none">{t("NAME")}:</small>
                  <h6 className="mb-0 fw-normal">{t(r.name)}</h6>
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("ACTIVE")}:</small>
                  <div className={`badge bg-opacity-10 ${r.isActive ? "bg-success text-success me-2" : "bg-danger text-danger me-2"}`}>{r.isActive ? t("ACTIVE") : t("INACTIVE")}</div>
                </div>
                <div className="col d-flex justify-content-end">
                  <button className="btn btn-sm btn-primary-soft me-2 h-40px" onClick={() => navigate(`edit/${r.id}`)}>
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button className="btn btn-sm btn-danger-soft border border-danger h-40px" onClick={() => handleDelete(r.id)}>
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            ))}
        </div>
        <PagingComponent
          className="pagination-bar"
          currentPage={data.pageNumber ?? 0}
          totalCount={data.totalCount ?? 0}
          pageSize={data.pageSize ?? 0}
          onPageChange={onPageChange}
        />
      </div>
      {showConfirmationDialog && <ConfirmationDialog
        onDiscard={() => setShowConfirmationDialog(false)}
        title={t("DELETE_REPORT_TYPE_ITEM")}
        description={t("DELETE_REPORT_TYPE")}
        confirmBtnLabel={t("DELETE")}
        onConfirm={() => deleteReportType(currentId)}
      ></ConfirmationDialog>}
    </>
  );
}
