import React from 'react'
import { useTranslation } from 'react-i18next'
import { httpSmartClient } from 'config/httpClient';
function UserTravelPreference({ data, showDialog, isMobile }) {
    const { t } = useTranslation();

    return (
        <>
            {!isMobile ?
                <div className='user-travel-preference'>
                    <div className='d-flex gap-3 my-2 align-items-center justify-content-center'>
                        <img
                            style={{ height: 23, width: 23 }}
                            src={data && data.iconId ? httpSmartClient.getPhotoUrl(data.iconId) : "/assets/images/blankicon.jpg"}
                        ></img>
                        <h6 className=' fs14 paragraphs'>{t(data.name)}</h6>
                    </div>
                    <div className='d-flex align-items-center justify-content-between cursor-pointer' onClick={() => showDialog(data.id, data.userTravelPreferenceOption ? data.userTravelPreferenceOption.travelPreferenceOption : "")}>
                        <p className='paragraphs-purple-medium fs14'>{data.userTravelPreferenceOption ? t(data.userTravelPreferenceOption.travelPreferenceOption.name) : t("CHOOSE_TRAVEL_PREFERENCE")}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                            <path d="M6.3396 3.38937L10.9579 8.00771C11.5033 8.55312 11.5033 9.44562 10.9579 9.99104L6.3396 14.6094" stroke="#900FC8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div> :
                <div className='d-flex '>
                    <div className='d-flex align-items-center' style={{minWidth:"23px"}}>
                        <img
                            style={{ height: 22, width: 23 }}
                            src={data && data.iconId ? httpSmartClient.getPhotoUrl(data.iconId) : "/assets/images/blankicon.jpg"}
                        ></img>
                    </div>
                    <div className='d-flex flex-column ms-3'>
                        <div className='d-flex'>
                            <h6 className='pt-2 paragraphs'>{t(data.name)}</h6>
                        </div>
                        <div className='d-flex cursor-pointer preference-mobile' onClick={() => showDialog(data.id, data.userTravelPreferenceOption ? data.userTravelPreferenceOption.travelPreferenceOption : "")}>
                            <p className='paragraphs-purple-medium'>{data.userTravelPreferenceOption ? t(data.userTravelPreferenceOption.travelPreferenceOption.name) : t("CHOOSE_TRAVEL_PREFERENCE")}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                <path d="M6.3396 3.38937L10.9579 8.00771C11.5033 8.55312 11.5033 9.44562 10.9579 9.99104L6.3396 14.6094" stroke="#900FC8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default UserTravelPreference