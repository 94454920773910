import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function TransportAtLowPrices() {

    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const navigate = useNavigate();
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="row" style={isMobile ? { height: "80px", backgroundColor: "#900fc8", marginTop: "-70px", marginBottom: "0px" } :
                { height: "60px", backgroundColor: "#900fc8", marginTop: "-70px", marginBottom: "40px" }}>
                <div className="col">
                </div>
                <div className="col-auto pt-1">
                    <h5 style={{ color: "white", fontWeight: "500", fontSize: isMobile ? "18px" : "22px", marginTop: "15px" }}>{t("TRAVEL_BY_LOW_PRICE")}</h5>
                </div>
                <div className="col">
                </div>
            </div>
            <div className={isMobile ? "bg-light" : ""} style={isMobile ? { paddingTop: "40px", marginTop: "-20px", borderRadius: "1.5em" } : {}}>
                <div className={isMobile ? "container mb-6 p-0" : "container mb-6"} style={isMobile ? { width: "90%" } : {}}>
                    {!isMobile ? <div className="card p-4 bg-purple" style={{ borderRadius: "60px", height: "300px", boxShadow:"rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
                        <div className="row">
                            <div className="col-8 mt-5">
                                <h5 className="text-white ms-4" style={{ fontSize: "25px", color: "black", fontWeight: "500" }}>{t("TRAVEL_BY_LOW_PRICE")}</h5>
                                <p className="" style={{ lineHeight: "23px", marginLeft: "25px", fontSize: "16px", fontWeight: "500", lineHeight: "25px", color: "white", marginTop: "20px", width: "88%" }}>{t("TRAVEl_BY_LOW_PRICE_DETAIL")}</p>
                            </div>
                            <div className="col-auto">
                                <div className="card shadow p-4 bg-white mt-7" style={{ position: "absolute", marginTop: "40px", width: "350px", height: "225px" }}>
                                    <h6 className="mb-3" style={{ fontWeight: "600", color: "black" }}>{t("IN_THIS_PAGE")} </h6>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-2" onClick={() => handleClick(ref3)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("HOW_WORKS_DETERMINING_PRICE")}</p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref4)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("HOW_CALCULATE_PRICE")}
                                        </p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref5)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("VOZIME_COMPENSATION")}
                                        </p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref6)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("ACCOUNT_OPENING")}
                                        </p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                        <div>
                            <div className="card  bg-purple" style={{ borderRadius: "30px", height: "auto", marginBottom: isMobile ? "20px" : "300px", marginTop: "-20px", boxShadow:"rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
                                <div className="row">
                                    <div className="col p-5">
                                        <h5 className="text-white mb-3" style={{ color: "black", fontSize: "22px", fontWeight: "500" }}>{t("TRAVEL_BY_LOW_PRICE")}</h5>
                                        <p className="" style={{ lineHeight: "22px", fontSize: "15px", fontWeight: "500", color: "white" }}>{t("TRAVEl_BY_LOW_PRICE_DETAIL")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="card shadow p-4 bg-white">
                                        <h6 className="mb-3" style={{ fontWeight: "600", color: "black" }}>{t("IN_THIS_PAGE")} </h6>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-2" onClick={() => handleClick(ref3)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("HOW_WORKS_DETERMINING_PRICE")}</p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-1" onClick={() => handleClick(ref4)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("HOW_CALCULATE_PRICE")}
                                            </p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-1" onClick={() => handleClick(ref5)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("VOZIME_COMPENSATION")}
                                            </p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-1" onClick={() => handleClick(ref6)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer" }}>{t("ACCOUNT_OPENING")}
                                            </p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div ref={ref3} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: isMobile ? "20px" : "40px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "20px", color: "black" }}>  {t("HOW_WORKS_DETERMINING_PRICE")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P25")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P26")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={ref4} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "20px", color: "black" }}>  {t("HOW_CALCULATE_PRICE")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P27")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P28")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={ref5} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "20px", color: "black" }}>  {t("VOZIME_COMPENSATION")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P29")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P30")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={ref6} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "20px", color: "black" }}>  {t("ACCOUNT_OPENING")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P31")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P32")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}