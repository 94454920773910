import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import moment from "moment/moment"
import "./scrollbar.css"
import Select from "react-select";
import { toastify } from "utils/toastify"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { ConfirmationDialog, BookRideConfirm, SuccessInfo } from "../user/userChunks/modals"
import userProfileStore from "data/stores/UserProfileStore"
import Checkout from "../paypal/PaypalSDK";
import {
  RidesService, AuthService, ChatsService, AppSettingsService, UserRidesService,
  NotificationsService, ConnectionsService, EPayService, CreditCardPaymentRequestsService, UsersService
} from "data/services"
import { httpSmartClient } from "config/httpClient";
import { RideMap } from "../user";
import { useIsFirstRender } from "../chat/hooks/useIsFirstRender"
import globalStore from "data/stores/GlobalStore";
import Modal from "react-responsive-modal"
import { CancelRideDialog, BookingInProgressModal, SuccessInfoFinalize } from "../user/userChunks/modals"
import notificationsStore from "data/stores/NotificationsStore"
import { UnFinalizedRideModal } from "../rides"
import { feePayment, merchantID, paymentCSSUrl, paymentFee, paymentJSUrl, paymentLocale, paymentUrl, terminalID, versionPayment } from "config/config"
import InformationModal from "../rides/InformationModal"

const RideDetail = observer(({ ride }) => {
  const { t } = useTranslation();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [notVerifiedModalOpen, setNotVerifiedModalOpen] = useState(false);
  const [differentGenders, setDifferentGenders] = useState(false);
  const [isReserved, setReserved] = useState(false);
  const [isReservedAnotherRoute, setReservedAnotherRoute] = useState(false);
  const [showPayPal, setShowPayPal] = useState(false);
  const [passengerInfoDetail, setPassengerInfoDetail] = useState();
  const [milestonesIds, setMilestonesIds] = useState();
  const [isMaxPassengers, setMaxPassengers] = useState(false);
  const [myRide, setMyRide] = useState(false)
  const user = AuthService.getCurrentUser();
  const [paymentType, setPaymentType] = useState();
  const [showPassengerInfo, setshowPassengerInfo] = useState(false);
  const [searchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const [payment, setPayment] = useState();
  const navigate = useNavigate();
  const parameters = useParams();
  const FirstRender = useIsFirstRender();
  const [successInfo, setSuccessInfo] = useState(false);
  const [showCancelRideDialog, setShowCancelRideDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmDialogBookRide, setConfirmDialogBookRide] = useState(false);
  const [selectedRide, setSelectedRide] = useState();
  const [isCancellation, setIsCancellation] = useState(false);
  const [userRide, setUserRide] = useState();
  const [daysBeforeReservation, setDaysBeforeReservation] = useState();
  const [tooEarlyToBook, setTooEarlyToBook] = useState();
  const [delay, setDelay] = useState(0);
  const [isLastPlaceBeingBooked, setIsLastPlaceBeingBooked] = useState(false);
  const [bookedTwice, setBookedTwice] = useState(false);
  const [minutes, setMinutes] = useState();
  const [infoModalDiffGender, setInfoModalDiffGender] = useState(false);
  const [infoModalAnotherRoute, setInfoModalAnotherRoute] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  useEffect(() => {
    if (!FirstRender) {
      navigate('/my-profile/my-rides?TranCode=' + globalStore.tranCode);
    }
  }, [globalStore.render])

  useEffect(() => {
    const rideMilestones = async () => {
      const rideMilestonesIds = await RidesService.getMilestonesIds({ ...currentParams });
      setMilestonesIds(rideMilestonesIds.data);
    }
    rideMilestones();
    IsCashAllowed();
    window.scrollTo(0, 0);
  }, []);

  const checkForChanges = async (e) => {

    const updatedRide = await RidesService.getByRideIdDetails(parameters);
    if (updatedRide && updatedRide.data && (updatedRide.data.isRideCompleted || updatedRide.data.isRideActive || updatedRide.isCancelled)) {
      window.location.reload();
      return;
    }
    const response = await UserRidesService.getByRideIdAndUserId(user.Id, ride.id);
    if (response && response.data == '' && userRide != null) {
      window.location.reload();
      return;
    }
    setSelectedRide(e);
    setIsCancellation(true);
    setConfirmDialog(true)
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = paymentJSUrl;
    script.async = true;

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = paymentCSSUrl;

    document.body.appendChild(script);
    document.head.appendChild(link);
    if (user?.Id == ride.driverId)
      setMyRide(true);
  }, []);

  const initUPCPayment = async () => {
    let object = {
      currency: 977,
      amount: ride.price * 100,
      time: moment(new Date()).format("yyMMDDHHmmss").slice(2),
      orderId: new Date().getTime(),
      description: "Ride ID" + parameters.rideId,
      delay: delay
    }

    const getSignature = async () => {
      let response = await EPayService.getSignature({ ...object });
      if (response.data) {
        const requestBody = {
          orderId: object.orderId.toString(),
          userId: user.Id,
          rideId: parameters.rideId,
          price: ride.price,
          rideMilestoneFromId: ride.milestonesForUser[0].id,
          rideMilestoneToId: ride.milestonesForUser[1].id,
        };
        const request = await CreditCardPaymentRequestsService.add(requestBody);
        if (request.isSuccess) {
          window.UpcPayment({
            url: paymentUrl,
            payment: {
              Version: versionPayment,
              MerchantID: merchantID,
              TerminalID: terminalID,
              locale: paymentLocale,
              Signature: response.data,
              Currency: object.currency,
              TotalAmount: object.amount,
              PurchaseTime: object.time,
              OrderID: object.orderId,
              PurchaseDesc: object.description,
              Delay: delay
            },
            options: {
              modal: true,
              modalHeight: '750px',
            }
          });
          const modal = document.getElementsByClassName("upc-modal");
          if (modal) {
            modal[0].style.width = "94%";
          }
        }
      }
    }
    getSignature();
  }

  const PaymentTypes = [
    {
      value: 1,
      label: "Credit Card"
    },
    {
      value: 2,
      label: "Paypal"
    }

  ]
  const CancelBooking = async (e) => {
    const finalize = async () => {
      const response = myRide == true ? await RidesService.changeRideStatus(ride.id, "Cancelled") : await UserRidesService.changeRideStatus(e.id, "Cancelled");
      if (response.isSuccess) {
        setShowCancelRideDialog(true);
        if (response.data.passengers && response.data.passengers.length > 0) {
          response.data.passengers.forEach(x => {
            const apiResponse = async () => {
              const notifResponse = await NotificationsService.add({ rideMilestoneFromId: x.rideMilstoneFromId, rideMilestoneToId: x.rideMilestoneToId, userId: x.userId, senderId: 2, text: 'RideCancelled', rideId: x.rideId })
              await ConnectionsService.InvokeCancelRide({ userId: x.userId, notificationId: notifResponse.data.id });
            }
            apiResponse();
          })
        } else {
          if (e.isDriver == false) {
            const apiResponse = async () => {
              const rideModel = response.data.rideId ? await RidesService.getById(response.data.rideId) : await RidesService.getById(response.data.id)
              const notifResponse = await NotificationsService.add({ rideMilestoneFromId: rideModel.data.milestones[0].id, rideMilestoneToId: rideModel.data.milestones[rideModel.data.milestonesCount - 1].id, userId: rideModel.data.driverId, senderId: 2, text: 'UserRideCancelled', rideId: response.data.rideId ? response.data.rideId : response.data.id, userCancellationId: response.data.userId })
              await ConnectionsService.InvokeCancelRide({ userId: rideModel.data.driverId, notificationId: notifResponse.data.id });
            }
            apiResponse();
          }
        }
      } else {
        if (response.error === "RIDE_ALREADY_CANCELLED") {
          toastify.error(t("RIDE_ALREADY_CANCELLED"));
          window.location.reload();
        }
        else if (response.error === "RIDE_ALREADY_UPCOMING") {
          toastify.error(t("RIDE_ALREADY_UPCOMING"));
          window.location.reload();
        }
        else if (response.error === "RIDE_ALREADY_COMPLETED") {
          toastify.error(t("RIDE_ALREADY_COMPLETED"));
          window.location.reload();
        }
        else {
          toastify.error(t("ERROR_RIDE_CANCELLED"));
        }
      }
      setConfirmDialog(false);
    }
    finalize();
  }
  const hasUserAlreadyBookedTwice = async () => {
    const response = await UserRidesService.hasUserAlreadyBookedTwice(user.Id, parameters.rideId);
    setBookedTwice(response);
    return response;
  }


  const ReservedRide = async () => {
    if (user == null) {
      navigate({ pathname: '/sign-in', search: `rideId=${parameters.rideId}&rideMilestoneFromId=${parameters.rideMilestoneFromId}&rideMilestoneToId=${parameters.rideMilestoneToId}` });
      return;
    }
    const loggedUser = await UsersService.getById(user.Id);
    if (loggedUser.data && !loggedUser.data.isVerifiedPhoneNumber) {
      setNotVerifiedModalOpen(true);
      return;
    }
    const updatedRide = await RidesService.getByRideIdDetails(parameters);
    if (updatedRide.data.currentPassengersCount == 0) {
      window.location.reload();
      return;
    }
    const fromDate = moment.utc(new Date(ride && ride.milestones[0].dateTime))
    const today = moment.utc(new Date())
    const days = fromDate.diff(today, "days")
    if (days > daysBeforeReservation) {
      setTooEarlyToBook(true);
      return;
    }
    if (ride.currentPassengersCount == 0) {
      setMaxPassengers(true);
      return;
    }
    const response = await UserRidesService.hasUserAlreadyBookedTwice(user.Id, parameters.rideId);
    if (response == true) {
      setBookedTwice(true);
      return;
    }
    if (ride.currentPassengersCount == 1) {
      const isLastPlaceBeingBookedResponse = await CreditCardPaymentRequestsService.isLastPlaceBeingBooked(parameters.rideId, user.Id);
      if (!isLastPlaceBeingBookedResponse.data.canBook) {
        setMinutes(isLastPlaceBeingBookedResponse.data.minutesLeft);
        setIsLastPlaceBeingBooked(true);
        return;
      }
    }
    if(differentGenders)
    {
      setInfoMessage(t("INFO_DIFF_GENDERS"));
      setInfoModalDiffGender(true);
      return;
    }
    if(isReservedAnotherRoute)
    {
      setInfoMessage(t("RESERVED_ANOTHER_ROUTE"));
      setInfoModalAnotherRoute(true);
      return;
    }
    if (user != null && isMaxPassengers == false && isReserved == false && user.Id != ride.driverId) {
      setConfirmDialogBookRide(true);

    } else {
      setMyRide(true);
      setMaxPassengers(true);
      return;
    }
  }

  const addUserRide = async () => {
    const userRide = {
      rideId: parameters.rideId,
      userId: user.Id,
      rideMilstoneFromId: ride.milestonesForUser[0].id,
      rideMilestoneToId: ride.milestonesForUser[1].id,
      price: ride.price
    }
    const userRideResponse = await UserRidesService.addWithCashPayment(userRide);
    if (userRideResponse.isSuccess) {
      setSuccessInfo(true);
      const notificationToDriver = { userId: ride.driverId, rideId: userRideResponse.data.rideId, rideMilestoneFromId: userRideResponse.data.rideMilstoneFromId, rideMilestoneToId: userRideResponse.data.rideMilestoneToId, senderId: user.Id, text: `Reservation` };
      const notifResponse = await NotificationsService.add(notificationToDriver);
      await ConnectionsService.InvokeReservedRideDriver({ userId: ride.driverId, userRideId: userRideResponse.data.id, notificationId: notifResponse.data.id })
      const notificationToUser = { userId: user.Id, rideId: userRideResponse.data.rideId, rideMilestoneFromId: userRideResponse.data.rideMilstoneFromId, rideMilestoneToId: userRideResponse.data.rideMilestoneToId, senderId: 2, text: `ReservationToMySelf` };
      const notifResponse2 = await NotificationsService.add(notificationToUser);
      notificationsStore.notify(notifResponse2.data);
      setConfirmDialogBookRide(false);
    }
    else {
      toastify.error(t("ERROR_TRYING_RESERVED_RIDE"));
    }
  }
  const getAppSettings = async () => {
    const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 })
    setPaymentType(response.data.items[0].isCashAllowed);
    setDelay(response.data.items[0].isReservationAllowed == true ? 1 : 0)
    setDaysBeforeReservation(response.data.items[0].minimumDaysForReservation);
  }

  const setPassengerInfoDialog = (passsenger) => {
    setPassengerInfoDetail(passsenger);
    setshowPassengerInfo(true);
  }
  const IsCashAllowed = async () => {
    const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 })
    setPaymentType(response.data.items[0].isCashAllowed);
  }

  useEffect(() => {
    // const fetchAndSetDetail = async () => {
    //   const reportResponse = await ReportsService.getByParams({ rideId: currentParams.rideId, reportState: 1 })
    //   setReport(reportResponse.data)
    //   const reportTypesList = await ReportTypesService.getByParams({ pageNumber: 1, pageSize: 9999 })
    //   setReportTypes(reportTypesList.data)
    // }

    if (ride.womenOnly && user?.Gender === 'Male') {
      setDifferentGenders(true);
    }

    const rideMilestones = async () => {
      const rideMilestonesIds = await RidesService.getMilestonesIds({ ...currentParams });
      setMilestonesIds(rideMilestonesIds.data);

    }
    const getUserRide = async () => {
      const response = await UserRidesService.getByRideIdAndUserId(user.Id, ride.id);
      if (response.isSuccess && response.data != '')
        setUserRide(response.data);
      ride.passengers.forEach((e) => {
        if (e.userId == user.Id) {
          if (response.data.rideMilstoneFromId.toString() === parameters.rideMilestoneFromId && response.data.rideMilestoneToId.toString() === parameters.rideMilestoneToId) {
            setReserved(true);
          }
          else {
            setReservedAnotherRoute(true);
          }
        }
      })
    }
    // const isUserAlreadyBooked = async () => {
    //   let reserved = false;
    //   ride.passengers.forEach((e) => {
    //     if (e.userId == user.Id) {
    //       reserved = true;
    //       setReserved(true);
    //     }
    //   })
    // }
    // fetchAndSetDetail();
    rideMilestones();
    //isUserAlreadyBooked();
    hasUserAlreadyBookedTwice();
    getAppSettings();
    getUserRide();
    window.scrollTo(0, 0);
  }, []);

  const handleChatNavigate = async (driverId) => {
    if (user == null) {
      navigate({ pathname: '/sign-in', search: `cityNameFrom=${currentParams.cityNameFrom}&cityNameTo=${currentParams.cityNameTo}&rideId=${currentParams.rideId}` });
      return
    }
    const response = await ChatsService.getChatGuidBetweenUsers({ UserOneId: driverId, UserTwoId: userProfileStore.user.id })
    navigate(`/chat/${response.data}`)
  }

  return (
    <>
      <div className="card mb-3 " style={{ backgroundColor: "#900fc8", height: "auto", marginTop: "-65px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
        <div className="card-body cursor-pointer p-3 ms-2 me-2 mb-5" style={{ color: "white" }}>
          <div className="row">
            {ride.milestones &&
              ride.milestones.map((milestone, index, milestoneFrom) => {
                if (milestone.type == "Start") {
                  const toDate = moment.utc(new Date(milestoneFrom[index + 1].dateTime))
                  const fromDate = moment.utc(new Date(milestoneFrom[0].dateTime))
                  const minutes = toDate.diff(fromDate, "minutes")
                  const hours = toDate.diff(fromDate, "hours");
                  const min = minutes % 60;
                  milestone.dateTimeFormatted = minutes >= 60 ? `${hours}h ${min}m` : `${min}m`
                }

                return (
                  milestone.id == parameters.rideMilestoneFromId && (
                    <>
                      <div className="col-auto" style={{ maxWidth: "120px" }}>
                        <p className="white fs16 fw-500 mt-3" >{milestone.cityName}</p>
                      </div>
                    </>
                  )
                )
              })}
            <div className="col">
              <div className="row mt-3">
                {ride.milestones && ride.milestones.map((milestone, index, milestoneFrom) => {
                  if (ride && ride.milestonesForUser) {
                    const toDate = moment.utc(new Date(ride.milestonesForUser[0].dateTime))
                    const fromDate = moment.utc(new Date(ride.milestonesForUser[1].dateTime))
                    const minutes = fromDate.diff(toDate, "minutes")
                    const hours = fromDate.diff(toDate, "hours");
                    const min = minutes % 60;
                    milestone.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                  }
                  return (
                    milestone.id == parameters.rideMilestoneFromId && (
                      <>
                        <div className="col-auto" style={{ marginLeft: "-10px" }}>
                          <i style={{ fontSize: "10px" }} className="bi bi-circle-fill"></i>
                          <p className="fw-400" style={{ fontSize: "12px", marginLeft: "-8px" }}>{moment.utc(milestone.dateTime).format("HH:mm")} </p>
                        </div>
                        <div className="col" style={{ textAlign: "center", marginLeft: "-40px", marginRight: "-30px", marginTop: "-12px" }}>
                          <div className=" text-center">
                            <div className="position-relative my-4">
                              <hr style={{ border: "none", borderTop: "2px dashed white" }} className="bg-primary opacity-8 position-relative" />
                              <button className="position-absolute  start-50 translate-middle" style={{ marginTop: ride.milestones.length > 2 ? "-42px" : "-21px", borderRadius: "30px", height: "22px", backgroundColor: "white", width: "85px", border: "1px solid #900fc8", fontWeight: "bold" }}><p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{milestone.dateTimeFormatted}</p></button>
                              <div className="row justify-content-center">
                                {[...Array(ride.milestones.length - 2)].map((e, i) =>
                                  <>
                                    <div className="col-auto">
                                      <i style={{ fontSize: "8px", color: "#e61870", marginTop: "-21px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )
                })}
                {ride.milestones &&
                  ride.milestones.map(
                    (milestone, index) =>
                      milestone.id == parameters.rideMilestoneToId && (
                        <div className="col-auto" style={{ marginRight: "-25px" }}>
                          <i style={{ fontSize: "10px" }} className="bi bi-circle-fill"></i>
                          <p className="fw-400" style={{ fontSize: "12px", marginLeft: "-8px" }}>{moment.utc(milestone.dateTime).format("HH:mm")}</p>
                        </div>
                      )
                  )}
              </div>
            </div>
            {ride.milestones &&
              ride.milestones.map(
                (milestone, index) =>
                  milestone.id == parameters.rideMilestoneToId && (
                    <div className="col-auto" style={{ maxWidth: "120px" }}>
                      <p className="white fs16 fw-500 mt-3" >{milestone.cityName}</p>
                    </div>
                  )
              )}
          </div>
          <div className="row">
            <div className="col-auto d-flex flex-row" style={{ marginRight: "16px" }}>
              <svg width="21" height="20" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.16675 1.91663V4.79163" stroke="white" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.8333 1.91663V4.79163" stroke="white" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.85425 8.7113H20.1459" stroke="white" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.625 8.14579V16.2916C20.625 19.1666 19.1875 21.0833 15.8333 21.0833H8.16667C4.8125 21.0833 3.375 19.1666 3.375 16.2916V8.14579C3.375 5.27079 4.8125 3.35413 8.16667 3.35413H15.8333C19.1875 3.35413 20.625 5.27079 20.625 8.14579Z" stroke="white" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.5408 13.1292H15.5494" stroke="white" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.5408 16.0042H15.5494" stroke="white" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.9956 13.1292H12.0042" stroke="white" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.9956 16.0042H12.0042" stroke="white" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.44876 13.1292H8.45736" stroke="white" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.44876 16.0042H8.45736" stroke="white" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p style={{ color: "white", fontSize: "13px", marginLeft: "5px", marginTop: "2px" }}>{moment.utc(ride.milestones[0].dateTime).format("DD.MM.yyy")}</p>
            </div>
            <div className="col d-flex flex-row">
              {[...Array(ride.maxPassengersCount - ride.currentPassengersCount)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="white" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="white" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
              </svg>)}
              {[...Array(ride.currentPassengersCount)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="white" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="white" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
              </svg>)}
              <p style={{ fontSize: "12px", marginTop: "1px", marginLeft: "5px" }}>{ride.currentPassengersCount == 0 ? t("NO_SEATS") : (ride.currentPassengersCount > 1) ? (ride.currentPassengersCount) + " " + t("SEATS_AVAILABLE") : (ride.currentPassengersCount) + " " + t("SEAT_AVAILABLE")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light" style={{ maxWidth: "100%", height: "20px", marginTop: "-30px", position: "relative", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}></div>
      <div className="card bg-white mb-3 mw-90 ms-auto me-auto" style={{ marginTop: '-65px' }} >
        <div className="card-body cursor-pointer p-3 ms-2 me-3" >
          <div className="row mb-2">
            <h6 style={{ fontWeight: "600", color: "black", fontSize: "14px" }}>{t("RIDE_DETAILS_VIEW")}</h6>
          </div>
          {ride.milestones &&
            ride.milestones.map((milestone, index, milestoneFrom) => {
              if (milestone.type == "Start") {
                const toDate = moment.utc(new Date(milestoneFrom[index + 1].dateTime))
                const fromDate = moment.utc(new Date(milestoneFrom[0].dateTime))
                const minutes = toDate.diff(fromDate, "minutes")
                const hours = toDate.diff(fromDate, "hours");
                const min = minutes % 60;
                milestone.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
              }

              return (
                milestone.type == "Start" && (
                  <>
                    <div className="row mb-3">
                      <button className="d-flex align-items-center" style={{ borderRadius: "30px", backgroundColor: "white", height: "36px", maxWidth: "100%", border: "1px solid grey", marginLeft: "5px" }}>
                        <svg width="16" height="14" style={{ marginRight: "5px", marginBottom: "2px" }} viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.4302 2.92993L21.5002 8.99993L15.4302 15.0699" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M4.5 9H21.33" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1 1L1 16" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p style={{ fontSize: "12px", textAlign: "left", fontWeight: "600", color: "black" }}>
                          {t("DETAILS_FROM")} {moment.utc(milestone.dateTime).format("DD.MM")} {t("IN")} {moment.utc(milestone.dateTime).format("HH:mm")}h</p>
                      </button>
                    </div>
                    <div className="row d-flex flex-row mt-3">
                      <div className="col d-flex flex-row">
                        <p style={{ fontSize: "12px" }}>{moment.utc(milestone.dateTime).format("HH:mm")}h</p>
                        <i style={{ fontSize: "12px", marginLeft: "10px" }} className="bi bi-circle"></i>
                        <div style={{ marginLeft: "-7px", height: "40px", marginTop: "15px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                        <div>
                          <h6 style={{ fontWeight: "500", color: "black", opacity: milestone.id == parameters.rideMilestoneFromId ? "" : "0.5" }} className="ms-3 me-2">{milestone.cityName}</h6>
                          <p style={{ fontSize: "12px", marginLeft: "15px" }}>{milestone.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-auto d-flex flex-row">
                        <button style={{ borderRadius: "35px", backgroundColor: "white", height: "25px", width: "85px", border: "1px solid grey", marginLeft: "15px" }}>
                          <p style={{ fontSize: "12px", fontWeight: "bold", color: "black", fontWeight: "600" }}>{milestone.dateTimeFormatted} </p></button>
                      </div>
                      {user != null && user.Id && ride.driverId == user.Id && <> <div className="col"></div>
                        <div className="col-auto d-flex flex-row" style={{ justifyContent: "end" }}>
                          <svg width="18" style={{ marginTop: "2px" }} height="18" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1529_6528)">
                              <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1529_6528">
                                <rect width="22.47" height="21.09" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <h5 className="text-end fw-500 ms-1" style={{ color: "#900fc8", fontSize: "16px" }}>{ride.milestones[1].price}</h5>
                          <h5 style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", marginLeft: "5px", marginTop: "1px" }} > {t("VALUTE")}</h5>
                        </div></>}
                    </div>
                  </>
                )
              )
            })}
          {ride.milestones &&
            ride.milestones.map((milestone, index, milestoneFrom) => {
              if (milestone.type == "Stop") {
                const toDate = moment.utc(new Date(milestoneFrom[index + 1].dateTime))
                const fromDate = moment.utc(new Date(milestone.dateTime))
                const minutes = toDate.diff(fromDate, "minutes")
                const hours = toDate.diff(fromDate, "hours");
                const min = minutes % 60;
                milestone.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
              }
              return (
                milestone.type == "Stop" && (
                  <>
                    <div className="row">
                      <div className="col d-flex flex-row">
                        <p style={{ fontSize: "12px", marginTop: "15px" }}>{moment.utc(milestone.dateTime).format("HH:mm")}h</p>
                        <i style={{ fontSize: "13px", marginLeft: "10px", marginTop: "13px" }} className="bi bi-circle"></i>
                        <div style={{ marginLeft: "-7.5px", color: "black", height: "29px", marginTop: "-15px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                        <div style={{ marginLeft: "-2px", color: "black", height: "38px", marginTop: "30px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                        <div style={{ marginTop: "13px" }}>
                          <h6 style={{ fontWeight: "500", color: "black", opacity: milestone.id <= parameters.rideMilestoneToId && milestone.id >= parameters.rideMilestoneFromId ? "" : "0.5" }} className="ms-3 me-2">{milestone.cityName}</h6>
                          <p style={{ fontSize: "12px", marginLeft: "15px" }}>{milestone.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-auto" >
                        <button style={{ borderRadius: "30px", backgroundColor: "white", height: "25px", width: "85px", border: "1px solid grey", marginLeft: "15px", opacity: milestone.id < parameters.rideMilestoneToId && milestone.id >= parameters.rideMilestoneFromId ? "" : "0.5" }}><p style={{ fontSize: "12px", color: "black", fontWeight: "600" }}>{milestone.dateTimeFormatted}</p></button>
                      </div>
                      {user != null && user.Id && ride.driverId == user.Id && <> <div className="col"></div>
                        <div className="col-auto d-flex flex-row" style={{ justifyContent: "end" }}>
                          <svg width="18" style={{ marginTop: "2px" }} height="18" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1529_6528)">
                              <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                              <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                              <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                              <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                              <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1529_6528">
                                <rect width="22.47" height="21.09" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <h5 style={{ textAlign: "right", fontSize: "16px", marginLeft: "5px", fontWeight: "500", color: "#900fc8" }}>{ride.milestones[index + 1].price}</h5><h5 style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", marginLeft: "5px", marginTop: "1px" }} > {t("VALUTE")}</h5>
                        </div></>}
                    </div>
                  </>
                )
              )
            })}
          {ride.milestones &&
            ride.milestones.map(
              (milestone, index) =>
                milestone.type == "End" && (
                  <>
                    <div className="row">
                      <div className="col d-flex flex-row">
                        <p style={{ fontSize: "12px", marginTop: "15px" }}>{moment.utc(milestone.dateTime).format("HH:mm")}h</p>
                        <i style={{ fontSize: "12px", marginLeft: "10px", marginTop: "13px" }} className="bi bi-circle"></i>
                        <div style={{ marginLeft: "-6.5px", color: "black", height: "29px", marginTop: "-15px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                        <div style={{ marginTop: "11px" }}>
                          <h6 style={{ fontWeight: "500", color: "black", opacity: milestone.id != parameters.rideMilestoneToId ? "0.5" : "" }} className="ms-3 me-2">{milestone.cityName}</h6>
                          <p style={{ fontSize: "12px", marginLeft: "15px" }}>{milestone.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <button className="d-flex align-items-center" style={{ borderRadius: "30px", backgroundColor: "white", height: "36px", maxWidth: "100%", border: "1px solid grey", marginLeft: "5px" }}>
                        <svg width="16" height="14" style={{ marginRight: "5px", marginBottom: "2px" }} viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.4302 2.92993L18.5002 8.99993L12.4302 15.0699" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1.5 9H18.33" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M22 1L22 16" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p style={{ fontSize: "12px", fontWeight: "600", color: "black", textAlign: "left" }}>
                          {t("DETAILS_TO")} {moment.utc(milestone.dateTime).format("DD.MM")} {t("IN")} {moment.utc(milestone.dateTime).format("HH:mm")}h
                        </p>
                      </button>
                    </div>
                  </>
                )
            )}
          <div className="row d-flex flex-row mt-3">
            <hr></hr>
            <div className="col">
              <p style={{ fontSize: "16px", fontWeight: "500", color: "#7E7E7E" }}>{t("PRICE_PERSON")}</p>
            </div>
            <div className="col-auto d-flex align-items-baseline flex-row">
              <p style={{ textAlign: "right", fontWeight: "500", color: "#900fc8", fontSize: "18px" }}>{ride.price}</p>
              <p style={{ textAlign: "right", fontWeight: "500", fontSize: "18px", opacity: "90%", marginLeft: "5px", marginTop: "0px" }}> {t("VALUTE")}</p>
            </div>
          </div>
        </div>

      </div>
      <div className="mw-90 m-auto mb-3">
        <RideMap className="" ride={ride} width={"auto"} height={250} />
      </div>
      <div className="card shadow p-3 bg-white mw-90 m-auto mb-3">

        <div className=" cursor-pointer  px-2 py-1">
          {user && ride.driverId == user.Id && <div className="row"><h6 className="ps-1" style={{ fontWeight: "500", fontSize: "16px", color: "black" }}>{t("VEHICLE")}</h6></div>}

          {(user == null || (user && ride.driverId != user.Id)) && <div className="row ">

            <div className='d-flex p-1 pt-0'>
              <div onClick={() => navigate("/user/" + ride.driver.id)} className='flex-fill d-flex justify-self-start'>
                <div style={{ position: "relative", display: "inline-block", height: "36px", width: "36px" }}>
                  <img width={"100%"} height={"100%"} style={{ display: "block" }} className="avatar-img  rounded-circle " src={ride.driver.profilePhotoId == null ? "/assets/images/avatar/01.jpg" : httpSmartClient.getPhotoUrl(ride.driver.profilePhotoId)} alt="no profile picture" />
                </div>
                <div>
                  <p className='black mb-0 ps-1 pb-0 ms-1 fs16 fw-500'>{ride.driver.firstName} {ride.driver.lastName}</p>
                  {ride.driver.isVerifiedPhoneNumber && <> <img style={{ height: "15px", position: "absolute", top: "40px", left: "56px" }} src='../../../../assets/images/verified.png'></img>
                    <p style={{ color: "#7e7e7e" }} className='mt-0  ms-4 fs12 fw-500'>{t("VERIFIED")}</p></>}
                  {!ride.driver.isVerifiedPhoneNumber && <>
                    <p style={{ color: "#7e7e7e" }} className='mt-0 fs12 fw-500'>{t("NOT_VERIFIED")}</p></>}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-end">
                <div className='flex-fill d-flex justify-content-end '>
                  <svg className='col-auto  p-0' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M10.5549 1.29976L12.1387 4.49359C12.3547 4.93818 12.9306 5.36463 13.4166 5.44629L16.2872 5.92718C18.123 6.23567 18.555 7.57853 17.2321 8.90325L15.0004 11.1534C14.6224 11.5345 14.4154 12.2695 14.5324 12.7957L15.1714 15.5812C15.6753 17.7861 14.5144 18.639 12.5796 17.4867L9.88895 15.8807C9.403 15.5903 8.60209 15.5903 8.10714 15.8807L5.41644 17.4867C3.49065 18.639 2.32078 17.777 2.82473 15.5812L3.46366 12.7957C3.58064 12.2695 3.37367 11.5345 2.99571 11.1534L0.763955 8.90325C-0.5499 7.57853 -0.126947 6.23567 1.70885 5.92718L4.57953 5.44629C5.05648 5.36463 5.63242 4.93818 5.84839 4.49359L7.43222 1.29976C8.29612 -0.433253 9.69997 -0.433253 10.5549 1.29976Z" fill="#F7C32E" />
                  </svg>
                  <p className='fs16 gray col-auto m-0  ps-1 d-inline fw-600' >{ride.driver.ridesAverageRating.toFixed(1)}</p>
                </div>
                <p className=' ps-1 gray  mt-0 ms-1 fs12 fw-500'>{t("TOTAL_REVIEWS")}: {ride.driver.ridesRatingCount}</p>
              </div>

            </div>
          </div>}
          <div className="row mt-3 px-0 ">
            <div className="col ps-1 d-flex flex-column">
              <div className="mb-2">
                <label className="fs12 gray fw-400" >{t("CAR")}:</label>
                <br />
                <p className="fw-500 fs-14 black">{ride.driverVehicle.vehicleModel.name}</p>
              </div>
              <div>
                <label className="fs12 gray fw-400">{t("LICENSE_PLATE_NUMBER")}:</label>
                <br />
                <p className="fw-500 fs-14 black">{ride.driverVehicle.licensePlateNumber}</p>
              </div>
            </div>
            <div className="col-auto pe-0" >
              <img className="me-2"
                style={{ height: 100, width: 170, borderRadius: "15px", objectFit: "cover" }}
                src={ride.driverVehicle.optimizedVehiclePhotoId == null ? "/assets/images/default-car.jpg" : httpSmartClient.getPhotoUrl(ride.driverVehicle.optimizedVehiclePhotoId)}
              ></img>
            </div>
          </div>
          <div className="row mt-3 ps-1 ">
            {!user || (user.Id != ride.driverId) && <div className="col ps-0 d-flex flex-row " onClick={() => handleChatNavigate(ride.driver.id)} style={{ color: "#9400D3" }}>
              <svg width="17" className="contact-driver cursor-pointer" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="vuesax/linear/messages-3">
                  <g id="vuesax/linear/messages-3_2">
                    <g id="messages-3">
                      <path id="Vector" d="M15.5846 4.42692V8.0394C15.5846 8.93899 15.2871 9.69692 14.7559 10.2211C14.2317 10.7523 13.4738 11.0498 12.5742 11.0498V12.3319C12.5742 12.8136 12.0359 13.104 11.6392 12.8348L10.9521 12.3815C11.0159 12.1619 11.0442 11.9211 11.0442 11.6661V8.78319C11.0442 7.33819 10.0809 6.37484 8.63589 6.37484H3.8263C3.72713 6.37484 3.63505 6.38193 3.54297 6.38902V4.42692C3.54297 2.62067 4.74714 1.4165 6.55339 1.4165H12.5742C14.3805 1.4165 15.5846 2.62067 15.5846 4.42692Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_2" d="M11.0442 8.78335V11.6662C11.0442 11.9212 11.0159 12.1621 10.9521 12.3817C10.6901 13.4229 9.82589 14.0746 8.63589 14.0746H6.70922L4.57005 15.4983C4.2513 15.7179 3.8263 15.4842 3.8263 15.1017V14.0746C3.1038 14.0746 2.50172 13.8338 2.0838 13.4158C1.6588 12.9908 1.41797 12.3887 1.41797 11.6662V8.78335C1.41797 7.43752 2.2538 6.5096 3.54297 6.38918C3.63505 6.3821 3.72713 6.375 3.8263 6.375H8.63589C10.0809 6.375 11.0442 7.33835 11.0442 8.78335Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </g>
              </svg>
              <p className="contact-driver ms-2  cursor-pointer fs14" style={{ marginTop: "-2px", fontSize: "15px", fontWeight: "500" }} >{t("CONTACT_DRIVER")}</p>
            </div>}
          </div>
        </div>
      </div>
      <div className="card shadow bg-white mw-90 m-auto mb-3">
        <div className="card-body p-3">
          <h6 className="mb-2 fw-500 fs-14 black" style={{ fontWeight: "600", color: "black" }}>{t("PASSENGERS")}</h6>
          {ride.passengers.length == 0 && <ul className="list-group list-group-borderless"><span className=" fs14 gray fw-500">{t("NO_PASSENGERS")}</span></ul>}
          {ride.passengers &&
            ride.passengers.map((passenger, index) => (
              <>
                <div className="row mb-1">
                  <div className="col d-flex flex-row" style={{ marginLeft: "-2px" }}>
                    <div style={{ position: "relative", display: "inline-block", height: "38px", width: "38px", minWidth: "38px" }}>
                      <img onClick={() => navigate("/user/" + passenger.user.id)}
                        style={{ height: 38, width: 38 }}
                        className={`avatar-img ${passenger.user.isVerifiedPhoneNumber && (user == null || (user && ride.driverId == user.Id)) ? 'profile__photo__isVerified' : ''} rounded-circle cursor-pointer`}
                        src={passenger.user.profilePhotoId === null || passenger.user.profilePhotoId === 0 ? "/assets/images/avatar/01.jpg" : httpSmartClient.getPhotoUrl(passenger.user.profilePhotoId)}
                      ></img>
                      {passenger.user.isVerifiedPhoneNumber && (user && ride.driverId == user.Id) && <img style={{ height: "15px", position: "absolute", top: "25px", left: "25px" }} src='../../../assets/images/verified.png'></img>}
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex ">
                        <h6 onClick={() => navigate("/user/" + passenger.user.id)} className="cursor-pointer fs14 fw-500 black ms-2 mb-0 ">
                          {passenger.user && passenger.user.firstName} {passenger.user && passenger.user.lastName}
                        </h6>
                        {user != null && user.Id != passenger.user.id &&
                          <svg width="20" className="cursor-pointer ms-1" height="18" onClick={() => handleChatNavigate(passenger.user.id)} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="vuesax/linear/messages-3">
                              <g id="vuesax/linear/messages-3_2">
                                <g id="messages-3">
                                  <path id="Vector" d="M15.5846 4.42692V8.0394C15.5846 8.93899 15.2871 9.69692 14.7559 10.2211C14.2317 10.7523 13.4738 11.0498 12.5742 11.0498V12.3319C12.5742 12.8136 12.0359 13.104 11.6392 12.8348L10.9521 12.3815C11.0159 12.1619 11.0442 11.9211 11.0442 11.6661V8.78319C11.0442 7.33819 10.0809 6.37484 8.63589 6.37484H3.8263C3.72713 6.37484 3.63505 6.38193 3.54297 6.38902V4.42692C3.54297 2.62067 4.74714 1.4165 6.55339 1.4165H12.5742C14.3805 1.4165 15.5846 2.62067 15.5846 4.42692Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path id="Vector_2" d="M11.0442 8.78335V11.6662C11.0442 11.9212 11.0159 12.1621 10.9521 12.3817C10.6901 13.4229 9.82589 14.0746 8.63589 14.0746H6.70922L4.57005 15.4983C4.2513 15.7179 3.8263 15.4842 3.8263 15.1017V14.0746C3.1038 14.0746 2.50172 13.8338 2.0838 13.4158C1.6588 12.9908 1.41797 12.3887 1.41797 11.6662V8.78335C1.41797 7.43752 2.2538 6.5096 3.54297 6.38918C3.63505 6.3821 3.72713 6.375 3.8263 6.375H8.63589C10.0809 6.375 11.0442 7.33835 11.0442 8.78335Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        }
                      </div>
                      {(user && ride.driverId == user.Id) ? <span style={{ position: "relative", bottom: "3px" }} onClick={() => navigate("/user/" + passenger.user.id)} className="cursor-pointer fs12 gray  fw-500 black ms-2 ">
                        {passenger.rideMilestoneFrom.locationName} -{'>'}   {passenger.rideMilestoneTo.locationName}
                      </span> :
                        <div className="d-flex ms-2">
                          {passenger.user.isVerifiedPhoneNumber ? <> <img style={{ height: "15px", position: "relative" }} src='../../../../assets/images/verified.png'></img>
                            <p style={{ color: "#7e7e7e" }} className='mt-0 ms-1  fs12 fw-500'>{t("VERIFIED")}</p></> :
                            <>
                              <p style={{ color: "#7e7e7e" }} className='mt-0 fs12 fw-500'>{t("NOT_VERIFIED")}</p></>}
                        </div>
                      }
                    </div>
                  </div>
                  {(user == null || (user && ride.driverId != user.Id)) && <div className="col-auto mt-2" >
                    <p className="fs14">{passenger.rideMilestoneFrom.cityName} <svg width="28" height="13" style={{ marginRight: "4px", marginLeft: "3px", marginTop: "-2px" }} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.4299 1L18.4999 7.07L12.4299 13.14" stroke="#656565" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.5 7.07007H18.33" stroke="#656565" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                      {passenger.rideMilestoneTo.cityName}</p>
                  </div>}
                  {user != null && user.Id && ride.driverId == user.Id && <div className="col-auto mt-2">
                    <div className="col-auto d-flex flex-row">
                      <svg width="20" style={{ marginTop: "2px" }} height="20" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1529_6528)">
                          <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                          <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                          <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                          <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                          <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1529_6528">
                            <rect width="22.47" height="21.09" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <h5 className="text-end fw-400 fs16 ms-1" style={{ color: "#900fc8" }}>{passenger.price}</h5><h5 className="text-end fw-400 f16 ms-1"> BAM</h5>
                    </div>
                  </div>}
                </div>
              </>
            ))}
          {user != null && user.Id && ride.driverId == user.Id &&
            <>
              <hr style={{ border: "0.5px solid #DDD" }}></hr>
              <div className="row">
                <div className="col-auto"><p className="fs16 fw-500 gray">{t("PRICE_ALL")}</p></div>
                <div className="col"></div>
                <div className="col-auto d-flex flex-row">
                  <h5 className="text-end fw-600 fs18 ms-1" style={{ color: "#900fc8" }}>{ride.priceOfAllPassengers}</h5><h5 className="text-end fw-600 f18 gray ms-1"   > BAM</h5>
                </div>
              </div>
            </>
          }
        </div>
      </div>
      <div className="card shadow bg-white mb-3 mw-90 m-auto">
        <div className="card-body cursor-pointer p-3 ">
          <h6 className="mb-2 fw-500 fs-14 black" >{t("CONDITIONS")}</h6>
          {ride.conditions.length == 0 && <ul className="list-group list-group-borderless"><span className=" fs14 gray fw-500">{t("NO_CONDITIONS")}</span></ul>}
          <ul className="list-group list-group-borderless">
            {ride.conditions &&
              ride.conditions.map((condition, index) => (
                <li className="d-flex flex-row mb-1" style={{ color: "#900fc8" }}>
                  <img
                    style={{ height: 20, width: 20, marginRight: "7px" }}
                    className="cursor-pointer"
                    src={condition.condition.noIconId == null ? "/assets/images/blankicon.jpg" : httpSmartClient.getPhotoUrl(condition.condition.noIconId)}
                  ></img>
                  <span className="mb-1 fs14 fw-500" >
                    {t(condition.condition.name)}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="card shadow bg-white mb-3 mw-90 m-auto">
        <div className="card-body cursor-pointer p-3 ">
          <h6 className="mb-2 fw-500 fs-14 black" >{t("TRAVEL_PREFERENCES")}</h6>
          {ride.driver.userTravelPreferenceOptions.length == 0 && <ul className="list-group list-group-borderless"><span className=" fs14 gray fw-500">{t("NO_TRAVEL_PREFERENCES")}</span></ul>}
          {ride.driver.userTravelPreferenceOptions.length > 0 && <ul className="list-group list-group-borderless  mt-3">
            {ride.driver.userTravelPreferenceOptions &&
              ride.driver.userTravelPreferenceOptions.map((preference, index) => (
                <li className="d-flex flex-row my-1" style={{ marginBottom: "7px" }}>
                  <img
                    style={{ height: 20, width: 20, marginRight: "7px" }}
                    className="cursor-pointer"
                    src={preference.travelPreferenceOption.travelPreference && preference.travelPreferenceOption.travelPreference.iconId == null ? "/assets/images/blankicon.jpg" : httpSmartClient.getPhotoUrl(preference.travelPreferenceOption.travelPreference && preference.travelPreferenceOption.travelPreference.iconId)}
                  ></img>
                  <span className="mb-1 ms-1 fs14 fw-500">
                    {t(preference.travelPreferenceOption.name)}
                  </span>
                </li>
              ))}
          </ul>}
        </div>
      </div>
      <div className="card bg-light mw-90 m-auto">
        {!isReserved && !myRide && <button
          disabled={tooEarlyToBook || isReserved || ride.isCancelled || ride.isRideCompleted || ride.isRideActive || bookedTwice || ride.currentPassengersCount == 0 || myRide ? true : false}
          onClick={() => ReservedRide()}
          style={{ backgroundColor: "#900fc8", color: "white", maxWidth: "auto", borderRadius: "18px", textAlign: "left" }}
          className={"btn mb-3 py-2"}
        >
          <div className="row">
            <div className="col-1" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="vuesax/linear/driving">
                  <g id="driving">
                    <g id="Group">
                      <path id="Vector" d="M14.5502 2H9.4502C7.6502 2 7.2502 2.90001 7.0202 4.01001L6.2002 7.92999H17.8002L16.9802 4.01001C16.7502 2.90001 16.3502 2 14.5502 2Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <g id="Group_2">
                        <path id="Vector_2" d="M19.2401 14.3199C19.3201 15.1699 18.6401 15.9 17.7701 15.9H16.4101C15.6301 15.9 15.5201 15.57 15.3801 15.15L15.23 14.7199C15.03 14.1299 14.9001 13.7299 13.8501 13.7299H10.1401C9.10005 13.7299 8.94005 14.1799 8.76005 14.7199L8.61005 15.15C8.47005 15.56 8.36006 15.9 7.58006 15.9H6.22005C5.35005 15.9 4.67005 15.1699 4.75005 14.3199L5.16006 9.89996C5.26006 8.80996 5.47005 7.91992 7.37005 7.91992H16.62C18.52 7.91992 18.7301 8.80996 18.8301 9.89996L19.2401 14.3199Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <path id="Vector_3" d="M6.20021 5.75H5.47021" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_4" d="M18.5303 5.75H17.8003" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_5" d="M7.6499 10.8301H9.81992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_6" d="M14.1802 10.8301H16.3502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <path id="Vector_7" d="M12 17V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_8" d="M12 21V22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_9" d="M3 18L2 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path id="Vector_10" d="M21 18L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="col " style={{ fontSize: "14px", marginLeft: "-5px", marginTop: "2px" }}>
              {tooEarlyToBook == true ? t("TOO_EARLY_TO_BOOK") + daysBeforeReservation + t("TOO_EARLY_TO_BOOK_1") : ride.isRideCompleted == true ? t("COMPLETED") : ride.isCancelled == true ? t("CANCELLED_RIDE") : ride.isRideActive == true ? t("UPCOMING") : isReserved == true ? t("RESERVED_ALREADY") : ride.currentPassengersCount == 0 ? t("FILLED") : myRide == true ? t("CANNOT_BOOK_MYRIDE") : bookedTwice ? t("CANNOT_BOOK_MORE_THAN_TWICE") : t("BOOK_A_RIDE")}
            </div>
            <div className="col-2" style={{ marginRight: "-5px", marginTop: "auto", marginBottom: "auto" }}>
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>
        </button>}
        {isReserved && userRide && !ride.isCancelled && !ride.isRideCompleted && !ride.isRideActive && <button
          onClick={() => { checkForChanges(userRide) }}
          style={{ backgroundColor: "#E76868", color: "white", maxWidth: "auto", borderRadius: "18px", textAlign: "left" }}
          className="btn mb-3"
        >
          <div className="row">
            <div className="col-1" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M7.6416 12.3583L12.3583 7.6416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.3583 12.3583L7.6416 7.6416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg> </div>
            <div className="col " style={{ fontSize: "14px", marginLeft: "-5px", marginTop: "2px" }}>
              {t("CANCEL_RIDE")}
            </div>
            <div className="col-2" style={{ marginRight: "-5px", marginTop: "auto", marginBottom: "auto" }}>
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>
        </button>}
        {myRide && !ride.isCancelled && !ride.isRideCompleted && !ride.isRideActive && <button
          onClick={() => { checkForChanges(ride) }}
          style={{ backgroundColor: "#E76868", color: "white", maxWidth: "auto", borderRadius: "18px", textAlign: "left" }}
          className="btn mb-3"
        >
          <div className="row">
            <div className="col-1" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M7.6416 12.3583L12.3583 7.6416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.3583 12.3583L7.6416 7.6416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg> </div>
            <div className="col " style={{ fontSize: "14px", marginLeft: "-5px", marginTop: "2px" }}>
              {t("CANCEL_RIDE")}
            </div>
            <div className="col-2" style={{ marginRight: "-5px", marginTop: "auto", marginBottom: "auto" }}>
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>
        </button>}
      </div>
      {showPassengerInfo && (
        <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="card shadow">
              <div className="card-header border-bottom">
                <h5 className="card-header-title">{t("PASSENGER_INFO")}</h5>
              </div>
              <div className="card-body " style={{ width: "360px" }}>
                <div className="col-lg">
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0"><i className="bi bi-arrow-up-right me-2"></i>{t("DESTINATION_FROM")}: </span>
                      <span className="h6 fw-normal mb-0">{passengerInfoDetail.rideMilestoneFrom.cityName} ({passengerInfoDetail.rideMilestoneFrom.locationName})</span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0"><i className="bi bi-sign-stop me-2"></i>{t("DESTINATION_TO")}: </span>
                      <span className="h6 fw-normal mb-0">{passengerInfoDetail.rideMilestoneTo.cityName} ({passengerInfoDetail.rideMilestoneTo.locationName})</span>
                    </li>
                    <li className="list-group-item d-sm-flex justify-content-between align-items-center">
                      <span className="mb-0"><i className="bi bi-currency-dollar me-2"></i>{t("PRICE")}: </span>
                      <span className="h6 fw-normal mb-0">{passengerInfoDetail.price} KM</span>
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button className="btn text-secondary border-0 me-2 ${props.onDiscard" onClick={() => setshowPassengerInfo(false)}>
                    {t("DISCARD")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {ride != null && showPayPal && (

        <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="card shadow">
              <div className="card-header border-bottom">
                <h5 className="card-header-title">{t("CHOOSE_PAYMENT")}</h5>
              </div>
              <div className="col-11 z-index-master align-self-center mt-3">
                <Select
                  name="paymentType"
                  isSearchable={false}
                  className="basic-select"
                  onChange={(e) => {
                    setPayment(e.label);
                  }}
                  placeholder={t("CHOOSE_PAYMENT")}
                  options={PaymentTypes}
                />
              </div>
              <div className="card-body row justify-content-center" style={{ width: "400px" }} >
                {ride != null && payment == "Paypal" && <Checkout milestonesIds={milestonesIds} orderdetail={ride} rideId={currentParams.rideId} className="mt-2 "></Checkout>}
                {ride != null && payment == "Credit Card" && <img style={{ width: "130px" }} src="/assets/images/raiffeisenBtn.png"></img>}
                <div className="d-flex justify-content-end mt-4">
                  <button className="btn text-secondary border-0 me-2 ${props.onDiscard" onClick={() => { setShowPayPal(false); setPayment(null) }}>
                    {t("DISCARD")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        closeIcon={globalStore.closeIcon}
        classNames={{
          modal: "modal-no-padding",
          modalContainer: 'modal-container-animation-none',
          closeButton: "closeButtonConfirmCancel"

        }}
        center
      >
        <ConfirmationDialog
          onDiscard={() => setConfirmDialog(false)}
          title={t("CANCEL_RIDE")}
          description={
            <div>
              {t("CANCEL_RIDE_CONFIRM_1")}
              <span className="darkgray fw-600"> {ride.milestonesForUser[0].cityName} -&gt; </span>
              <span className="darkgray fw-600">{ride.milestonesForUser[1].cityName} </span>
              {t("BOOK_RIDE_CONFIRM_2")}
              <span className="darkgray fw-600">{moment(ride.milestonesForUser[0].dateTime).format("DD.MM.yyy")}{t("YEAR")} </span>
              {t("IN")}
              <span className="darkgray fw-600"> {moment(ride.milestonesForUser[0].dateTime).format("HH:mm")} h </span>
              {t("BOOK_RIDE_CONFIRM_3")}
              <span className="darkgray fw-600">{ride.price} {t("VALUTE")}</span>?
              <br /><br />
              <span className="darkgray fw-600">{ride.hasAlreadyBeenCancelledOnce ? t("NOTE1") : null}</span>
              <span className="italic">{ride.hasAlreadyBeenCancelledOnce ? t("CANCEL_RIDE_CONFIRM_2") : null}</span>
            </div>
          }
          confirmBtnLabel={t("CANCEL")}
          onConfirm={() => CancelBooking(selectedRide)}
        >
        </ConfirmationDialog>
      </Modal>
      <Modal
        open={successInfo}
        showCloseIcon={true}
        classNames={{
          modal: 'confirm-success-dialog-mobile ms-2',
          modalContainer: 'modal-container-animation-none'
        }}
        center
      >
        <SuccessInfo
          title={t("BOOK_A_RIDE")}
          description={t("SUCCESSFULLY_RESERVED")}
          confirmBtnLabel={t("CLOSE")}
          onConfirm={() => navigate("/my-profile/my-rides")}
        ></SuccessInfo>
      </Modal>

      <Modal
        open={isLastPlaceBeingBooked}
        onClose={() => setIsLastPlaceBeingBooked(false)}
        closeIcon={globalStore.closeIcon}
        classNames={{
          modal: "modal-no-padding",
          closeButton: "me-2 mt-2"
        }}
        center
      >
        <BookingInProgressModal
          onDiscard={() => setIsLastPlaceBeingBooked(false)}
          title={t("NOTE")}
          description={
            <div>
              {t("LAST_PLACE_BEING_BOOKED_NOTE_1")}
              <br/>
              {t("LAST_PLACE_BEING_BOOKED_NOTE_2")}
              <span className="darkgray fw-600"> {minutes} {t("LAST_PLACE_BEING_BOOKED_NOTE_3")}</span>
            </div>
          }
        >
        </BookingInProgressModal>
      </Modal>

      <Modal
        open={confirmDialogBookRide}
        onClose={() => setConfirmDialogBookRide(false)}
        closeIcon={globalStore.closeIcon}
        classNames={{
          modal: "modal-no-padding",
          closeButton: "mt-2"
        }}
        center
      >
        <BookRideConfirm
          onDiscard={() => setConfirmDialogBookRide(false)}
          description={
            <div>
              {t("BOOK_RIDE_CONFIRM_1")}
              <span className="darkgray fw-600"> {ride.milestonesForUser[0].cityName} -&gt; </span>
              <span className="darkgray fw-600">{ride.milestonesForUser[1].cityName} </span>
              {t("BOOK_RIDE_CONFIRM_2")}
              <span className="darkgray fw-600">{moment(ride.milestonesForUser[0].dateTime).format("DD.MM.yyy")}{t("YEAR")} </span>
                {t("IN")}
              <span className="darkgray fw-600"> {moment(ride.milestonesForUser[0].dateTime).format("HH:mm")} h </span>
              {t("BOOK_RIDE_CONFIRM_3")}
              <span className="darkgray fw-600">{ride.price} {t("VALUTE")}</span>?
              <br /><br />
              <span className="darkgray fw-600">{t("NOTE1")}</span>
              <span className="italic"> {paymentType ? t("BOOK_RIDE_CONFIRM_4") : t("BOOK_RIDE_CONFIRM_5")}</span>
            </div>
          }
          confirmBtnLabel={t("BOOK_A_RIDE")}
          onConfirm={() => {
            if (paymentType == false) {
              setConfirmDialogBookRide(false);
              initUPCPayment();
            }
            else {
              addUserRide()
            }
          }}
        >
        </BookRideConfirm>
      </Modal>
      <>
        <Modal
          open={notVerifiedModalOpen}
          onClose={() => setNotVerifiedModalOpen(false)}
          closeIcon={globalStore.closeIcon}
          center
          classNames={
            { modal: "" }
          }
        >
          {/* Should be renamed to InformationModal because it's reusable */}
          <UnFinalizedRideModal
            onDiscard={() => setNotVerifiedModalOpen(false)}
            title={t("INFO")}
            description={t("PHONE_NOT_VERIFIED_MESSAGE")}
            confirmBtnLabel={t("GO_VERIFY")}
            onConfirm={() => { setNotVerifiedModalOpen(false); navigate("/my-profile") }}
          ></UnFinalizedRideModal>
        </Modal>
      </>
      <Modal
        open={showCancelRideDialog}
        showCloseIcon={true}
        classNames={{
          modal: 'successInfoFinalize successInfoFinalizeMobile',
          modalContainer: 'modal-container-animation-none',
          closeButton: 'mt-1'
        }}
        center
      >
        <CancelRideDialog
          title={t("CANCEL_BOOKING")}
          description={t("SUCCESS_RIDE_CANCELLED")}
          confirmBtnLabel={t("CLOSE")}
          onConfirm={() => { setShowCancelRideDialog(false); navigate("/my-profile/my-rides") }}
        ></CancelRideDialog>
      </Modal>

      {(infoModalDiffGender || infoModalAnotherRoute) &&
       <>
          <Modal
            open={infoModalDiffGender || infoModalAnotherRoute}
            onClose={() => {infoModalDiffGender ? setInfoModalDiffGender(false) : setInfoModalAnotherRoute(false)}}
            closeIcon={globalStore.closeIcon}
            center
            classNames={
              { modal: "",
              closeButton: "mt-2 me-2"
             }
            }
          >
            <InformationModal
              onDiscard={() => {infoModalDiffGender ? setInfoModalDiffGender(false) : setInfoModalAnotherRoute(false)}}
              title={t("WARNING")}
              description={infoMessage}
            ></InformationModal>
          </Modal>
        </>
      }
    </>
  )
});

export default RideDetail;