import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useState } from "react";
import React, { useRef } from "react";
import { UsersService } from "data/services";
import { toastify } from "utils/toastify";

export default function ResetPasswordForm(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [passwordConfirmationVisibility, setPasswordConfirmationVisibility] = useState(false);
    const [serverError, setServerError] = useState("");

    const newPassword = useRef({});
    newPassword.current = watch("password", "");

    const togglePasswordVisibility = (e) => {
        setPasswordVisibility((previousVisibility) => !previousVisibility);
    }
    const togglePasswordConfirmationVisibility = (e) => {
        setPasswordConfirmationVisibility((previousVisibility) => !previousVisibility);
    }

    const onSubmit = async (data) => {
        setFormSubmitting(true);
        const resetPassword = await UsersService.resetPassword(data.password, props.token);
        setFormSubmitting(false);
        if (resetPassword === true) {
            setServerError("");
            toastify.success(t("SUCCESS_RESETING_PASSWORD"))
            navigate("/sign-in");
        } else {
            toastify.error(t("ERROR_RESETING_PASSWORD"))
            setServerError(t("ERROR_RESETING_PASSWORD"));
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-0 mt-md-4 text-start">
                <div className="mb-3">
                    <label className="form-label" style={{ fontSize: "14px", marginLeft: "5px" }} >{t("PASSWORD_INPUT")}</label>
                    <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-5px" }}>
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.75 9.58317V7.6665C5.75 4.49442 6.70833 1.9165 11.5 1.9165C16.2917 1.9165 17.25 4.49442 17.25 7.6665V9.58317" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.5001 17.7292C12.8233 17.7292 13.8959 16.6565 13.8959 15.3333C13.8959 14.0102 12.8233 12.9375 11.5001 12.9375C10.1769 12.9375 9.10425 14.0102 9.10425 15.3333C9.10425 16.6565 10.1769 17.7292 11.5001 17.7292Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.2917 21.0835H6.70841C2.87508 21.0835 1.91675 20.1252 1.91675 16.2918V14.3752C1.91675 10.5418 2.87508 9.5835 6.70841 9.5835H16.2917C20.1251 9.5835 21.0834 10.5418 21.0834 14.3752V16.2918C21.0834 20.1252 20.1251 21.0835 16.2917 21.0835Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                        <input
                            {...register("password", {
                                required: t("PASSWORD_IS_REQUIRED"),
                                minLength: {
                                    value: 4,
                                    message: t("PASSWORD_MIN_LENGTH", { length: 4 }),
                                }
                            })}
                            name="password"
                            placeholder={t("ENTER_PASSWORD")}
                            type={passwordVisibility ? "text" : "password"}
                            style={{ border: "none", marginLeft: "-5px" }} className="form-control bg-light" />
                        <i onClick={togglePasswordVisibility} style={{ color: "#9400D3" }} className={`fakepasswordicon fas ${passwordVisibility ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2 mt-1`}></i>
                    </div>
                    {errors.password && <small className="text-danger">{errors.password.message}</small>}
                </div>
                <div className="mb-3">
                    <label className="form-label" style={{ fontSize: "14px", marginLeft: "5px" }} >{t("PASSWORD_CONFRIM_INPUT")}</label>
                    <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-5px" }}>
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.75 9.58317V7.6665C5.75 4.49442 6.70833 1.9165 11.5 1.9165C16.2917 1.9165 17.25 4.49442 17.25 7.6665V9.58317" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.5001 17.7292C12.8233 17.7292 13.8959 16.6565 13.8959 15.3333C13.8959 14.0102 12.8233 12.9375 11.5001 12.9375C10.1769 12.9375 9.10425 14.0102 9.10425 15.3333C9.10425 16.6565 10.1769 17.7292 11.5001 17.7292Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.2917 21.0835H6.70841C2.87508 21.0835 1.91675 20.1252 1.91675 16.2918V14.3752C1.91675 10.5418 2.87508 9.5835 6.70841 9.5835H16.2917C20.1251 9.5835 21.0834 10.5418 21.0834 14.3752V16.2918C21.0834 20.1252 20.1251 21.0835 16.2917 21.0835Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                        <input
                            {...register("newPasswordConfirm", {
                                required: t("PASSWORD_CONFIRM_IS_REQUIRED"),
                                minLength: {
                                    value: 4,
                                    message: t("PASSWORD_MIN_LENGTH", { length: 4 }),
                                },
                                validate: value =>
                                    value === newPassword.current || t("NOT_MATCH_PASSWORDS")
                            })}
                            name="newPasswordConfirm"
                            placeholder={t("ENTER_CONFIRMATION_PASSWORD")}
                            type={passwordConfirmationVisibility ? "text" : "password"}
                            style={{ border: "none", marginLeft: "-5px" }} className="form-control bg-light" />
                        <i onClick={togglePasswordConfirmationVisibility} style={{ color: "#9400D3" }} className={`fakepasswordicon fas ${passwordConfirmationVisibility ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2 mt-1`}></i>
                    </div>
                    {errors.newPasswordConfirm && <small className="text-danger">{errors.newPasswordConfirm.message}</small>}
                </div>
                <div className="mb-3">
                    <p className="mb-0 mt-3" style={{ fontSize: "14px" }}>
                        {t("BACK_TO")}
                        <a style={{ fontWeight: "bold" }} className="purple cursor-pointer" onClick={() => navigate("/sign-in")}>
                            &nbsp; {t("SIGN_IN_RESET")}
                        </a>
                    </p>
                </div>
                {serverError && <small className="text-danger">{serverError}</small>}
                <div>
                    <button disabled={isFormSubmitting} type="submit" className="btn btn-primary w-100 mb-0 mt-4">
                        <div className="row" style={{ height: "30px" }}>
                            <div className="col" style={{ textAlign: "left", marginTop: "2px" }}>
                                {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                <span style={{ fontSize: "15px", fontWeight: "normal" }}>    {t("SUBMIT")}</span>
                            </div>
                            <div className="col-auto" style={{ marginTop: "2px" }}>
                                <i className="bi bi-chevron-right"></i>
                            </div>
                        </div>
                    </button>
                </div>
            </form>
        </>
    )
};