import { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toastify } from "utils/toastify";
import globalStore from "data/stores/GlobalStore";
import { AuthService, UsersService, ReportTypesService, ReportsService } from "data/services";

export default function ReportAddPage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();
  const [reportTypes, setReportTypes] = useState();
  const [users, setUsers] = useState();
  const [reporterUser, setReporterUser] = useState();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAndSetReportTypes = async () => {
      const reportTypesList = await ReportTypesService.getByParams({
        pageNumber: 1,
        pageSize: 9999
      });
      setReportTypes(() => {
        return reportTypesList.data.items.map(x => {
          return {
            value: x.id,
            label: x.name
          }
        })
      });
    };
    fetchAndSetReportTypes();
    const fetchAndSetReporterUser = async () => {
      const response = await AuthService.getCurrentUser();
      if (response) {
        setReporterUser(response);
      }
    };
    fetchAndSetReporterUser();
    const fetchAndSetReportedUser = async () => {
      const reportedUsersList = await UsersService.getByParams({
        pageNumber: 1,
        pageSize: 9999
      });
      setUsers(() => {
        return reportedUsersList.data.items.map(x => {
          return {
            value: x.id,
            label: x.firstName + " " + x.lastName
          }
        })
      });
    };
    fetchAndSetReportedUser();
  }, []);

  const onSubmit = async (data) => {
    setFormSubmitting(true);
    const apiModel = { reporterUserId: reporterUser.Id, ...data, typeId: data.reportTypeId };
    const newReport = await ReportsService.add(apiModel);
    setFormSubmitting(false);
    if (newReport) {
      navigate(`/admin/reports?reportTypeId=${newReport.reportTypeId}`);
      toastify.success(t("SUCCESSFULLY_ADDED_REPORT"),<img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png"/>);
    } else {
      toastify.error(t("ERROR_ADDING_REPORT"));
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-12 mb-4 mb-sm-5">
          <h1 className="h4 mb-0">{t("NEW_REPORT")}</h1>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card shadow">
          <div className="card-header border-bottom">
            <h5 className="card-header-title">{t("REPORT_INFORMATION")}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="mb-3">
                <label className="form-label">{t("REPORT_TYPE")}</label>
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                    })
                  }}
                  onChange={(e) => { setValue("reportTypeId", e.value) }}
                  className="basic-select"
                  placeholder={t("CHOOSE_REPORT_TYPE")}
                  options={reportTypes}
                />
                {errors.reportTypeId && <span className="text-danger">{t("REPORT_TYPE_IS_REQUIRED")}</span>}
              </div>

              <div className="mb-3">
                <label className="form-label">{t("NOTE")}</label>
                <input
                  {...register("note", {
                    required: t("NOTE_IS_REQUIRED"),
                  })}
                  type="text"
                  className="form-control"
                  placeholder={t("NOTE")}
                />
                {errors.note && <span className="text-danger">{t("NOTE_IS_REQUIRED")}</span>}
              </div>

              <div className="mb-3">
                <label className="form-label">{t("REPORTER_USER")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("REPORTER_USER")}
                  disabled={true}
                  value={reporterUser?.FirstName + " " + reporterUser?.LastName} />
              </div>

              <div className="mb-3">
                <label className="form-label">{t("REPORTED_USER")}</label>

                <Select
                  {...register("reportedUserId", {
                    required: t("REPORTED_USER_IS_REQUIRED"),
                  })}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                    })
                  }}
                  onChange={(e) => { setValue("reportedUserId", e.value) }}
                  className="basic-select"
                  placeholder={t("CHOOSE_REPORTED_USER")}
                  options={users}
                />
                {errors.reportedUserId && <span className="text-danger">{t("REPORTED_USER_IS_REQUIRED")}</span>}

              </div>

              <div className="d-flex justify-content-end mt-4">
                <button className="btn text-secondary border-0 me-2" onClick={() => navigate("/admin/reports")}>
                  {t("DISCARD")}
                </button>
                <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                  {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                  {t("SAVE")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
