import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import "../common.css"

export default function ActionRoute({ route, labelKey }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <ul style={{listStyle:"none", padding: "unset"}}>
            <li className="nav-css nav-item cursor-pointer">
                <a className={`nav-link ${location.pathname === route ? "active" : ""}`} onClick={() => navigate(route)}>
                    {t(labelKey)}
                </a>
            </li>
        </ul>
    );
};
