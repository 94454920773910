import { useState } from "react";
import { useTranslation } from "react-i18next";

const ConfirmationDialog = (props) => {
    const { t } = useTranslation();
    const [isConfirming, setIsConfirming] = useState(false);

    const handleDiscard = () => {
        props.onDiscard();
    }
    const handleConfirm = () => {
        props.onConfirm();
    }
    return (
        <>
            <div className="pb-2 h-75">
                <div className="border-bottom mb-2 pb-2">
                    <h5 className="card-header-title">{props.title}</h5>
                </div>
                <div className="d-flex flex-column justify-content-between mt-4 w-100" style={{height:"90%"}}>
                    <div className="mb-3 w-100">
                        <label className="form-label text-wrap">{props.description}</label><br />
                    </div>
                    <div className="align-items-end d-flex justify-content-end pt-2 w-100">
                        <button className="btn mb-0 text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                            {t("DISCARD")}
                        </button>
                        <button disabled={isConfirming} type="submit" className={props.btnType ? `btn ${props.btnType}` : `mb-0 btn btn-danger`} onClick={() => handleConfirm()}>
                            {isConfirming && <i className="fa fa-spin fa-spinner"></i>}
                            {props.confirmBtnLabel}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmationDialog;