import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import Rating from "@mui/material/Rating";
import { httpSmartClient } from 'config/httpClient';
import { toastify } from 'utils/toastify';
import notificationsStore from 'data/stores/NotificationsStore';
import { UserReviewsService, AuthService, ConnectionsService, NotificationsService, } from 'data/services';

function DriverReviewDialog(props) {
    const {
        formState: { errors },
        setValue,
        handleSubmit,
        clearErrors,
        setError
    } = useForm();
    const navigate = useNavigate();
    const [starValue, setStarValue] = useState(0);
    const user = AuthService.getCurrentUser();
    const { t } = useTranslation();
    const handleDiscard = () => {
        props.HandleShowDriverDialog();
    }
    const onSubmit = async (data) => {
        if (data.reviewRating == null) {
            setError("reviewRating", t("REVIEW_RATING_ERROR"))
            return;
        }
        if (data.reviewComment == null || data.reviewComment.trim()==='') {
            setError("reviewComment", t("REVIEW_COMMENT_ERROR"));
            return;
        }
        const response = await UserReviewsService.add({ UserReviewerId: user.Id, UserId: props.DriverReview.UserId, UserRideId: props.DriverReview.UserRideId, ...data })
        if (response.isSuccess) {
            const notificationToUser = {
                userId: response.data.userId,
                senderId: user.Id,
                text: 'ReviewOnUser',
                rideId: response.data.userRide.rideId,
                cityNameFrom: response.data.userRide.rideMilestoneFrom.cityName,
                cityNameTo: response.data.userRide.rideMilestoneTo.cityName,
                userModelReviewOnUserId: props.DriverReview.UserId
            };
            const toUser = await NotificationsService.add(notificationToUser);
            if (toUser.isSuccess) {
                await ConnectionsService.InvokeNewReview({ ...toUser.data, isReview: true })
            }
            const notificationToMyself = {
                userId: user.Id,
                senderId: 2,
                text: "ReviewOnUserToMySelf",
                rideId: response.data.userRide.rideId,
                cityNameFrom: response.data.userRide.rideMilestoneFrom.cityName,
                cityNameTo: response.data.userRide.rideMilestoneTo.cityName,
                userModelReviewOnUserId: props.DriverReview.UserId
            }
            const toMySelf = await NotificationsService.add(notificationToMyself)
            notificationsStore.notify({ ...toMySelf.data, isReviewOnUser: true })
            navigate(`/user/${props.DriverReview.UserId}/ratings`, { state: { navigateFromDialog: true } })
            toastify.success(t("SUCCESS_REVIEW"))
        } else {
            toastify.error(t("ERROR_REVIEW"))
        }
    }
    return (
        <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
                <div className="card shadow w-25">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title d-inline">
                            <img className='image-custom' src={props.DriverReview.User.profilePhotoId != null ? httpSmartClient.getPhotoUrl(props.DriverReview.User.profilePhotoId) : "/assets/images/avatar/01.jpg"} />
                            {" " + props.DriverReview.User.firstName + " " + props.DriverReview.User.lastName}
                        </h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='col-md-12 col-sm-12 d-flex flex-column mb-2'>
                                <label>{t("RATE_PASSENGER")}</label>
                                <Rating
                                    name="simple-controlled"
                                    value={starValue}
                                    onChange={(event, newValue) => {
                                        clearErrors("reviewRating");
                                        setStarValue(newValue);
                                        setValue("reviewRating", newValue)
                                    }}
                                />
                                {errors.reviewRating && <span className="text-danger d-block mb-2">{t("REVIEW_PASSENGER_RATING_ERROR")}</span>}
                            </div>

                            <div className='col-md-12 col-sm-12'>
                                <label>{t("RATE_PASSENGER_COMMENT")}</label>
                                <textarea
                                    type='text'
                                    className='form-control'
                                    onChange={(e) => {
                                        clearErrors("reviewComment");
                                        setValue("reviewComment", e.target.value)
                                    }}
                                ></textarea>
                                {errors.reviewComment && <span className="text-danger d-block">{t("REVIEW_PASSENGER_COMMENT_ERROR")}</span>}
                            </div>
                            <div className="d-flex justify-content-end mt-4">
                                <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                                    {t("DISCARD")}
                                </button>
                                <button type="submit" className="btn btn-primary" >
                                    {t("SAVE")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DriverReviewDialog
