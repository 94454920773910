import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Box, Slider } from "@mui/material";
import "../catalogPage/CatalogSideFilters.css"
import { useNavigate, useSearchParams } from "react-router-dom";
import { CatalogFiltersService } from "data/services";
import catalogStore from "data/stores/CatalogStore";

export default function CatalogSideFilters() {
  const refOrderBy = useRef([]);
  const refDepartureTime = useRef([]);
  const refConditions = useRef([]);
  const { t } = useTranslation();
  const [filter, setFilter] = useState({});
  const [orderBy, setOrderBy] = useState([]);
  const [departureTime, setDepartureTime] = useState([]);
  const [conditionToApi, setConditonToApi] = useState();
  const [conditions, setConditions] = useState([]);
  const [checked, setChecked] = useState([]);
  const [orderByIndex, setOrderByIndex] = useState([]);
  const [departureIndex, setDepartureIndex] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [value, setValue] = useState([0, 500]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const [url, setUrl] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [orderByCheck, setOrderByCheck] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setUrl({
      pageNumber: 1,
      pageSize: 9999,
      cityNameFrom: currentParams.cityNameFrom,
      cityNameTo: currentParams.cityNameTo,
      startDate: currentParams.startDate,
      passengersCount: currentParams.passengersCount,
    })
  }, [searchParams])

  const handleChangeFunction = async () => {
    const queryString = {
      cityNameFrom: url.cityNameFrom,
      cityNameTo: url.cityNameTo,
      startDate: url.startDate ? `&startDate=${url.startDate}` : ``,
      passengersCount: url.passengersCount,
    }
    const conditionIdsStrings = conditions.map(id => `conditionsIds=${id}`);
    const combinedConditionIds = conditionIdsStrings.join('&');
    const final = '&' + combinedConditionIds;
    const fromHour = catalogStore.filteredData && catalogStore.filteredData.fromHour != null ? `&fromHour=${catalogStore.filteredData.fromHour}` : ``;
    const toHour = catalogStore.filteredData && catalogStore.filteredData.toHour != null ? `&toHour=${catalogStore.filteredData.toHour}` : ``;
    const minPrice = catalogStore.filteredData && catalogStore.filteredData.minPrice && catalogStore.filteredData.minPrice != 0 ? `&minPrice=${catalogStore.filteredData.minPrice}` : `&minPrice=0`;
    const maxPrice = catalogStore.filteredData && catalogStore.filteredData.maxPrice && catalogStore.filteredData.maxPrice != 500 ? `&maxPrice=${catalogStore.filteredData.maxPrice}` : `&maxPrice=500`;
    const listingOrder = catalogStore.filteredData && catalogStore.filteredData.orderBy != null ? `&listingOrder=${catalogStore.filteredData.orderBy}` : ``;
    navigate({ pathname: '/rides', search: `cityNameFrom=${queryString.cityNameFrom}&cityNameTo=${queryString.cityNameTo}${queryString.startDate}&passengersCount=${queryString.passengersCount}${fromHour}${toHour}${combinedConditionIds.length > 0 ? final : combinedConditionIds}${minPrice}${maxPrice}${listingOrder}` });
  }

  const setFilters = () => {
    setFilter({
      ...filter, orderBy, departureTime, conditions
    });
  };

  const removeFilters = () => {
    setFilter({
      ...filter, price: null, models: null, seats: null
    })
    const filterData = { ...catalogStore.filteredData, orderBy: null, fromHour: null, toHour: null, conditionsIds: null };
    catalogStore.setFilters(filterData);
  }

  const clearAll = () => {
    setDefaultCheckedIndex();
    setOrderByCheck();
    for (let i = 0; i < refOrderBy.current.length; i++) {
      setOrderByIndex([]);
      refOrderBy.current[i].checked = false;
    };
    for (let i = 0; i < refDepartureTime.current.length; i++) {
      refDepartureTime.current[i].checked = false;
      setDepartureIndex([]);
    };
    for (let i = 0; i < refConditions.current.length; i++) {
      setChecked(checked.filter((i) => { return (false) }));
      setConditions([]);
    };
    removeFilters();
    setValue([0, 500]);
    const filterData = {
      ...catalogStore.filteredData, minPrice: 0, maxPrice: 500
    };
    catalogStore.setFilters(filterData);
    navigate({ pathname: '/rides', search: `cityNameFrom=${url.cityNameFrom}&cityNameTo=${url.cityNameTo}${url.startDate != null ? `&startDate=${url.startDate}` : ``}&passengersCount=${url.passengersCount}` });
  };

  const handleCheckboxChangeOrderBy = (e, index) => {
    if (e.target.checked) {
      setOrderBy([e.target.value]);
      setOrderByIndex([index]);
      setOrderByCheck(index);
      const filterData = { ...catalogStore.filteredData, orderBy: index };
      catalogStore.setFilters(filterData);
    }
    else {
      setOrderBy([]);
      setOrderByIndex([]);
      const filterData = { ...catalogStore.filteredData, orderBy: null };
      catalogStore.setFilters(filterData);
    }
    setFilters();
  };

  const handleCheckboxChangeDeparture = (e, time, index) => {
    if (e.target.checked) {
      setDepartureTime([e.target.value]);
      setDepartureIndex([index]);
      setDefaultCheckedIndex(index)
      const filterData = { ...catalogStore.filteredData, fromHour: time.fromHour, toHour: time.toHour };
      catalogStore.setFilters(filterData);
    }
    else {
      setDepartureTime([]);
      setDepartureIndex([]);
      const filterData = { ...catalogStore.filteredData, fromHour: null, toHour: null };
      catalogStore.setFilters(filterData);
    }
    setFilters();
  };
  const handleCheckboxChangeConditions = (e, value) => {
    if (e.target.checked) {
      setConditions([...conditions, value]);
      setChecked([...checked, value]);
    }
    else {
      setConditions(conditions.filter(cond => cond !== value));
      setChecked(checked.filter((i) => { return (i !== value) }));
      const filterData = { ...catalogStore.filteredData, conditionsIds: null };
      catalogStore.setFilters(filterData);
    }
    setFilters();
  };

  useEffect(() => {
    const fetchAndSetFilters = async () => {
      const Values = await CatalogFiltersService.getFilters();
      setFilterValues(Values);
    };
    fetchAndSetFilters();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const filterData = { ...catalogStore.filteredData, minPrice: newValue[0], maxPrice: newValue[1] };
    catalogStore.setFilters(filterData);
  };

  const defaultFromHour = searchParams.get('fromHour');
  const defaultToHour = searchParams.get('toHour');
  const minPrice = searchParams.get('minPrice');
  const maxPrice = searchParams.get('maxPrice');
  const conditionsIds = searchParams.getAll('conditionsIds');
  const orderbyIndex = searchParams.get('listingOrder');

  const isDefaultChecked = (time) => {
    const result = time.fromHour == defaultFromHour && time.toHour == defaultToHour;
    return result;
  };

  const [defaultCheckedIndex, setDefaultCheckedIndex] = useState(null);

  useEffect(() => {
    if (defaultFromHour !== null && defaultToHour !== null) {
      const index = filterValues.availableDepartureTimes && filterValues.availableDepartureTimes.findIndex(time => isDefaultChecked(time));
      if (index !== -1) {
        setDefaultCheckedIndex(index);
      }
    }
    if ((minPrice != null || minPrice != undefined) && (maxPrice != null || maxPrice != undefined)) {
      setValue([minPrice, maxPrice]);
    }
    else {
      setValue([0, 500]);
    }
    if (conditionsIds != null) {
      setConditions(() => {
        return conditionsIds.map(x => {
          return parseInt(x);
        })
      })
      setChecked(() => {
        return conditionsIds.map(x => {
          return parseInt(x);
        })
      });
    }
    if (orderbyIndex != null) {
      setOrderByCheck(orderbyIndex);
    }
  }, [filterValues.availableDepartureTimes, defaultFromHour, defaultToHour]);

  return (
    <>
      <div className="card">
        <div className="row clearfix ">
          <div className="card-filter filter-app " >
            <div id="filters" className="filter-list " >
              <div className="col-lg-12 col-xl-12 h-100">
                <div className="offcanvas-xl offcanvas-end h-100" tabIndex="-1" id="offcanvasSidebarFilters">
                  <div className="offcanvas-header pb-2 mt-2">
                    <h5 className="mb-1" id="offcanvasSidebarLabelFilters" style={{ fontSize: "18px", color: "black", marginTop: "5px", fontWeight: "bold", marginLeft: "3px" }}><svg width="24" height="24" style={{ marginRight: "5px" }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="filter">
                        <path id="Vector" d="M4.95052 1.92529H17.0505C18.0589 1.92529 18.8839 2.75029 18.8839 3.75863V5.77529C18.8839 6.50863 18.4255 7.42529 17.9672 7.88363L14.0255 11.367C13.4755 11.8253 13.1089 12.742 13.1089 13.4753V17.417C13.1089 17.967 12.7422 18.7003 12.2839 18.9753L11.0005 19.8003C9.80885 20.5336 8.15885 19.7086 8.15885 18.242V13.3836C8.15885 12.742 7.79219 11.917 7.42552 11.4586L3.94219 7.79196C3.48385 7.33363 3.11719 6.50863 3.11719 5.95863V3.85029C3.11719 2.75029 3.94219 1.92529 4.95052 1.92529Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path id="Vector_2" d="M10.0192 1.92529L5.5 9.16696" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                      {t("FILTERS")}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebarFilters" aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body flex-column p-3 p-xl-0 ">
                    <div className="card mb-5 mb-md-0" style={{boxShadow:"rgba(29, 58, 83, 0.1) 0px 10px 20px"}}>
                      {!isMobile && <h5 className="mb-1 mt-4" style={{marginLeft:"1.4rem", fontSize: "18px", color: "black", marginTop: "5px", fontWeight: "bold"}}><svg width="24" height="24" style={{ marginRight: "5px" }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="filter">
                          <path id="Vector" d="M4.95052 1.92529H17.0505C18.0589 1.92529 18.8839 2.75029 18.8839 3.75863V5.77529C18.8839 6.50863 18.4255 7.42529 17.9672 7.88363L14.0255 11.367C13.4755 11.8253 13.1089 12.742 13.1089 13.4753V17.417C13.1089 17.967 12.7422 18.7003 12.2839 18.9753L11.0005 19.8003C9.80885 20.5336 8.15885 19.7086 8.15885 18.242V13.3836C8.15885 12.742 7.79219 11.917 7.42552 11.4586L3.94219 7.79196C3.48385 7.33363 3.11719 6.50863 3.11719 5.95863V3.85029C3.11719 2.75029 3.94219 1.92529 4.95052 1.92529Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path id="Vector_2" d="M10.0192 1.92529L5.5 9.16696" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                      </svg>
                        {t("FILTERS")}</h5>}
                      <div className="card card-body d-xl-none d-xxl-none ps-4 pb-0">
                        <h5 className="mb-2" id="offcanvasSidebarLabelFilters" style={{ fontSize: "14px", fontWeight: "600", color: "black" }}>{t("ORDER_BY_FILTERS")}</h5>
                        {filterValues.availableOrderings && filterValues.availableOrderings.map((ordering, index) => {
                          return (
                            <div key={index} className="form-check mb-2">
                              <input
                                value={ordering}
                                id={`order${index}`}
                                checked={orderByCheck == index}
                                onChange={(e) => (handleCheckboxChangeOrderBy(e, index))}
                                className="form-check-input" type="radio"
                                ref={(element) => { refOrderBy.current[index] = element }}
                                readOnly
                              />
                              <label htmlFor={`order${index}`} className="form-check-label" style={{fontSize: "14px", fontWeight: "500"}}>{t(`${ordering}`)}</label>
                            </div>
                          )
                        })}
                      </div>

                      <div className="card card-body rounded-3 pb-0">
                        <h5 className="mb-2" id="offcanvasSidebarLabelFilters" style={{marginLeft:"4px", fontSize: "14px", fontWeight: "600", color: "black" }}>{t("PRICE")}</h5>
                        <Box sx={isMobile? {width:"88%"} : { width: "91%" }} style={{marginLeft:"auto", marginRight:"auto"}}>
                          <div className="noui-wrapper mb-2" style={{marginLeft:"-9px", marginRight:"-9px"}}>
                            <div className="d-flex justify-content-between" style={{ marginBottom: "-7px" }}>
                              <input type="text" style={{ fontWeight: "bold", fontSize: "13px" }} className="text-body input-with-range-min gray" value={value[0] + " KM"} readOnly/>
                              <input type="text" style={{ fontWeight: "bold", fontSize: "13px" }} className="text-body input-with-range-max ms-3 gray" value={value[1] + " KM"} readOnly/>
                            </div>
                          </div>
                          <Slider
                            value={value}
                            className="slider-custom"
                            onChange={handleChange}
                            min={0}
                            max={500}
                            color="secondary"
                          ></Slider>
                        </Box>
                      </div>
                      <div className="card card-body ps-4 pb-0">
                        <h5 className="mb-2" id="offcanvasSidebarLabelFilters" style={{ fontSize: "14px", fontWeight: "600", color: "black" }}>{t("DEPARTURE_TIME")}</h5>
                        {filterValues.availableDepartureTimes && filterValues.availableDepartureTimes.map((time, index) => {
                          return (
                            <div key={index} className="form-check mb-2">
                              <input
                                value={time}
                                id={`departure${index}`}
                                checked={defaultCheckedIndex == index}
                                onChange={(e) => (handleCheckboxChangeDeparture(e, time, index))}
                                className="form-check-input " type="radio"
                                ref={(element) => { refDepartureTime.current[index] = element }}
                                readOnly
                              />
                              <label htmlFor={`departure${index}`} className="form-check-label" style={{ fontSize: "14px", fontWeight: "500" }} >{time.fromHour == 0 || time.fromHour == 9 ? `0${time.fromHour}` : time.fromHour}:00 - {time.toHour == 9 ? `0${time.toHour}` : time.toHour}:00</label>
                            </div>
                          )
                        })}
                      </div>
                      <div className="card ps-4 card-body">
                        <h5 className="mb-2" id="offcanvasSidebarLabelFilters" style={{ fontSize: "14px", fontWeight: "600", color: "black" }}>{t("CONDITIONS")}</h5>
                        {filterValues.availableConditions && filterValues.availableConditions.map((condition, index) => {
                          return (
                            <div key={index} className="form-check mb-2">
                              <input
                                value={condition.id}
                                id={`condition${index}`}
                                checked={checked.includes(condition.id)}
                                onChange={(e) => handleCheckboxChangeConditions(e, condition.id)}
                                className="form-check-input"
                                type="checkbox"
                                ref={(element) => {
                                  refConditions.current[index] = element;
                                }}
                                style={{ accentColor: "RGB(146, 15, 205)" }}
                                readOnly
                              />
                              <label htmlFor={`condition${index}`} style={{ fontSize: "14px", fontWeight: "500" }} className="form-check-label ps-2">{t(condition.name)}</label>
                            </div>
                          )
                        })}
                      </div>
                      <div className="row mt-4" style={isMobile ? {width:"82%", marginLeft:"auto", marginRight:"auto"} : {width:"83%", marginLeft:"auto", marginRight:"auto"}}>
                        <button className="col btn btn-primary mb-1" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebarFilters" aria-label="Close" style={{ fontSize: "14px", paddingTop: "12px", paddingBottom: "12px" }} onClick={() => handleChangeFunction()}>{t("FILTER_RESULT")}</button>
                      </div>
                      <div className="row ms-3 me-3">
                        <button className="col btn btn-link mb-3" style={{ fontSize: "14px", fontWeight: "bold" }} onClick={clearAll}>{t("CLEAR_FILTERS")}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}  