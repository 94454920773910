import React, { useEffect, useState, } from 'react'
import HeroSearchComponent from '../home/hero/HeroSearchComponent';
import CatalogBody from '../catalogPage/CatalogBody';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import heroSearch from 'data/stores/HeroSearchStore';
import { useSearchParams } from 'react-router-dom';
import { RideMilestonesService } from 'data/services';

const CatalogFilter = observer(() => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const [IsTablet, setIsTablet] = useState(window.innerWidth <= 992);
  const currentParams = Object.fromEntries([...searchParams]);
  const [countryFlags, setCountryFlags] = useState();
  const [previousSearchParams, setPreviousSearchParams] = useState();
  const [showDiv1, setShowDiv1] = useState(true);
  const [fixDiv2, setFixDiv2] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setShowDiv1(false);
      setFixDiv2(true);
    } else {
      setShowDiv1(true);
      setFixDiv2(false);
    }
  };

 useEffect(() => {
    const handleScroll = () => {
      setIsScrollingUp(window.scrollY < lastScrollY);
      lastScrollY = window.scrollY;
    };

    let lastScrollY = window.scrollY;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const search = {
      cityNameFrom: currentParams.cityNameFrom,
      cityNameTo: currentParams.cityNameTo
    }
    if (countryFlags == null) {

      const fetchAndSetFlags = async () => {
        const response = await RideMilestonesService.getFlags(search);
        if (response.isSuccess) {
          setCountryFlags(response.data)
        }
      }
      fetchAndSetFlags();
    }
    setPreviousSearchParams(searchParams);

    if (searchParams.size == 0) {
      heroSearch.setFilters(null);
    }
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
      setIsTablet(window.innerWidth >= 992 &&  window.innerWidth <= 1200);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {!isMobile ? <div style={{ position: "relative", top: IsTablet ? "-20px": "-40px" }} className="bg-purple mw-100">
        <section className="container  p-0">
          <div className="row g-4 g-lg-5 justify-content-center bg-purple pt-lg-8 pt-5" >
            <div style={{ position: "relative", maxWidth: "90%", maxHeight: "100%", }} className=" justify-content-center mt-0  pb-5 px-0">
              <div className="container p-0 m-0 mw-100" >
                <HeroSearchComponent colN={12} />
              </div>
            </div>
          </div>
        </section>
      </div> : 
        <section className="container p-0 fade-in">
          <div className='bg-purple' style={{ maxWidth: "100%", height: "75px", marginTop: "-65px" }}></div>
          <div className='bg-light w-100' style={{borderRadius:"1.5em"}}>
          <div className="row g-4 g-lg-5 justify-content-center bg-light pt-lg-8 pt-5" style={{width: "90%", position: "relative", marginLeft: "5%", marginRight: "5%", borderTopRightRadius: "40px", borderTopLeftRadius: "40px" }} >
            <div style={{ marginTop: "-65px" }} className=" justify-content-center pb-5 px-0">
              <HeroSearchComponent colN={12} />
            </div>
          </div>
          </div>
        </section> 
      }
      <div className={isMobile ? 'container px-0 fade-in' : 'container px-4 fade-in'} style={{marginTop:"-20px"}}>
        {searchParams.size != 0 && <CatalogBody></CatalogBody>}
        {searchParams.size == 0 &&
          <div className="row mb-6">
          <div className="col"></div>
          <div className="col-auto text-center"> 
            <img src="/assets/images/search.svg" style={{ width: "300px", height: "300px", marginRight:"15px" }} />
          </div>
          <div className="col"></div>
          <h4 className="mt-0 text-center" style={{ color: "purple", fontWeight: "bold", fontSize: "24px", width:"90%", margin:"auto" }}>  {t("SEARCH_RIDES")}</h4>
        </div>
        }
      </div>
    </div>
  );
})
export default CatalogFilter;