import { action, makeObservable, observable } from "mobx";

class HeroSearchStore {
  filteredData = null;

  constructor() {
    makeObservable(this, {
      filteredData: observable,
      setFilters: action
    });

    var fromLocalStorage = localStorage.getItem("searchParams");

    if (fromLocalStorage) {
      try {
        var searchParamsParsed = JSON.parse(fromLocalStorage);
        this.filteredData = searchParamsParsed;
        
      } catch (error) {
      }
    }
  }

  setFilters(filter) {
    this.filteredData = filter;
    localStorage.setItem("searchParams",JSON.stringify(this.filteredData));
  }

}

const heroSearch = new HeroSearchStore();
export default heroSearch;