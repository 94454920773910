import { default as i18n } from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./languages/en.json";
import de from "./languages/de.json";
import ba from "./languages/ba.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      ba: {
        translation: ba,
      }
    },
    lng : localStorage. getItem('i18nextLng') || 'ba'
  });

  

export default i18n;
