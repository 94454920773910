import { httpSmartClient } from "../../config/httpClient";

const baseName = "UserReviews";

const UserReviewsService = {
  add: async (userRide) => {
    var response = await httpSmartClient.post(`/${baseName}`, userRide);
    return response;
  },
  edit: async (userRide) => {
    var response = await httpSmartClient.put(`/${baseName}`, userRide);
    return response;
  },
  getByDriverId: async (filter) => {
    var response = await httpSmartClient.getById(`/${baseName}/GetByDriverId`, { params: { ...filter } });
    return response;
  },
  getById: async (id) => {
    var response = await httpSmartClient.getById(`/${baseName}/${id}`);
    return response;
  },
  delete: async (id) => {
    var response = await httpSmartClient.delete(`/${baseName}/${id}`);
    return response;
  },
  getReviewsForProfileChart: async (userId) => {
    var response = await httpSmartClient.get(`/${baseName}/GetReviewsForProfileChart?userId=${userId}`);
    return response.data;
  },
  getAllForUser: async (params) => {
    var response = await httpSmartClient.get(`/${baseName}/GetAllForUser`, {params});
    return response;
  }
}

export default UserReviewsService;
