import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import userProfileStore from 'data/stores/UserProfileStore';
import { UsersService,AuthService } from 'data/services';
import { toastify } from 'utils/toastify';
import { useTranslation } from 'react-i18next';

const MyChangeMail = observer(() => {
    const { t } = useTranslation();
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    let user = userProfileStore.user;
    const navigate = useNavigate();

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError
    } = useForm();

    const onSubmit = async (data) => {
        setFormSubmitting(true);
        if (user.email !== data.email) {
            const response = await UsersService.edit({ ...user, email: data.email });
            setFormSubmitting(false);
            if (response.statusCode == 409) {
                toastify.error(t("USER_SAME_EMAIL_EXISTS"));
                return;
            }
            else if (response.isSuccess) {
                const logOutUser = async () => {
                    toastify.success(t("SUCCESSFULLY_UPDATED_EMAIL"));
                    await AuthService.signOut();
                    navigate("/sign-in");
                }
                logOutUser();
            }
            else toastify.error(t("EMAIL_UPDATE_ERROR"));
        } else {
            setFormSubmitting(false);
            toastify.error(t("EMAIL_SAME"))
        }
    }

    return (
        <div className="card border">
            <div className="card-header border-bottom">
                <h4 className="card-header-title">{t("CHANGE_EMAIL")}</h4>
                <p className="mb-0">{t("CURRENT_EMAIL")} <span className="text-primary purple">{user.email}</span></p>
            </div>

            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <label className="form-label">{t("NEW_EMAIL")}<span className="text-danger">*</span></label>
                    <input {...register("email", {
                        required: t("EMAIL_IS_REQUIRED"),
                        validate: {
                            maxLength: (v) =>
                                v.length <= 50 || t("EMAIL_MORE_THAN_50"),
                            matchPattern: (v) =>
                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || t("EMAIL_NOT_VALID"),
                        },
                    })}
                        className="form-control"
                        placeholder={t("EMAIL")}
                    />
                    {errors.email && <span className="text-danger">{t("EMAIL_IS_REQUIRED")}</span>}
                    <div className="text-end mt-3">
                        <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                            {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                            {t("UPDATE_EMAIL")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
});

export default MyChangeMail;