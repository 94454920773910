import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import UsersService from '../../../../data/services/UsersService';
import { httpSmartClient } from '../../../../config/httpClient';
import { useTranslation } from "react-i18next";
import toastify from '../../../../utils/toastify/toastify';
import moment from 'moment';
import { useWindowSize } from '@uidotdev/usehooks';
import Select, { components, Props } from 'react-select';
import PagingComponent from "../../../layout/components/PagingComponent";
import UserReviewsService from '../../../../data/services/UserReviewsService';
import { colors } from '@mui/material';
import { reactSelectCustomStyle } from '../../../../assets/styles/react-select-customstyle';
import LoadMoreText from './components/LoadMoreText';


export default function UserProfileReviewsMobile() {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { t } = useTranslation();
    const [user, setUser] = useState();
    const [chartInfo, setChartInfo] = useState();
    const [searchParams, setSearchParams] = useSearchParams("");
    var tranCode = searchParams.get("TranCode");
    const [reviews, setReviews] = useState();
    const [pagingData, setPagingData] = useState({ pageNumber: 1, pageSize: 5 });
    const [sortingOrder, setSortingOrder] = useState(null);
    const [ratingNumber, setRatingNumber] = useState(null);
    const [sortBySelect, setSortBySelect] = useState();
    const sortBy = [{ value: "null", label: t("CHOOSE") }, { value: "DESC", label: t("NEWEST") }, { value: "ASC", label: t("OLDEST") }];

    const getReviews = async () => {
        const params = { ...pagingData, userId: userId, orderType: sortingOrder, ratingNumber: ratingNumber }
        const response = await UserReviewsService.getAllForUser({ ...params });
        if (response && response.data) {
            setReviews(response.data);
            setPagingData(prev => {
                return {
                    ...prev,
                    totalCount: response.data.totalCount
                }
            });
        }
    }
    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
        getReviews();
    }

    useEffect(() => {
        getReviews();
    }, [sortingOrder, ratingNumber]);

    useEffect(() => {
        const fetch = async () => {
            const response = await UsersService.getById(userId);
            if (response.isSuccess) {
                let dri = response.data;
                if (dri.profilePhoto) {
                    dri.profilePhotoUrl = httpSmartClient.getPhotoUrlObjectFromBase64(dri.profilePhoto);
                }
                else if (dri.profilePhotoId) {
                    dri.profilePhotoUrl = httpSmartClient.getPhotoUrl(dri.profilePhotoId);
                }
                setUser(dri);
            }
            else {
                navigate("/*")
            }
        }
        const getChartInfo = async () => {
            const response = await UserReviewsService.getReviewsForProfileChart(userId);
            if (response) {
                setChartInfo(response);
            }
        }
        fetch();
        getChartInfo();
    }, []);


    useEffect(() => {
        const element = document.getElementsByClassName("user-info-mobile-background")
        Array.from(element).forEach(x => {
            x.className = "user-info-mobile-background h-100 w-100"
        })
        return () => {
            Array.from(element).forEach(x => {
                x.classList.remove("h-100");
                x.classList.remove("user-info-mobile-background");
                x.classList.remove("w-100");
            })
        }
    }, []);

    const handleSorting = (e) => {
        setSortBySelect(e.value);
        setSortingOrder(e.value);
    }

    return (
        <>
            <div className='row' style={{ height: "90px", backgroundColor: "#900FC8", marginTop: "-70px" }} >
                <div className='col'>
                </div>
            </div>
            <div className='bg-light' style={{ marginTop: "-30px", position: "relative", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
                <div className='row justify-content-center w-90 p-0 m-auto' style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
                    {user && <div style={{ zIndex: "100", marginTop: "-20px"}} className='col-12 pb-2'>
                        <div style={{ marginTop: "-30px" }} className='card p-3 row justify-content-center shadow '>
                            <div className="col-12 d-flex px-0 justify-content-start  mb-2 mb-sm-0">
                                <div className='cursor-pointer' onClick={() => navigate("/user/" + user.id)} style={{ position: "relative", display: "inline-block", height: "80px", width: "80px", minWidth: "80px" }}>
                                    <img width={"100%"} height={"100%"} style={{ display: "block" }} className={`avatar-img ${user.isVerifiedPhoneNumber ? 'profile__photo__isVerified' : ''} rounded-circle `} src={user && user.profilePhotoId ? user.profilePhotoUrl : "/assets/images/avatar/01.jpg"} alt="no profile picture" />
                                    {user.isVerifiedPhoneNumber && <img style={{ height: "15px", position: "absolute", top: "50px", left: "54px" }} src='../../assets/images/verified.png'></img>}
                                </div>
                                {chartInfo && <div className='' >
                                    <p onClick={() => navigate("/user/" + user.id)} className='fw-600 fs18 m-2 black cursor-pointer mb-1 col-12' >{user != null && user.firstName + " " + user.lastName}</p>
                                    <div className=' row col-12 p-0  ms-2 align-items-center'>
                                        <ul className="list-inline col-auto px-0 mb-0">
                                            {[...Array(Math.floor(chartInfo.averageRatings))].map((x, i) => (
                                                <li key={i} className="list-inline-item small me-1"><i className="fas fa-star text-warning"></i></li>
                                            ))}
                                            {(chartInfo.averageRatings - Math.floor(chartInfo.averageRatings)) >= 0.5 && [...Array(1)].map((x, i) => (
                                                <li key={i} className="list-inline-item small me-1"><i className="fas fa-star-half-stroke text-warning"></i></li>
                                            ))}
                                            {(chartInfo.averageRatings - Math.floor(chartInfo.averageRatings)) == 0.5 ? [...Array(Math.floor(5 - chartInfo.averageRatings))].map((x, i) => (
                                                <li key={i} className="list-inline-item small me-1"><i className="far fa-star text-warning"></i></li>
                                            )) :
                                                [...Array(Math.round(5 - chartInfo.averageRatings))].map((x, i) => (
                                                    <li key={i} className="list-inline-item small me-1"><i className="far fa-star text-warning"></i></li>
                                                ))}
                                        </ul>
                                        <p style={{ color: "#7E7E7E" }} className='fs18 col-auto m-0 ps-1 d-inline fw-600 px-0' >{chartInfo && chartInfo.averageRatings.toFixed(1)} {t("OUT_OF")} 5</p>
                                        <p style={{ color: "#7E7E7E" }} className='fs16 fw-500 pt-1 col-auto m-0 ps-0 pe-0 d-inline'>{t("TOTAL_REVIEWS")}: {chartInfo && chartInfo.ratingsTotal}</p>
                                    </div>
                                </div>}
                            </div>
                            <hr style={{ border: "1px solid grey", width: "90%" }} className='my-3'></hr>
                            <div className="m-0 p-0 px- 1">
                                <div className='d-flex flex-column' >
                                    {chartInfo && chartInfo.userReviewsChartModel.map((e, i) => (
                                        <button key={i} onClick={() => { ratingNumber === e.rating ? setRatingNumber(null) : setRatingNumber(e.rating) }} className={`${ratingNumber === e.rating ? 'clicked' : ''}  d-flex p-2   review align-items-center gap-1`}>
                                            <svg className='col-auto p-0 me-1' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18" fill="none">
                                                <path className={`${ratingNumber == e.rating ? "star" : ""}`} d="M10.5549 1.29976L12.1387 4.49359C12.3547 4.93818 12.9306 5.36463 13.4166 5.44629L16.2872 5.92718C18.123 6.23567 18.555 7.57853 17.2321 8.90325L15.0004 11.1534C14.6224 11.5345 14.4154 12.2695 14.5324 12.7957L15.1714 15.5812C15.6753 17.7861 14.5144 18.639 12.5796 17.4867L9.88895 15.8807C9.403 15.5903 8.60209 15.5903 8.10714 15.8807L5.41644 17.4867C3.49065 18.639 2.32078 17.777 2.82473 15.5812L3.46366 12.7957C3.58064 12.2695 3.37367 11.5345 2.99571 11.1534L0.763955 8.90325C-0.5499 7.57853 -0.126947 6.23567 1.70885 5.92718L4.57953 5.44629C5.05648 5.36463 5.63242 4.93818 5.84839 4.49359L7.43222 1.29976C8.29612 -0.433253 9.69997 -0.433253 10.5549 1.29976Z" fill="lightgrey" />
                                            </svg>
                                            <p style={{ color: "#7E7E7E" }} className='mb-0 fs18 fw-600' >{e.rating}</p>
                                            <div style={{ width: e.percentage + (e.ratingCount == 0 ? 5 : 0) + "%" }} className=' mx-2'>
                                                <div className={`reviews  ${e.rating === 5 ? 'five' : e.rating === 4 ? 'four' : e.rating === 3 ? 'three' : e.rating === 2 ? 'two' : 'one'}`}></div>
                                            </div>
                                            <p style={{ color: "#7E7E7E" }} className='mb-0 fw-600 fs15' >{e.ratingCount}</p>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="col-12 p-0 mb-4">
                        {reviews && reviews.items.length > 0 ? <div className="row mb-1">
                            <div className='col-6 d-flex  align-items-center '>
                                <h5 style={{ fontSize: "14px", color: "black", fontWeight: "500" }} className="ms-2  mb-1 fw-700">{t("TOTAL_REVIEWS") + ": "} {reviews && pagingData.totalCount}</h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <div className='d-flex  align-items-center '>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                        <path d="M6.38201 14.5209L2.82617 10.9722" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.38281 2.47925V14.5209" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.6172 2.47925L14.173 6.028" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.6172 14.5209V2.47925" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <Select
                                        value={sortBy.filter(s => s.value === sortBySelect)}
                                        options={[
                                            { value: "null", label: t("CHOOSE") },
                                            { value: "DESC", label: t("NEWEST") },
                                            { value: "ASC", label: t("OLDEST") }
                                        ]}
                                        styles={reactSelectCustomStyle}
                                        placeholder={t("CHOOSE")}
                                        className="basic react-select-price"
                                        components={{ SingleValue: ({ children, ...props }) => (<components.SingleValue {...props}>{children}</components.SingleValue>) }}
                                        onChange={(e) => handleSorting(e)}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                        </div> : <div className='container text-center '><p className='small mt-5 fw-500'>{t("NO_REVIEWS")}</p></div>}
                        <div className='row justify-content-center '>
                            {reviews && reviews.items.map((review, i) => (
                                <div key={i} className='col-11 mb-3  card shadow'>
                                    <div className='d-flex p-1 pt-3'>
                                        <div onClick={() => navigate("/user/" + review.userReviewer.id)} className='flex-fill d-flex justify-self-start'>
                                            <div style={{ position: "relative", display: "inline-block", height: "36px", width: "36px" }}>
                                                <img width={"100%"} height={"100%"} style={{ display: "block" }} className="avatar-img  rounded-circle " src={review.userReviewer.profilePhotoId != null ? httpSmartClient.getPhotoUrl(review.userReviewer.profilePhotoId) : "/assets/images/avatar/01.jpg"} alt="no profile picture" />
                                            </div>
                                            <div>
                                                <p className='black mb-0 ps-1 pb-0 ms-1 fs16 fw-500'>{review.userReviewer.firstName} {review.userReviewer.lastName}</p>
                                                <p style={{ color: "#7e7e7e" }} className=' ps-1 mt-0 ms-1 fs12 fw-500'>{moment(review.createdAt).format("DD.MM.yy")}</p>

                                            </div>
                                        </div>
                                        <div className='flex-fill d-flex justify-content-end '>
                                            <svg className='col-auto  p-0' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M10.5549 1.29976L12.1387 4.49359C12.3547 4.93818 12.9306 5.36463 13.4166 5.44629L16.2872 5.92718C18.123 6.23567 18.555 7.57853 17.2321 8.90325L15.0004 11.1534C14.6224 11.5345 14.4154 12.2695 14.5324 12.7957L15.1714 15.5812C15.6753 17.7861 14.5144 18.639 12.5796 17.4867L9.88895 15.8807C9.403 15.5903 8.60209 15.5903 8.10714 15.8807L5.41644 17.4867C3.49065 18.639 2.32078 17.777 2.82473 15.5812L3.46366 12.7957C3.58064 12.2695 3.37367 11.5345 2.99571 11.1534L0.763955 8.90325C-0.5499 7.57853 -0.126947 6.23567 1.70885 5.92718L4.57953 5.44629C5.05648 5.36463 5.63242 4.93818 5.84839 4.49359L7.43222 1.29976C8.29612 -0.433253 9.69997 -0.433253 10.5549 1.29976Z" fill="#F7C32E" />
                                            </svg>
                                            <p style={{ color: "#7e7e7e" }} className='fs16  col-auto m-0  ps-1 d-inline fw-600' >{review.reviewRating.toFixed(1)}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex px-1  py-2 mb-2'>
                                        <LoadMoreText text={review.reviewComment}></LoadMoreText>
                                        {/* <p style={{color:"#7e7e7e"}} className='fs14 fw-500'>{review.reviewComment}</p> */}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className='row pe-0'>
                        <PagingComponent
                            className="pagination-bar "
                            currentPage={pagingData.pageNumber ?? 0}
                            totalCount={pagingData.totalCount ?? 0}
                            pageSize={pagingData.pageSize ?? 0}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}
