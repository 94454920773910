import { httpSmartClient } from "../../config/httpClient";

const baseName = "Viber"

const ViberMessagesService = {
  add: async (data) => {
    const response = await httpSmartClient.post(`/${baseName}/`, { ...data });
    return response;
  },
  delete: async (id) => {
    const response = await httpSmartClient.delete(`/${baseName}/${id}`);
    return response;
  },
  getByParams: async (params) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return response;
  },
  getById: async (id) => {
    const response = await httpSmartClient.getById(`/${baseName}/${id}`);
    return response;
  },
  put: async (data) => {
    const response = await httpSmartClient.put(`/${baseName}/`, data);
    return response;
  },

}
export default ViberMessagesService;