import { httpSmartClient } from "../../config/httpClient";

const baseName = "UserVehicles";

const UserVehiclesService = {
  getById: async (id) => {
    var response = await httpSmartClient.getById(`/${baseName}/`, { params: { Id: id } });
    return response;
  },
  getPaged: async (params) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return response;
  },
  getForLoggedUser: async (params) => {
    var response = await httpSmartClient.get(`/${baseName}/GetForLoggedUser`, {params});
    return response;
  },
  getCountForLoggedUser: async () => {
    var response = await httpSmartClient.get(`/${baseName}/GetCountForLoggedUser`);
    return response;
  },
  add: async (userVehicle) => {
    var response = await httpSmartClient.post(`/${baseName}/`, userVehicle);
    return response;
  },
  addVehicle: async (userVehicle) => {
    var response = await httpSmartClient.postForm(`/${baseName}/AddVehicle`, userVehicle);
    return response;
  },
  edit: async (userVehicle) => {
    var response = await httpSmartClient.put(`/${baseName}`, userVehicle);
    return response;
  },
  editVehicle: async (userVehicle) => {
    var response = await httpSmartClient.putForm(`/${baseName}/UpdateVehicle`, userVehicle);
    return response;
  },
  deactivateVehicle: async (vehicleId) => {
    var response = await httpSmartClient.put(`/${baseName}/DeactivateVehicle?userVehicleId=${vehicleId}`);
    return response;
  },
  delete: async (id) => {
    var response = await httpSmartClient.delete(`/${baseName}/` + id);
    return response;
  }
};

export default UserVehiclesService;
