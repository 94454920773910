import { observer } from "mobx-react-lite";

import { useEffect, useState } from "react";

import UserProfileReviewsMobile from "./UserProfileReviewsMobile";
import UserProfileReviews from "./UserProfileReviews";

const UserReviewsPage = observer(() => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 760);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  return (
    <>
         <div style={{ display: isMobile ? "block" : "none" }} >
          <UserProfileReviewsMobile></UserProfileReviewsMobile>
          </div>
          <div style={{ display: !isMobile ? "block" : "none" }} >
          <UserProfileReviews></UserProfileReviews>
          </div>
    </>
  );
})

export default UserReviewsPage;