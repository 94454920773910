import React, { useState } from 'react'
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import userProfileStore from 'data/stores/UserProfileStore';
import { toastify } from 'utils/toastify';
import { UsersService, AuthService } from 'data/services';

const UpdateEmailModal = observer((props) => {
    const { t } = useTranslation();
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    let user = userProfileStore.user
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError
    } = useForm();

    const onDiscard = () => {
        props.onDiscard();
    }

    const onSubmit = async (data) => {
        setFormSubmitting(true);
        if (user.email !== data.email) {
            const response = await UsersService.edit({ ...user, email: data.email });
            setFormSubmitting(false);
            if (response.statusCode == 409) {
                toastify.error(t("USER_SAME_EMAIL_EXISTS"));
                return;
            }
            else if (response.isSuccess) {
                const logOutUser = async () => {
                    toastify.success(t("SUCCESSFULLY_UPDATED_EMAIL"));
                    await AuthService.signOut();
                    navigate("/sign-in");
                }
                logOutUser();
            }
            else toastify.error(t("EMAIL_UPDATE_ERROR"));
        } else {
            setFormSubmitting(false);
            toastify.error(t("EMAIL_SAME"))
        }
    }

    return (
        <>
            <div className="z-index-master h-100">
                <div className="card shadow h-100 opacity-modal">
                    <div className="card-header pb-0 ps-2 d-flex flex-column justify-content-between">
                        <div className='d-flex justify-content-between'>
                            <h5 className="card-header-title ms-1 d-inline headers">{t("UPDATE_EMAIL")}</h5>
                        </div>
                    </div>
                    <div className="card-body p-0 mt-3 h-100">
                        <div className='d-flex'>
                            <span className='labels ms-3 me-1'>{t("CURRENT_EMAIL") + " "}
                            </span>
                            <p className='paragraphs-purple-small d-inline me-3'>{user.email}</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate className='h-100 d-flex flex-column justify-content-between'>
                            <div className='mb-8'>
                                <label className="labels ms-3 mt-3">{t("NEW_EMAIL")}:</label>
                                <input {...register("email", {
                                    required: t("EMAIL_IS_REQUIRED"),
                                    validate: {
                                        maxLength: (v) =>
                                            v.length <= 50 || t("EMAIL_MORE_THAN_50"),
                                        matchPattern: (v) =>
                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || t("EMAIL_NOT_VALID"),
                                    },
                                })}
                                    id="email"
                                    className="form-control m-auto b-none inputs"
                                    placeholder={t("EMAIL")}
                                />
                                <svg className='email-icon position-relative' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {errors.email && <span className="text-danger">{t("EMAIL_IS_REQUIRED")}</span>}
                            </div>
                            <div className='ps-3 pe-3'>
                                <button disabled={isFormSubmitting} type="submit" className="btn btn-primary mb-4 btn-size btn-font">
                                    {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                    {t("UPDATE_EMAIL")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
});

export default UpdateEmailModal;