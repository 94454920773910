import React from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import './FlagTooltip.css'
function CountryFlag(props) {
    if (props.code != null) {
        return (
            <>
                <Tooltip id={`${props.tooltip ? props.tooltip : 'country'}`} className='tooltip' />
                <img style={{marginTop:"2px", marginBottom:"3px"}} src={`/assets/images/flags/${props.code.toLowerCase()}.svg`} className={`${props.className ? "country-flag2 " + props.className : "country-flag mb-1"}`} data-tooltip-id={`${props.tooltip ? props.tooltip : 'country'}`} data-tooltip-content={props.fullName} />
            </>
        )
    }
    return (
        ""
    )
}

export default CountryFlag
