import { toast } from "react-toastify";

const toastify = {
  success: (message, customIcon) => {
    toast.success(message, {
      position: window.innerWidth>576 ? "bottom-right" : "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      closeButton: <div className="Toastify__close-button"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M256-213.847 213.847-256l224-224-224-224L256-746.153l224 224 224-224L746.153-704l-224 224 224 224L704-213.847l-224-224-224 224Z" /></svg></div>,
      icon: customIcon || <img className="Toastify__toast-icon--success" src="../../../../assets/images/driving.png" />
    });
  },
  error: (message) => {
    toast.error(message, {
      position: window.innerWidth>576 ? "bottom-right" : "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      closeButton: <div className="Toastify__close-button"><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M256-213.847 213.847-256l224-224-224-224L256-746.153l224 224 224-224L746.153-704l-224 224 224 224L704-213.847l-224-224-224 224Z" /></svg></div>,
      icon: <div className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter" style={{marginTop:"6.5px", marginLeft:"7px"}}><svg viewBox="0 0 24 24" width="70%" height="70%" fill="currentColor"><path d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path></svg></div>
    });
  },
  warning: (message) => {
    toast.warning(message, {
      position: window.innerWidth>576 ? "bottom-right" : "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  },
  info: (message) => {
    toast.info(message, {
      position: window.innerWidth>576 ? "bottom-right" : "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      closeButton: <div className="Toastify__close-button"><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M256-213.847 213.847-256l224-224-224-224L256-746.153l224 224 224-224L746.153-704l-224 224 224 224L704-213.847l-224-224-224 224Z" /></svg></div>,
      icon: <img className="Toastify__toast-icon--info" src="../../../../assets/images/informative.png" />

    });
  },

  default: (message) => {
    toast(message, {
      position: window.innerWidth>576 ? "bottom-right" : "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  },
};

export default toastify;
