import { httpSmartClient } from "../../config/httpClient";

const baseName = "CreditCards"

const CreditCardsService = {
  add: async (data) => {
    const response = await httpSmartClient.post(`/${baseName}/`, { ...data });
    return response;
  },
  getAll: async (id) => {
    const response = await httpSmartClient.get(`/${baseName}/GetAllCards`, id);
    return response;
  },
  delete: async (id) => {
    const response = await httpSmartClient.delete(`/${baseName}/${id}`);
    return response;
  },
  getById: async (id) => {
    const response = await httpSmartClient.getById(`/${baseName}/${id}`);
    return response;
  },
  put: async (data) => {
    const response = await httpSmartClient.put(`/${baseName}/`, data);
    return response;
  },
  doesUserHaveCreditCard: async (id) => {
    const response = await httpSmartClient.get(`/${baseName}/DoesUserHaveCreditCard`);
    return response;
  },
}
export default CreditCardsService;