import { httpSmartClient } from "config/httpClient";

const baseName = "CreditCardPaymentRequests"

const CreditCardPaymentRequestsService = {
    getByParams: async (params) => {
        var response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
        return response;
    },
    add: async (data) => {
        const response = await httpSmartClient.post(`/${baseName}`, data);
        return response;
    },
    delete: async (id) => {
        const response = await httpSmartClient.delete(`/${baseName}/${id}`);
        return response;
    },
    put: async (data) => {
        const response = await httpSmartClient.put(`/${baseName}/`, data);
        return response;
    },
    isLastPlaceBeingBooked:async(rideId,userId)=>{
        const response = await httpSmartClient.get(`/${baseName}/IsLastPlaceBeingBooked?rideId=${rideId}&userId=${userId}`);
        return response;
    }

}
export default CreditCardPaymentRequestsService;