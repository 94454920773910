import { useWindowSize } from "@uidotdev/usehooks";
import globalStore from "data/stores/GlobalStore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"

export default function Page500() {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState();
    const size = useWindowSize();
    const {t}= useTranslation();

    useEffect(() => {
        if (size.width < 768) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false)
        }
    }, [size, globalStore.lng])

    return (
        <>
       {isMobile && <div className="bg-purple h-30px" style={{marginTop:"-66px"}}></div>}
       <div className={isMobile ? "bg-light row text-center mb-7 w-100": "row text-center"} style={isMobile ? {marginTop:"-25px", zIndex:"100", position:"relative", borderRadius:"1.5em", marginLeft:"0px"}:{}}>
            <div className="col-12 ">
                <img src="/assets/images/500.svg" className="h-300px mx-auto" alt=""/>
                <h3 className="mt-2 purple fw-700">{t("INTERNAL_SERVER_ERROR")}</h3>
                <div className="m">
                    <p className="form-label mb-4 ">{t("INTERNAL_SERVER_ERROR_DESCRIPTION")}</p>
                    <a className="purple cursor-pointer fw-700" onClick={()=>navigate("/")}>{t("BACK_TO_HOMEPAGE")}</a>
                </div>
            </div>
        </div>
        </>
    )

} 