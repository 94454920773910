import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next'
import General from './General';
import CancellationPolicy from './CancellationPolicy'
import PaymenType from './PaymentType';
import FinalizeAfter from './FinalizeAfter';
import PricePerKilometers from './PricePerKilometers';
import AllowReservationMinDays from './AllowReservationMinDays';
import IsReservationAllowed from './IsReservationAllowed';
import { AppSettingsService } from 'data/services';
import globalStore from 'data/stores/GlobalStore';
import MinHoursBeforeRide from './MinHoursBeforeRide';
import MaximumNumberOfSeats from './MaximumNumberOfSeats';

const AppSettings = observer(()=> {
    const { t } = useTranslation();
    const [settings, setSettings] = useState();
    const { reset } = useForm();
    useEffect(() => {
        setSettings(null);
        const apiCall = async () => {
            const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
            reset(response.data.items[0]);
            setSettings(response.data.items[0]);
        }
        apiCall();
    }, [globalStore.render]);
    return (
        <>
            {settings &&
                <>
                    <div className='row justify-content-between me-3 me-md-0'>
                        <General settings={settings} />
                        <PaymenType settings={settings}></PaymenType>
                    </div>
                    <div className='row justify-content-between me-3 me-md-0'>
                        <IsReservationAllowed settings={settings}/>
                        <AllowReservationMinDays settings={settings}/>
                    </div>
                    <div className='row justify-content-between me-3 me-md-0'>
                        <FinalizeAfter settings={settings}></FinalizeAfter>
                        <PricePerKilometers settings={settings}></PricePerKilometers>
                    </div>
                    <div className='row justify-content-between me-3 me-md-0'>
                        <MinHoursBeforeRide settings={settings}></MinHoursBeforeRide>
                        <MaximumNumberOfSeats settings={settings}></MaximumNumberOfSeats>
                    </div>
                    <div className='row'>
                        <CancellationPolicy />
                    </div>
                </>}
        </>
    )
});

export default AppSettings;
