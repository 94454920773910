import flatpickr from 'flatpickr';
import React, { useEffect, useState } from 'react'
import { camelCase } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { EnumsService,UsersService } from 'data/services';
import { toastify } from 'utils/toastify';
import "./../../../layout/common.css"
import { httpSmartClient } from 'config/httpClient';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Flatpickr from 'react-flatpickr'
import Select from 'react-select';
import globalStore from 'data/stores/GlobalStore';
import moment from 'moment/moment';
import IntlTelInput from 'react-intl-tel-input';

export default function UserEditPage() {
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const [defaultGender, setDefaultGender] = useState();
    const [defaultRole, setDefaultRole] = useState();
    const [genders, setGenders] = useState();
    const [roles, setRoles] = useState(null);
    const [languageCode, setLanguageCode] = useState();
    const [birthDate, setBirthDate] = useState();
    const [user, setUser] = useState(null);
    const [previewUrl, setPreviewUrl] = useState();
    const [isValid, setIsValid] = useState();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const currentDate = new Date();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        setError,
        getValues,
        clearErrors
    } = useForm();
    const handlePhotoUpload = (e) => {
        const file = e.target.files[0];
        setPreviewUrl(URL.createObjectURL(file));

    };
    useEffect(() => {
        flatpickr("#myFlatPickr");
    }, [])
    useEffect(() => {
        const fetchAndSetRoles = async () => {
            const user = await UsersService.getById(id);
            if(user.data)
            {
                setUser(user.data);
            const rolesList = await EnumsService.getRoles();
            const genders = await EnumsService.getGenders();
            setRoles(() => {
                return rolesList.map(x => {
                    if (x.value.toLowerCase() == user.data.role.toLowerCase()) {
                        setDefaultRole({
                            value: x.key,
                            label: x.value
                        })
                    }
                    return {
                        value: x.key,
                        label: x.value
                    }
                })
            });
            setGenders(() => {
                return genders.map(x => {
                    if (x.value.toLowerCase() == user.data.gender.toLowerCase()) {
                        setDefaultGender({
                            value: x.key,
                            label: x.value
                        })
                    }
                    return {
                        value: x.key,
                        label: x.value
                    }
                })
            });

            setBirthDate(user.data.birthDate)
            const photoId = user.data.profilePhotoId;
            photoId ? setPreviewUrl(httpSmartClient.getPhotoUrl(photoId)) : setPreviewUrl("/assets/images/avatar/01.jpg")
            reset();
            reset(user.data);
        }
        else
        {
            navigate("/NotFound");
        }
        };
        fetchAndSetRoles();
    }, []);
    const ERROR_MESSAGES = {
        NotEmpty: 'REQUIRED_FIELD',
        NotNull: 'REQUIRED_FIELD',
        InvalidValue: 'INVALID_FILE_VALUE',
        InvalidType: 'INVALID_FILE_TYPE',
        InvalidSize: 'INVALID_FILE_SIZE',
    };
    const onSubmit = async (data) => {
        setFormSubmitting(true);
        if (data.profilePhoto && data.profilePhoto.length > 0) {
            data.profilePhoto = data.profilePhoto[0];
        }
        data.phoneNumber = data.phoneNumber?.replace(/\s/g, '');
        const editedUser = await UsersService.userEditAdmin({ id: id, ...data });
        setFormSubmitting(false);
        if (editedUser.isSuccess) {
            navigate(`/admin/users`);
            toastify.success(t("SUCCESSFULLY_EDITED_USER"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png" />);
        } else if (editedUser.isError) {
            toastify.error(t(editedUser.error));
        } else if (editedUser.isValidationError) {
            const apiErrorsObject = editedUser.validationErrors;
            apiErrorsObject.forEach(error => {
                const fieldName = camelCase(error.propertyName);
                error.errorCodes.forEach(errorCode => {
                    const errorMessage = t("ERROR_MESSAGES"[errorCode]);
                    setError(fieldName, { type: 'custom', message: errorMessage });
                });
            });
        }
    };
    const toolbarOptions = [
        ['bold', 'italic', 'underline'],
        ['clean']
    ];
    return (
        <>
        {user===null ? (<></>):
            (<><div className="row mb-3">
                <div className="col-12 ">
                    <h1 className="h4 mb-0">{t("EDIT_USER")}</h1>
                </div>

            </div>
            <div className="col-lg-12">
                <div className="card shadow">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">{t("USER_INFORMATION")}</h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("FIRST_NAME")}</label>
                                        <input
                                            {...register("firstName", {
                                                required: t("FIRST_NAME_IS_REQUIRED"),
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder={t("FIRST_NAME")}
                                        />
                                        {errors.firstName && <span className="text-danger">{t("FIRST_NAME_IS_REQUIRED")}</span>}
                                    </div>
                                </div>
                                <div className='col-md-6  col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("LAST_NAME")}</label>
                                        <input
                                            {...register("lastName", {
                                                required: t("LAST_NAME_IS_REQUIRED"),
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder={t("LAST_NAME")}
                                        />
                                        {errors.lastName && <span className="text-danger">{t("LAST_NAME_IS_REQUIRED")}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                {birthDate &&
                                    <div className='col-md-6  col-sm-12'>
                                        <label className="mb-2">{t("BIRTHDATE")}</label>
                                        <Flatpickr
                                            {...register("birthDate", {
                                                required: t("BIRTH_DATE_IS_REQUIRED")
                                            })}
                                            onChange={(e) => { setValue("birthDate", e) }}
                                            type="text"
                                            defaultValue={moment(birthDate).format("DD.MM.YYYY")}
                                            options={{
                                                dateFormat: "d.m.Y",
                                                defaultDate: moment(birthDate).format("DD.MM.YYYY"),
                                                minDate: '01.01.1950',
                                                maxDate: currentDate.setFullYear(currentDate.getFullYear() - 18)
                                            }}
                                            className="form-control cursor-pointer"
                                            placeholder={t("SELECT_DATE_AND_TIME")}
                                        />
                                        {errors.birthDate && <span className="text-danger">{t("BIRTH_DATE_IS_REQUIRED")}</span>}
                                    </div>}
                                <div className='col-md-6  col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("GENDER")}</label>
                                        {defaultGender && <Select
                                            {...register("gender", {
                                                required: t("GENDER_IS_REQUIRED"),
                                            })}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                                    color: globalStore.theme === 'dark' ? 'white' : "black",
                                                    cursor: 'pointer'
                                                }),
                                            }}
                                            className="basic cursor-pointer"
                                            onChange={(e) => {
                                                clearErrors("gender")
                                                setValue("gender", e.value)
                                            }}
                                            defaultValue={defaultGender}
                                            options={genders}
                                        />}
                                        {errors.gender && <span className="text-danger">{t("GENDER_IS_REQUIRED")}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6  col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("EMAIL")}</label>
                                        <input
                                            {...register("email", {
                                                required: t("EMAIL_IS_REQUIRED"),
                                                validate: {
                                                    maxLength: (v) =>
                                                        v.length <= 50 || "The email should have at most 50 characters",
                                                    matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
                                                        "Email address must be a valid address",
                                                },
                                            })}
                                            type="text"
                                            className="form-control"
                                            placeholder={t("EMAIL")}
                                        />
                                        {errors.email && <span className="text-danger">{t("EMAIL_NOT_VALID")}</span>}
                                    </div>
                                </div>
                                <div className='col-md-6  col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("PHONE_NUMBER")}</label>
                                        <IntlTelInput
                                            {...register("phoneNumber", {
                                                required: t("PHONE_NUMBER_IS_REQUIRED"),
                                                validate: {
                                                    matchPattern: (v) => {
                                                        let test = /[a-zA-Z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/.test(v);
                                                        if (test || (v.includes("+") && v.indexOf("+") !== 0) || !isValid) {
                                                            return t("PHONE_NUMBER_VALIDATION")
                                                        }
                                                    }
                                                },
                                            })}
                                            containerClassName="intl-tel-input w-100 phoneInput"
                                            inputClassName="form-control"
                                            defaultCountry="ba"
                                            defaultValue={user.phoneNumber}
                                            onPhoneNumberChange={(isValid, value, fullNumber, extension) => {
                                                if (value.length < 7 || value.length > 15)
                                                  setIsValid(false);
                                                else if (value.split('+').length - 1 > 1)
                                                  setIsValid(false);
                                                else setIsValid(true);
                                                const phoneNumber=fullNumber.dialCode.concat(value.replace(/^0+/, ''));
                                                setValue('phoneNumber', phoneNumber)
                                              }}
                                        />
                                        {errors.phoneNumber && errors.phoneNumber.type == 'matchPattern' && <small className="text-danger">{t("PHONE_NUMBER_VALIDATION")}</small>}
                                        {errors.phoneNumber && errors.phoneNumber.type == 'required' && <small className="text-danger">{t("PHONE_NUMBER_IS_REQUIRED")}</small>}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6  col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("PASSWORD")}</label>
                                        <input
                                            {...register("password")}
                                            type="text"
                                            className="form-control"
                                            placeholder={t("PASSWORD")}
                                        />
                                    </div>

                                </div>
                                <div className='col-md-6  col-sm-12 '>
                                    <div className="mb-3 ">
                                        <label className="form-label">{t("ROLE")}</label>
                                        {defaultRole && <Select
                                            {...register("role", {
                                                required: t("GENDER_IS_REQUIRED"),
                                            })}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                                    color: globalStore.theme === 'dark' ? 'white' : "black",
                                                    cursor: 'pointer'
                                                }),
                                            }}
                                            className="basic"
                                            onChange={(e) => {
                                                clearErrors("role")
                                                setValue("role", e.value)
                                            }}
                                            defaultValue={defaultRole}
                                            options={roles}
                                        />}
                                        {errors.role && <span className="text-danger">{t("ROLE_IS_REQUIRED")}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("BIOGRAPHY")}</label>
                                        <div className="">
                                            <ReactQuill
                                             className='b-none'
                                                value={getValues("biography")}
                                                onChange={(value) => setValue("biography", value)}
                                                modules={{ toolbar: toolbarOptions }}
                                                style={{ height: "150px", paddingBottom: "50px" }} />
                                        </div>
                                        {errors.biography && <span className="text-danger">{errors.biography.message}</span>}
                                    </div>
                                </div>
                                <div className='col-md  col-sm-12 mt-3'>
                                    <div className="form-check form-switch form-check-md mb-0">
                                        <div className="col-3 mt-2">
                                            <label className="form-label">{t("ACTIVITY_STATUS")}</label>
                                            <div className="form-check form-switch form-check-md ">
                                                <input {...register("isActive")} className="form-check-input" type="checkbox" />
                                            </div>
                                        </div>
                                        <div className="col-3 mt-2">
                                            <label className="form-label">{t("VERIFICATION_STATUS")}</label>
                                            <div className="form-check form-switch form-check-md mb-0">
                                                <input {...register("isVerified")} className="form-check-input" type="checkbox" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <div className="mb-3">
                                        <label className="form-label">{t("PROFILE_PHOTO")}</label>
                                        <div className="form-check form-switch form-check-md mb-0">
                                            {previewUrl && <img src={previewUrl} style={{width:"150px",height:"150px"}} className='photo-preview' />}
                                            <input
                                                {...register("profilePhoto", { defaultValue: null })}
                                                onChange={handlePhotoUpload}
                                                className="form-control"
                                                type="file"
                                            />
                                            {errors.profilePhoto && <span className="text-danger">{errors.profilePhoto.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-4">
                                <button className="btn text-secondary border-0 me-2" onClick={() => navigate("/admin/users")}>
                                    {t("DISCARD")}
                                </button>
                                <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                                    {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                    {t("SAVE")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div></>)}
        </>
    )
}
