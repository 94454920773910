import { observer } from "mobx-react-lite";
import CatalogPageMainComponent from "./CatalogPageMainComponent";
import CatalogSideFilters from "./CatalogSideFilters";
import { useEffect, useState } from "react";
import CatalogPageMainComponentMobile from "./CatalogPageMainComponentMobile";

const CatalogBody = observer(() => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [IsTablet, setIsTablet] = useState(window.innerWidth <= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1200);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className='row' style={isMobile ? {width:"90%", position:"relative", marginLeft:"5%", marginRight:"5%" }:{}}>
        {!IsTablet ? <div  style={{width:"330px",marginLeft:"10px"}}>
          {<CatalogSideFilters></CatalogSideFilters>}
        </div> : <div className=''>
          {<CatalogSideFilters></CatalogSideFilters>}
        </div>}
        
        {isMobile ? (
          <CatalogPageMainComponentMobile> </CatalogPageMainComponentMobile>
        ) : (
          <>
            {!IsTablet ? <div className='col-md col-sm-12  me-3 ms-2'>
              {<CatalogPageMainComponent></CatalogPageMainComponent>}
            </div> :
              <div className='col col-sm-12 mt-3'>
                {<CatalogPageMainComponent></CatalogPageMainComponent>}
              </div>
            }
          </>
        )}
      </div>
    </>
  );
})

export default CatalogBody;