import { useEffect, useState } from "react";
import { toastify } from "utils/toastify";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { EnumsService, VehicleModelsService, VehicleBrandsService } from "data/services";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { getAppPagingSettings } from "config/paging";
import { PagingComponent } from "components/layout/components";
import Select from "react-select";
import globalStore from "data/stores/GlobalStore";
import VehicleModelAddModal from "./modals/VehicleModelAddModal";
import VehicleModelEditModal from "./modals/VehicleModelEditModal";

export default function VehicleModelsPage() {
  let pagingSettings = getAppPagingSettings();
  const [pagingData, setPagingData] = useState(pagingSettings);
  const [data, setData] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState();
  const [vehicleTypes, setVehicleTypes] = useState();
  const [vehicleBrandIdFilter, setVehicleBrandIdFilter] = useState("");
  const [vehicleBrandLabel, setVehicleBrandLabel] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [currentId, setCurrentId] = useState();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fetchVehicleModels, setFetchVehicleModels] = useState(false);

  useEffect(() => {
    const fetchAndSetVehicleBrands = async () => {
      const vehicleBrandsList = await VehicleBrandsService.getByParams({
        pageNumber: 1, pageSize: 1000
      });
      setVehicleBrands(() => {
        return vehicleBrandsList.data.items.map(x => {
          return {
            value: x.id,
            label: x.name
          }
        })
      });
    };
    fetchAndSetVehicleBrands();
  }, [])
  useEffect(() => {
    const vehicleBrandId = searchParams.get("vehicleBrandId");
    if (vehicleBrandId != undefined) {
      setVehicleBrandIdFilter(vehicleBrandId);
      const fetchVehicleModelsByVehicleBrandId = async () => {
        const vehicleModelsByVehicleBrand = await VehicleModelsService.getByParams({
          pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize,
          vehicleBrandId: vehicleBrandId
        });
        setData(vehicleModelsByVehicleBrand.data);
      };
      fetchVehicleModelsByVehicleBrandId();
    }
    const fetchAndSetVehicleTypes = async () => {
      const vehicleTypesList = await EnumsService.getVehicleTypes();
      setVehicleTypes(vehicleTypesList);
    };
    fetchAndSetVehicleTypes();
  }, [pagingData, fetchVehicleModels]);

  const onVehicleBrandChange = async (data) => {
    const vehicleBrandId = data;
    if (data != undefined) {
      setVehicleBrandIdFilter(vehicleBrandId);
      setSearchParams({ ...searchParams, vehicleBrandId: vehicleBrandId });
      if (vehicleBrandId) {
        const vehicleModelsByVehicleBrand = await VehicleModelsService.getByParams({
          pageNumber: pagingData.pageNumber,
          pageSize: 5,
          vehicleBrandId: vehicleBrandId
        });
        setData(vehicleModelsByVehicleBrand.data);
      } else {
        setData({});
      }
    }
  };
  const onPageChange = (page) => {
    pagingData.pageNumber = page;
    setPagingData({ ...pagingData });
  }

  const handleDelete = (id) => {
    setShowConfirmationDialog(true);
    setCurrentId(id);
  };

  const deleteVehicleModel = async (id) => {
    const deleted = await VehicleModelsService.delete(id);
    if (deleted.isSuccess) {
      const fetchVehicleModelsByVehicleBrandId = async () => {
        const vehicleModelsByVehicleBrand = await VehicleModelsService.getByParams({
          pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize,
          vehicleBrandId: searchParams.get("vehicleBrandId")
        });
        setData(vehicleModelsByVehicleBrand.data);
      };
      fetchVehicleModelsByVehicleBrandId();

      toastify.success(t("SUCCESSFULLY_DELETED_VEHICLE_MODEL"));
      setShowConfirmationDialog(false);
    } else {
      toastify.error(t("ERROR_DELETING_VEHICLE_MODEL"));
    }
  };
  const ClearFilters = () => {
    setVehicleBrandLabel(null);
    setSearchParams({});
    setData({});
  }

  const onSuccess = (isEdit) => {
    if (isEdit) {
      setShowEditModal(false);
    }
    else {
      setShowAddModal(false);
    }
    if (searchParams.get("vehicleBrandId")) {
      setFetchVehicleModels(!fetchVehicleModels);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mb-4 mb-sm-4">
          <div className="d-sm-flex justify-content-between align-items-center">
            <h1 className="h4 mb-3 mb-sm-0">{t("VEHICLE_MODELS")}</h1>
            <div className="d-grid">
              <a className="btn btn-primary-soft" onClick={() => setShowAddModal(true)}>
                + {t("NEW_VEHICLE_MODEL")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-body">
          <div className="row me-2 mb-4">
            <div className=" col-md-3 pe-0 col-12">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                  })
                }}
                className="basic-select"
                onChange={(e) => {
                  setVehicleBrandLabel(e.label)
                  onVehicleBrandChange(e.value)
                }}
                placeholder={t("CHOOSE_VEHICLE_BRAND")}
                options={vehicleBrands}
                value={vehicleBrandLabel ? { label: vehicleBrandLabel } : null}
              />
            </div>
            <div className='col-md  col-sm-12 p-0  ps-0 d-flex justify-content-end mt-2 mt-md-0'>
              <button onClick={() => ClearFilters()} className="btn d-flex  justify-content-center w-sm-100 ms-3 align-items-center btn-sm btn-neutral-soft border border-danger " >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="bg-light rounded p-3 d-none d-lg-block">
            <div className="row row-cols-7 g-4">
              <div className="col">
                <h6 className="mb-0">{t("NAME")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("VEHICLE_BRAND")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("VEHICLE_TYPE")}</h6>
              </div>
              <div className="col">
                <h6 className="float-end mb-0">{t("ACTION")}</h6>

              </div>
            </div>
          </div>
          {(!data.items || data.items.length === 0) && (
            <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col text-center">{vehicleBrandIdFilter ? t("NO_RESULTS") : t("PLEASE_CHOOSE_VEHICLE_BRAND_TO_SHOW")}</div>
            </div>
          )}
          {data && data.items &&
            data.items.map((vehicleModel, index) => (
              < div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4" >
                <div className="col">
                  <small className="d-block d-lg-none">{t("NAME")}:</small>
                  <h6 className="mb-0 fw-normal">{vehicleModel.name}</h6>
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("VEHICLE_BRAND")}:</small>
                  <h6 className="mb-0 fw-normal">{vehicleModel.vehicleBrand.name}</h6>
                </div>
                <div className="col">
                  <small className="d-block d-lg-none">{t("VEHICLE_TYPE")}:</small>
                  <h6 className="mb-0 fw-normal">{t(`VEHICLE_TYPE_${vehicleModel.vehicleType.toUpperCase()}`)}</h6>
                </div>
                <div className="col-md col-sm-12 d-flex justify-content-end">
                  <button className="btn btn-sm btn-primary-soft me-2" onClick={() => { setCurrentId(vehicleModel.id); setShowEditModal(true) }}>
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button className="btn btn-sm btn-danger-soft border border-danger" onClick={() => handleDelete(vehicleModel.id)}>
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            ))}
        </div>
        <PagingComponent
          className="pagination-bar"
          currentPage={data.pageNumber ?? 0}
          totalCount={data.totalCount ?? 0}
          pageSize={data.pageSize ?? 0}
          onPageChange={onPageChange}
        />
      </div >
      {showConfirmationDialog && <ConfirmationDialog
        onDiscard={() => setShowConfirmationDialog(false)}
        title={t("DELETE_VEHICLE_MODEL_ITEM")}
        description={t("DELETE_VEHICLE_MODEL")}
        confirmBtnLabel={t("DELETE")}
        onConfirm={() => deleteVehicleModel(currentId)}
      ></ConfirmationDialog>
      }
      {
        showAddModal && <VehicleModelAddModal
          onDiscard={() => setShowAddModal(false)}
          title={t("NEW_VEHICLE_MODEL")}
          description={t("VEHICLE_MODEL_INFORMATION")}
          confirmBtnLabel={t("SAVE")}
          onSuccess={() => { onSuccess() }}
        ></VehicleModelAddModal>
      }
      {showEditModal && <VehicleModelEditModal
        onDiscard={() => setShowEditModal(false)}
        title={t("EDIT_VEHICLE_MODEL")}
        description={t("VEHICLE_MODEL_INFORMATION")}
        confirmBtnLabel={t("SAVE")}
        onSuccess={() => { onSuccess(true) }}
        vehicleModelId={currentId}
      ></VehicleModelEditModal>}
    </>
  );
}
