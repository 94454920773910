import React, { useEffect, useState } from "react";
import HeroSearchComponent from "./HeroSearchComponent";
import "./hero.css"
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

const HeroComponent = observer (()=> {
    const{t}=useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
         {isMobile && <div className="bg-purple h-50px" style={{marginTop:"-66px"}}></div>}
            <section className={isMobile ? "container p-0 bg-light" : "container p-0"} style={isMobile ? {marginTop:"-25px", zIndex:"100", position:"relative", borderRadius:"1.5em", marginLeft:"0px"}:{}}>
                <div className="row g-4 g-lg-5 justify-content-center  p-0 ">
                    <div style={{ position: "relative", maxWidth: "90%", maxHeight: "100%" }} className={isMobile ? "justify-content-center mt-0 p-0 mt-n4" : "justify-content-center mt-0 p-0"}>
                        <img style={{ borderRadius: '8px' }} className="p-0" src={isMobile ? "assets/images/myImages/homepagePhotoMobile.jpg" :"assets/images/myImages/homepagePhoto.jpg"} alt="" />
                        <div className="gradiant"></div>
                        <h1 style={{ position: "absolute", top: "30%", left: "5%", zIndex: 20, color: "white" }}>{t("PICK")}<br />{t("OWN_RIDE")}</h1>
                    </div>
                    <HeroSearchComponent colN={isMobile? 12 : 11}/>
                </div>
            </section>
        </>
    );
});

export default HeroComponent;
