import React, { useEffect, useState } from 'react'
import MyProfileSidebar from './MyProfileSidebar';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { observer } from 'mobx-react-lite';
import { useWindowSize } from '@uidotdev/usehooks';
import vehicleStore from 'data/stores/VehiclesStore';

const MyProfilePage = observer(() => {
	const { t } = useTranslation();
	const [meni, setMeni] = useState(window.innerWidth <= 1400);
	const size = useWindowSize();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
	const location = useLocation();

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 850);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		setMeni(window.innerWidth <= 1400)
	}, [size])

	useEffect(() => {
		const section = document.getElementById("section")
		const sectionHeader = document.getElementById("section-header")
		const header = document.getElementById("header-height")
		header.className = "header-height"
		sectionHeader.className = "container p-0 pt-5"
		section.classList.add("pt-0")
		section.classList.add("pb-0")
		return () => {
			section.classList.remove("pt-0")
			section.classList.remove("pb-0")
			sectionHeader.classList.remove("pt-5")
			header.className = "mt-5"
		}
	}, [])
	return (
		<div>
			<div className='profileHeader' id="profileHeader"></div>
			<div className="container w-100 p-0" id='container-profile-page'>
				<div className="row px-0" style={!meni ? {marginLeft:"0px", marginRight:"9px"} : {}}>
					<MyProfileSidebar />
					<div className='px-0' id='outlet' style={isMobile? {minHeight:"0px"}: {minHeight:"700px"}}>
						<div id="add-new-vehicle-button-mobile" className='d-none' onClick={() => vehicleStore.setShowNewVehicleDialog(true)}>
							<div className='d-flex gap-1 ms-3 justify-content-start align-items-center'>
								<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
									<path d="M12.7313 1.75H8.2688C6.6938 1.75 6.34381 2.53751 6.14256 3.50876L5.42505 6.93874H15.5751L14.8575 3.50876C14.6563 2.53751 14.3063 1.75 12.7313 1.75Z" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M16.835 12.5297C16.905 13.2735 16.31 13.9122 15.5487 13.9122H14.3587C13.6762 13.9122 13.58 13.6235 13.4575 13.256L13.3262 12.8797C13.1512 12.3634 13.0375 12.0134 12.1187 12.0134H8.87249C7.96249 12.0134 7.82248 12.4072 7.66498 12.8797L7.53374 13.256C7.41124 13.6147 7.31499 13.9122 6.63249 13.9122H5.44249C4.68124 13.9122 4.08624 13.2735 4.15624 12.5297L4.51499 8.66222C4.60249 7.70847 4.78623 6.92969 6.44873 6.92969H14.5425C16.205 6.92969 16.3887 7.70847 16.4762 8.66222L16.835 12.5297Z" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M5.425 5.03125H4.78625" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M16.2137 5.03125H15.575" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M6.69373 9.47656H8.59249" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M12.4075 9.47656H14.3062" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M10.5 14.875V15.75" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M10.5 18.375V19.25" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M2.625 15.75L1.75 19.25" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M18.375 15.75L19.25 19.25" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<p className='paragraphs-mobile-add-vehicle'>{t("MY_NEW_VEHICLE")}</p>
							</div>
							<div className='w-25 d-flex justify-content-center align-items-center'>
								<svg className=' ms-4' xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none">
									<path d="M0.910156 16.92L7.43016 10.4C8.20016 9.62996 8.20016 8.36996 7.43016 7.59996L0.910156 1.07996" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</div>
						</div>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	)
})
export default MyProfilePage;

