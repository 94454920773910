import { httpSmartClient } from "../../config/httpClient";

const baseName = "Paypal"

const PaypalService = {
  makePayment: async (value, email) => {
    const response = await httpSmartClient.post(`/${baseName}/MakePayment?value=${value}&userMail=${email}`, );
    return response;
  },
  GetEmailWithCode: async (code) => {
    const response = await httpSmartClient.post(`/${baseName}/PostEmailWithCode?code=${code}`, );
    return response;
  },
}
export default PaypalService;