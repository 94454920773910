import { httpSmartClient } from "../../config/httpClient";

const baseName = "CreditCardTransactions";

const EPayService = {
    getSignature: async ({...obj}) => {
        var response = await httpSmartClient.getById(`/${baseName}/GetSignature?Currency=${obj.currency}&TotalAmount=${obj.amount}&PurchaseTime=${obj.time}&Delay=${obj.delay}&OrderID=${obj.orderId}&PurchaseDescription=${obj.description}`);
        return response;
    },

   
}

export default EPayService;