import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import toastify from "../../../../utils/toastify/toastify";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../../../../data/services/AuthService";
import UserRidesService from "../../../../data/services/UserRidesService";
import NotificationsService from "../../../../data/services/NotificationsService";
import ConnectionsService from "../../../../data/services/ConnectionsService";
import notificationsStore from "../../../../data/stores/NotificationsStore";

const Checkout = ({ milestonesIds, orderdetail, rideId }) => {
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);
    const { t } = useTranslation();
    const user = AuthService.getCurrentUser();
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Ride ID: " + orderdetail.id,
                    amount: {
                        currency_code: "EUR",
                        value: orderdetail.price,
                        breakdown: {
                            item_total: {
                                currency_code: "EUR",
                                value: orderdetail.price
                            },

                        },
                    },
                },
            ],
        }).then((orderID) => {
            setOrderID(orderID);
            return orderID;
        });
    };

    const onApprove = async (data, actions) => {
        return actions.order.capture().then(function (details) {
            const transaction = {
                rideId: rideId,
                orderId: details.id,
                captureId: details.purchase_units[0].payments.captures[0].id,
                price: orderdetail.price,
                userId: user.Id,
                rideMilstoneFromId: orderdetail.milestonesForUser[0].id,
                rideMilestoneToId: orderdetail.milestonesForUser[1].id,
                payerId: details.payer.payer_id,
                payerEmail: details.payer.email_address,
                merchantId: details.purchase_units[0].payee.merchant_id,
                merchantEmail: details.purchase_units[0].payee.email_address,
                total: details.purchase_units[0].amount.value,
                currencyCode: details.purchase_units[0].amount.currency_code,
                transactionStatus: details.status,
                description: details.purchase_units[0].description,
                fullName: details.purchase_units[0].shipping.name.full_name,
                addressLine1: details.purchase_units[0].shipping.address.address_line_1,
                addressLine2: details.purchase_units[0].shipping.address.address_line_2 ?? "",
                adminArea1: details.purchase_units[0].shipping.address.admin_area_1,
                adminArea2: details.purchase_units[0].shipping.address.admin_area_2,
                postalCode: details.purchase_units[0].shipping.address.postal_code,
                countryCode: details.purchase_units[0].shipping.address.country_code,
                href: details.links[0].href,
                driverPaypalMail: "sb-sj9q426268712@personal.example.com",
                facilitatorAccessToken: data.facilitatorAccessToken
            }
            const postTransaction = async () => {
                const response = await UserRidesService.add(transaction);
                if (response.isSuccess) {
                    setSuccess(true);
                    navigate("/my-profile/my-rides");
                    const notificationToDriver = { userId: orderdetail.driverId, rideId: response.data.rideId, cityNameFrom: response.data.rideMilestoneFrom.cityName, cityNameTo: response.data.rideMilestoneTo.cityName, senderId: user.Id, text: 'Reservation' };
                    const notifResponse = await NotificationsService.add(notificationToDriver);
                    await ConnectionsService.InvokeReservedRide({ userId: orderdetail.driverId, userRideId: response.data.id, notificationId: notifResponse.data.id })
                    const notificationToUser = { userId: user.Id, rideId: response.data.rideId, cityNameFrom: response.data.rideMilestoneFrom.cityName, cityNameTo: response.data.rideMilestoneTo.cityName, senderId: 2, text: 'ReservationToMySelf' };
                    const notifToUser = await NotificationsService.add(notificationToUser);
                    notificationsStore.notify(notifToUser.data);
                    toastify.success(t("SUCCESSFULLY_RESERVED"))
                }
                else {
                    toastify.error("ERROR_WITH_PAYPAL")
                }
            };
            postTransaction();
        });
    };

    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };
    return (
        <PayPalScriptProvider options={{ "client-id": "Acas11V8xxd_1_l2_omGGKLGbCk6AMXl-lzDDe6FJTU3-U4E8TR0ciaCXRs9vpKaUDbV-czGgSQJEPCa", "currency": "EUR" }}>
            <div>
                <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}

                />
            </div>
        </PayPalScriptProvider>
    );
}

export default Checkout;