import { useEffect, useRef, useState } from "react";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { useForm } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getAppPagingSettings } from "config/paging";
import { PagingComponent } from "components/layout/components";
import { RidesService, RideMilestonesService } from "data/services";
import globalStore from "data/stores/GlobalStore";
import { UpwardIcon } from "components/layout/components/Icons";
import { observer } from "mobx-react-lite";

const RidesPage = observer(() => {
    const {
        formState: { errors },
        setValue,
        register,
        resetField,
        reset
    } = useForm();
    let pagingSettings = getAppPagingSettings();
    const [pagingData, setPagingData] = useState(pagingSettings);
    const [data, setData] = useState([]);
    const [rideDetails, setRideDetails] = useState();
    const [showRidesAboutToExpire, setShowRidesAboutToExpire] = useState(false);
    const [sortDate, setSortDate] = useState(null);
    const [counterChangeSortDate, setCounterChangeSortDate] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const statusSelectRef = useRef(null);
    const [reloadOptionsKey, setReloadOptionsKey] = useState(0);
    const [filters, setFilters] = useState({
        cityNameFrom: null,
        cityNameTo: null,
        driverId: null,
        startDate: null,
        endDate: null,
        status: null
    });
    const [defaultFetched, setDefaultFetched] = useState(false);
    const datePicker = useRef(null);
    const { t } = useTranslation();


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const reloadOptions = () => {
        setReloadOptionsKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        if (statusSelectRef.current?.props?.value) {
            const currentRideStatus = rideStatus.find(x => x.value === filters.status);
            filters.status = currentRideStatus.value;
            filters.statusLabel = currentRideStatus.label;
            reloadOptions();
        }
    }, [globalStore.lng])

    const rideStatus = [
        {
            value: "Upcoming",
            label: t("UPCOMING")
        },
        {
            value: "Cancelled",
            label: t("CANCELLED")
        },
        {
            value: "Completed",
            label: t("COMPLETED")
        }
    ]

    const FilterData = async (e) => {
        let response;
        let temp = [];
        if (defaultFetched == false) {
            response = await RideMilestonesService.getTopLocations(5);
            response.data.forEach(r => {
                temp.push({ value: r.key, label: r.key });
            });
            setDefaultFetched(true);
        }
        else {
            response = await RideMilestonesService.getByParams({ cityName: e, pageNumber: 1, pageSize: 999 });
            response.data.items.forEach(r => {
                temp.push({ value: r.cityName, label: r.cityName });
            });
        }
        return temp;
    }
    const FilterDrivers = async (e) => {
        let temp = [];
        let response = await RidesService.getDrivers(e);
        response.data.forEach(r => {
            temp.push({ value: r.id, label: r.firstName + ' ' + r.lastName });
        });
        return temp;
    }
    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
    }

    useEffect(() => {
        const fetchRides = async (e) => {
            const response = await RidesService.getAllRides({ sortDate: sortDate, pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, ...filters });
            if (response) setData(response.data);
        };
        fetchRides();
    }, [filters, pagingData.pageNumber, pagingData.pageSize, sortDate]);

    useEffect(() => {
        setPagingData(pagingSettings);
    }, [filters]);

    const ClearFilters = () => {
        datePicker.current.flatpickr.clear();
        setFilters({ cityFromId: null, cityToId: null, driverId: null, startDate: null, endDate: null, status: null });
    }
    const ShowRideDetails = (e) => {
        setRideDetails(e);
    }

    const handleSetSortDate = () => {
        if (counterChangeSortDate === 2) {
            setSortDate(null);
            setCounterChangeSortDate(0);
            return;
        }
        setCounterChangeSortDate(counterChangeSortDate + 1);
        if (sortDate === null || sortDate === 1) {
            setSortDate(0);
        }
        else if (sortDate == 0) {
            setSortDate(1);
        }
    }

    return (
        <>
            <style>
                {`
          .css-13cymwt-control {
            background-color: transparent!important;
            border: 1px solid #c5c5c7!important;
            border-radius: 4px!important;
          }
          .css-t3ipsp-control{
            background-color: transparent!important;
            border: 1px solid #c5c5c7!important;
            border-radius: 4px!important;
          }
        `}
            </style>
            <div className="row">
                <div className="col-12 mb-4 mb-sm-4">
                    <div className="d-sm-flex justify-content-between align-items-center ">
                        <h1 className="h4 mb-3 mb-sm-0">{t("RIDES")}</h1>
                        <div className="d-grid">

                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-md col-sm-12 mt-3">
                            <Flatpickr style={{ borderRadius: "4px", height: "38px", }}
                                options={{ disableMobile: true, mode: "range", dateFormat: "d.m.Y" }}
                                id="flatpickr"
                                type="date"
                                className="form-control flatpickr col-lg-8 col-sm-11 col-10"
                                placeholder={t("DATE")} readOnly="readonly"
                                onChange={(e) => setFilters({ ...filters, startDate: moment(e[0]).format("YYYY-MM-DD"), endDate: moment(e[1]).format("YYYY-MM-DD") })}
                                ref={datePicker}
                            />
                        </div>
                        <div className="col-md col-sm-12 mt-3">
                            <Select
                                key={reloadOptionsKey}
                                ref={statusSelectRef}
                                name="statusSelect"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                isSearchable={false}
                                className="basic-select"
                                onChange={(e) => {
                                    setFilters({ ...filters, status: e.value, statusLabel: e.label });
                                }}
                                placeholder={t("CHOOSE_RIDE_STATUS")}
                                options={rideStatus}
                                value={filters.statusLabel ? { label: filters.statusLabel, value: filters.status } : null}
                            />
                        </div>
                        <div className="col-md me-0 col-sm-12 mt-3">
                            <AsyncSelect
                                name="locationFrom"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                className="basic-single col"
                                noOptionsMessage={({ inputValue }) => !inputValue ? t("TYPE_IN_YOUR_LOCATION") : t("NO_RESULTS")}
                                loadingMessage={({ inputValue }) => t("LOADING")}
                                loadOptions={(e) => FilterData(e)}
                                placeholder={t("LEAVING_FROM")}
                                defaultOptions={true}
                                onChange={(e) => {
                                    setFilters({ ...filters, cityNameFrom: e.value, cityNameFrom: e.label });
                                    setValue("locationFrom", e);
                                }}
                                value={filters.cityNameFrom != null ? { label: filters.cityNameFrom, value: filters.cityNameFrom } : null}
                            />
                        </div>
                        <div className="col-md col-sm-12 mt-3">
                            <AsyncSelect
                                name="locationTo"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                className="basic-single col"
                                noOptionsMessage={({ inputValue }) => !inputValue ? t("TYPE_IN_YOUR_LOCATION") : t("NO_RESULTS")}
                                loadingMessage={({ inputValue }) => t("LOADING")}
                                loadOptions={(e) => FilterData(e)}
                                placeholder={t("GOING")}
                                defaultOptions={true}
                                onChange={(e) => {
                                    setFilters({ ...filters, cityNameTo: e.value, cityNameTo: e.label });
                                    setValue("locationTo", e);
                                }}
                                value={filters.cityNameTo != null ? { label: filters.cityNameTo, value: filters.cityNameTo } : null}

                            />
                        </div>
                        <div className="col-md col-sm-12 mt-3">
                            <AsyncSelect
                                name="driverId"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                className="basic-single col"
                                noOptionsMessage={({ inputValue }) => !inputValue ? t("TYPE_IN_DRIVER_NAME") : t("NO_RESULTS")}
                                loadingMessage={({ inputValue }) => t("LOADING")}
                                loadOptions={(e) => FilterDrivers(e)}
                                placeholder={t("DRIVER")}
                                onChange={(e) => {
                                    setFilters({ ...filters, driverId: e.value, driverName: e.label });
                                    setValue("driverId", e);
                                }}
                                value={filters.driverId != null && filters.driverName != null ? { label: filters.driverName, value: filters.driverId } : null}
                            />
                        </div>
                        <div className='col-md-auto col-12 pt-3  ps-0 d-flex justify-content-end mt-1 mt-md-0'>
                            <button onClick={() => ClearFilters()} className="btn d-flex w-100 justify-content-center ms-3 align-items-center btn-sm btn-neutral-soft border border-danger " >
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <input
                            className="form-check-input  cursor-pointer"
                            onClick={() => {
                                setShowRidesAboutToExpire(prev => !prev);
                                setFilters({ ...filters, reservationsAboutToExpire: !showRidesAboutToExpire });
                            }}

                            type="checkbox"
                            id='checkConfirm'
                        />
                        <label className='ms-2  cursor-pointer' htmlFor='checkConfirm'>{t("SHOW_RESERVATIONS_ABOUT_TO_EXPIRE")}</label>
                    </div>
                    <div className="bg-light rounded p-3 d-none d-lg-block">
                        <div className="row row-cols-7 g-4">
                            <div onClick={() => { handleSetSortDate(); }} className="col-lg cursor-pointer col-md-4 col-sm-6 ">
                                <h6 className="mb-0">{t("DATE")}
                                    <UpwardIcon size={"18px"} color={sortDate !== null && sortDate === 0 ? "#900fc8" : "grey"} styles={{ marginLeft: "5px", transform: "rotate(180deg)" }}></UpwardIcon>
                                    <UpwardIcon size={"18px"} color={sortDate !== null && sortDate === 1 ? "#900fc8" : "grey"}></UpwardIcon>

                                </h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("DESTINATION")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("DRIVER")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("PRICE")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("PROFIT")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("STATUS")}</h6>
                            </div>

                            <div className="col">
                                <h6 className="float-end mb-0">{t("ACTION")}</h6>
                            </div>
                        </div>
                    </div>

                    {data && data.items &&
                        data.items.map((data, index) => (
                            <div key={index} className={`row row-cols-xl-7 rounded align-items-lg-center border-bottom g-4 mx-0 pb-4 mt-0 ${data.reservationAboutToExpire == true && 'bg-critical '}`}>
                                <div className="col-12 col-sm d-inline-flex">
                                    <small className="d-block d-lg-none">{t("DATE")}:</small>
                                    <h6 className="mb-0 ms-2 fw-normal">{moment.utc(data.startDateTime).format('DD.MM.YYYY')}</h6>
                                </div>
                                <div className="col-12 col-sm d-inline-flex">
                                    <small className="d-block d-lg-none">{t("DESTINATION")}:</small>
                                    <h6 className="mb-0 ms-2 fw-normal">
                                        {data.rideMilestoneFrom}-{data.rideMilestoneTo}
                                    </h6>
                                </div>
                                <div className="col-12 col-sm d-inline-flex">
                                    <small className="d-block d-lg-none">{t("DRIVER")}:</small>
                                    <h6 className="mb-0 ms-2 fw-normal">{data.firstName} {data.lastName}</h6>
                                </div>
                                <div className="col-12 col-sm d-inline-flex">
                                    <small className="d-block d-lg-none">{t("TOTAL")}:</small>
                                    <h6 className="mb-0 ms-2 fw-normal">{data.price} BAM</h6>
                                </div>
                                <div className="col-12 col-sm d-inline-flex">
                                    <small className="d-block d-lg-none">{t("PROFIT")}:</small>
                                    <h6 className="mb-0 ms-2 fw-normal">{data.profit != null ? data.profit : 0} BAM</h6>
                                </div>
                                <div className="col-12 col-sm d-inline-flex">
                                    <small className="d-block d-lg-none">{t("STATUS")}:</small>
                                    <div className={`badge bg-opacity-10 ms-2 ${data.status == "Upcoming" ? "bg-processing me-2" : data.status == "Cancelled" ? "bg-danger text-danger me-2" : "bg-success text-success me-2"}`}>{data.status == "Upcoming" ? t("UPCOMING") : data.status == "Cancelled" ? t("CANCELLED") : t("COMPLETED")}</div>
                                </div>
                                <div className="col-md col-sm-12 d-flex justify-content-end me-2">
                                    <button className="btn btn-sm btn-primary-soft w-sm-100" onClick={() => ShowRideDetails(data)}>
                                        {t("DETAILS")}
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
                {rideDetails != null && !isMobile &&
                    <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 bg-dark bg-lighten-lg bg-opacity-50">
                        <div className="d-flex justify-content-center align-items-center h-100 " >
                            <div className="card mw-90 shadow">
                                <div className="card-header border-bottom">
                                    <h5 className="card-header-title me-2" style={{ display: "inline-block" }}>
                                        {t("RIDE_DETAILS")}
                                    </h5>
                                    -
                                    <span className="h6 fw-normal mb-0 ms-2" style={{ display: "inline-block" }}>
                                        # {rideDetails.id}
                                    </span>
                                </div>
                                <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => setRideDetails(null)}>
                                    <svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none">
                                        <path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                        </path>
                                        <path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                        </path>
                                    </svg>
                                </button>
                                <div className="card-body pb-0 " style={isMobile ? { width: "100%" } : { width: "550px" }}>
                                    <div className='row justify-content-between '>
                                        <div className='col-6 d-flex flex-column'>
                                            <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("DESTINATION")}:</label>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" height="19" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3.50008 5.66663H2.79175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M15.5418 5.66663H14.8335" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.16675 2.125V3.54167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.10425 3.54163H10.2292" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.91675 10.625H7.04175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.2917 10.625H13.4167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                                <p className='paragraphs mb-0'>
                                                    {rideDetails.rideMilestoneFrom}
                                                </p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 9 7" fill="none">
                                                    <path d="M5.52197 0.727539L8.2944 3.49997L5.52197 6.27239" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M0.529297 3.5H8.21628" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <p className='paragraphs mb-0'>
                                                    {rideDetails.rideMilestoneTo}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-6 d-flex flex-column'>
                                            <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("TIME_START")}:</label>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" className='ms-2' height="18" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.4302 2.92993L21.5002 8.99993L15.4302 15.0699" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.5 9H21.33" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M1 1L1 16" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <p className="paragraphs mb-0"> {moment.utc(rideDetails.startDateTime).format("DD.MM.YYYY, HH:mm")}h</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <div className='col d-flex flex-column'>
                                            <label className="labels fs12 fw-500 gray ms-0 mb-2">{t("DRIVER")}:</label>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <p className='paragraphs mb-0' >  <i className="bi bi-person"></i> {rideDetails.firstName} {rideDetails.lastName} </p>
                                            </div>
                                        </div>
                                        <div className='col d-flex flex-column'>
                                            <label className="labels fs12 fw-500 gray ms-0 mb-2">{t("STATUS")}:</label>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <i className="bi bi-wallet"></i>
                                                <p className={`badge bg-opacity-10 paragraphs mb-0 p-1 ${rideDetails.status == "Upcoming" ? "bg-processing" : rideDetails.status == "Cancelled" ? "bg-danger text-danger" : "bg-success text-success"}`}> {rideDetails.status == "Upcoming" ? t("UPCOMING") : rideDetails.status == "Cancelled" ? t("CANCELLED") : t("COMPLETED")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <div className='col d-flex flex-column'>
                                            <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("PROFIT")}:</label>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1529_6528)">
                                                        <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1529_6528">
                                                            <rect width="22.47" height="21.09" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <h5 className="BAM-big-purple "> {rideDetails.profit != null ? rideDetails.profit : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                            </div>
                                        </div>
                                        <div className='col d-flex flex-column'>
                                            <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("PRICE")}:</label>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1529_6528)">
                                                        <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1529_6528">
                                                            <rect width="22.47" height="21.09" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <h5 className="BAM-big-purple "> {rideDetails.price != null ? rideDetails.price : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                    <button className="btn text-danger border-0 me-2 ${props.onDiscard" onClick={() => setRideDetails(null)}>
                                        {t("CLOSE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {rideDetails != null && isMobile &&
                    <div className="position-fixed  z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                        <div className="d-flex justify-content-center align-items-center h-100 " >
                            <div className="card mw-90 w-90">
                                <div className="card-header border-bottom">
                                    <h5 className="card-header-title me-2" style={{ display: "inline-block" }}>
                                        {t("RIDE_DETAILS")}
                                    </h5>
                                    -
                                    <span className="h6 fw-normal mb-0 ms-2" style={{ display: "inline-block" }}>
                                        # {rideDetails.id}
                                    </span>
                                </div>
                                <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => setRideDetails(null)}>
                                    <svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none">
                                        <path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                        </path>
                                        <path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                        </path>
                                    </svg>
                                </button>
                                <div className="card-body pb-0 " style={isMobile ? { width: "100%" } : { width: "550px" }}>
                                    <div className='row justify-content-between '>
                                        <div className='col-auto d-flex flex-column'>
                                            <label className='labels fs14 fw-500 gray ms-0 mb-2'>{t("DESTINATION")}:</label>
                                        </div>
                                        <div className='col-auto d-flex flex-column'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" height="19" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3.50008 5.66663H2.79175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M15.5418 5.66663H14.8335" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.16675 2.125V3.54167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.10425 3.54163H10.2292" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.91675 10.625H7.04175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.2917 10.625H13.4167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                                <p className='paragraphs mb-0'>
                                                    {rideDetails.rideMilestoneFrom}
                                                </p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 9 7" fill="none">
                                                    <path d="M5.52197 0.727539L8.2944 3.49997L5.52197 6.27239" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M0.529297 3.5H8.21628" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <p className='paragraphs mb-0'>
                                                    {rideDetails.rideMilestoneTo}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <div className='col-auto d-flex flex-column'>
                                            <label className='labels fs14 fw-500 gray ms-0 mb-2'>{t("TIME_START")}:</label>
                                        </div>
                                        <div className='col-auto d-flex flex-column'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" className='ms-2' height="18" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.4302 2.92993L21.5002 8.99993L15.4302 15.0699" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.5 9H21.33" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M1 1L1 16" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <p className="paragraphs mb-0"> {moment.utc(rideDetails.startDateTime).format("DD.MM.YYYY, HH:mm")}h</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <div className='col-auto d-flex flex-column'>
                                            <label className="labels fs14 fw-500 gray ms-0 mb-2">{t("DRIVER")}:</label>
                                        </div>
                                        <div className='col-auto d-flex flex-column'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <p className='paragraphs mb-0' >  <i className="bi bi-person"></i> {rideDetails.firstName} {rideDetails.lastName} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <div className='col-auto d-flex flex-column'>
                                            <label className="labels fs14 fw-500 gray ms-0 mb-2">{t("STATUS")}:</label>
                                        </div>
                                        <div className='col-auto d-flex flex-column'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <i className="bi bi-wallet"></i>
                                                <p className={`badge bg-opacity-10 paragraphs mb-0 p-1 ${rideDetails.status == "Upcoming" ? "bg-processing" : rideDetails.status == "Cancelled" ? "bg-danger text-danger" : "bg-success text-success"}`}> {rideDetails.status == "Upcoming" ? t("UPCOMING") : rideDetails.status == "Cancelled" ? t("CANCELLED") : t("COMPLETED")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <div className='col-auto d-flex flex-column'>
                                            <label className='labels fs14 fw-500 gray ms-0 mb-2'>{t("PROFIT")}:</label>
                                        </div>
                                        <div className='col-auto d-flex flex-column'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1529_6528)">
                                                        <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1529_6528">
                                                            <rect width="22.47" height="21.09" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <h5 className="BAM-big-purple "> {rideDetails.profit != null ? rideDetails.profit : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-between mt-3'>
                                        <div className='col-auto d-flex flex-column'>
                                            <label className='labels fs14 fw-500 gray ms-0 mb-2'>{t("PRICE")}:</label>
                                        </div>
                                        <div className='col-auto d-flex flex-column'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1529_6528)">
                                                        <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                        <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                        <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                        <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                        <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1529_6528">
                                                            <rect width="22.47" height="21.09" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <h5 className="BAM-big-purple "> {rideDetails.price != null ? rideDetails.price : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                    <button className="btn text-danger border-0 me-2 ${props.onDiscard" onClick={() => setRideDetails(null)}>
                                        {t("CLOSE")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {data && <PagingComponent
                    className="pagination-bar"
                    currentPage={data.pageNumber ?? 0}
                    totalCount={data.totalCount ?? 0}
                    pageSize={data.pageSize ?? 0}
                    onPageChange={onPageChange}
                />}
            </div>
        </>
    );
});
export default RidesPage;
