import { useEffect, useState } from "react";
import { VehicleBrandsService } from "data/services";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toastify } from "utils/toastify";
const VehicleBrandEditModal = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    reset
  } = useForm();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const onSubmit = async (data) => {
    setFormSubmitting(true);
    const newVehicleBrandResponse = await VehicleBrandsService.edit({ id: props.vehicleBrandId, ...data });
    if (newVehicleBrandResponse.statusCode === 409) {
      setError("vehicleBrandExists")
      setFormSubmitting(false);
      return;
    }
    setFormSubmitting(false);
    if (newVehicleBrandResponse.isSuccess) {
      props.onSuccess();
      toastify.success(t("SUCCESSFULLY_EDITED_VEHICLE_BRAND"));
    } else if (newVehicleBrandResponse.isError) {
      toastify.error(t(newVehicleBrandResponse.error));
    }
  };

  useEffect(() => {
    const fetchVehicleBrandAndSetForm = async () => {
      const vehicleBrand = await VehicleBrandsService.getById(props.vehicleBrandId);
      setData(vehicleBrand.data);
      if (vehicleBrand.data) {
        reset(vehicleBrand.data);
      } else {
        props.onDiscard();
      }
    };
    fetchVehicleBrandAndSetForm();
  }, []);

  const handleDiscard = () => {
    props.onDiscard();
  }
  return (
    <>
      {data &&
        <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
          <div className="d-flex justify-content-center align-items-center h-100">
          <div className="card shadow mw-100" style={isMobile? {width:"90%", maxHeight: "70vh"} :{ width: "30%", maxHeight: "70vh" }}>
              <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                <span className="headers">{props.title}</span>
                <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => handleDiscard()}><svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none"><path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></button>
              </div>
              <div className="card-body" style={{overflowY:"auto"}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label className="form-label">{t("NAME")}</label>
                    <input
                      {...register("name", { required: t("VEHICLE_BRAND_IS_REQUIRED") })}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        clearErrors();
                        setValue("name", e.target.value);
                      }}
                      placeholder={t("VEHICLE_BRAND_PLACEHOLDER")}
                    />
                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                    {errors.vehicleBrandExists && (
                      <span className="text-danger">{t("VEHICLE_BRAND_EXISTS")}</span>
                    )}
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                      {t("DISCARD")}
                    </button>
                    <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                      {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                      {t("SAVE")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>}
    </>

  );

};

export default VehicleBrandEditModal;