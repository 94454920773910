import { httpSmartClient } from "../../config/httpClient";

const baseName = "TravelPreference";

const TravelPreferencesService = {
  getById: async (id) => {
    const response = await httpSmartClient.getById(`/${baseName}/` + id);
    return response;
  },
  getByUserId: async (id) => {
    const response = await httpSmartClient.get(`/${baseName}/GetByUserId`);
    return response;
  },

  getByParams: async (params) => {
    const response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return response;
  },

  add: async (travelPreference) => {
    const response = await httpSmartClient.post(`/${baseName}`, travelPreference);
    return response;
  },

  addTravelPreferences: async (travelPreference) => {
    const response = await httpSmartClient.postForm(`/${baseName}/AddTravelPreferences`, travelPreference);
    return response;
  },

  edit: async (travelPreference) => {
    const response = await httpSmartClient.put(`/${baseName}`, travelPreference);
    return response;
  },

  editTravelPreferences: async (travelPreference) => {
    const response = await httpSmartClient.putForm(`/${baseName}/UpdateTravelPreferences`, travelPreference);
    return response;
  },

  delete: async (id) => {
    const response = await httpSmartClient.delete(`/${baseName}/` + id);
    return response;
  },
};

export default TravelPreferencesService;
