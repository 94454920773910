import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment/moment";
import notificationsStore from "data/stores/NotificationsStore";
import { httpSmartClient } from "config/httpClient";
import userProfileStore from "data/stores/UserProfileStore";
import globalStore from "data/stores/GlobalStore";
import "../common.css"
import { NotificationsService } from "data/services";

const Notifications = observer((props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [theme, setTheme] = useState();
  const user = userProfileStore.user;

  useEffect(() => {

    const apiCall = async () => {
      if (window.location.pathname == "/sign-in")
        return;
      const response = await NotificationsService.getAll();
      notificationsStore.setNotifications(response.data, user.id);
    }
    apiCall();
  }, [globalStore.lng])

  useEffect(() => {
    const model = localStorage.getItem('theme')
    setTheme(model);
  }, [globalStore.theme])

  const MarkAllRead = async () => {
    notificationsStore.markAllRead();
    await NotificationsService.toggleIsReadByUserId();
  }
  const onClickFunction = async (notify) => {
    if ((notify.isReview == false || notify.isReview == null) && notify.isReviewOnUser == null) {
      navigate(`/rides/${notify.rideId}/${notify.rideMilestoneFromId}/${notify.rideMilestoneToId}`);
    } else {
      if (notify.userModel !== -1) {
        navigate(`/user/${notify.userModel}/ratings`, { state: { navigateFromDialog: true } })
      } else if (notify.isReviewOnUser == true) {
        navigate(`/user/${notify.userModelReviewOnUserId}/ratings`, { state: { navigateFromDialog: true } })
      }
      else {
        navigate(`/user/${notify.userId}/ratings`, { state: { navigateFromDialog: true } })
      }
    }
  }
  return (
    <ul className="notificationsList">
      <li onClick={() => MarkAllRead()} className="nav-item dropdown  ms-3" data-bs-toggle="dropdown">
        <a className="rounded-5 btn btn-purple p-0 mb-0 mt-2" href="#" role="button">
          {props.text === "black" ?
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
              <path d="M10.5168 2.54639C7.62053 2.54639 5.26678 4.90014 5.26678 7.79639V10.3251C5.26678 10.8589 5.03928 11.6726 4.76803 12.1276L3.76178 13.7989C3.14053 14.8314 3.56928 15.9776 4.70678 16.3626C8.47803 17.6226 12.5468 17.6226 16.318 16.3626C17.3768 16.0126 17.8405 14.7614 17.263 13.7989L16.2568 12.1276C15.9943 11.6726 15.7668 10.8589 15.7668 10.3251V7.79639C15.7668 4.90889 13.4043 2.54639 10.5168 2.54639Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
              <path d="M12.1359 2.79977C11.8647 2.72102 11.5847 2.65977 11.2959 2.62477C10.4559 2.51977 9.65094 2.58102 8.89844 2.79977C9.15219 2.15227 9.78219 1.69727 10.5172 1.69727C11.2522 1.69727 11.8822 2.15227 12.1359 2.79977Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.1406 16.6777C13.1406 18.1215 11.9594 19.3027 10.5156 19.3027C9.79813 19.3027 9.13312 19.0052 8.66062 18.5327C8.18812 18.0602 7.89062 17.3952 7.89062 16.6777" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" />
            </svg>
            :
            <svg width="19" height="19" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="notification">
                <path id="Vector" d="M10.5168 2.54688C7.62053 2.54688 5.26678 4.90063 5.26678 7.79688V10.3256C5.26678 10.8594 5.03928 11.6731 4.76803 12.1281L3.76178 13.7994C3.14053 14.8319 3.56928 15.9781 4.70678 16.3631C8.47803 17.6231 12.5468 17.6231 16.318 16.3631C17.3768 16.0131 17.8405 14.7619 17.263 13.7994L16.2568 12.1281C15.9943 11.6731 15.7668 10.8594 15.7668 10.3256V7.79688C15.7668 4.90938 13.4043 2.54688 10.5168 2.54688Z" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" />
                <path id="Vector_2" d="M12.1359 2.79977C11.8647 2.72102 11.5847 2.65977 11.2959 2.62477C10.4559 2.51977 9.65094 2.58102 8.89844 2.79977C9.15219 2.15227 9.78219 1.69727 10.5172 1.69727C11.2522 1.69727 11.8822 2.15227 12.1359 2.79977Z" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M13.1416 16.6777C13.1416 18.1215 11.9604 19.3027 10.5166 19.3027C9.7991 19.3027 9.1341 19.0052 8.6616 18.5327C8.1891 18.0602 7.8916 17.3952 7.8916 16.6777" stroke="white" strokeWidth="1.6" strokeMiterlimit="10" />
              </g>
            </svg>
          }
        </a>
        {notificationsStore.notifications.some(x => x.isRead === false) && <span className="notif-badge animation-blink" style={{ left: "20px", top: "8px" }}></span>}
        <div className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0 z-index-master">
          <div className="card bg-transparent">
            <div className="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom">
              <h6 className="m-0" style={{ color: "black" }}>
                {t("NOTIFICATIONS")}
              </h6>
            </div>
            <div className="card-body p-0">
              <ul className="list-group list-group-flush list-unstyled p-2 overflow-auto max-height-300 scrollbar-primary" style={{scrollbarColor:"#8d0bf8 #f5f5f5"}}>
                {notificationsStore.notifications.length > 0 ? notificationsStore.notifications.map((notification, index) => {
                  return (<li key={index} className="cursor-pointer" onClick={() => onClickFunction(notification)} >
                    <a className={`notification-list list-group-item list-group-item-action bg-info-soft rounded border-0 mb-1 ps-3 pe-3 ${!notification.isRead ? "notif-unread" : ""}`}>
                      <div className="d-flex py-1 align-items-center">
                        <div>
                          <img className="image-custom-notif" style={{ width: "40px", height:"38px" }} src={notification.sender && notification.sender.profilePhotoId ? httpSmartClient.getPhotoUrl(notification.sender.profilePhotoId) : notification.userCancellationId ?
                            notification.userCancellation.profilePhotoId ? httpSmartClient.getPhotoUrl(notification.userCancellation.profilePhotoId) :
                              "/assets/images/avatar/01.jpg" : "/assets/images/logo-icon-round.svg"} />
                        </div>
                        <div className="d-flex flex-column" style={{ minWidth: '250px' }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="ms-2 darkgray" style={{ width: "50%" }}>{notification.sender && notification.sender.firstName + " " + notification.sender.lastName}</h6>
                            <span className="fs12 gray fw-400" style={{}}>{moment(notification.createdAt).format("DD.MM.YYYY HH:mm")}</span> {/* Ovde koristiš marginLeft: 'auto' da bi poravnao desno */}
                          </div>
                          <p className="mb-0 gray ms-2 fs14 mt-1">{notification.senderId === 2 ? notification.userCancellationId ? notification.userCancellation.firstName + " " + notification.userCancellation.lastName + " " + `${t(notification.text)}` : `${t(notification.text)}` : notification.sender.firstName + " " + notification.sender.lastName + " " + `${t(notification.text)}`}</p>
                        </div>
                      </div>
                    </a>

                    {notificationsStore.notifications.length - 1 != index && <hr style={{ marginBottom: "7px", marginTop: "7px" }} />}
                  </li>)
                }) : <h6 className="p-2 m-auto" style={{ color: "black" }}>{t("NO_NOTIFICATIONS")}</h6>

                }
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
});

export default Notifications;
