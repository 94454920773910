import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "../home/hero/hero.css"

export default function QuestionsSection() {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className='py-0'>
            <div style={{ backgroundColor: 'white', marginBottom: '-200px' }}>
                <div style={{ backgroundColor: '#900fc8', marginBottom: '-400px' }}>
                    <div className={isMobile? "container" : "container pt-5"} style={{ backgroundColor: '#900fc8' }}>
                        <div className={isMobile ? "row justify-content-center pt-5" : "row justify-content-center pt-lg-0 pt-sm-7 pt-9"} 
                        style={isMobile?{backgroundColor: '#900fc8', height: '150px', alignContent: 'center'}:{ backgroundColor: '#900fc8', height: '150px', alignContent: 'center' }}>
                            <div className="col-auto">
                                <h4 className='text-white'>{t("FAQ")}</h4>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-10 pt-lg-0 pt-sm-0 pt-4">
                                <div className="accordion" id="accordionExample1">
                                    <div className="accordion-item bg-transparent" style={{ borderRadius: '0', borderRight: '0', borderLeft: '0', borderBottom: '0', borderTop: '1px solid white' }}>
                                        <h2 className="accordion-header bg-transparent border-0" id="headingOne">
                                            <button className="accordion-button bg-transparent text-light p-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                {t("Q_RIDE_RESERVE")}
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                            <div className="accordion-body bg-transparent text-white">
                                                <ul>
                                                    <li style={{fontWeight:"normal"}}>{t("SELECT_LOCATION")}</li>
                                                    <li style={{fontWeight:"normal"}}>{t("SELECT_TRAVEL_DATE")}</li>
                                                    <li style={{fontWeight:"normal"}}>{t("SELECT_RIDE_AND_DETAILS")}</li>
                                                    <li style={{fontWeight:"normal"}}>{t("RESERVE_A_RIDE")}</li>
                                                </ul>
                                                <span>{t("COMMUNICATE_WITH_DRIVER")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item bg-transparent" style={{ borderRadius: '0', borderRight: '0', borderLeft: '0', borderBottom: '0', borderTop: '1px solid white' }}>
                                        <h2 className="accordion-header bg-transparent border-0" id="headingTwo">
                                            <button className="accordion-button bg-transparent text-light  p-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                {t("Q_RIDE_BOOK_A_RIDE")}
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                            <div className="accordion-body bg-transparent text-white">
                                                <span>{t("POST_RIDE_EXPLANATION")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item bg-transparent" style={{ borderRadius: '0', borderRight: '0', borderLeft: '0', borderBottom: '0', borderTop: '1px solid white' }}>
                                        <h2 className="accordion-header bg-transparent border-0" id="headingThree">
                                            <button className="accordion-button bg-transparent text-light  p-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                {t("Q_RIDE_CANCEL")}
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordionExample2">
                                            <div className="accordion-body bg-transparent text-white">
                                                <span>{t("RIDE_CANCEL")}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item bg-transparent" style={{ borderRadius: '0', borderRight: '0', borderLeft: '0', borderBottom: '1px solid white', borderTop: '1px solid white' }}>
                                        <h2 className="accordion-header bg-transparent border-0" id="headingFour">
                                            <button className="accordion-button bg-transparent text-light  p-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                {t("Q_RIDE_PRICE_SHARE")}
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordionExample2">
                                            <div className="accordion-body bg-transparent text-white">
                                                <span>{t("RIDE_PRICE_SHARE_DETAILS")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ height: '380px' }}>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
