import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import getAppPagingSettings from "../../../../config/paging/pagingConsts";
import PagingComponent from "../../../layout/components/PagingComponent";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import _debounce from 'debounce';
import { ViberMessagesService } from "data/services";
import { UpwardIcon } from "components/layout/components/Icons";
import Select from "react-select";
import globalStore from "data/stores/GlobalStore";
import { observer } from "mobx-react-lite";

const ViberMessagesPage = observer(() => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    let pagingSettings = getAppPagingSettings();
    const [pagingData, setPagingData] = useState(pagingSettings);
    const [showDetails, setShowDetails] = useState(false);
    const datePicker = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [transactionDetail, setTransactionDetail] = useState();
    const [sortDate, setSortDate] = useState(null);
    const [counterChangeSortDate, setCounterChangeSortDate] = useState(0);
    const [reloadOptionsKey, setReloadOptionsKey] = useState(0);
    const statusSelectRef = useRef(null);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        status: null
    });
    const fetchAndSetMessages = async () => {
        const response = await ViberMessagesService.getByParams({ sortDate: sortDate, pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, ...filters });
        setData(response.data);
    }
    useEffect(() => {
        fetchAndSetMessages();
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [filters, pagingData.pageNumber, pagingData.pageSize, sortDate])

    const reloadOptions = () => {
        setReloadOptionsKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        if (statusSelectRef.current?.props?.value) {
            const currentViberMessageStatus = viberMessageStatus.find(x => x.value === filters.status);
            filters.status = currentViberMessageStatus.value;
            filters.statusLabel = currentViberMessageStatus.label;
            reloadOptions();
        }
    }, [globalStore.lng])

    const viberMessageStatus = [
        {
            value: "DELIVERED",
            label: t("DELIVERED")
        },
        {
            value: "PENDING",
            label: t("PENDING")
        },
        {
            value: "REJECTED",
            label: t("REJECTED")
        },
        {
            value: "UNDELIVERABLE",
            label: t("UNDELIVERABLE")
        },
        {
            value: "EXPIRED",
            label: t("EXPIRED")
        }

    ]
    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
    }
    const searchQuery = (e) => {
        const params = { ...filters, phoneNumber: e.target.value };
        setFilters(params);
        fetchAndSetMessages();
    };

    const ClearFilters = () => {
        let searchElement = document.getElementById("searchViberMessage");
        searchElement.value = "";
        datePicker.current.flatpickr.clear();
        setFilters({ startDate: null, endDate: null, phoneNumber: null });
    }

    const handleSetSortDate = () => {
        if (counterChangeSortDate === 2) {
            setSortDate(null);
            setCounterChangeSortDate(0);
            return;
        }
        setCounterChangeSortDate(counterChangeSortDate + 1);
        if (sortDate === null || sortDate === 1) {
            setSortDate(0);
        }
        else if (sortDate == 0) {
            setSortDate(1);
        }
    }

    const ShowMessageDetail = (message) => {
        setCurrentMessage(message);
        setShowDetails(true);
    }

    return (
        <>
            <style>
                {`
          .css-13cymwt-control {
            background-color: transparent!important;
            border: 1px solid #c5c5c7!important;
            border-radius: 4px!important;
          }
          .css-t3ipsp-control{
            background-color: transparent!important;
            border: 1px solid #c5c5c7!important;
            border-radius: 4px!important;
          }
        `}
            </style>
            <div className="row">

                <div className="col-12 mb-4 mb-sm-4">
                    <div className="d-sm-flex justify-content-between align-items-center ">
                        <h1 className="h4 mb-3 mb-sm-0">{t("VIBER_MESSAGES")}</h1>
                    </div>
                </div>
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-md-3 col-sm-12 mt-3">
                            <div className="form-group ">
                                <form className="rounded position-relative">
                                    <input style={{ height: "38px", borderRadius: "5px" }} id="searchViberMessage" className="form-control bg-transparent" type="text" placeholder={t("SEARCH_PHONE_NUMBER")} aria-label="Search" onChange={_debounce(searchQuery, 500)} />
                                    <button className="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                                        <i className="fas fa-search fs-6"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12 mt-3">
                            <Flatpickr style={{ borderRadius: "4px", height: "38px", }}
                                options={{ disableMobile: true, mode: "range", dateFormat: "d.m.Y" }}
                                id="flatpickr"
                                type="date"
                                className="form-control flatpickr col-lg-8 col-sm-11 col-10"
                                placeholder={t("DATE")} readOnly="readonly"
                                onChange={(e) => setFilters({ ...filters, startDate: moment(e[0]).format("YYYY-MM-DD"), endDate: moment(e[1]).format("YYYY-MM-DD") })}
                                ref={datePicker}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mt-3">
                            <Select
                                key={reloadOptionsKey}
                                ref={statusSelectRef}
                                name="statusSelect"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                isSearchable={false}
                                className="basic-select"
                                onChange={(e) => {
                                    setFilters({ ...filters, status: e.value, statusLabel: e.label });
                                    setFilters({ ...filters, status: e.value, statusLabel: e.label });
                                }}
                                placeholder={t("CHOOSE_RIDE_STATUS")}
                                options={viberMessageStatus}
                                value={filters.statusLabel ? { label: filters.statusLabel, value: filters.status } : null}

                            />
                        </div>
                        <div className='col-md col-sm-12 pt-3  ps-0 d-flex justify-content-end mt-1 mt-md-0'>
                            <button onClick={() => ClearFilters()} className="btn d-flex  justify-content-center w-sm-100 ms-3 align-items-center btn-sm btn-neutral-soft border border-danger " >
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                    </div>
                    <div className="bg-light rounded  p-3 d-none d-lg-block">
                        <div className="row row-cols-7 g-4">
                            <div onClick={() => { handleSetSortDate(); }} className="col-lg cursor-pointer col-md-4 col-sm-6 ">
                                <h6 className="mb-0">{t("DATE")}
                                    <UpwardIcon size={"18px"} color={sortDate !== null && sortDate === 0 ? "#900fc8" : "grey"} styles={{ marginLeft: "5px", transform: "rotate(180deg)" }}></UpwardIcon>
                                    <UpwardIcon size={"18px"} color={sortDate !== null && sortDate === 1 ? "#900fc8" : "grey"}></UpwardIcon>

                                </h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("RECEIVER")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("CONTENT")}</h6>
                            </div>

                            <div className="col">
                                <h6 className="mb-0">{t("STATUS")}</h6>
                            </div>
                            <div className="col">
                                <h6 className=" mb-0">{t("PRICE_PER_MESSAGE")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="float-end mb-0">{t("ACTION")}</h6>
                            </div>
                        </div>
                    </div>
                    {(!data || !data.items || data.items.length === 0) && (
                        <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
                            <div className="col text-center">{t("NO_RESULTS")}</div>
                        </div>
                    )}
                    {data && data.items && data.items.map((message, index) => (
                        <div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("DATE")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">{moment.utc(message.createdAt).format('DD.MM.YYYY HH:mm')}</h6>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("RECEIVER")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">
                                    <span>{message.destination.replace(/\s/g, '')}</span>
                                </h6>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("MESSAGE")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">{message.message.length > 25 ? `${message.message.substring(0, 25)}...` : message.message}</h6>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("STATUS")}:</small>
                                <div className={`badge bg-opacity-10 ms-2 ${message.statusGroupName === "PENDING" ? "bg-processing me-2" : message.statusGroupName === "REJECTED" ? "bg-danger text-danger me-2" : message.statusGroupName === "DELIVERED" ? "bg-success text-success me-2" : message.statusGroupName === "UNDELIVERABLE" ? "bg-warning text-warning me-2" : "bg-secondary text-secondary me-2"}`}>{t(message.statusGroupName)}</div>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("PRICE_PER_MESSAGE")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">
                                    <span>{message.price} $</span>
                                </h6>
                            </div>
                            <div className="col-md col-sm-12 d-flex justify-content-end me-2">
                                <button className="btn btn-sm btn-primary-soft w-sm-100" onClick={() => ShowMessageDetail(message.message)}>
                                    {t("DETAILS")}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {data && <PagingComponent
                    className="pagination-bar"
                    currentPage={data.pageNumber ?? 0}
                    totalCount={data.totalCount ?? 0}
                    pageSize={data.pageSize ?? 0}
                    onPageChange={onPageChange}
                />}
            </div>

            {showDetails &&
                <div className="position-fixed  z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="card shadow  mw-90">
                            <div className="card-header border-bottom">
                                <h5 className="card-header-title">{t("VIBER_MESSAGE_DETAIL")}</h5>
                            </div>
                            <div className="card-body pb-0" style={{ width: "500px" }} >
                                <div className="row justify-content-around text-start mb-4">
                                    <div className="col-lg-12">
                                        <p className="pharagraph mb-0">
                                            {currentMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-2 justify-content-end mt-4">
                                <button className="btn text-danger border-0 me-2 ${props.onDiscard" onClick={() => setShowDetails(false)}>
                                    {t("CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </>
    );
});
export default ViberMessagesPage;