import React from 'react'
import { useTranslation } from 'react-i18next'

function TodayMessage() {
    const { t } = useTranslation();
    return (
        <div className='d-flex justify-content-between align-items-center'>
            <hr className='today-message-hr' />
            <span className='pb-1' style={{ fontSize: '80%' }}>{t("TODAY")}</span>
            <hr className='today-message-hr' />
        </div>
    )
}

export default TodayMessage
