import { httpSmartClient } from "../../config/httpClient";

const baseName = "Chats";

const ChatsService = {
    getById: async (id) => {
        var response = await httpSmartClient.getById(`/${baseName}/` + id);
        return response;
    },
    getUnreadChats: async () => {
        var response = await httpSmartClient.get(`/${baseName}/GetUnreadChats`);
        return response;
    },
    getByGuid: async (guid) => {
        var response = await httpSmartClient.getById(`/${baseName}/GetByGuid/` + guid);
        return response;
    },
    getChatGuidBetweenUsers: async (params) => {
        var response = await httpSmartClient.get(`/${baseName}/GetChatGuidBetweenUsers`, { params });
        return response;
    },
    getPagedChatsForUser: async (params) => {
        var response = await httpSmartClient.getPaged(`/${baseName}/GetPagedChatsForUser`, { params });
        return response;
    },
    getPaged: async (params) => {
        var response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
        return response;
    },
    add: async (chat) => {
        var response = await httpSmartClient.post(`/${baseName}/`, chat);
        return response;
    },
    edit: async (chat) => {
        var response = await httpSmartClient.put(`/${baseName}`, chat);
        return response;
    },
    delete: async (id) => {
        var response = await httpSmartClient.delete(`/${baseName}/` + id);
        return response;
    },
    getTop5: async () => {
        var response = await httpSmartClient.get(`/${baseName}/Top5`);
        return response;
    }
};

export default ChatsService;
