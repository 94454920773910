import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import { ConfirmationDialog } from 'components/modules/public/dialogs';
import { CancellationPoliciesService } from 'data/services';
import { toastify } from 'utils/toastify';
import CancellationPolicyAdd from './CancellationPolicyAdd';
import CancellationPolicyEdit from './CancellationPolicyEdit';

function CancellationPolicy() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState();
    const [showEditDialog, setShowEditDialog] = useState();
    const [currentId, setCurrentId] = useState();
    const [data, setData] = useState();
    const [render, setRender] = useState();
    useEffect(() => {
        const api = async () => {
            const response = await CancellationPoliciesService.getByParams({ pageNumber: 1, pageSize: 999 })
            setData(response.data);
        }
        api();
    }, [render])
    const handleChange = (id) => {
        setShowConfirmationDialog(true);
        setCurrentId(id)
    }
    const onClickEditButton = (id) => {
        setShowEditDialog(true);
        setCurrentId(id)
    }
    const handleDelete = async (id) => {
        const response = await CancellationPoliciesService.delete(id);
        setShowConfirmationDialog(false)
        if (response.isSuccess) {
            toastify.success(t("SUCCESS_DELETE_CANCELLATION_POLICY"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png" />)
            setRender(prev => !prev)
        }
        else {
            toastify.error(t("ERROR_DELETING_CANCELLATION_POLICY"))
        }
    }
    const handleDiscard = () => {
        setShowAddDialog(false);
        setShowEditDialog(false);
    }
    const addCurrentState = (addData) => {
        setRender(prev => !prev)
    }
    const editCurrentState = (editData) => {
        setRender(prev => !prev)
    }
    return (
        <div className='col-md-12 col-sm-12'>
            <div className="card shadow">
                <div className="col-sm-12 pb-0 mb-sm-2 p-3">
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <h1 className="h4 mb-3 mb-sm-0 col-8">{t("CANCELLATION_POLICY")}</h1>
                        <Tooltip style={{ width: "100%", zIndex: 1 }} id="tooltip-cancellation-policy" ></Tooltip>
                        <span className='me-2 d-flex justify-content-end col-1 mt-2' data-tooltip-id="tooltip-cancellation-policy" data-tooltip-content={t("TOOLTIP_CANCELLATIONPOLICY")}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill='grey' height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                        </span>
                        <div className="d-grid mt-3 ">
                            <a href="#" className="btn btn-primary-soft" onClick={() => setShowAddDialog(true)}>
                                + {t("NEW_CANCELLATION_POLICY")}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className="bg-light rounded p-3 d-none d-lg-block">
                        <div className="row row-cols-7 g-4">
                            <div className="col">
                                <h6 className="mb-0">{t("HOURS_BEFORE_CANCELLATION")}</h6>
                            </div>
                            <div className="col text-center">
                                <h6 className="mb-0">{t("RETURN_PERCENTAGE")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="float-end mb-0">{t("ACTION")}</h6>
                            </div>
                        </div>
                    </div>

                    {data && data.items.length > 0 && data.items.map((cancellationPolicy, index) => (
                        <div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
                            <div className="col">
                                <small className="d-block d-lg-none">{t("HOURS_BEFORE_CANCELLATION")}:</small>
                                <h6 className="mb-0 fw-normal">{cancellationPolicy.hoursBeforeCancellation}</h6>
                            </div>
                            <div className="col">
                                <small className="d-block d-lg-none">{t("RETURN_PERCENTAGE")}:</small>
                                <h6 className="mb-0 fw-normal">{cancellationPolicy.returnPercentage}%</h6>
                            </div>

                            <div className="col-auto">
                                <button className="btn btn-sm btn-primary-soft me-2" onClick={() => onClickEditButton(cancellationPolicy.id)}>
                                    <i className="bi bi-pencil-square"></i>
                                </button>
                                <button className="btn btn-sm btn-danger-soft border border-danger " onClick={() => handleChange(cancellationPolicy.id)}>
                                    <i className="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showConfirmationDialog && <ConfirmationDialog
                onDiscard={() => setShowConfirmationDialog(false)}
                title={t("DELETE_CANCELLATION_POLICY_ITEM")}
                description={t("DELETE_CANCELLATION_POLICY")}
                confirmBtnLabel={t("DELETE")}
                onConfirm={() => handleDelete(currentId)}
            ></ConfirmationDialog>}
            {showAddDialog && <CancellationPolicyAdd addCurrentState={addCurrentState} onDiscard={handleDiscard}></CancellationPolicyAdd>}
            {showEditDialog && <CancellationPolicyEdit editCurrentState={editCurrentState} id={currentId} onDiscard={handleDiscard}></CancellationPolicyEdit>}
        </div>
    )
}

export default CancellationPolicy
