import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { toastify } from 'utils/toastify';
import { useWindowSize } from '@uidotdev/usehooks';
import { useTranslation } from "react-i18next";
import Vehicle from './Vehicle';
import MyVehicleAdd from './MyVehicleAdd'
import MyVehicleEdit from './MyVehicleEdit'
import { UserVehiclesService } from 'data/services';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import vehicleStore from 'data/stores/VehiclesStore';
import globalStore from 'data/stores/GlobalStore';
import { ConfirmationDialog } from '../userChunks/modals';
import { PagingComponent } from 'components/layout/components';

const MyVehicles = observer(() => {
    let pagingSettings = { pageNumber: 1, pageSize: 6 }
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [vehicles, setVehicles] = useState();
    const [totalVehicles, setTotalVehicles] = useState(0);
    const [currentId, setCurrentId] = useState();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showNewVehicleDialog, setShowNewVehicleDialog] = useState(false);
    const [showEditVehicleDialog, setShowEditVehicleDialog] = useState(false);
    const [fetchVehicles, setFetchVehicles] = useState(false);
    const [isMobile, setIsMobile] = useState();
    const [pagingData, setPagingData] = useState(pagingSettings);
    const size = useWindowSize();

    const fetchAndSetUserVehicleData = async () => {
        const response = await UserVehiclesService.getForLoggedUser({ pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize });
        if (response.isSuccess) {
            setData(response.data);
            setVehicles(response.data.items);
            if (response.data) {
                setTotalVehicles(response.data.totalCount);
            }
            else {
                setTotalVehicles(0);
            }
        } else {
            console.log(response.error);
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        fetchAndSetUserVehicleData();
    }, [pagingData.pageNumber, pagingData.pageSize, fetchVehicles])

    const deleteVehicleModel = async (id) => {
        const deleted = await UserVehiclesService.deactivateVehicle(id);
        if (deleted.isSuccess) {
            //const index = vehicles.findIndex(x => x.id === deleted.id)
            //const Arr = vehicles;
            //Arr.splice(index, 1);
            //setVehicles(Arr);
            //setTotalVehicles(totalVehicles-1);
            fetchAndSetUserVehicleData();
            toastify.success(t("SUCCESSFULLY_DEACTIVATE_VEHICLE"));
            setShowConfirmationDialog(false);
        } else if (deleted.statusCode === 409) {
            toastify.error(t("ERROR_TRYING_DEACTIVATE_ACTIVATE_VEHICLE"));
            setShowConfirmationDialog(false);
        }
        else {
            toastify.error(t("ERROR_DEACTIVATING_VEHICLE"));
            setShowConfirmationDialog(false);
        }
    };
    const triggerDeleteDialog = (props) => {
        setShowConfirmationDialog(prev => !prev);
        setCurrentId(props?.vehicleId)
    }
    const triggerAddVehicleDialog = (prop) => {
        setShowNewVehicleDialog(prev => !prev);

    }
    const triggerEditVehicleDialog = (props) => {
        setCurrentId(props.vehicleId)
        if (props && !props.targetId) {
            setShowEditVehicleDialog(prev => !prev)
        }
    }
    const closeEditVehicleDialog = () => {
        setShowEditVehicleDialog(prev => !prev)

    }
    const onSuccess = () => {
        setFetchVehicles(prev => !prev)
    }
    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
    }
    useEffect(() => {
        if (size.width < 765) {
            setIsMobile(true);
            const outlet = document.getElementById("outlet");
            outlet.style.marginBottom = "0px";
        }
        else {
            setIsMobile(false)
        }
        if (size.width >= 1400) {
            const outlet = document.getElementById("outlet");
            outlet.classList.remove("overflow-scroll")
            outlet.classList.remove("w-50")
            outlet.className = "w-75 col-md";
            outlet.style.marginBottom="0px";
        }
        else {
            const outlet = document.getElementById("outlet");
            outlet.classList.remove("overflow-scroll")
            outlet.classList.remove("w-75")
            outlet.className = "w-73"
        }
        if (size.width >= 1200) {
            const div = document.createElement('div')
            div.className = "div-mobile"
            const header = document.getElementById("profileHeader")
            header.classList.remove("d-none")
            header.className = "profileHeader"
            const element = document.createElement("h6");
            const spanElement = document.createElement("span");
            spanElement.innerText = t("MY_VEHICLES_ADD_EDIT")
            spanElement.className = 'span-header mt-0'
            element.innerText = t("MY_VEHICLES")
            element.className = 'header-profile mb-1';
            div.appendChild(element)
            div.appendChild(spanElement)
            header.appendChild(div)
            return () => header.removeChild(div);
        }
        else {
            const header = document.getElementById("profileHeader");
            header.classList.remove("profileHeader")

        }

    }, [size, globalStore.lng])
    useEffect(() => {
        if (size.width < 768) {
            const header = document.getElementById("header-height");
            header.className = "my-vehicle-add-header-row header-height z-index-master";
            return () => {
                header.classList.remove("my-vehicle-add-header-row")
                header.classList.remove("z-index-master")
            }
        }
    }, [vehicleStore.showNewVehicleDialog, vehicleStore.showEditVehicleDialog])
    return (
        <>
            <Modal
                open={vehicleStore.showNewVehicleDialog}
                onClose={() => { vehicleStore.setShowNewVehicleDialog(false); }}
                closeIcon={globalStore.closeIcon}
                modalId='add-vehicle'
                classNames={{
                    modal: 'my-vehicle-add-mobile my-vehicle-add',
                    overlay: 'my-vehicle-add-mobile-overlay ',
                    modalAnimationIn: "modalAnimationIn",
                    closeButton: "mt-2 me-3"
                }}
                center
            >
                <MyVehicleAdd
                    onDiscard={(prop) => triggerAddVehicleDialog(prop)}
                    onSuccess={() => onSuccess()}
                ></MyVehicleAdd>
            </Modal>
            <Modal
                open={vehicleStore.showEditVehicleDialog}
                onClose={() => { vehicleStore.setShowEditVehicleDialog(false); }}
                closeIcon={globalStore.closeIcon}
                modalId='edit-vehicle'
                classNames={{
                    modal: 'my-vehicle-add-mobile my-vehicle-add',
                    overlay: 'my-vehicle-add-mobile-overlay ',
                    modalAnimationIn: "modalAnimationIn",
                    closeButton: "mt-2 me-3"
                }}
                center
            >
                <MyVehicleEdit
                    onDiscard={() => closeEditVehicleDialog()}
                    onSuccess={() => onSuccess()}
                    userVehicleId={currentId}
                ></MyVehicleEdit>
            </Modal>
            {isMobile && <div className="d-flex w-100 pt-4 pb-5 bg-purple flex-column text-center">
                <p className='fs18 white  fw-600'>{t("MY_VEHICLES")}</p>
                <p className='fs12 white  fw-600'>{t("MY_VEHICLES_ADD_EDIT")}</p>
            </div>
            }
            {/* {isMobile && <div style={{ position: "relative", marginTop: "-3px" }} className="d-flex w-100 pt-1 pb-2 bg-purple flex-column text-center">
            </div>} */}
            <div className={isMobile?'vehicle-page bg-light':'vehicle-page mt-4 mt-md-4'} style={isMobile?{marginTop:"-20px", top:"0px", borderRadius:"1.5em"}:{}}>
                <div className={isMobile ? '' : "container"} style={isMobile ? { width: "90%", margin: "auto", marginTop:"-30px" } : {}}>
                    {data && data.totalCount > 0 && <div className={!isMobile ? 'd-flex justify-content-between pb-3 pt-4 pt-md-0 ps-2 ps-md-0' :
                        'd-flex justify-content-between pb-3 pt-4 pt-md-0 ps-2 ps-md-0 mt-5'}>
                        {data && <span className='headers'>{data && t("CURRENT_VEHICLE_NUMBER") + totalVehicles}</span>}
                        {!isMobile && <div className='d-flex justify-content-end cursor-pointer ' onClick={() => { vehicleStore.setShowNewVehicleDialog(true) }}>
                            <svg width="20" height="20" className='me-2' viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3.50008 5.66663H2.79175" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5418 5.66663H14.8335" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.16675 2.125V3.54167" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.10425 3.54163H10.2292" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.91675 10.625H7.04175" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.2917 10.625H13.4167" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p className='paragraphs-purple-large'>{t("ADD_NEW_VEHICLE")}</p>
                        </div>
                        }
                    </div>}
                    {data && data.totalCount === 0 && !isMobile && <>
                        <div className='d-flex justify-content-end cursor-pointer me-5 ' onClick={() => { vehicleStore.setShowNewVehicleDialog(true); }}>
                            <svg width="20" height="20" className='me-2' viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3.50008 5.66663H2.79175" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5418 5.66663H14.8335" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.16675 2.125V3.54167" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.10425 3.54163H10.2292" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.91675 10.625H7.04175" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.2917 10.625H13.4167" stroke="#900FC8" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p className='paragraphs-purple-large'>{t("ADD_NEW_VEHICLE")}</p>
                        </div>
                        <div className='d-flex justify-content-between mb-4 pe-4'>
                            <div className="w-100 text-center"><span className="labels">{t("NO_VEHICLE")}</span></div>
                        </div></>
                    }
                    {data && data.totalCount === 0 && isMobile && <>
                        <div className='d-flex justify-content-between mb-4 mt-7 pe-4'>
                            <div className="w-100 text-center"><span className="labels">{t("NO_VEHICLE")}</span></div>
                        </div></>
                    }
                </div>
                <div className={isMobile ? "" : "container"} style={isMobile ? { width: "90%", margin: "auto" } : {}}>
                    <div className='row justify-content-md-between justify-content-sm-center'>
                        {vehicles && vehicles.map((vehicle, index) => {
                            return <Vehicle
                                key={index}
                                editDialog={(props) => triggerEditVehicleDialog(props)}
                                deleteVehicleModel={deleteVehicleModel}
                                currentId={vehicle.id}
                                vehicle={vehicle}
                                showConfirmationDialog={showConfirmationDialog}
                                triggerDialog={(id) => triggerDeleteDialog(id)} />
                        })}
                        {/* {isMobile && data && data.totalCount === 2 && <div style={{ marginTop: "100px" }}></div>}
                        {isMobile && data && data.totalCount === 1 && <div style={{ marginTop: "320px" }}></div>}
                        {isMobile && data && data.totalCount === 0 && <div style={{ marginTop: "520px" }}></div>} */}
                        {isMobile &&
                            <div>
                                <button onClick={() => { vehicleStore.setShowNewVehicleDialog(true) }} style={{ backgroundColor: "#900fc8", color: "white", width: "100%", borderRadius: "18px", textAlign: "left" }} className="btn mb-3">
                                    <div className="row align-self-end py-1">
                                        <div className="col-1" style={{ marginTop: "auto", marginBottom: "auto" }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="vuesax/linear/driving">
                                                    <g id="driving">
                                                        <g id="Group">
                                                            <path id="Vector" d="M14.5502 2H9.4502C7.6502 2 7.2502 2.90001 7.0202 4.01001L6.2002 7.92999H17.8002L16.9802 4.01001C16.7502 2.90001 16.3502 2 14.5502 2Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <g id="Group_2">
                                                                <path id="Vector_2" d="M19.2401 14.3199C19.3201 15.1699 18.6401 15.9 17.7701 15.9H16.4101C15.6301 15.9 15.5201 15.57 15.3801 15.15L15.23 14.7199C15.03 14.1299 14.9001 13.7299 13.8501 13.7299H10.1401C9.10005 13.7299 8.94005 14.1799 8.76005 14.7199L8.61005 15.15C8.47005 15.56 8.36006 15.9 7.58006 15.9H6.22005C5.35005 15.9 4.67005 15.1699 4.75005 14.3199L5.16006 9.89996C5.26006 8.80996 5.47005 7.91992 7.37005 7.91992H16.62C18.52 7.91992 18.7301 8.80996 18.8301 9.89996L19.2401 14.3199Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                            <path id="Vector_3" d="M6.20021 5.75H5.47021" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path id="Vector_4" d="M18.5303 5.75H17.8003" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path id="Vector_5" d="M7.6499 10.8301H9.81992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path id="Vector_6" d="M14.1802 10.8301H16.3502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </g>
                                                        <path id="Vector_7" d="M12 17V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path id="Vector_8" d="M12 21V22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path id="Vector_9" d="M3 18L2 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path id="Vector_10" d="M21 18L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="col fw-500 " style={{ fontSize: "14px", marginLeft: "-5px", marginTop: "2px" }}>
                                            {t("ADD_NEW_VEHICLE")}
                                        </div>
                                        <div className="col-1" style={{ marginRight: "15px", marginTop: "auto", marginBottom: "auto" }}>
                                            <i className="bi bi-chevron-right"></i>
                                        </div>
                                    </div>
                                </button>
                            </div>}
                    </div>
                    <PagingComponent
                        className="pagination-bar mb-2"
                        currentPage={data.pageNumber ?? 0}
                        totalCount={data.totalCount ?? 0}
                        pageSize={data.pageSize ?? 0}
                        onPageChange={onPageChange}
                        pageCount={data.pageCount}
                    />
                </div>
                <Modal
                    open={showConfirmationDialog}
                    onClose={() => setShowConfirmationDialog(false)}
                    closeIcon={globalStore.closeIcon}
                    classNames={{
                        modal: 'modal-no-padding',
                        modalContainer: 'modal-container-animation-none',
                        closeButton: "mt-2 me-2"
                    }}
                    center
                >
                    <ConfirmationDialog
                        onDiscard={() => triggerDeleteDialog()}
                        title={t("DEACTIVATE_VEHICLE")}
                        description=
                            {<div>
                            {t("DEACTIVATE_VEHICLE_DETAIL")}
                            <br /><br />
                            <span className="darkgray fw-600">{t("NOTE1")}</span>
                            <span className="italic"> {t("DEACTIVATE_VEHICLE_DETAIL_NOTE")}</span>
                            </div>}
                        confirmBtnLabel={t("DEACTIVATE_VEHICLE")}
                        onConfirm={() => deleteVehicleModel(currentId)}
                    ></ConfirmationDialog>
                </Modal>
            </div>
        </>
    )
})
export default MyVehicles;