import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAppPagingSettings } from "config/paging";
import { PagingComponent } from "components/layout/components";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import globalStore from "data/stores/GlobalStore";
import { CreditCardTransactionsService, RideMilestonesService } from "data/services";
import { UpwardIcon } from "components/layout/components/Icons";
import AsyncSelect from 'react-select/async';
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";

const TransactionsPage = observer(() => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    let pagingSettings = getAppPagingSettings();
    const [pagingData, setPagingData] = useState(pagingSettings);
    const [showDetails, setShowDetails] = useState(false);
    const datePicker = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [transactionDetail, setTransactionDetail] = useState();
    const [sortDate, setSortDate] = useState(null);
    const [counterChangeSortDate, setCounterChangeSortDate] = useState(0);
    const [defaultFetched, setDefaultFetched] = useState(false);
    const statusSelectRef = useRef(null);
    const [reloadOptionsKey, setReloadOptionsKey] = useState(0);
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        status: null
    });

    const {
        formState: { errors },
        setValue,
        register,
        resetField,
        reset
    } = useForm();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const reloadOptions = () => {
        setReloadOptionsKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        if (statusSelectRef.current?.props?.value) {
            const currentTransactionStatus = transactionStatus.find(x => x.value === filters.status);
            filters.status = currentTransactionStatus.value;
            filters.statusLabel = currentTransactionStatus.label;
            reloadOptions();
        }
    }, [globalStore.lng])

    useEffect(() => {
        const fetchAndSetTransaction = async () => {
            const response = await CreditCardTransactionsService.getByParams({ sortDate: sortDate, pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, ...filters });
            setData(response.data);
        }
        fetchAndSetTransaction();
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [filters, pagingData.pageNumber, pagingData.pageSize, sortDate])

    const transactionStatus = [
        {
            value: "COMPLETED",
            label: t("COMPLETED")
        },
        {
            value: "REFUNDED",
            label: t("REFUNDED")
        },
        {
            value: "PARTIALLY_REFUNDED",
            label: t("PARTIALLY_REFUNDED")
        },
        {
            value: "POSTAUTH",
            label: t("POSTAUTH")
        },
        {
            value: "PREAUTH",
            label: t("PREAUTH")
        }

    ]
    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
    }


    const ClearFilters = () => {
        datePicker.current.flatpickr.clear();
        setFilters({ startDate: null, endDate: null, status: null, statusLabel: null });
    }
    const ShowDetailDialog = async (id) => {
        const transactionDetail = await CreditCardTransactionsService.getById(id);
        if (transactionDetail.isSuccess) {
            setTransactionDetail(transactionDetail.data);
            setShowDetails(true);
        }
    }

    const handleSetSortDate = () => {
        if (counterChangeSortDate === 2) {
            setSortDate(null);
            setCounterChangeSortDate(0);
            return;
        }
        setCounterChangeSortDate(counterChangeSortDate + 1);
        if (sortDate === null || sortDate === 1) {
            setSortDate(0);
        }
        else if (sortDate == 0) {
            setSortDate(1);
        }
    }

    const FilterData = async (e) => {
        let response;
        let temp = [];
        if (defaultFetched == false) {
            response = await RideMilestonesService.getTopLocations(5);
            response.data.forEach(r => {
                temp.push({ value: r.key, label: r.key });
            });
            setDefaultFetched(true);
        }
        else {
            response = await RideMilestonesService.getByParams({ cityName: e, pageNumber: 1, pageSize: 999 });
            response.data.items.forEach(r => {
                temp.push({ value: r.cityName, label: r.cityName });
            });
        }
        return temp;
    }

    return (
        <>
            <style>
                {`
          .css-13cymwt-control {
            background-color: transparent!important;
            border: 1px solid #c5c5c7!important;
            border-radius: 4px!important;
          }
          .css-t3ipsp-control{
            background-color: transparent!important;
            border: 1px solid #c5c5c7!important;
            border-radius: 4px!important;
          }
        `}
            </style>
            <div className="row">
                <div className="col-12 mb-4 mb-sm-4">
                    <div className="d-sm-flex justify-content-between align-items-center ">
                        <h1 className="h4 mb-3 mb-sm-0">{t("TRANSACTION")}</h1>
                    </div>
                </div>
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-md-3 col-sm-12 mt-3">
                            <Flatpickr style={{ borderRadius: "4px", height: "38px", }}
                                options={{ disableMobile: true, mode: "range", dateFormat: "d.m.Y" }}
                                id="flatpickr"
                                type="date"
                                className="form-control flatpickr col-lg-8 col-sm-11 col-10"
                                placeholder={t("DATE")} readOnly="readonly"
                                onChange={(e) => setFilters({ ...filters, startDate: moment(e[0]).format("YYYY-MM-DD"), endDate: moment(e[1]).format("YYYY-MM-DD") })}
                                ref={datePicker}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12 mt-3">
                            <Select
                                key={reloadOptionsKey}
                                ref={statusSelectRef}
                                name="statusSelect"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                isSearchable={false}
                                className="basic-select"
                                onChange={(e) => {
                                    setFilters({ ...filters, status: e.value, statusLabel: e.label });
                                    setFilters({ ...filters, status: e.value, statusLabel: e.label });
                                }}
                                placeholder={t("CHOOSE_RIDE_STATUS")}
                                options={transactionStatus}
                                value={filters.statusLabel ? { label: filters.statusLabel, value: filters.status } : null}

                            />
                        </div>
                        <div className="col-md me-0 col-sm-12 mt-3">
                            <AsyncSelect
                                name="locationFrom"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                className="basic-single col"
                                noOptionsMessage={({ inputValue }) => !inputValue ? t("TYPE_IN_YOUR_LOCATION") : t("NO_RESULTS")}
                                loadingMessage={({ inputValue }) => t("LOADING")}
                                loadOptions={(e) => FilterData(e)}
                                placeholder={t("DESTINATION_FROM")}
                                defaultOptions={true}
                                onChange={(e) => {
                                    setFilters({ ...filters, cityNameFrom: e.value, cityNameFrom: e.label });
                                    setValue("locationFrom", e);
                                }}
                                value={filters.cityNameFrom != null ? { label: filters.cityNameFrom, value: filters.cityNameFrom } : null}
                            />
                        </div>
                        <div className="col-md col-sm-12 mt-3">
                            <AsyncSelect
                                name="locationTo"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                    })
                                }}
                                className="basic-single col"
                                noOptionsMessage={({ inputValue }) => !inputValue ? t("TYPE_IN_YOUR_LOCATION") : t("NO_RESULTS")}
                                loadingMessage={({ inputValue }) => t("LOADING")}
                                loadOptions={(e) => FilterData(e)}
                                placeholder={t("DESTINATION_TO")}
                                defaultOptions={true}
                                onChange={(e) => {
                                    setFilters({ ...filters, cityNameTo: e.value, cityNameTo: e.label });
                                    setValue("locationTo", e);
                                }}
                                value={filters.cityNameTo != null ? { label: filters.cityNameTo, value: filters.cityNameTo } : null}

                            />
                        </div>
                        <div className='col-md col-sm-12 pt-3  ps-0 d-flex justify-content-end mt-1 mt-md-0'>
                            <button onClick={() => ClearFilters()} className="btn d-flex  justify-content-center w-sm-100 ms-3 align-items-center btn-sm btn-neutral-soft border border-danger " >
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                    </div>
                    <div className="bg-light rounded p-3 d-none d-lg-block">
                        <div className="row row-cols-7 g-4">
                            <div onClick={() => { handleSetSortDate(); }} className="col-lg cursor-pointer col-md-4 col-sm-6 ">
                                <h6 className="mb-0">{t("DATE")}
                                    <UpwardIcon size={"18px"} color={sortDate !== null && sortDate === 0 ? "#900fc8" : "grey"} styles={{ marginLeft: "5px", transform: "rotate(180deg)" }}></UpwardIcon>
                                    <UpwardIcon size={"18px"} color={sortDate !== null && sortDate === 1 ? "#900fc8" : "grey"}></UpwardIcon>

                                </h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("DESTINATION")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("PAYER")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="mb-0">{t("TOTAL")}</h6>
                            </div>
                            {/* <div className="col">
                                <h6 className="mb-0">{t("FEE")}</h6>
                            </div> */}
                            <div className="col">
                                <h6 className="mb-0">{t("STATUS")}</h6>
                            </div>
                            <div className="col">
                                <h6 className="float-end mb-0">{t("ACTION")}</h6>
                            </div>
                        </div>
                    </div>
                    {(!data || !data.items || data.items.length === 0) && (
                        <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
                            <div className="col text-center">{t("NO_RESULTS")}</div>
                        </div>
                    )}
                    {data && data.items && data.items.map((trans, index) => (
                        <div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("DATE")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">{moment.utc(trans.createdAt).format('DD.MM.YYYY')}</h6>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("DESTINATION")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">
                                    <span>{trans.userRide.rideMilestoneFrom.cityName}-</span>
                                    <span>{trans.userRide.rideMilestoneTo.cityName}</span>
                                </h6>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("PAYER")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">{trans.userRide.user.firstName} {trans.userRide.user.lastName}</h6>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("TOTAL")}:</small>
                                <h6 className="mb-0 ms-2 fw-normal">{trans.totalAmount} BAM</h6>
                            </div>
                            <div className="col-12 col-sm d-sm-block d-inline-flex">
                                <small className="d-block d-lg-none">{t("STATUS")}:</small>
                                <div className={`ms-2 badge bg-opacity-10 ${(trans.transactionStatus == "COMPLETED" || trans.transactionStatus == "POSTAUTH") ? "bg-success text-success me-2" : trans.transactionStatus == "DENIED" ? "bg-danger text-danger me-2" : trans.transactionStatus == "PREAUTH" ? "bg-warning text-warning me-2" : "bg-secondary text-secondary me-2"}`}>{t(trans.transactionStatus)}</div>
                            </div>
                            <div className="col-md col-sm-12 d-flex justify-content-end me-2">
                                <button className="btn btn-sm btn-primary-soft w-sm-100" onClick={() => ShowDetailDialog(trans.id)}>
                                    {t("DETAILS")}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {data && <PagingComponent
                    className="pagination-bar"
                    currentPage={data.pageNumber ?? 0}
                    totalCount={data.totalCount ?? 0}
                    pageSize={data.pageSize ?? 0}
                    onPageChange={onPageChange}
                />}
            </div>

            {showDetails && !isMobile &&
                <div className="position-fixed  z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="card shadow  mw-90">
                            <div className="card-header border-bottom">
                                <h5 className="card-header-title me-2" style={{ display: "inline-block" }}>{t("TRANSACTIONDETAIL")}</h5>
                                -
                                <h6 className="mb-0 black fw-normal ms-2" style={{ display: "inline-block" }}>
                                    # {transactionDetail.orderID}
                                </h6>
                            </div>
                            <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => setShowDetails(false)}>
                                <svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none">
                                    <path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                    </path>
                                    <path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                    </path>
                                </svg>
                            </button>
                            <div className="card-body pb-0" style={isMobile ? { width: "100%" } : { width: "550px" }} >
                                <div className='row justify-content-between '>
                                    <div className='col-6 d-flex flex-column'>
                                        <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("DESTINATION")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <svg width="19" height="19" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3.50008 5.66663H2.79175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5418 5.66663H14.8335" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.16675 2.125V3.54167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.10425 3.54163H10.2292" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.91675 10.625H7.04175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.2917 10.625H13.4167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                            <p className='paragraphs mb-0'>
                                                {transactionDetail.userRide.rideMilestoneFrom.cityName}
                                            </p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 9 7" fill="none">
                                                <path d="M5.52197 0.727539L8.2944 3.49997L5.52197 6.27239" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M0.529297 3.5H8.21628" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='paragraphs mb-0'>
                                                {transactionDetail.userRide.rideMilestoneTo.cityName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("DATE")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className="paragraphs mb-0"> <i className="bi bi-calendar me-2"></i> {moment.utc(transactionDetail.createdAt).format("DD.MM.YYYY")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col-6 d-flex flex-column'>
                                        <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("FULLNAME")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className='paragraphs mb-0'>
                                                <i className="bi bi-person me-2"></i>
                                                {transactionDetail.userRide.user.firstName} {transactionDetail.userRide.user.lastName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-6 d-flex flex-column'>
                                        <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("PAYEREMAIL")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className="paragraphs mb-0"> <i className="bi bi-envelope me-2"></i> {transactionDetail.email} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col d-flex flex-column'>
                                        <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("STATUS")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <i className="bi bi-wallet"></i>
                                            <p className={`paragraphs mb-0 badge bg-opacity-10 p-1 ${(transactionDetail.transactionStatus == "COMPLETED" || transactionDetail.transactionStatus == "POSTAUTH") ? "bg-success text-success me-2" : transactionDetail.transactionStatus == "DENIED" ? "bg-danger text-danger me-2" : transactionDetail.transactionStatus == "PREAUTH" ? "bg-warning text-warning me-2" : "bg-secondary text-secondary me-2"}`}> {t(transactionDetail.transactionStatus)} </p>
                                        </div>
                                    </div>
                                    {(transactionDetail.transactionStatus == "PARTIALLY_REFUNDED" || transactionDetail.transactionStatus == "REFUNDED" || transactionDetail.transactionStatus == "REVERSE") && <div className='col d-flex flex-column'>
                                        <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("REFUND_AMOUNT")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1529_6528)">
                                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1529_6528">
                                                        <rect width="22.47" height="21.09" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h5 className="BAM-big-purple "> {transactionDetail.refundAmount != null ? transactionDetail.refundAmount : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                        </div>
                                    </div>}
                                    <div className='col d-flex flex-column'>
                                        <label className='labels fs12 fw-500 gray ms-0 mb-2'>{t("TOTAL")}:</label>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1529_6528)">
                                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1529_6528">
                                                        <rect width="22.47" height="21.09" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h5 className="BAM-big-purple "> {transactionDetail.totalAmount != null ? transactionDetail.totalAmount : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-2 justify-content-end mt-4">
                                <button className="btn text-danger border-0 me-2 ${props.onDiscard" onClick={() => setShowDetails(false)}>
                                    {t("CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showDetails && isMobile &&
                <div className="position-fixed  z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="card shadow  mw-90 w-90">
                            <div className="card-header border-bottom">
                                <h5 className="card-header-title me-2" style={{ display: "inline-block" }}>{t("TRANSACTIONDETAIL")}</h5>
                                -
                                <h6 className="mb-0 black fw-normal">
                                    # {transactionDetail.orderID}
                                </h6>
                            </div>
                            <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => setShowDetails(false)}>
                                <svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none">
                                    <path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                    </path>
                                    <path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                    </path>
                                </svg>
                            </button>
                            <div className="card-body pb-0" style={isMobile ? { width: "100%" } : { width: "550px" }} >
                                <div className='row justify-content-between '>
                                    <div className='col-auto d-flex flex-column'>
                                        <label className='labels fs14 fw-500 gray ms-0 mb-0'>{t("DESTINATION")}:</label>
                                    </div>
                                    <div className='col-auto d-flex flex-column'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <svg width="19" height="19" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3.50008 5.66663H2.79175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5418 5.66663H14.8335" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.16675 2.125V3.54167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.10425 3.54163H10.2292" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.91675 10.625H7.04175" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.2917 10.625H13.4167" stroke="#656565" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                            <p className='paragraphs mb-0'>
                                                {transactionDetail.userRide.rideMilestoneFrom.cityName}
                                            </p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 9 7" fill="none">
                                                <path d="M5.52197 0.727539L8.2944 3.49997L5.52197 6.27239" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M0.529297 3.5H8.21628" stroke="#7E7E7E" strokeWidth="0.685114" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='paragraphs mb-0'>
                                                {transactionDetail.userRide.rideMilestoneTo.cityName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col-auto d-flex flex-column'>
                                        <label className='labels fs14 fw-500 gray ms-0 mb-0'>{t("DATE")}:</label>
                                    </div>
                                    <div className='col-auto d-flex flex-column'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className="paragraphs mb-0"> <i className="bi bi-calendar me-2"></i> {moment.utc(transactionDetail.createdAt).format("DD.MM.YYYY HH:mm")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col-auto d-flex flex-column'>
                                        <label className='labels fs14 fw-500 gray ms-0 mb-0'>{t("FULLNAME")}:</label>
                                    </div>
                                    <div className='col-auto d-flex flex-column'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className='paragraphs mb-0'>
                                                <i className="bi bi-person me-2"></i>
                                                {transactionDetail.userRide.user.firstName} {transactionDetail.userRide.user.lastName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col-auto d-flex flex-column'>
                                        <label className='labels fs14 fw-500 gray ms-0 mb-0'>{t("PAYEREMAIL")}:</label>
                                    </div>
                                    <div className='col-auto d-flex flex-column'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <p className="paragraphs mb-0"> <i className="bi bi-envelope me-2"></i> {transactionDetail.email} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-between mt-3'>
                                    <div className='col-auto d-flex flex-column'>
                                        <label className='labels fs14 fw-500 gray ms-0 mb-2'>{t("STATUS")}:</label>
                                    </div>
                                    <div className='col-auto d-flex flex-column'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <i className="bi bi-wallet"></i>
                                            <p className={`paragraphs mb-0 badge bg-opacity-10 p-1 ${(transactionDetail.transactionStatus == "COMPLETED" || transactionDetail.transactionStatus == "POSTAUTH") ? "bg-success text-success" : transactionDetail.transactionStatus == "DENIED" ? "bg-danger text-danger" : transactionDetail.transactionStatus == "PREAUTH" ? "bg-warning text-warning" : "bg-secondary text-secondary"}`}> {t(transactionDetail.transactionStatus)} </p>
                                        </div>
                                    </div>
                                </div>
                                {(transactionDetail.transactionStatus == "PARTIALLY_REFUNDED" || transactionDetail.transactionStatus == "REFUNDED" || transactionDetail.transactionStatus == "REVERSE") && <div className='row justify-content-between mt-3'>
                                    <div className='col-auto d-flex flex-column'>
                                        <label className='labels fs14 fw-500 gray ms-0 mb-2'>{t("REFUND_AMOUNT")}:</label>
                                    </div>
                                    <div className='col-auto d-flex flex-column'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1529_6528)">
                                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1529_6528">
                                                        <rect width="22.47" height="21.09" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h5 className="BAM-big-purple "> {transactionDetail.refundAmount != null ? transactionDetail.refundAmount : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                        </div>
                                    </div>
                                </div>}
                                <div className='row justify-content-between mt-3'>
                                    <div className='col-auto d-flex flex-column'>
                                        <label className='labels fs14 fw-500 gray ms-0 mb-2'>{t("TOTAL")}:</label>
                                    </div>
                                    <div className='col-auto d-flex flex-column'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <svg width="19" className='ms-1' height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1529_6528)">
                                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1529_6528">
                                                        <rect width="22.47" height="21.09" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h5 className="BAM-big-purple "> {transactionDetail.totalAmount != null ? transactionDetail.totalAmount : 0} </h5><h5 style={{ fontSize: "16px", color: "grey" }} >{t("VALUTE")}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mb-2 justify-content-end mt-4">
                                <button className="btn text-danger border-0 me-2 ${props.onDiscard" onClick={() => setShowDetails(false)}>
                                    {t("CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </>
    );
});
export default TransactionsPage;