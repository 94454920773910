import { action, makeObservable, observable } from "mobx";
import AuthService from "../services/AuthService";

class VehicleStore {
    showNewVehicleDialog = false;
    showEditVehicleDialog = false;

    constructor() {
        makeObservable(this, {
            showNewVehicleDialog: observable,
            showEditVehicleDialog: observable,
            setShowEditVehicleDialog: action,
            setShowNewVehicleDialog: action
        });

    }
    setShowEditVehicleDialog(prop) {
        this.showEditVehicleDialog = prop;
    }

    setShowNewVehicleDialog(prop) {
        this.showNewVehicleDialog = prop;
    }

}

const vehicleStore = new VehicleStore();

export default vehicleStore;