import { action, makeObservable, observable } from "mobx";
import AuthService from "../services/AuthService";

class UserProfileStore {
  user = null;
  
  constructor() {
    makeObservable(this, {
      user: observable,
      setUser: action
    });

    this.setUser(AuthService.getCurrentUser())
  }

  setUser(user) {
    this.user = user;
  }
}

const userProfileStore = new UserProfileStore();

export default userProfileStore;