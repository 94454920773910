import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toastify } from 'utils/toastify';
import { CancellationPoliciesService } from 'data/services';

function CancellationPolicyAdd(props) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm();
    const [isFormSubmitting, setFormSubmitting] = useState();
    const { t } = useTranslation();
    const handleDiscard = () => {
        props.onDiscard()
    }
    const onSubmit = async (data) => {
        setFormSubmitting(true);
        if (data.hoursBeforeCancellation <= 0) {
            setError('hoursBeforeCancellation', { type: 'minLength' })
            setFormSubmitting(false)
            return
        }
        if (data.returnPercentage > 100) {
            setError('returnPercentage', { type: 'maxLength' })
            setFormSubmitting(false)
            return
        }
        if (data.returnPercentage < 0) {
            setError('returnPercentage', { type: 'minLength' })
            setFormSubmitting(false)
            return
        }
        const response = await CancellationPoliciesService.add({ ...data })
        setFormSubmitting(false);
        if (response.isSuccess) {
            toastify.success(t("SUCCESS_ADDING_CANCELLATION_POLICY"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png"/>)
            props.addCurrentState(response.data)
            handleDiscard()
        }
        else {
            toastify.error(t("ERROR_ADDING_CANCELLATION_POLICY"))
            handleDiscard()
        }
    };
    return (
        <>
            <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50 d-flex flex-column justify-content-center align-items-center">
                <div className="card shadow mw-90">
                    <div className="col-lg-12">
                        <div className="row p-3">
                            <div className="col-12">
                                <h1 className="h4 mb-0">{t("NEW_CANCELLATION_POLICY")}</h1>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <div className="mb-3">
                                            <label className="form-label">{t("HOURS_BEFORE_CANCELLATION")}</label>
                                            <input
                                                {...register("hoursBeforeCancellation", {
                                                    required: t("HOURS_BEFORE_CANCELLATION_IS_REQUIRED"),

                                                })}
                                                type="number"
                                                className="form-control"
                                                placeholder={t("HOURS_BEFORE_CANCELLATION")}
                                            />
                                            {errors.hoursBeforeCancellation && errors.hoursBeforeCancellation.type === "required" && <span className="text-danger">{t("HOURS_BEFORE_CANCELLATION_IS_REQUIRED")}</span>}
                                            {errors.hoursBeforeCancellation && errors.hoursBeforeCancellation.type === "minLength" && <small className="text-danger">{t("HOURS_BEFORE_CANCELLATION_LESS_THAN_0")}</small>}

                                        </div>
                                    </div>
                                    <div className='col-md-6  col-sm-12'>
                                        <div className="mb-3">
                                            <label className="form-label">{t("RETURN_PERCENTAGE")}</label>
                                            <input
                                                {...register("returnPercentage", {
                                                    required: t("RETURN_PERCENTAGE_IS_REQUIRED"),

                                                })}
                                                type="number"
                                                className="form-control"
                                                placeholder={t("RETURN_PERCENTAGE")}
                                            />
                                            {errors.returnPercentage && errors.returnPercentage.type === "required" && <span className="text-danger">{t("RETURN_PERCENTAGE_IS_REQUIRED")}</span>}
                                            {errors.returnPercentage && errors.returnPercentage.type === "maxLength" && <small className="text-danger">{t("RETURN_PERCENTAGE_IS_MORE_THAN_100")}</small>}
                                            {errors.returnPercentage && errors.returnPercentage.type === "minLength" && <small className="text-danger">{t("RETURN_PERCENTAGE_IS_LESS_THAN_0")}</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                                        {t("DISCARD")}
                                    </button>
                                    <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                                        {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                        {t("SAVE")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CancellationPolicyAdd
