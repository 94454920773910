import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toastify } from "utils/toastify";
import { ConditionsService } from "data/services";

const ConditionAddModal = (props) => {
    const { t } = useTranslation();
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const { register, formState: { errors }, handleSubmit, setError, setValue, clearErrors } = useForm();
    const navigate = useNavigate();
    const [apiErrors, setApiErrors] = useState();
    const [previewUrl1, setPreviewUrl1] = useState(null);
    const [previewUrl2, setPreviewUrl2] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
    const onSubmit = async (data) => {
      setFormSubmitting(true);
      if (data.icon.length > 0) {
        data.icon = data.icon[0];
      }
  
      if (data.noIcon.length > 0) {
        data.noIcon = data.noIcon[0];
      }
      const newCondition = await ConditionsService.addCondition(data);
      if (newCondition.statusCode === 409) {
        setError("conditionExists", 409)
        setFormSubmitting(false);
        return;
      }
      setFormSubmitting(false);
  
      if (newCondition.isSuccess) {
        props.onSuccess();
        toastify.success(t("SUCCESSFULLY_ADDED_CONDITION"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png" />)
      } else if (newCondition.isError) {
        toastify.error(t("ERROR_ADDING_CONDITION"));
      } else if (newCondition.isValidationError) {
        setApiErrors(newCondition.validationErrors);
      }
    }
    const handlePhotoUpload1 = (e) => {
      const file = e.target.files[0];
      setPreviewUrl1(URL.createObjectURL(file));
    }
    const handlePhotoUpload2 = (e) => {
      const file = e.target.files[0];
      setPreviewUrl2(URL.createObjectURL(file));
    }

  const handleDiscard = () => {
    props.onDiscard();
  }
  return (
    <>
      <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
        <div className="d-flex justify-content-center align-items-center h-100">
        <div className="card shadow mw-100" style={isMobile? {width:"90%", maxHeight: "70vh"} :{ width: "30%", maxHeight: "70vh" }}>
            <div className="card-header border-bottom d-flex justify-content-between align-items-center">
              <span className="headers">{props.title}</span>
              <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => handleDiscard()}><svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none"><path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></button>
            </div>
            <div className="card-body" style={{overflowY:"auto"}}>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="form-label">{t("NAME")}</label>
                <input {...register("name", { required: t("NAME_IS_REQUIRED") })}
                  onChange={(e) => {
                    clearErrors();
                    setValue("name", e.target.value)
                  }}
                  type="text"
                  className="form-control"
                  placeholder={t("CONDITION_NAME_PLACEHOLDER")}
                />
                {errors.name && <span className="text-danger">{t("NAME_IS_REQUIRED")}</span>}
                {errors.conditionExists && <span className="text-danger">{t("CONDITION_EXISTS")}</span>}
              </div>
              <div className="mb-3">
                <label className="form-label">{t("DESCRIPTION")}</label>
                <input {...register("description", { required: t("DESCRIPTION_IS_REQUIRED") })}
                  type="text"
                  className="form-control"
                  placeholder={t("CONDITION_DESCRIPTION_PLACEHOLDER")}
                />
                {errors.description && <span className="text-danger">{t("DESCRIPTION_IS_REQUIRED")}</span>}
              </div>
              <div className="mb-3">
                <label className="form-label">{t("PERMISSION_ICON")}</label>
                <div className="row">
                  <div className="col f-flex flex-column">
                    {previewUrl1 && <img src={previewUrl1} alt="Profile photo preview" style={{ height: "60px", width: "60px" }} className="photo-preview cursor-pointer" />}
                    <input
                      {...register("icon", { required: t("PHOTO_IS_REQUIRED") }, { defaultValue: null })}
                      type="file"
                      className="form-control"
                      onChange={handlePhotoUpload1}
                    />
                  </div>
                </div>
              </div>
              {errors.icon && <span className="text-danger">{t("PHOTO_IS_REQUIRED")}</span>}
              <div className="mb-3">
                <label className="form-label">{t("NOPERMISSION_ICON")}</label>
                <div className="row">
                  <div className="col f-flex flex-column">
                    {previewUrl2 && <img src={previewUrl2} alt="Profile photo preview" style={{ height: "60px", width: "60px" }} className="photo-preview cursor-pointer" />}
                    <input
                      {...register("noIcon", { required: t("PHOTO_IS_REQUIRED") }, { defaultValue: null })}
                      type="file"
                      className="form-control"
                      onChange={handlePhotoUpload2}
                    />
                  </div>
                </div>
              </div>
              {errors.noIcon && <span className="text-danger">{t("PHOTO_IS_REQUIRED")}</span>}
                <div className="d-flex justify-content-end mt-4">
                  <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                    {t("DISCARD")}
                  </button>
                  <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                    {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                    {t("SAVE")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  );

};

export default ConditionAddModal;