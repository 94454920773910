import { httpSmartClient } from "../../config/httpClient";

const baseName = "AppSettings";

const AppSettingsService = {
  getById: async (id) => {
    var respone = await httpSmartClient.getById(`/${baseName}/` + id);
    return respone;
  },

  getByParams: async (params) => {
    var respone = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return respone;
  },

  getPricePerKilometers: async (pricePerKilometers) => {
    let response = await httpSmartClient.get(`/${baseName}/PricePerKilometers`, { PricePerKilometers: pricePerKilometers });
    return response;
  },

  add: async (appSetting) => {
    var respone = await httpSmartClient.post(`/${baseName}`, appSetting);
    return respone;
  },

  edit: async (appSetting) => {
    var respone = await httpSmartClient.put(`/${baseName}`, appSetting);
    return respone;
  },
  editFee: async (rideFee, bankFee) => {
    var respone = await httpSmartClient.put(`/${baseName}/UpdateFee`, { RideFee: rideFee, BankFee: bankFee });
    return respone;
  },
  editPaymentType: async (type) => {
    var respone = await httpSmartClient.put(`/${baseName}/UpdatePaymentType`, { isCashAllowed: type });
    return respone;
  },
  editFinalizationAfterHours: async (hours) => {
    var respone = await httpSmartClient.put(`/${baseName}/UpdateFinalizationAfterHours`, { FinalizationAfterHours: hours });
    return respone;
  },

  delete: async (id) => {
    var respone = await httpSmartClient.delete(`/${baseName}/` + id);
    return respone;
  },
};

export default AppSettingsService;
