import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toastify } from "utils/toastify";
import { useTranslation } from "react-i18next";
import { httpSmartClient } from "config/httpClient";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { PagingComponent } from "components/layout/components";
import { getAppPagingSettings } from "config/paging";
import { TravelPreferencesService } from "data/services";
import TravelPreferenceAddModal from "./modals/TravelPreferenceAddModal";
import TravelPreferenceEditModal from "./modals/TravelPreferenceEditModal";

const TravelPreferencesPage = observer(() => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [currentId, setCurrentId] = useState();
  const { t } = useTranslation();
  let pagingSettings = getAppPagingSettings();
  const [pagingData, setPagingData] = useState(pagingSettings);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fetchData, setFetchData] = useState(false);

  const fetchAndSetState = async () => {
    const travelPreferencesList = await TravelPreferencesService.getByParams({ pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, name: filters ? filters.name : null });
    setData(travelPreferencesList.data);
  };

  const onPageChange = (page) => {
    pagingData.pageNumber = page;
    setPagingData({ ...pagingData });
  }

  useEffect(() => {
    fetchAndSetState();
  }, [pagingData.pageNumber, pagingData.pageSize, fetchData, filters]);

  const handleDelete = (id) => {
    setShowConfirmationDialog(true);
    setCurrentId(id);
  };

  const deleteTravelPreference = async (id) => {
    const deleted = await TravelPreferencesService.delete(id);
    if (deleted.isSuccess) {
      setFetchData(!fetchData);
      toastify.success(t("SUCCESSFULLY_DELETED_TRAVEL_PREFERENCE"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png" />);
      setShowConfirmationDialog(false);
    } else {
      toastify.error(t("ERROR_DELETING_TRAVEL_PREFERENCE"));
    }
  };

  const onSuccess = (isEdit) => {
    if (isEdit) {
      setShowEditModal(false);
    }
    else {
      setShowAddModal(false);
    }
    setFetchData(!fetchData);
  }

  return (
    <>
      <div className="row ">
        <div className="col-12 mb-4 mb-sm-4">
          <div className="d-sm-flex justify-content-between align-items-center">
            <h1 className="h4 mb-3 mb-sm-0">{t("TRAVEL_PREFERENCES")}</h1>
            <div className="d-grid">
              <a className="btn btn-primary-soft" onClick={() => setShowAddModal(true)}>
                + {t("NEW_TRAVEL_PREFERENCE")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-body pb-0 mb-3">
          <div className="mb-4 col col-md-4 col-sm-12">
            <form className="rounded position-relative">
              <input style={{ height: "38px", borderRadius: "5px" }} className="form-control bg-transparent" type="text" placeholder={t("SEARCH")} aria-label="Search" onChange={(e) => setFilters({ ...filters, name: e.target.value })} />
              <button className="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                <i className="fas fa-search fs-6"></i>
              </button>
            </form>
          </div>
          <div className="bg-light rounded p-3 d-none d-lg-block">
            <div className="row g-4">
              <div className="col-md col-sm-12">
                <h6 className="mb-0">{t("NAME")}</h6>
              </div>
              <div className="col-md col-sm-12">
                <h6 className="mb-0">{t("ICON")}</h6>
              </div>
              <div className="col-md col-sm-12">
                <h6 className="mb-0">{t("OPTIONS")}</h6>
              </div>
              <div className="col-md col-sm-12">
                <h6 className="float-end mb-0">{t("ACTION")}</h6>
              </div>
            </div>
          </div>
          {(!data.items || data.items.length === 0) && (
            <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col text-center">{t("NO_RESULTS")}</div>
            </div>
          )}
          {data && data.items &&
            data.items.map((travelPreference, index) => (
              <div key={index} className="row align-items-lg-center border-bottom g-4 px-2 py-4">
                <div className="col-md col-sm-12">
                  <small className="d-block d-lg-none">{t("NAME")}:</small>
                  <h6 className="mb-0 fw-normal">{t(travelPreference.name)}</h6>
                </div>
                <div className="col-md col-sm-12">
                  <small className="d-block d-lg-none">{t("ICON")}:</small>
                  <div style={{ width: "30px", height: "30px", overflow: "hidden", display: "flex", alignItems: "center" }}>
                    <img src={travelPreference.iconId != null ? httpSmartClient.getPhotoUrl(travelPreference.iconId) : "/assets/images/blankicon.jpg"}
                      alt="Profile Photo"
                      style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
                  </div>
                </div>
                <div className="col-md col-sm-12">
                  <small className="d-block d-lg-none">{t("OPTIONS")}:</small>
                  <ul className="list-group list-group-borderless ">
                    {travelPreference.travelPreferenceOptions &&
                      travelPreference.travelPreferenceOptions.map((option, index) => (
                        <span className="mb-1" style={{ color: "black", display: "flex", alignItems: "baseline" }} key={index}>
                          <div className="bi bi-dot" style={{ marginRight: "0.5rem" }}></div>
                          <div style={{ maxWidth: "calc(100% - 1.5rem)" }}>
                            {t(option.name)}
                          </div>
                        </span>
                      ))}
                  </ul>
                </div>
                <div className="col-md col-sm-12 d-flex justify-content-end">
                  <button className="btn btn-sm btn-primary-soft me-2" onClick={() => { setCurrentId(travelPreference.id); setShowEditModal(true) }}>
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button className="btn btn-sm btn-danger-soft border border-danger" onClick={() => handleDelete(travelPreference.id)}>
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            ))}
        </div>
        <PagingComponent
          className="pagination-bar"
          currentPage={data.pageNumber ?? 0}
          totalCount={data.totalCount ?? 0}
          pageSize={data.pageSize ?? 0}
          onPageChange={onPageChange}
        />
      </div>
      {showConfirmationDialog && <ConfirmationDialog
        onDiscard={() => setShowConfirmationDialog(false)}
        title={t("DELETE_TRAVEL_PREFERENCE_ITEM")}
        description={t("DELETE_TRAVEL_PREFERENCE")}
        confirmBtnLabel={t("DELETE")}
        onConfirm={() => deleteTravelPreference(currentId)}
      ></ConfirmationDialog>}

      {showAddModal && <TravelPreferenceAddModal
        onDiscard={() => setShowAddModal(false)}
        title={t("NEW_TRAVEL_PREFERENCE")}
        description={t("TRAVEL_PREFERENCE_INFORMATION")}
        confirmBtnLabel={t("SAVE")}
        onSuccess={() => { onSuccess() }}
      ></TravelPreferenceAddModal>}

      {showEditModal && <TravelPreferenceEditModal
        onDiscard={() => setShowEditModal(false)}
        title={t("EDIT_TRAVEL_PREFERENCE")}
        description={t("TRAVEL_PREFERENCE_INFORMATION")}
        confirmBtnLabel={t("SAVE")}
        onSuccess={() => { onSuccess(true) }}
        travelPreferenceId={currentId}
      ></TravelPreferenceEditModal>}
    </>
  );
})
export default TravelPreferencesPage;
