import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguagesService } from "data/services";
import globalStore from "data/stores/GlobalStore";

export default function LanguageSwitcher(props) {
    const { i18n } = useTranslation();
    const location = useLocation();
    const [dropup, setDropUp] = useState(false);
    const changeLanguage = async (lng) => {
        setDropUp(prev => !prev)
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
        if (localStorage.getItem('Logged') === "true") {
            await LanguagesService.edit(lng);
            globalStore.changeLng(lng);
        }
    };
    const getCurrentLanguageIconPath = () => {
        switch (i18n.language) {
            case "en":
                return "/assets/images/flags/us.svg";
            case "de":
                return "/assets/images/flags/de.svg";
            case "ba":
                return "/assets/images/flags/ba.svg";
        }
    };
    const getCurrentLanguage = () => {
        switch (i18n.language) {
            case "en":
                return "English";
            case "de":
                return "Deutsch";
            case "ba":
                return "Bosanski";
        }
    };
    const showModal = () => {
        setDropUp(prev => !prev)
    }
    return (
        <div className="dropup">
            <button className="dropbtn d-flex ps-0" onClick={() => showModal()}>
                <img className="m-0" src={getCurrentLanguageIconPath()} width="16" />
                <span className="black p-1 ms-1" style={{marginTop:"11px"}}>{getCurrentLanguage()}</span>
                <svg className="dropup-point" style={{marginTop:"1px"}} fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="-241.08 -241.08 974.16 974.16" space="preserve"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M484.136,328.473L264.988,109.329c-5.064-5.064-11.816-7.844-19.172-7.844c-7.208,0-13.964,2.78-19.02,7.844 L7.852,328.265C2.788,333.333,0,340.089,0,347.297c0,7.208,2.784,13.968,7.852,19.032l16.124,16.124 c5.064,5.064,11.824,7.86,19.032,7.86s13.964-2.796,19.032-7.86l183.852-183.852l184.056,184.064 c5.064,5.06,11.82,7.852,19.032,7.852c7.208,0,13.96-2.792,19.028-7.852l16.128-16.132 C494.624,356.041,494.624,338.965,484.136,328.473z"></path> </g> </g> </g></svg>
            </button>
            <div className={`dropup-content ${dropup && "d-block"}`}>
                <a className={`${i18n.language === "en" ? "active-language" : ""} `} href="#" onClick={() => changeLanguage("en")} >
                    <img src="/assets/images/flags/us.svg" width="16" height="16" className="me-2" />
                    English
                </a>
                <a className={`${i18n.language === "de" ? "active-language" : ""} `} href="#" onClick={() => changeLanguage("de")}><img src="/assets/images/flags/de.svg" width="16" className="me-2" />
                    Deutsch</a>
                <a className={`${i18n.language === "ba" ? "active-language" : ""} `} href="#" onClick={() => changeLanguage("ba")}><img src="/assets/images/flags/ba.svg" width="16" className="me-2" />
                    Bosanski</a>
            </div>
        </div>

    );
}
