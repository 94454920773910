import LanguageSwitcher from "./LanguageSwitcher";
import MyProfileNavbar from "./MyProfileNavbar";
import globalStore from "data/stores/GlobalStore";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
const AdminNavbar = observer(() => {
  const [theme, setTheme] = useState();
  useEffect(() => {
    const model = localStorage.getItem('theme')
    setTheme(model)
  }, [globalStore.theme])
  return (
    <nav className="navbar top-bar navbar-light py-0 py-xl-3">
      <div className="container-fluid p-0">
        <div className="d-flex align-items-center w-100 justify-content-between">
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex  align-items-center d-xl-none">
              <a className="navbar-brand " href="/">
                <img  width={"40px"} height={"40px"} className="navbar-brand-item " src="/assets/images/logo-icon.svg" alt="" />
              </a>
            </div>
            <div className="navbar-expand-xl sidebar-offcanvas-menu h-50">
              <button
                className="navbar-toggler me-auto p-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasSidebar"
                aria-controls="offcanvasSidebar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                data-bs-auto-close="outside"
              >
                <i className="bi bi-list text-primary fa-fw" data-bs-target="#offcanvasMenu"></i>
              </button>
            </div>
          </div>
          <div className=" ms-1 navbar-expand-lg d-flex align-items-center justify-content-end w-100 list-unstyled" >
            <LanguageSwitcher/>
            <MyProfileNavbar></MyProfileNavbar>
          </div>
        </div>
      </div>
    </nav>
  );
})
export default AdminNavbar;