import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toastify } from 'utils/toastify';
import { AuthService } from 'data/services';

const VerificationModal = observer((props) => {
    const { t } = useTranslation();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [verificationNumber, setVerificationNumber] = useState();
    const [countdownActive, setCountdownActive] = useState(false);
    const [count, setCount] = useState(10);
    const phoneNumber = props?.phoneNumber?.replaceAll(" ","");
    console.log(props.phoneNumber);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
        reset
    } = useForm();

    const startCountdown = () => {
        setCountdownActive(true);
    };

    useEffect(() => {
        let countdownInterval;
        if (countdownActive) {
            if(count===10)
            {
                props.onVerification(true);
            }
            countdownInterval = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
        } else {
            clearInterval(countdownInterval);
        }

        if (count === 0) {
            props.onVerification(false);
            setCountdownActive(false);
            setCount(10);
        }

        return () => clearInterval(countdownInterval);
    }, [countdownActive, count]);

    const sendVerificationNumberAndOpenModal = async () => {
        const response = await AuthService.addVerifyPhoneNumberRequest();
        if (response.isSuccess) {
            reset();
            toastify.success(t("NEW_VERIFICATION_CODE_SENT"));
        } else {
            toastify.error(t("ERROR_SENDING_VERIFICATION_CODE"));
        }
    }

    const onSubmit = async () => {
        setIsFormSubmitting(true);
        const response = await AuthService.verifyPhoneNumber(verificationNumber);
        if (response.data === true) {
            props.setShowVerificationModal(false);
            setIsFormSubmitting(false);
            props?.fetchUpdatedUser();
            toastify.success(t("VERIFICATION_MODAL_MSG"));
        } else {
            setIsFormSubmitting(false);
            setError('codeIsNotValid', t("VERIFICATION_CODE_IS_NOT_VALID"))
        }
    }

    return (
        <>
            <div className="z-index-master h-100">
                <div className="card shadow h-100 opacity-modal">
                    <div className="card-header pb-0 ps-2 d-flex flex-column justify-content-between">
                        <div className='d-flex justify-content-between'>
                            <h5 className="card-header-title ms-1 d-inline headers">{t("PHONE_NUMBER_VERIFICATION")}</h5>
                        </div>
                    </div>
                    <div className="card-body px-3 h-100">
                        <span className='phoneVerificationInfo'>{t("PHONE_VERIFICATION_INFO")}</span>
                        <span className='phoneVerificationInfo fs-14 fw-600' style={{color:"#900FC8"}}> +{phoneNumber}</span>
                        <form onSubmit={handleSubmit(onSubmit)} className='h-100 d-flex flex-column justify-content-between mt-3'>
                            <div>
                                <input
                                    {...register("verificationNumber", {
                                        required: t("VERIFICATION_CODE_IS_REQUIRED"),
                                    })}
                                    id="verificationNumber"
                                    className="inputs form-control m-auto text-center"
                                    placeholder="X X X X X X"
                                    maxLength={6}
                                    onInput={(e) => {
                                        clearErrors();
                                        setVerificationNumber(e.target.value);
                                        if (e.target.value.length > e.target.maxLength)
                                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                                    }}
                                />
                                {errors.verificationNumber && <p className="text-danger mb-2">{t("VERIFICATION_CODE_IS_REQUIRED")}</p>}
                                {errors.codeIsNotValid && <p className="text-danger mb-2">{t("VERIFICATION_CODE_IS_NOT_VALID")}</p>}
                                <span className='phoneVerificationInfo'>{t("VERIFICATION_CODE_NOT_RECEIVED")}</span>
                                {countdownActive ? (
                                    <span className='requestNewVerificationCode'>00:{count === 10 ? "" : "0"}{count} s</span>
                                ) : (
                                    <span className='requestNewVerificationCode cursor-pointer' onClick={() => {sendVerificationNumberAndOpenModal(); startCountdown();} }>
                                        {t("REQUEST_NEW_VERIFICATION_CODE_NOT_RECEIVED")}
                                    </span>
                                )}
                            </div>
                            <button disabled={isFormSubmitting} type="submit" className="btn btn-primary mt-8 col-11 btn-size btn-font">
                                {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                {t("VERIFICATION")}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
});

export default VerificationModal;