import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { httpSmartClient } from "config/httpClient";
import { toastify } from "utils/toastify";
import { useFieldArray } from "react-hook-form";
import { TravelPreferencesService } from "data/services";

const TravelPreferenceEditModal = (props) => {
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const { t } = useTranslation();
    const [defaultOptions, setDefaultOptions] = useState();
    const [apiErrors, setApiErrors] = useState();
    const [previewUrl, setPreviewUrl] = useState(null);
    const [data, setData] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const fetchPreferenceAndSetForm = async () => {
            const preference = await TravelPreferencesService.getById(props.travelPreferenceId);
            if (preference) {
                setData(preference.data);
            }
            const iconId = preference.data.iconId;
            iconId ? setPreviewUrl(httpSmartClient.getPhotoUrl(iconId)) : setPreviewUrl("/assets/images/blankicon.jpg")

            if (preference) {
                reset(preference.data);
                const options = preference.data.travelPreferenceOptions;
                setDefaultOptions(options);
            } else {
                props.onDiscard();
            }
        };
        fetchPreferenceAndSetForm();
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
        clearErrors,
        setValue,
        control
    } = useForm({
        defaultValues: {
            travelPreferenceOptions: defaultOptions
        }
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "travelPreferenceOptions",
    });

    const handlePhotoUpload = (e) => {
        const file = e.target.files[0];
        setPreviewUrl(URL.createObjectURL(file));
    }

    const onSubmit = async (data) => {
        setFormSubmitting(true);
        if (data.icon && data.icon.length > 0) {
            data.icon = data.icon[0];
        }
        const editedPreferenceResponse = await TravelPreferencesService.editTravelPreferences({ id: props.travelPreferenceId, ...data });
        if (editedPreferenceResponse.statusCode === 409) {
            setError("travelPreferencesExist", 409)
            setFormSubmitting(false);
            return;
        }
        setFormSubmitting(false);
        if (editedPreferenceResponse.isSuccess) {
            props.onSuccess();
            toastify.success(t("SUCCESSFULLY_EDITED_TRAVEL_PREFERENCE"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png" />);
        } else if (editedPreferenceResponse.isError) {
            toastify.error(t(editedPreferenceResponse.error));
        } else if (editedPreferenceResponse.isValidationError) {
            setApiErrors(editedPreferenceResponse.validationErrors);
        }
    };

    const handleDiscard = () => {
        props.onDiscard();
    }
    return (
        <>{data &&
            <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="card shadow mw-100" style={isMobile? {width:"90%", maxHeight: "70vh"} :{ width: "30%", maxHeight: "70vh" }}>
                        <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                            <span className="headers">{props.title}</span>
                            <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => handleDiscard()}><svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none"><path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></button>
                        </div>
                        <div className="card-body" style={{ overflowY: "auto"}}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label className="form-label">{t("PREFERENCE_NAME")}</label>
                                    <input
                                        {...register("name", {
                                            required: t("PREFERENCE_NAME_IS_REQUIRED"),
                                        })}
                                        type="text"
                                        className="form-control"
                                        placeholder={t("PREFERENCE_NAME_PLACEHOLDER")}
                                        onChange={(e) => {
                                            clearErrors();
                                            setValue("name", e.target.value)
                                        }}
                                    />
                                    {errors.name && <span className="text-danger">{t("PREFERENCE_NAME_IS_REQUIRED")}</span>}
                                    {errors.travelPreferencesExist && <span className="text-danger">{t("TRAVELPREFERENCES_EXIST")}</span>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{t("ICON")}</label>
                                    <div className="row">
                                        <div className="col f-flex flex-column">
                                            {previewUrl && <img src={previewUrl} alt="Profile photo preview" style={{ height: "60px", width: "60px" }} className="photo-preview cursor-pointer" />}
                                            <input
                                                {...register("icon", { defaultValue: null })}
                                                type="file"
                                                className="form-control"
                                                onChange={handlePhotoUpload}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {errors.icon && <span className="text-danger">{t("PHOTO_IS_REQUIRED")}</span>}
                                <br></br>
                                {fields.length > 0 && <label className="form-label">{t("PREFERENCE_OPTION")}</label>}
                                {fields.map((option, index) => (
                                    <div key={option.id} className="mb-3">
                                        <div className="d-flex">
                                            <input
                                                {...register(`travelPreferenceOptions.${index}.name`, {
                                                    required: t("OPTION_IS_REQUIRED"),
                                                })}
                                                type="text"
                                                className="form-control"
                                                placeholder={`${index + 1}.${t("NEW_PREFERENCE_OPTION")}`}
                                            />
                                            <button onClick={() => remove(index)} className="btn d-flex mt-1 ms-2 align-items-center btn-sm btn-neutral-soft border border-danger " >
                                                <i className="bi bi-x-lg"></i>
                                            </button>
                                        </div>
                                        {errors.travelPreferenceOptions && <span className="text-danger">{t("OPTION_IS_REQUIRED")}</span>}
                                    </div>
                                ))}
                                <button className="btn btn-primary-soft border-0 me-2 mt-2" type="button" onClick={() => { append({ placeholder: t("NEW_PREFERENCE_OPTION") }); }}>
                                    <i className="fa fa-plus text-soft"></i>
                                </button>
                                <div className="d-flex justify-content-end mt-4">
                                    <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                                        {t("DISCARD")}
                                    </button>
                                    <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                                        {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                        {t("SAVE")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}
        </>

    );
};

export default TravelPreferenceEditModal;