import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { httpSmartClient } from "config/httpClient"
import { Timeline } from "rsuite"
import GoogleRoutesMobile from "./GoogleRoutesMobile"
import SubmitRideMobile from "./SubmitRideMobile"
import moment from "moment/moment"
import "rsuite/dist/rsuite.min.css"
import "./customSlider.css"
import { ConditionsService, UserVehiclesService, AuthService, AppSettingsService } from "data/services"

export default function PublishRideMobile({
  rows,
  setRows,
  setEditingIndex,
  formData,
  setFormData,
  setIndex,
  driverVehicles,
  setDriverVehicles,
  totalPrice,
  setTotalPrice,
  milestoneModalOpen,
  setMilestoneModalOpen,
  step,
  setStep,
  selectedDriverVehicle,
  setSelectedDriverVehicle,
  selectedRouteIndex,
  setSelectedRouteIndex,
  routeKilometers,
  setRouteKilometers,
  maxPassengersCount,
  setMaxPassengersCount,
  conditions,
  setConditions,
  zeroResults,
  setZeroResults,
  selectedSummary,
  setSelectedSummary,
  routeError,
  setRouteError,
  driverVehicleError,
  setDriverVehicleError,
  refreshRecommendedPrice,
  setRefreshRecommendedPrice,
  womenOnly,
  setWomenOnly,
  pricePerKilometers,
  priceError,
  setPriceError,
  recalculateTotalPrice
}) {
  const { t } = useTranslation();
  const user = AuthService.getCurrentUser();
  const [maxNumberOfSeats, setMaxNumberOfSeats] = useState(4);
  const mapWidth = "100%"
  const mapHeight = "250px"

  const changeWomenOnly = () => {
    setWomenOnly(!womenOnly);
  }

  useEffect(() => {
    setMilestoneModalOpen(true);
    const fetchAndSetMaxNumberOfSeats = async () => {
      const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
      setMaxNumberOfSeats(response.data.items[0].maximumNumberOfSeats);
    }
    fetchAndSetMaxNumberOfSeats();
  }, [])

  const editPoint = (index) => {
    setTimeout(() => {
      setEditingIndex(index);
      setMilestoneModalOpen(true);

      setSelectedRouteIndex(null);
    }, 500);
    rows[index].oldValue = { ...rows[index] };
    setRows([...rows]);
  }

  const chooseStopOver = () => {
    const newIndex = rows.length - 1;
    setIndex(newIndex);
    rows.splice(newIndex, null, { isSubmitted: false, dateTime: rows[newIndex - 1] && rows[newIndex - 1].dateTime });
    setMilestoneModalOpen(true);
    setEditingIndex(-1);
  }

  const decrementCount = () => {
    if (maxPassengersCount > 1) {
      setMaxPassengersCount((prev) => prev - 1)
    }
  }

  const incrementCount = () => {
    if (maxPassengersCount < maxNumberOfSeats) {
      setMaxPassengersCount((prev) => prev + 1)
    }
  }

  useEffect(() => {
    const footer = document.getElementById("footer")
    const body = document.getElementsByTagName("body")[0]

    if (footer) {
      footer.style.display = "none"
    }

    if (body) {
      body.classList.add("bg-light")
    }

    const fetchConditions = async (params) => {
      const conditionsListDataApiResponse = await ConditionsService.getByParams({ ...params })
      let conditions = conditionsListDataApiResponse.data.items
      conditions.forEach((condition) => (condition.checked = formData && formData.conditions && formData.conditions.some((c) => c.id === condition.id && c.checked)))
      setConditions(conditions)
    }
    fetchConditions()

    const fetchUserVehicles = async () => {
      const userVehicles = await UserVehiclesService.getForLoggedUser({ pageNumber: 1, pageSize: 999 })
      setDriverVehicles(userVehicles.data.items)
      if (userVehicles.data && userVehicles.data.items.length === 1) {
        setSelectedDriverVehicle(userVehicles.data.items[0]);
      }
    }
    fetchUserVehicles()

    return () => {
      const footer = document.getElementById("footer")
      if (footer) {
        footer.style.display = "block"
      }

      const body = document.getElementsByTagName("body")[0]
      if (body) {
        body.classList.add("bg-light")
      }
    }
  }, [])

  const handleCheckboxChange = (event, id) => {
    const updatedConditions = conditions.map((condition) => {
      if (condition.id === id) {
        condition.checked = event.target.checked
      }
      return condition
    })
    setConditions(updatedConditions)
  }

  const nextStep = () => {
    if (step === 1) {
      if (formData.selectedDriverVehicle === null) {
        setDriverVehicleError(t("USER_VEHICLE_ERROR"))
      } else {
        setStep(prev => prev + 1);
        setDriverVehicleError(null);
        setZeroResults(false);
      }
    }

    if (step === 2) {
      setRouteError(null)

      if (formData.selectedRouteIndex !== null) {
        setStep(prev => prev + 1);
      } else {
        setRouteError(t("ROUTE_ERROR"));
        return false;
      }
      setRouteError(null);
    }

    if (step === 3) {
      if (totalPrice === 0) {
        setPriceError(t("PRICE_ERROR"));
      } else {
        setPriceError(null);
        setStep(prev => prev + 1);
      }
    }
  }

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
    setZeroResults(false);
  }

  const deleteMilestone = (index) => {
    let copy = [...rows]
    if (copy) {
      copy.splice(index, 1)
      recalculateTotalPrice();
      setIndex(index)
      setRows(copy)
    }
  }

  const onVehicleClick = (vehicle) => {
    setSelectedDriverVehicle(vehicle)
  }

  useEffect(() => {
    setFormData((prevObj) => ({
      ...prevObj,
      rows,
      conditions: conditions,
      selectedDriverVehicle: selectedDriverVehicle,
      maxPassengersCount: maxPassengersCount,
      price: totalPrice,
      womenOnly: womenOnly
    }));
  }, [conditions, selectedDriverVehicle, maxPassengersCount, rows, totalPrice, womenOnly]);

  return (
    <>
      {step === 1 && (
        <>
          <div className={milestoneModalOpen === true ? "descriptionHeightMobile" : "titleWithDescriptionMobile"}>
            <span className={milestoneModalOpen === true ? "text-center googleRoutesmainTitleMobile mobileFixedTitleAndDescription textWhite" : "text-center googleRoutesmainTitleMobile textWhite"}>{t("POST_RIDE")}</span>
            <span className={milestoneModalOpen === true ? "text-center googleRoutesmainDescriptionMobile mobileFixedTitleAndDescription textWhite fontWeight100" : "text-center googleRoutesmainDescriptionMobile textWhite fontWeight100"}>{t("PUBLISH_RIDE_1_STEP_DESCRIPTION")}</span>
          </div>

          <div className="card mb-3 mainCard bg-light">
            <div className="card-body cursor-pointer px-0 mx-0 textWhite">

            </div>
          </div>

          <div className="card addDepartureCard bg-light">
            <Timeline isItemActive={() => false}>
              <Timeline.Item dot={<></>}>
                <div className="row mt-2">
                  <div className="col-auto d-flex flex-row departureMobile">
                    <h6 className="departure fs16 textDark  fw-600">{t("DEPARTURE")}</h6>
                  </div>
                </div>
              </Timeline.Item>

              <Timeline.Item className="mb-1">
                <div onClick={() => editPoint(0)} className="card-body shadow cursor-pointer rounded-4 addMilestone">
                  <div className="row" >
                    <div className="col d-flex ms-1" style={{ alignItems: "baseline" }}>
                      <div className="h-100" style={{ marginRight: "12px" }}>
                        <img className="h-15px" src="/assets/images/from.svg" alt="from" />
                      </div>
                      {!rows[0] || !rows[0].isSubmitted ? (
                        <b>
                          <span className="textDark ms-2 mt-5">{t("ADD_DEPARTURE")}</span>{" "}
                        </b>
                      ) : (
                        <div className="d-inline">
                          <p className="black d-inline fs14 fw-600 cityNameMargin ms-0">
                            {rows[0] && rows[0].cityName}, {rows[0] && rows[0].address}
                          </p>
                          {/* <div className="d-flex addressMobileDeparture">{rows[0] && rows[0].address}</div> */}
                        </div>
                      )}
                    </div>
                    <div className="flex-row mt-1">
                      {!rows[0] || !rows[0].isSubmitted ? (
                        ""
                      ) : (
                        <>
                          <img height="15px" width="15px" className=" mt-1 mb-1 calendarDeparture" src="/assets/images/calendar.svg" alt="calendar" />
                          <span className="fs12 ms-2 fw-500 gray">{rows[0] && moment(rows[0].dateTime).format("DD.MM.YYYY HH:mm")}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Timeline.Item>


              {/* {rows && rows.slice(1, rows.length - 1).filter((r) => r.isSubmitted).length === 0 && (
                <Timeline.Item dot={<></>}><div className="row mt-1">
                  <div className="col-auto d-flex flex-row destinationMargin">
                      <h6 className="stop textDark fontWeight500 fw-700">{t("STOPS")}</h6>
                  </div>
                </div>  </Timeline.Item>
              )} */}


              {rows &&
                rows
                  .slice(1, rows.length - 1)
                  .filter((r) => r.isSubmitted)
                  .map((row, index) => (
                    <>
                      <Timeline.Item dot={<></>}>
                        <div className="row mt-1" key={index}>
                          <div className="col-auto d-flex flex-row">
                            <h6 className="textDark fontWeight500 fw-700">
                              {t("STOP1")} {index + 1}
                            </h6>
                          </div>
                        </div>
                      </Timeline.Item>
                      <Timeline.Item>
                        <div onClick={() => editPoint(index + 1)} className="card-body shadow cursor-pointer rounded-4 addMilestone">
                          <div className="flex">
                            <div className="col-11">
                              <div className="flex" >
                                <img className="mt-1" height={"16px"} width={"16px"} src="/assets/images/location.svg" alt="location" />
                                <div className="d-inline marginStops">
                                  <b>
                                    <span className="fs14 black fw-600 marginLeftMobile ms-0">
                                      {row.cityName}, {row.address}
                                    </span>
                                  </b>
                                  {/* <div className="d-flex marginLeftMobile">{row.address}</div> */}
                                </div>
                              </div>
                              <div className="flex-row mt-1">
                                <div className="col d-inline-flex">
                                  <img width="15px" height="15px" className="mt-1 mb-1 calendarDeparture" src="/assets/images/calendar.svg" alt="calendar" />
                                  <span className="fs12 ms-2 mt-1 fw-500 gray">{moment(row.dateTime).format("DD.MM.YYYY HH:mm")}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="align-items-center col-1 flex justify-content-end">
                              <img
                                className="float-end"
                                src="/assets/images/trash.svg"
                                alt="trash"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  deleteMilestone(index + 1)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Timeline.Item>
                    </>
                  ))}

              <Timeline.Item>
                <div onClick={() => chooseStopOver()} className="card-body shadow cursor-pointer rounded-4 addMilestone">
                  <div className="row" >
                    <div className="col">
                      <img className="h-20px mb-1" src="/assets/images/location11.svg" alt="location" />
                      <span className=" black fs14 fw-600 margin">
                        {t("ADD_STOPS")}

                      </span>
                    </div>
                  </div>
                </div>
              </Timeline.Item>
              <Timeline.Item dot={<></>}>
                <div className="row mt-1">
                  <div className="col-auto d-flex flex-row destinationMargin">
                    <h6 className="destination fs16 textDark  fw-600">{t("DESTINATION")}</h6>
                  </div>
                </div>
              </Timeline.Item>
              <Timeline.Item>
                <div onClick={() => editPoint(rows.length - 1)} className="card-body shadow cursor-pointer rounded-4 addMilestone">
                  <div className="row" >
                    <div className="col d-flex ms-1" style={{ alignItems: "baseline" }}>
                      <div className="h-100" style={{ marginRight: "12px" }}>
                        <img className="h-15px" src="/assets/images/from.svg" alt="from" />
                      </div>
                      {!rows[rows.length - 1] || !rows[rows.length - 1].isSubmitted ? (
                        <b>
                          <span className="textDark ms-2 mt-5">{t("ADD_DESTINATION")}</span>{" "}
                        </b>
                      ) : (
                        <div className="d-inline">
                          <p className="black d-inline fs14 fw-600 marginDestination ms-0">
                            {rows[rows.length - 1] && rows[rows.length - 1].cityName}, {rows[rows.length - 1] && rows[rows.length - 1].address}
                          </p>
                          {/* <div className="d-flex addressMobileDestination">{rows[rows.length - 1] && rows[rows.length - 1].address}</div> */}
                        </div>
                      )}
                    </div>
                    <div className="flex-row mt-1">
                      {!rows[rows.length - 1] || !rows[rows.length - 1].isSubmitted ? (
                        ""
                      ) : (
                        <>
                          <img width="15px" height="15px" className=" mb-1 mt-1 calendarDeparture" src="/assets/images/calendar.svg" alt="calendar" />
                          <span className="fs12 ms-2 fw-500 gray">
                            {rows[0] && moment(rows[rows.length - 1].dateTime).format("DD.MM.YYYY HH:mm")}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Timeline.Item>
            </Timeline>
            <span className="text-center my-3 textDark widthCard">
              <b>{t("BASIC_INFORMATION")}</b>
            </span>

            <div className="card shadow widthCard">
              <div className="card-body">
                <div className="float-left">
                  <span className="textDark fw-600 fontSize16 ms-2">{t("MAX_PASSENGERS_COUNT")}</span>
                </div>
                <div className="float-right maxPassengersCount">
                  <span type="button" className="minus me-3" style={{ display: "inline-block" }} onClick={decrementCount}>
                    <i className="fa-solid fa-minus passengersCount d-flex justify-content-evenly"></i>
                  </span>
                  <span className="textDark">
                    <b>{maxPassengersCount}</b>
                  </span>
                  <span type="button" className="plus ms-3" style={{ display: "inline-block" }} onClick={incrementCount}>
                    <i className="fa-solid fa-plus passengersCount d-flex justify-content-evenly"></i>
                  </span>
                </div>
              </div>
            </div>

            {user.Gender === "Female" &&
              <div className="card shadow widthCard mt-3">
                <div className="card-body">
                  <div className="float-left">
                    <span className="textDark fw-600 fontSize16 ms-2">{t("WOMEN_ONLY")}</span>
                  </div>
                  <div className="float-right womenOnlyCard">
                    <input key={womenOnly} onChange={changeWomenOnly} className="form-check-input custom-input womenOnly cursor-pointer" style={{ marginTop: "2px" }} type="checkbox" defaultChecked={womenOnly} />
                  </div>
                </div>
              </div>
            }

            <div className="card shadow mt-3 widthCard">
              <div className="card-body">
                <div className="row justify-content-between">
                  <div className="textDark fw-600 fontSize16 ms-2">
                    <span>{t("CONDITIONS")}</span>
                  </div>
                  <div className="mt-3">
                    {conditions &&
                      conditions.map((condition, index) => {
                        return (
                          <div key={`checkboxMobile_${index}`} className="form-check d-flex ms-2">
                            <input
                              id={`checkboxMobile_${index}`}
                              type="checkbox"
                              defaultValue={condition}
                              defaultChecked={condition.checked}
                              onChange={(e) => handleCheckboxChange(e, condition.id)}
                              className="form-check-input inputCheckColor mt-2 conditionMargin"
                              readOnly
                            />
                            <img
                              className="conditionsImages inputCheckColor mb-2 mt-1 ms-2"
                              src={condition.checked ? httpSmartClient.getPhotoUrl(condition.noIconId) : httpSmartClient.getPhotoUrl(condition.iconId)}
                              alt=""
                            ></img>
                            <div style={{ marginTop: "0.25rem" }}>
                              <label htmlFor={`checkboxMobile_${index}`} className={`${condition.checked ? "form-check-label ps-2 condition labelColor" : "form-check-label ps-2 condition"} `}>
                                <h6 className="mb-0 fw-normal me-2">{condition.name}</h6>
                              </label>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow h-100 col mt-3 widthCard">
              <div className="card-header d-flex justify-content-between align-items-center p-3 ms-1">
                <span className="textDark fw-600 fontSize16 ms-2">{t("VEHICLES")}</span>
              </div>
              {driverVehicles &&
                driverVehicles.map((vehicle, index) => (
                  <div className="ms-1" key={index} onClick={(e) => onVehicleClick(vehicle)}>
                    <div className="form-check cursor-pointer mb-3">
                      <input
                        className="form-check-input directionChecked ms-0 mt-3"
                        type="radio"
                        defaultChecked={selectedDriverVehicle && selectedDriverVehicle.id === vehicle.id ? `checked` : ""}
                        name="driverVehicleMobile"
                        id={`radioMobile_${index}`}
                      />
                      <label className="form-check-label route d-flex" htmlFor={`radioMobile_${index}`}>
                        <h6 className="mb-0 fw-normal me-2">{vehicle.vehicleModel.vehicleBrand.name + " - " + vehicle.vehicleModel.name}</h6>
                        <span className="vehicle-color-circle  mt-1 mr-2" style={{ backgroundColor: vehicle.color }}></span>
                      </label>
                      <label className="textGray cursor-pointer" htmlFor={`radioMobile_${index}`}>
                        <ul className="nav nav-divider">
                          <li className="nav-item">{vehicle.licensePlateNumber} </li>
                          <li className="nav-item">{vehicle.manufactureYear} </li>
                        </ul>
                      </label>
                    </div>
                  </div>
                ))}
              {driverVehicleError && <span className="alert alert-danger text-danger mb-3 me-4 ms-4"><i className="fa-solid fa-circle-exclamation me-2 dangerIcon"></i>{t("USER_VEHICLE_ERROR")}</span>}
            </div>

            {step === 1 && (
              <div className="card mt-3 mb-3 h-40px justify-content-center textWhite addMilestone nextBtnBackgroundColor cursor-pointer  btnHeight" style={{ marginLeft: "1%" }}>
                <div className="d-flex p-3" onClick={() => nextStep()}>
                  <span className="col fontSize14 ms-2 fontWeight500">{t("NEXT")}</span>
                  <div className="col">
                    <img className="float-end" src="/assets/images/arrow-right.svg" alt="arrow-right" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {(step === 2 || step === 3) &&
        <>
          {step === 2 && <div className="googleRoutesTitle" style={zeroResults ? { height: "100px" } : {}}>
            <span className="d-flex justify-content-center googleRoutesmainTitle textWhite">{t("CHOOSE_ROUTE")}</span>
            <span className="d-flex justify-content-center googleRoutesmainDescription textWhite">{t("CHOOSE_TRAVEL_ROUTE")}</span>
          </div>}
          {step === 3 &&
            <div className="googleRoutesTitle">
              <span className="d-flex justify-content-center googleRoutesmainTitle textWhite">
                <div style={{ maxWidth: "200px", fontSize: "14px" }}>{rows[0].cityName}</div>
                <svg className="ms-3 me-3 mt-1" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 24 18" fill="none">
                  <path d="M14.8594 1.85938L22.0003 9.00034L14.8594 16.1413" stroke="#fff" strokeWidth="2.139" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2 9.00098H21.7994" stroke="#fff" strokeWidth="2.139" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div style={{ maxWidth: "200px", fontSize: "14px" }}>{rows[rows.length - 1].cityName}
                </div>
              </span>
              <span className="d-flex justify-content-center googleRoutesmainDescription textWhite">{t("CHOOSE_TRAVEL_PRICE")}</span>
            </div>
          }
          <div className="container d-inline">
            <GoogleRoutesMobile
              rows={rows}
              setRows={setRows}
              formData={formData}
              setFormData={setFormData}
              width={mapWidth}
              height={mapHeight}
              selectedRouteIndex={selectedRouteIndex}
              setSelectedRouteIndex={setSelectedRouteIndex}
              selectedSummary={selectedSummary}
              setSelectedSummary={setSelectedSummary}
              routeKilometers={routeKilometers}
              setRouteKilometers={setRouteKilometers}
              routeError={routeError}
              setRouteError={setRouteError}
              zeroResults={zeroResults}
              setZeroResults={setZeroResults}
              step={step}
              setStep={setStep}
              refreshRecommendedPrice={refreshRecommendedPrice}
              setRefreshRecommendedPrice={setRefreshRecommendedPrice}
              pricePerKilometers={pricePerKilometers}
              priceError={priceError}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              recalculateTotalPrice={recalculateTotalPrice}
            >
            </GoogleRoutesMobile>

            <div className="mt-6 cursor-pointer ms-2 me-2" >
              <div className="d-flex justify-content-between">
                <button className="btn btnSoft btn-md center-block me-2 btnStepDesktop d-flex justify-content-between btnHeightOther" onClick={() => prevStep()}>
                  <img className="mt-2" src="/assets/images/arrow-right2.svg" alt="arrow-right" />
                  <span className="fontWeight500 marginTop">{t("GO_BACK")}</span>
                </button>
                <button className="btn btnPrimary btn-md center-block btnStepDesktop d-flex justify-content-between btnHeightOther" onClick={() => nextStep()}>
                  <span className="fontWeight500 marginTop">{t("NEXT")}</span>
                  <img className="mt-2" src="/assets/images/arrow-right.svg" alt="arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </>
      }

      {step === 4 && <SubmitRideMobile
        formData={formData}
        prevStep={() => prevStep()}
      ></SubmitRideMobile>}
    </>
  )
}