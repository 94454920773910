import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastify } from "utils/toastify";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { PagingComponent } from "components/layout/components";
import { getAppPagingSettings } from "config/paging";
import { httpSmartClient } from "config/httpClient";
import { ConditionsService } from "data/services";
import ConditionAddModal from "./modals/ConditionAddModal";
import ConditionEditModal from "./modals/ConditionEditModal";

export default function ConditionsPage() {
  let pagingSettings = getAppPagingSettings();
  const { t } = useTranslation();
  const [pagingData, setPagingData] = useState(pagingSettings);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fetchData, setFetchData] = useState(false);

  const fetchConditions = async () => {
    const conditionsListDataApiResponse = await ConditionsService.getByParams({ pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, name: filters && filters.name ? filters.name : null });
    setData(conditionsListDataApiResponse.data);
  }

  useEffect(() => {
    fetchConditions();
  }, [pagingData.pageNumber, pagingData.pageSize, fetchData, filters]);

  const onPageChange = (page) => {
    pagingData.pageNumber = page;
    setPagingData({ ...pagingData });
  }

  const handleDelete = (id) => {
    setShowConfirmationDialog(true);
    setCurrentId(id);
  };

  const deleteCondition = async (id) => {
    const deleted = await ConditionsService.delete(id);
    setShowConfirmationDialog(false)
    if (deleted.isSuccess) {
      const index = data.items.findIndex(x => x.id == id)
      const Arr = data.items;
      Arr.splice(index, 1);
      setData((data) => {
        return {
          ...data,
          items: [...Arr]
        }
      })
      toastify.success(t("SUCCESSFULLY_DELETED_CONDITION"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png" />);
      setShowConfirmationDialog(false);
    } else {
      toastify.error(t("ERROR_DELETING_CONDITION"));
    }
  };

  const onSuccess = (isEdit) => {
    if (isEdit) {
      setShowEditModal(false);
    }
    else {
      setShowAddModal(false);
    }
    setFetchData(!fetchData);
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mb-4 mb-sm-4">
          <div className="d-sm-flex justify-content-between align-items-center">
            <h1 className="h4 mb-3 mb-sm-0">{t("CONDITIONS")}</h1>
            <div className="d-grid">
              <a className="btn btn-primary-soft" onClick={() => setShowAddModal(true)}>
                + {t("NEW_CONDITION")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-body">
          <div className="mb-4 col col-md-4 col-sm-12">
            <form className="rounded position-relative">
              <input style={{ height: "38px", borderRadius: "5px" }} className="form-control bg-transparent" type="text" placeholder={t("SEARCH")} aria-label="Search" onChange={(e) => setFilters({ ...filters, name: e.target.value })} />
              <button className="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                <i className="fas fa-search fs-6"></i>
              </button>
            </form>

          </div>
          <div className="bg-light rounded p-3 d-none d-lg-block">
            <div className="row row-cols-7 g-4">
              <div className="col">
                <h6 className="mb-0">{t("NAME")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("DESCRIPTION")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("PERMISSION_ICON")}</h6>
              </div>
              <div className="col">
                <h6 className="mb-0">{t("NOPERMISSION_ICON")}</h6>
              </div>
              <div className="col">
                <h6 className="float-end mb-0">{t("ACTION")}</h6>
              </div>
            </div>
          </div>
          {(data && data.length === 0) && (
            <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col text-center">{t("NO_RESULTS")}</div>
            </div>
          )}
          {data && data.items && data.items.map((condition, index) => (
            <div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col-sm col-6 ">
                <small className="d-block d-lg-none">{t("NAME")}:</small>
                <h6 className="mb-0 fw-normal">{t(condition.name)}</h6>
              </div>
              <div className="col-sm col-6 ">
                <small className="d-block d-lg-none">{t("DESCRIPTION")}:</small>
                <h6 className="mb-0 fw-normal">{t(condition.description)}</h6>
              </div>
              <div className="col-sm col-6 ">
                <small className="d-block d-lg-none">{t("PERMISSION_ICON")}:</small>
                <div style={{ width: "30px", height: "30px", overflow: "hidden", display: "flex", alignItems: "center" }}>
                  <img src={condition.iconId != null ? httpSmartClient.getPhotoUrl(condition.iconId) : "/assets/images/blankicon.jpg"}
                    alt="Profile Photo"
                    style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
                </div>
              </div>
              <div className="col-sm col-6 ">
                <small className="d-block d-lg-none">{t("NOPERMISSION_ICON")}:</small>
                <div style={{ width: "30px", height: "30px", overflow: "hidden", display: "flex", alignItems: "center" }}>
                  <img src={condition.noIconId != null ? httpSmartClient.getPhotoUrl(condition.noIconId) : "/assets/images/blankicon.jpg"}
                    style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
                </div>
              </div>

              <div className="col-sm col-12 text-end ">
                <small className="d-block d-lg-none mb-1 ">{t("ACTION")}:</small>

                <div className="d-inline">
                  <button className="btn   btn-sm btn-primary-soft me-2" onClick={() => {setCurrentId(condition.id); setShowEditModal(true)}}>
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button className="btn btn-sm btn-danger-soft border border-danger" onClick={() => handleDelete(condition.id)}>
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <PagingComponent
          className="pagination-bar"
          currentPage={data.pageNumber ?? 0}
          totalCount={data.totalCount ?? 0}
          pageSize={data.pageSize ?? 0}
          onPageChange={onPageChange}
        />
      </div>
      {showConfirmationDialog && <ConfirmationDialog
        onDiscard={() => setShowConfirmationDialog(false)}
        title={t("DELETE_CONDITION_ITEM")}
        description={t("DELETE_CONDITION")}
        confirmBtnLabel={t("DELETE")}
        onConfirm={() => deleteCondition(currentId)}
      ></ConfirmationDialog>}

      {showAddModal && <ConditionAddModal
        onDiscard={() => setShowAddModal(false)}
        title={t("NEW_CONDITION")}
        description={t("CONDITION_INFORMATION")}
        confirmBtnLabel={t("SAVE")}
        onSuccess={() => { onSuccess() }}
      ></ConditionAddModal>}

      {showEditModal && <ConditionEditModal
        onDiscard={() => setShowEditModal(false)}
        title={t("EDIT_CONDITION")}
        description={t("CONDITION_INFORMATION")}
        confirmBtnLabel={t("SAVE")}
        onSuccess={() => { onSuccess(true) }}
        conditionId={currentId}
      ></ConditionEditModal>}
    </>
  );
}