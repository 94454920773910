import { httpSmartClient } from "../../config/httpClient";
const baseName = "Notifications";

const NotificationsService = {

    getById: async (id) => {
        var respone = await httpSmartClient.getById(`/${baseName}/` + id);
        return respone;
    },
    markAllRead: async (data) => {
        var respone = await httpSmartClient.put(`/${baseName}/MarkAllRead`, data);
        return respone;
    },
    getByParams: async (params) => {
        var respone = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
        return respone;
    },
    getAll: async () => {
        var respone = await httpSmartClient.get(`/${baseName}/GetAll`);
        return respone;
    },
    toggleIsReadByUserId: async () => {
        var respone = await httpSmartClient.put(`/${baseName}/ToggleIsReadByUserId`);
        return respone;
    },

    add: async (notification) => {
        var respone = await httpSmartClient.post(`/${baseName}`, notification);
        return respone;
    },

    edit: async (notification) => {
        var respone = await httpSmartClient.put(`/${baseName}`, notification);
        return respone;
    },

    delete: async (id) => {
        var respone = await httpSmartClient.delete(`/${baseName}/` + id);
        return respone;
    },

}
export default NotificationsService;