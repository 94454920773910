import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toastify } from "utils/toastify";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import { EnumsService, VehicleModelsService, VehicleBrandsService } from "data/services";

const VehicleModelEditModal = (props) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors
    } = useForm();
    const [vehicleBrands, setVehicleBrands] = useState();
    const [vehicle, setVehicle] = useState();
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const [vehicleTypes, setVehicleTypes] = useState();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const setVehicleBrandsAndSetForm = async () => {
            const response = await VehicleModelsService.getById(props.vehicleModelId);
            setVehicle(response.data)
            const vehicleBrandsList = await VehicleBrandsService.getByParams({
                pageNumber: 1,
                pageSize: 9999
            });
            setVehicleBrands(() => {
                return vehicleBrandsList.data.items.map(x => {
                    return {
                        label: x.name,
                        value: x.id
                    }
                })
            });
            const vehicleTypesList = await EnumsService.getVehicleTypes();
            setVehicleTypes(() => {
                return vehicleTypesList.map(x => {
                    return {
                        label: t(`VEHICLE_TYPE_${x.value.toUpperCase()}`),
                        value: x.key
                    }
                })
            });
            const vehicleModel = await VehicleModelsService.getById(props.vehicleModelId);
            if (vehicleModel) {
                reset(vehicleModel.data);
            } else {
                props.onDiscard();
            }
        };
        setVehicleBrandsAndSetForm();
    }, []);

    const onSubmit = async (data) => {
        setFormSubmitting(true);
        const editedVehicleModel = await VehicleModelsService.edit({ id: props.vehicleModelId, ...data });
        if (editedVehicleModel.statusCode === 409) {
            setError("vehicleModelExists", 409)
            setFormSubmitting(false);
            return;
        }
        setFormSubmitting(false);
        if (editedVehicleModel.isSuccess) {
            props.onSuccess(true);
            toastify.success(t("SUCCESSFULLY_EDITED_VEHICLE_MODEL"));
        } else {
            toastify.error(t("ERROR_EDITING_VEHICLE_MODEL"));
        }
    };

    const handleDiscard = () => {
        props.onDiscard();
    }
    return (
        <>{vehicle &&
            <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                <div className="d-flex justify-content-center align-items-center h-100">
                <div className="card shadow mw-100" style={isMobile? {width:"90%", maxHeight: "70vh"} :{ width: "30%", maxHeight: "70vh" }}>
                        <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                            <span className="headers">{props.title}</span>
                            <button className="react-responsive-modal-closeButton mt-2 me-2" data-testid="close-button" onClick={() => handleDiscard()}><svg className="cursor-pointer mt-3" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 15 15" fill="none"><path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></button>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label className="form-label">{t("VEHICLE_BRAND")}</label>
                                    <Select
                                        {...register("vehicleBrandId", {
                                            required: t("VEHICLE_BRAND_IS_REQUIRED"),
                                        })}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                backgroundColor: 'white',
                                            })
                                        }}
                                        className="basic-select"
                                        onChange={(e) => {
                                            clearErrors();
                                            setValue("vehicleBrandId", e.value)
                                        }}
                                        defaultValue={{ label: vehicle.vehicleBrand.name, value: vehicle.vehicleBrandId }}
                                        options={vehicleBrands}
                                    />
                                    {errors.vehicleBrandId && <span className="text-danger">{t("VEHICLE_BRAND_IS_REQUIRED")}</span>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{t("NAME")}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            clearErrors()
                                            setValue("name", e.target.value)
                                        }}
                                        defaultValue={vehicle.name}
                                        placeholder={t("VEHICLE_MODEL_PLACEHOLDER")}
                                    />
                                    {errors.name && <span className="text-danger">{t("NAME_IS_REQUIRED")}</span>}
                                    {errors.vehicleModelExists && <span className="text-danger">{t("VEHICLE_MODEL_EXISTS")}</span>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">{t("VEHICLE_TYPE")}</label>
                                    <Select
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                backgroundColor: 'white',
                                            })
                                        }}
                                        className="basic-select"
                                        onChange={(e) => { setValue("vehicleType", e.value) }}
                                        defaultValue={{ label: t(`VEHICLE_TYPE_${vehicle.vehicleType.toUpperCase()}`), value: vehicle.vehicleType }}
                                        options={vehicleTypes}
                                    />
                                    {errors.vehicleType && <span className="text-danger">{t("VEHICLE_TYPE_IS_REQUIRED")}</span>}
                                </div>
                                <div className="d-flex justify-content-end mt-4">
                                    <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                                        {t("DISCARD")}
                                    </button>
                                    <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                                        {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                        {t("SAVE")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}
        </>

    );

};

export default VehicleModelEditModal;