import { httpSmartClient } from "../../config/httpClient";

const baseName = "UserRides";

const UserRidesService = {
  getTotalIncome: async () => {
    const response = await httpSmartClient.get(`/${baseName}/TotalIncome`);
    return response;
  },
  add: async (userRide) => {
    var response = await httpSmartClient.post(`/${baseName}`, userRide);
    return response;
  },
  addWithCashPayment: async (userRide) => {
    var response = await httpSmartClient.post(`/${baseName}/AddReservationWithCashPayment`, userRide);
    return response;
  },
  GetAllPassengersByRideId: async (rideId) => {
    var response = await httpSmartClient.get(`/${baseName}/GetByRideId?rideId=${rideId}`,);
    return response;
  },
  edit: async (userRide) => {
    var response = await httpSmartClient.put(`/${baseName}`, userRide);
    return response;
  },
  updateReview: async (userRide) => {
    var response = await httpSmartClient.put(`/${baseName}/UpdateReview`, userRide);
    return response;
  },
  getByDriverId: async (filter) => {
    var response = await httpSmartClient.getById(`/${baseName}/GetByDriverId`, { params: { ...filter } });
    return response;
  },
  getUpcoming: async (id) => {
    var response = await httpSmartClient.get(`/${baseName}/GetUpcoming?userId=${id}`);
    return response;
  },
  getCancelled: async (id) => {
    var response = await httpSmartClient.get(`/${baseName}/GetCancelled?userId=${id}`);
    return response;
  },
  changeRideStatus: async (userRideId, status) => {
    var response = await httpSmartClient.put(`/${baseName}/UpdateRideStatus?userRideId=${userRideId}&status=${status}`);
    return response;
  },
  getCompleted: async (id) => {
    var response = await httpSmartClient.get(`/${baseName}/GetCompleted?userId=${id}`);
    return response;
  },
  getIncomesPerMonths: async (from, to) => {
    var response = await httpSmartClient.get(`/${baseName}/GetIncomesPerMonths?from=${from}&to=${to}`);
    return response;
  },
  hasUserAlreadyBookedTwice: async (userId, rideId) => {
    var response = await httpSmartClient.get(`/${baseName}/AlreadyExistsTwice?userId=${userId}&rideId=${rideId}`)
    return response.data;
  },
  getTopRoutes: async () => {
    var response = await httpSmartClient.get(`/${baseName}/GetTopRoutes`)
    return response;
  },
  getByRideIdAndUserId: async (userId, rideId)=>{
      var response = await httpSmartClient.get(`/${baseName}/GetByRideIdAndUserId?userId=${userId}&rideId=${rideId}`)
      return response;
  }
}

export default UserRidesService;
