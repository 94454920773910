import React from "react";
import { useState } from "react";
import HeroComponent from "./hero/HeroComponent";
import { DriverInformation, InfoHeader } from "./info";
import { QuestionsSection } from "../landingPage";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import heroSearch from "data/stores/HeroSearchStore";
import HomePageCards from "./info/HomePageCards";
import { AuthService, UsersService } from "data/services";
import Modal from "react-responsive-modal";
import { VerificationModal } from "../user/userChunks/modals";
import globalStore from "data/stores/GlobalStore";
import { toastify } from "utils/toastify";
import { useTranslation } from "react-i18next";
import { httpSmartClient } from "config/httpClient";
import userProfileStore from "data/stores/UserProfileStore";
import chatStore from "data/stores/ChatStore";

const HomePage = observer(() => {
  const { t } = useTranslation();
  let user = AuthService.getCurrentUser();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [isCloseButtonDisabled, setIsClosedButtonDisabled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    heroSearch.setFilters(null);
    window.scrollTo(0, 0);

    if (user && globalStore.firstLogin) {
      const sendVerificationNumberAndOpenModal = async () => {
        const currentUser = await UsersService.getById(user.Id);
        if (currentUser) {
          setPhoneNumber(currentUser.data.phoneNumber);
        }
        const response = await AuthService.addVerifyPhoneNumberRequest();
        if (response.isSuccess) {
          setShowVerificationModal(true);
        } else {
          toastify.error(t("ERROR_SENDING_VERIFICATION_CODE"));
        }
      }
      sendVerificationNumberAndOpenModal();
    }
  }, []);

  const fetchUpdatedUser = async () => {
    const response = await UsersService.getById(user.Id);
    if (response.isSuccess) {
        const updatedUser = response.data;
        if (updatedUser.profilePhoto) {
            updatedUser.profilePhotoUrl = httpSmartClient.getPhotoUrlObjectFromBase64(updatedUser.profilePhoto);
        }
        else if (updatedUser.profilePhotoId) {
            updatedUser.profilePhotoUrl = httpSmartClient.getPhotoUrl(updatedUser.profilePhotoId);
        }
        userProfileStore.setUser(updatedUser);
        setIsVerified(updatedUser.isVerifiedPhoneNumber);
        globalStore.changeFirstLogin(!updatedUser.isVerifiedPhoneNumber);
        if (chatStore.chatMessages.length > 0) {
            chatStore.updateProfilePhotoId(response.data.profilePhotoId, response.data.id)
        }
    }
    else {
        console.log(response.error);
    }
}

  const handleCloseVerification = (bool) => {
    setIsClosedButtonDisabled(bool);
  };

  return (
    <>
      <HeroComponent />
      <InfoHeader />
      <DriverInformation />
      <HomePageCards />
      <QuestionsSection></QuestionsSection>

      {globalStore.firstLogin && <Modal
        open={showVerificationModal}
        onClose={() => {
          if (!isCloseButtonDisabled) {
            setShowVerificationModal(false);
          }
          globalStore.changeFirstLogin(false);
        }}
        closeIcon={globalStore.closeIcon}
        modalId='updateModals'
        classNames={{
          modal: 'modal-size h-auto',
          closeButton: "mt-1"
        }}
        center>
        <VerificationModal
          onDiscard={() => {
            setShowVerificationModal(false)
            localStorage.removeItem("firstLogin")
          }}
          setShowVerificationModal={setShowVerificationModal}
          phoneNumber={phoneNumber}
          fetchUpdatedUser={fetchUpdatedUser}
          onVerification={handleCloseVerification}
        />
      </Modal>}
    </>
  );

})

export default HomePage;
