import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { AuthService } from "data/services";
import { useNavigate } from "react-router-dom";
import { toastify } from "utils/toastify";
import Flatpickr from 'react-flatpickr';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { LanguageSwitcher } from "components/layout/components";
import { useIsFirstRender } from "../chat/hooks/useIsFirstRender";
import globalStore from "data/stores/GlobalStore";
import Select from "react-select";
import Header from "../../../layout/components/Header";

export default function SignUpPage() {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [policySubmit, setpolicySubmit] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [data, setData] = useState();
  const [isValid, setIsValid] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [IsTablet, setIsTablet] = useState(window.innerWidth <= 1200);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth <= 1500);
  const IsFirstLoad = useIsFirstRender();
  const currentDate = new Date();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const defaultDate = new Date();
  defaultDate.setFullYear(defaultDate.getFullYear() - 18);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    trigger
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const gender = [
    {
      value: 0,
      label: t("MALE")
    },
    {
      value: 1,
      label: t("FEMALE")
    }
  ]

  useEffect(() => {
    setValue("birthDate", defaultDate);
    trigger("birthDate");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1200);
      setIsDesktop(window.innerWidth <= 1500);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    //if (!IsFirstLoad) {
      //window.location.reload(false);
    //}
  }, [localStorage.getItem("i18nextLng")])

  const togglePasswordVisibility = (e) => {
    setPasswordVisibility((previousVisibility) => !previousVisibility);
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file != undefined) {
      setPreviewUrl(URL.createObjectURL(file));
      setValue("profilePhoto", file);
    }
  }

  const onSubmit = async (data) => {
    if (data.phoneNumber == "" || data.phoneNumber == undefined) {
      setError('phoneNumber', {
        type: "required",
        message: t('PHONE_NUMBER_IS_REQUIRED')
      })
      return;
    } else if (Object.keys(errors).length === 0 && checkbox == true) {
      if (data.profilePhoto && data.profilePhoto.length > 0) {
        data.profilePhoto = data.profilePhoto[0];
      }
      data = { ...data, phoneNumber: data.phoneNumber?.replace(/\s/g, ''), languageCode: localStorage.getItem('i18nextLng') };
      setData(data);
      setFormSubmitting(true);
      const signedUp = await AuthService.signUp(data);
      setFormSubmitting(false);
      if (signedUp.status === 200) {
        toastify.success(t("SUCCESSFULLY_SIGNED_UP"));
        navigate("/sign-in");
      }
      else if (signedUp.response.status === 409) {
        toastify.error(t("USER_SAME_EMAIL_EXISTS"));
      }
      else {
        toastify.error(t("ERROR_TRYING_SIGNUP"));
      }
    }
    setCheckboxError(true);
  };


  const clickCheckbox = () => {
    setCheckbox(prev => !prev)
    setCheckboxError(false);
  }

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <style>
        {`
      .css-v68sna-control {
        background-color: transparent!important;
        border: none!important;
      }
      .css-blhudp-control{
        background-color: transparent!important;
        border: none !important;
        box-shadow:none !important;
      }
      .css-b62m3t-container {
        border-radius:7px;
      }
    `}
      </style>
      <main>
        {!isMobile ? <div className="row justify-content-center align-items-center"  >
          <div className="col-12">
            <div className="row g-1">
              {!IsTablet ? <div className="col-5" >
                {localStorage.getItem("i18nextLng") == "ba" && <img src="assets/images/registracija-ba.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                  style={{ objectFit: "cover", maxHeight: "100%" }} />}
                {localStorage.getItem("i18nextLng") == "en" && <img src="assets/images/registracija-en.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                  style={{ objectFit: "cover", maxHeight: "100%" }} />}
                {localStorage.getItem("i18nextLng") == "de" && <img src="assets/images/registracija-de.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                  style={{ objectFit: "cover", maxHeight: "100%" }} />}
              </div> : <div className="col" >
                {localStorage.getItem("i18nextLng") == "ba" && <img src="assets/images/registracija-ba.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                  style={{ objectFit: "cover", maxHeight: "100%" }} />}
                {localStorage.getItem("i18nextLng") == "en" && <img src="assets/images/registracija-en.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                  style={{ objectFit: "cover", maxHeight: "100%" }} />}
                {localStorage.getItem("i18nextLng") == "de" && <img src="assets/images/registracija-de.png" className="cursor-pointer fade-in" onClick={() => navigate("/")} alt=""
                  style={{ objectFit: "cover", maxHeight: "100%" }} />}
              </div>}
              <div className="col-lg order-1 " style={{ backgroundColor: "white" }}>
                <div className="row" style={{ marginTop: "40px", marginBottom: "30px" }}>
                  <div className="col"></div>
                  <div className="col-auto d-flex flex-row">
                    <div className="col d-flex justify-content-center align-items-center">
                      <svg width="16" onClick={() => navigate("/rides")} style={{ marginTop: "1px" }} className="cursor-pointer" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_202_1401)">
                          <path d="M8.14812 15.1852C12.0346 15.1852 15.1852 12.0346 15.1852 8.14816C15.1852 4.26171 12.0346 1.11113 8.14812 1.11113C4.26167 1.11113 1.11108 4.26171 1.11108 8.14816C1.11108 12.0346 4.26167 15.1852 8.14812 15.1852Z" stroke="black" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M18.889 18.8889L13.5557 13.5555" stroke="black" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_202_1401">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p onClick={() => navigate("/rides")} className="cursor-pointer me-3" style={{ marginLeft: "10px", color: "black", fontSize: "14px" }}>{t("SEARCH")}</p>
                      <ul className="nav">
                        <LanguageSwitcher />
                      </ul>
                    </div>
                  </div>
                  <div className="col-2"></div>
                </div>
                <div className="row" >
                  {!isDesktop ? <div className="col"></div> : <div className="col-1"></div>}
                  <div className={!isDesktop ? "col-8 " : "col"}>
                    <h4 className="mb-2" style={{ fontWeight: "bold" }}>{t("CREATE_ACCOUNT")}</h4>
                    <p className="mb-0" style={{ fontSize: "14px" }}>
                      {t("SIGNUP_INFO")}
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} className="mt-4 text-start">
                      <div className="row mb-2" >
                        <div className="col"></div>
                        <div className="col" style={{ textAlign: "center" }}>
                          {previewUrl ?
                            <img src={previewUrl} alt="Profile photo preview" onClick={openFileInput} style={{ height: "120px", width: "120px" }} className="photo-preview  cursor-pointer" />
                            :
                            <div className="d-flex justify-content-center align-items-center cursor-pointer" >
                              <div className="bg-light photo-preview cursor-pointer" onClick={openFileInput} style={{ borderRadius: "100px", height: "120px", width: "120px" }}>
                                <svg width="65" height="65" style={{ marginTop: "25px" }} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M34.9998 35.0002C43.054 35.0002 49.5832 28.471 49.5832 20.4168C49.5832 12.3627 43.054 5.8335 34.9998 5.8335C26.9457 5.8335 20.4165 12.3627 20.4165 20.4168C20.4165 28.471 26.9457 35.0002 34.9998 35.0002Z" stroke="#292D32" strokeWidth="4.375" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M60.0546 64.1667C60.0546 52.8792 48.8254 43.75 35.0004 43.75C21.1754 43.75 9.94629 52.8792 9.94629 64.1667" stroke="#292D32" strokeWidth="4.375" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </div>
                              <svg width="40" height="41" style={{ marginTop: "70px", marginLeft: "-35px" }} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 40.103C31.0457 40.103 40 31.1487 40 20.103C40 9.05733 31.0457 0.103027 20 0.103027C8.9543 0.103027 0 9.05733 0 20.103C0 31.1487 8.9543 40.103 20 40.103Z" fill="#900FC8" />
                              </svg>
                              <svg width="18" height="17" style={{ marginTop: "70px", marginLeft: "-29px" }} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.19524 12.4921C1.0077 12.6797 0.902344 12.934 0.902344 13.1992V15.149C0.902344 15.7013 1.35006 16.149 1.90234 16.149H3.85213C4.11735 16.149 4.3717 16.0437 4.55924 15.8561L14.1913 6.22402L10.8273 2.86002L1.19524 12.4921Z" fill="white" />
                                <path d="M16.7823 2.36202L14.6884 0.267023C14.5197 0.0993976 14.2916 0.00531006 14.0539 0.00531006C13.8161 0.00531006 13.588 0.0993976 13.4194 0.267023L11.7773 1.90502L15.1414 5.26902L16.7823 3.62802C16.9491 3.45952 17.0426 3.23206 17.0426 2.99502C17.0426 2.75799 16.9491 2.53052 16.7823 2.36202Z" fill="white" />
                              </svg>
                            </div>
                          }
                          <div className="file-upload">
                            <input
                              {...register("profilePhoto", { defaultValue: null })}
                              type="file"
                              ref={fileInputRef}
                              hidden
                              className="form-control"
                              onChange={handlePhotoUpload}
                            />
                            <p style={{ color: "#9400d3", fontSize: "15px" }} className="cursor-pointer" onClick={openFileInput}>{t("CHOOSE_PICTURE")}</p>
                          </div>
                        </div>
                        <div className="col"></div>
                      </div>
                      <div className="row mb-3">
                        <div className="col">
                          <label className="form-label">{t("FIRST_NAME")}:</label>
                          <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                            <div className="input-group-prepend">
                              <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </span>
                            </div>
                            <input placeholder={t("FIRST_NAME")}
                              {...register("firstName",
                                {
                                  required: t("FIRST_NAME_IS_REQUIRED"),
                                  maxLength: {
                                    value: 50,
                                    message: t("MAXLENGTH_EXCEDED"),
                                  }
                                })}
                              style={{ border: "none", marginLeft: "-5px" }}
                              className="form-control bg-light firstName"
                            />
                          </div>
                          {errors.firstName && <small className="text-danger">{errors.firstName.message}</small>}
                        </div>
                        <div className="col">
                          <label className="form-label">{t("LAST_NAME")}:</label>
                          <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                            <div className="input-group-prepend">
                              <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                              </span>
                            </div>
                            <input placeholder={t("LAST_NAME")}
                              {...register("lastName",
                                {
                                  required: t("LAST_NAME_IS_REQUIRED"),
                                  maxLength: {
                                    value: 50,
                                    message: t("MAXLENGTH_EXCEDED"),
                                  }
                                })}
                              style={{ border: "none", marginLeft: "-5px" }}
                              className="form-control  bg-light"
                            />
                          </div>
                          {errors.lastName && <small className="text-danger">{errors.lastName.message}</small>}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col">
                          <label className="form-label">{t("EMAIL")}:</label>
                          <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                            <div className="input-group-prepend">
                              <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M16.2917 19.6457H6.70841C3.83341 19.6457 1.91675 18.2082 1.91675 14.854V8.14567C1.91675 4.7915 3.83341 3.354 6.70841 3.354H16.2917C19.1667 3.354 21.0834 4.7915 21.0834 8.14567V14.854C21.0834 18.2082 19.1667 19.6457 16.2917 19.6457Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M16.2916 8.625L13.292 11.0208C12.3049 11.8067 10.6853 11.8067 9.69825 11.0208L6.70825 8.625" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </span>
                            </div>
                            <input placeholder={t("EMAIL")}
                              type="email"
                              {...register("email", {
                                required: t("EMAIL_IS_REQUIRED"),
                                maxLength: {
                                  value: 50,
                                  message: t("MAXLENGTH_EXCEDED"),
                                },
                                pattern: {
                                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
                                  message: t("INVALID_EMAIL"),
                                },
                              })}
                              className="form-control bg-light"
                              style={{ border: "none" }} />
                          </div>
                          {errors.email && <small className="text-danger">{errors.email.message}</small>}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col position-relative">
                          <label className="form-label">{t("PASSWORD")}:</label>
                          <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                            <div className="input-group-prepend">
                              <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.75 9.58317V7.6665C5.75 4.49442 6.70833 1.9165 11.5 1.9165C16.2917 1.9165 17.25 4.49442 17.25 7.6665V9.58317" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M11.5001 17.7292C12.8233 17.7292 13.8959 16.6565 13.8959 15.3333C13.8959 14.0102 12.8233 12.9375 11.5001 12.9375C10.1769 12.9375 9.10425 14.0102 9.10425 15.3333C9.10425 16.6565 10.1769 17.7292 11.5001 17.7292Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M16.2917 21.0835H6.70841C2.87508 21.0835 1.91675 20.1252 1.91675 16.2918V14.3752C1.91675 10.5418 2.87508 9.5835 6.70841 9.5835H16.2917C20.1251 9.5835 21.0834 10.5418 21.0834 14.3752V16.2918C21.0834 20.1252 20.1251 21.0835 16.2917 21.0835Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </span>
                            </div>
                            <input placeholder={t("PASSWORD")}
                              {...register("password", {
                                required: t("PASSWORD_IS_REQUIRED"),
                                pattern: {
                                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!.%*?&])[A-Za-z\d@$!.%*?&]{8,}$/,
                                }
                              })}
                              className="form-control fakepassword  bg-light"
                              style={{ border: "none" }}
                              type={passwordVisibility ? "text" : "password"}
                            />
                            <i onClick={togglePasswordVisibility} style={{ color: "#9400D3" }} className={`fakepasswordicon fas ${passwordVisibility ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2 mt-1`}></i>
                          </div>
                          {errors.password && <small className="text-danger">{t("ERROR_PASSWORD")}</small>}
                        </div>

                        <div className="col">
                          <label className="form-label">{t("GENDER")}:</label>
                          <div className="row">
                            <div className="col-auto bg-light ms-3" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}>
                              <svg width="22" height="23" style={{ marginTop: "8px" }} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1013_7031)">
                                  <path d="M15.8853 12.2715C15.8853 14.8517 13.7936 16.9434 11.2134 16.9434C8.63317 16.9434 6.5415 14.8517 6.5415 12.2715C6.5415 9.69124 8.63317 7.59961 11.2134 7.59961C13.7936 7.59961 15.8853 9.69124 15.8853 12.2715Z" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M16.604 1.84961H21.6353V6.88086" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M6.04074 21.4736L2.22893 17.6622" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M15.0938 8.60498L21.5625 2.13623" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" />
                                  <path d="M1.4375 22.2612L7.90625 15.7925" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1013_7031">
                                    <rect width="23" height="23" fill="white" transform="translate(0 0.412109)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="col" style={{ marginLeft: "-20px" }}>
                              <Select
                                {...register("gender", { required: t("GENDER_IS_REQUIRED") })}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                  })
                                }}
                                placeholder={t("CHOOSE_GENDER")}
                                onChange={(e) => {
                                  clearErrors("gender")
                                  setValue("gender", e.value)
                                }}
                                className="bg-light"
                                options={gender}
                              />
                            </div>
                          </div>
                          {errors.gender && <small className="text-danger">{t("GENDER_IS_REQUIRED")}</small>}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col">
                          <label className="mb-2 form-label">{t("BIRTHDATE")}:</label>
                          <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                            <div className="input-group-prepend">
                              <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.66675 1.91663V4.79163" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M15.3333 1.91663V4.79163" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M3.35425 8.7113H19.6459" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M20.125 8.14579V16.2916C20.125 19.1666 18.6875 21.0833 15.3333 21.0833H7.66667C4.3125 21.0833 2.875 19.1666 2.875 16.2916V8.14579C2.875 5.27079 4.3125 3.35413 7.66667 3.35413H15.3333C18.6875 3.35413 20.125 5.27079 20.125 8.14579Z" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M15.0408 13.1292H15.0494" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M15.0408 16.0042H15.0494" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M11.4956 13.1292H11.5042" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M11.4956 16.0042H11.5042" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7.94876 13.1292H7.95736" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7.94876 16.0042H7.95736" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </span>
                            </div>
                            <Flatpickr
                              {...register("birthDate", {
                                required: t("BIRTH_DATE_IS_REQUIRED"),
                              })}
                              onChange={(e) => { clearErrors('birthDate'); setValue("birthDate", e) }}
                              type="text"
                              className="form-control bg-light"
                              placeholder={t("DATE")}
                              style={{ border: "none" }}
                              options={{
                                minDate: '01.01.1950',
                                maxDate: currentDate,
                                dateFormat: "d.m.Y",
                                defaultDate: defaultDate
                              }}
                            />
                          </div>
                          {errors.birthDate && <small className="text-danger">{t("BIRTH_DATE_IS_REQUIRED")}</small>}
                        </div>
                        <div className="col d-flex flex-column">
                          <label className="form-label mb-2">{t("PHONE_NUMBER")}:</label>
                          <IntlTelInput
                            {...register("phoneNumber", {
                              required: t("PHONE_NUMBER_IS_REQUIRED"),
                              validate: {
                                matchPattern: (v) => {
                                  const test = /[a-zA-Z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/.test(v);
                                  if (test || (v.includes("+") && v.indexOf("+") !== 0) || !isValid) {
                                    return t("PHONE_NUMBER_VALIDATION")
                                  }
                                }
                              },
                            })}
                            containerClassName="intl-tel-input"
                            inputClassName="form-control bg-light border-0 "
                            defaultCountry="ba"
                            onPhoneNumberChange={(isValid, value, fullNumber, extension) => {
                              if (value.length < 7 || value.length > 15)
                                setIsValid(false);
                              else if (value.split('+').length - 1 > 1)
                                setIsValid(false);
                              else setIsValid(true);
                              const phoneNumber = fullNumber.dialCode.concat(value.replace(/^0+/, ''));
                              setValue('phoneNumber', phoneNumber);
                            }}
                          />
                          {errors.phoneNumber && errors.phoneNumber.type == 'matchPattern' && <small className="text-danger">{t("PHONE_NUMBER_VALIDATION")}</small>}
                          {errors.phoneNumber && errors.phoneNumber.type == 'required' && <small className="text-danger">{t("PHONE_NUMBER_IS_REQUIRED")}</small>}
                        </div>
                        <div className="row mt-3">
                          <div className="col">
                            <div className="form-check">
                              <input className="form-check-input" style={{ height: "18px", width: "18px" }} type="checkbox" value="" id="confirmPolicy" onClick={clickCheckbox} />
                              <label className="form-check-label text-secondary" style={{marginTop:"1px"}} htmlFor="confirmPolicy">{t("ACCEPT_POLICY")}
                              </label>
                              <label><a style={{ fontWeight: "bold" }} target="_blank" className="purple cursor-pointer" onClick={() => window.open("/privacy-policy", "_blank")}>
                                &nbsp; {t("POLICY_SIGNUP")}
                              </a></label>
                            </div>
                          </div>
                          {checkboxError && !checkbox && <small className="text-danger">{t("ACCEPT_POLICY_ERROR")}</small>}
                        </div>
                      </div>
                      <div>
                        <button disabled={isFormSubmitting} type="submit" className="btn btn-primary w-100 mb-0 mt-4" style={{ borderRadius: "10px" }}>
                          <div className="row" style={{ height: "30px" }}>
                            <div className="col" style={{ textAlign: "left", marginTop: "2px" }}>
                              {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                              <span style={{ fontSize: "15px", fontWeight: "normal" }}> {t("CREATE_ACCOUNT_BUTTON")}</span>
                            </div>
                            <div className="col-auto" style={{ marginTop: "2px" }}>
                              <i className="bi bi-chevron-right"></i>
                            </div>
                          </div>
                        </button>
                        <p className="mb-0 mt-3" style={{ textAlign: "center", fontSize: "14px" }}>
                          {t("ALREADY_HAVE_ACCOUNT")}
                          <a style={{ fontWeight: "bold" }} className="purple cursor-pointer" onClick={() => navigate("/sign-in")}>
                            &nbsp; {t("SIGNIN")}
                          </a>
                        </p>
                      </div>
                    </form>
                  </div>
                  {!isDesktop ? <div className="col"></div> : <div className="col-1"></div>}
                </div>
                <div className="row mt-5" >
                  <div className="col"></div>
                  <div className="col-auto ">
                  <div className="col-md-12 text-center mb-4" style={{ height: "20px", fontFamily: "Inter-Medium, Helvetica", fontWeight: "500", color: "#ababab", fontSize: "14px" }}>
                    © 2024 VoziMe.ba
                  </div>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </div>
        </div> :
          <div className="card">
            <div className="card bg-purple" style={{ height: "250px", top: "0", maxWidth: "100%", borderRadius: "0px", textAlign: "center" }}>
              <Header></Header>
              <span className="mb-1" style={{ color: "white", fontWeight: "500", fontSize: "17px" }}>{t("CREATE_ACCOUNT")}</span>
              <p style={{ fontSize: "13px", color: "white" }}>
                {t("SIGNUP_INFO")}
              </p>
            </div>
            <div className="card bg-light" style={{ maxWidth: "100%", marginTop: "-10px", height: "1000px" }}>
              <div className="card p-4" style={{ maxWidth: "100%", marginTop: "-60px", marginLeft: "7px", borderRadius: "20px", marginRight: "7px", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)} className="text-start" style={{ marginTop: "-5px" }}>
                  <div className="row mb-3" style={{ marginTop: "-35px" }}>
                    <div className="col-2"></div>
                    <div className="col" style={{ textAlign: "center" }}>
                      {previewUrl ?
                        <img src={previewUrl} alt="Profile photo preview" onClick={openFileInput} style={{ height: "120px", width: "120px" }} className="photo-preview" />
                        :
                        <div className="d-flex justify-content-center align-items-center cursor-pointer" >
                          <div className="bg-light photo-preview cursor-pointer" onClick={openFileInput} style={{ borderRadius: "100px", height: "120px", width: "120px" }}>
                            <svg width="65" height="65" style={{ marginTop: "25px" }} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M34.9998 35.0002C43.054 35.0002 49.5832 28.471 49.5832 20.4168C49.5832 12.3627 43.054 5.8335 34.9998 5.8335C26.9457 5.8335 20.4165 12.3627 20.4165 20.4168C20.4165 28.471 26.9457 35.0002 34.9998 35.0002Z" stroke="#292D32" strokeWidth="4.375" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M60.0546 64.1667C60.0546 52.8792 48.8254 43.75 35.0004 43.75C21.1754 43.75 9.94629 52.8792 9.94629 64.1667" stroke="#292D32" strokeWidth="4.375" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                          <svg width="40" height="41" style={{ marginTop: "70px", marginLeft: "-35px" }} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 40.103C31.0457 40.103 40 31.1487 40 20.103C40 9.05733 31.0457 0.103027 20 0.103027C8.9543 0.103027 0 9.05733 0 20.103C0 31.1487 8.9543 40.103 20 40.103Z" fill="#900FC8" />
                          </svg>
                          <svg width="18" height="17" style={{ marginTop: "70px", marginLeft: "-29px" }} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.19524 12.4921C1.0077 12.6797 0.902344 12.934 0.902344 13.1992V15.149C0.902344 15.7013 1.35006 16.149 1.90234 16.149H3.85213C4.11735 16.149 4.3717 16.0437 4.55924 15.8561L14.1913 6.22402L10.8273 2.86002L1.19524 12.4921Z" fill="white" />
                            <path d="M16.7823 2.36202L14.6884 0.267023C14.5197 0.0993976 14.2916 0.00531006 14.0539 0.00531006C13.8161 0.00531006 13.588 0.0993976 13.4194 0.267023L11.7773 1.90502L15.1414 5.26902L16.7823 3.62802C16.9491 3.45952 17.0426 3.23206 17.0426 2.99502C17.0426 2.75799 16.9491 2.53052 16.7823 2.36202Z" fill="white" />
                          </svg>
                        </div>

                      }
                      <div className="file-upload">
                        <input
                          {...register("profilePhoto", { defaultValue: null })}
                          type="file"
                          ref={fileInputRef}
                          hidden
                          onChange={handlePhotoUpload}
                        />
                        <p style={{ color: "#9400d3", fontWeight: "bold" }} className="cursor-pointer" onClick={openFileInput}>{t("CHOOSE_PICTURE")}</p>
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <label className="form-label">{t("FIRST_NAME")}:</label>
                      <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                            <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </div>
                        <input placeholder={t("FIRST_NAME")}
                          {...register("firstName",
                            {
                              required: t("FIRST_NAME_IS_REQUIRED"),
                              maxLength: {
                                value: 50,
                                message: t("MAXLENGTH_EXCEDED"),
                              }
                            })}
                          style={{ border: "none", marginLeft: "-5px" }}
                          className="form-control  bg-light"
                        />
                      </div>
                      {errors.firstName && <small className="text-danger">{errors.firstName.message}</small>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <label className="form-label">{t("LAST_NAME")}:</label>
                      <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                            <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          </span>
                        </div>
                        <input placeholder={t("LAST_NAME")}
                          {...register("lastName",
                            {
                              required: t("LAST_NAME_IS_REQUIRED"),
                              maxLength: {
                                value: 50,
                                message: t("MAXLENGTH_EXCEDED"),
                              }
                            })}
                          style={{ border: "none", marginLeft: "-5px" }}
                          className="form-control  bg-light"
                        />
                      </div>
                      {errors.lastName && <small className="text-danger">{errors.lastName.message}</small>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <label className="form-label">{t("EMAIL")}:</label>
                      <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.2917 19.6457H6.70841C3.83341 19.6457 1.91675 18.2082 1.91675 14.854V8.14567C1.91675 4.7915 3.83341 3.354 6.70841 3.354H16.2917C19.1667 3.354 21.0834 4.7915 21.0834 8.14567V14.854C21.0834 18.2082 19.1667 19.6457 16.2917 19.6457Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M16.2916 8.625L13.292 11.0208C12.3049 11.8067 10.6853 11.8067 9.69825 11.0208L6.70825 8.625" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </div>
                        <input placeholder={t("EMAIL")}
                          type="email"
                          {...register("email", {
                            required: t("EMAIL_IS_REQUIRED"),
                            maxLength: {
                              value: 50,
                              message: t("MAXLENGTH_EXCEDED"),
                            },
                            pattern: {
                              value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
                              message: t("INVALID_EMAIL"),
                            },
                          })}
                          className="form-control  bg-light"
                          style={{ border: "none" }} />
                      </div>
                      {errors.email && <small className="text-danger">{errors.email.message}</small>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col position-relative">
                      <label className="form-label">{t("PASSWORD")}:</label>
                      <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.75 9.58317V7.6665C5.75 4.49442 6.70833 1.9165 11.5 1.9165C16.2917 1.9165 17.25 4.49442 17.25 7.6665V9.58317" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.5001 17.7292C12.8233 17.7292 13.8959 16.6565 13.8959 15.3333C13.8959 14.0102 12.8233 12.9375 11.5001 12.9375C10.1769 12.9375 9.10425 14.0102 9.10425 15.3333C9.10425 16.6565 10.1769 17.7292 11.5001 17.7292Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M16.2917 21.0835H6.70841C2.87508 21.0835 1.91675 20.1252 1.91675 16.2918V14.3752C1.91675 10.5418 2.87508 9.5835 6.70841 9.5835H16.2917C20.1251 9.5835 21.0834 10.5418 21.0834 14.3752V16.2918C21.0834 20.1252 20.1251 21.0835 16.2917 21.0835Z" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </div>
                        <input placeholder={t("PASSWORD")}
                          {...register("password", {
                            required: t("PASSWORD_IS_REQUIRED"),
                            pattern: {
                              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!.%*?&])[A-Za-z\d@$!.%*?&]{8,}$/
                              ,
                            }
                          })}
                          className="form-control fakepassword  bg-light"
                          style={{ border: "none" }}
                          type={passwordVisibility ? "text" : "password"}
                        />
                        <i onClick={togglePasswordVisibility} style={{ color: "#9400D3" }} className={`fakepasswordicon fas ${passwordVisibility ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2 mt-1`}></i>
                      </div>
                      {errors.password && <small className="text-danger">{t("ERROR_PASSWORD")}</small>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <label className="form-label">{t("GENDER")}:</label>
                      <div className="row">
                        <div className="col-auto bg-light ms-3" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}>
                          <svg width="22" height="23" style={{ marginTop: "8px" }} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1013_7031)">
                              <path d="M15.8853 12.2715C15.8853 14.8517 13.7936 16.9434 11.2134 16.9434C8.63317 16.9434 6.5415 14.8517 6.5415 12.2715C6.5415 9.69124 8.63317 7.59961 11.2134 7.59961C13.7936 7.59961 15.8853 9.69124 15.8853 12.2715Z" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M16.604 1.84961H21.6353V6.88086" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.04074 21.4736L2.22893 17.6622" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M15.0938 8.60498L21.5625 2.13623" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" />
                              <path d="M1.4375 22.2612L7.90625 15.7925" stroke="#7E7E7E" strokeWidth="1.62917" strokeLinecap="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1013_7031">
                                <rect width="23" height="23" fill="white" transform="translate(0 0.412109)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="col" style={{ marginLeft: "-20px" }}>
                          <Select
                            {...register("gender", { required: t("GENDER_IS_REQUIRED") })}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                              })
                            }}
                            placeholder={t("CHOOSE_GENDER")}
                            onChange={(e) => {
                              clearErrors("gender")
                              setValue("gender", e.value)
                            }}
                            className="bg-light"
                            options={gender}
                          />
                        </div>
                      </div>
                      {errors.gender && <small className="text-danger">{t("GENDER_IS_REQUIRED")}</small>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <label className="form-label mb-2">{t("BIRTHDATE")}:</label>
                      <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-10px" }}>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.66675 1.91663V4.79163" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M15.3333 1.91663V4.79163" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M3.35425 8.7113H19.6459" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M20.125 8.14579V16.2916C20.125 19.1666 18.6875 21.0833 15.3333 21.0833H7.66667C4.3125 21.0833 2.875 19.1666 2.875 16.2916V8.14579C2.875 5.27079 4.3125 3.35413 7.66667 3.35413H15.3333C18.6875 3.35413 20.125 5.27079 20.125 8.14579Z" stroke="#7E7E7E" strokeWidth="1.4375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M15.0408 13.1292H15.0494" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M15.0408 16.0042H15.0494" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.4956 13.1292H11.5042" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.4956 16.0042H11.5042" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.94876 13.1292H7.95736" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.94876 16.0042H7.95736" stroke="#7E7E7E" strokeWidth="1.91667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </span>
                        </div>
                        <Flatpickr
                          {...register("birthDate", {
                            required: t("BIRTH_DATE_IS_REQUIRED"),
                          })}
                          onChange={(e) => { clearErrors('birthDate'); setValue("birthDate", e) }}
                          type="text"
                          className="form-control cursor-pointer bg-light border-0"
                          placeholder={t("DATE")}
                          style={{ border: "none" }}
                          options={{
                            disableMobile: true,
                            minDate: '01.01.1950',
                            maxDate: currentDate,
                            dateFormat: "d.m.Y",
                            defaultDate: defaultDate
                          }}
                        />
                      </div>
                      {errors.birthDate && <small className="text-danger">{t("BIRTH_DATE_IS_REQUIRED")}</small>}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col d-flex flex-column">
                      <label className="form-label">{t("PHONE_NUMBER")}:</label>
                      <IntlTelInput
                        {...register("phoneNumber", {
                          required: t("PHONE_NUMBER_IS_REQUIRED"),
                          validate: {
                            matchPattern: (v) => {
                              const test = /[a-zA-Z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/.test(v);
                              if (test || (v.includes("+") && v.indexOf("+") !== 0) || !isValid) {
                                return t("PHONE_NUMBER_VALIDATION")
                              }

                            }
                          },
                        })}
                        containerClassName="intl-tel-input"
                        inputClassName="form-control bg-light border-0 "
                        defaultCountry="ba"
                        onPhoneNumberChange={(isValid, value, fullNumber, extension) => {
                          if (value.length < 7 || value.length > 15)
                            setIsValid(false);
                          else if (value.split('+').length - 1 > 1)
                            setIsValid(false);
                          else setIsValid(true);
                          const phoneNumber = fullNumber.dialCode.concat(value.replace(/^0+/, ''));
                          setValue('phoneNumber', phoneNumber);
                        }}
                      />
                      {errors.phoneNumber && errors.phoneNumber.type == 'matchPattern' && <small className="text-danger">{t("PHONE_NUMBER_VALIDATION")}</small>}
                      {errors.phoneNumber && errors.phoneNumber.type == 'required' && <small className="text-danger">{t("PHONE_NUMBER_IS_REQUIRED")}</small>}
                    </div>
                  </div>
                  <div className="row mb-2 mt-3">
                    <div className="col">
                      <div className="form-check">
                        <input className="form-check-input" style={{ height: "18px", width: "18px" }} type="checkbox" value="" id="confirmPolicy" onClick={clickCheckbox} />
                        <label className="form-check-label text-secondary" style={{marginTop:"1px"}} htmlFor="confirmPolicy" >{t("ACCEPT_POLICY")}
                        </label>
                        <label ><a style={{ fontWeight: "bold" }} target="_blank" className="purple cursor-pointer" onClick={() => window.open("/privacy-policy", "_blank")}>
                          &nbsp; {t("POLICY_SIGNUP")}
                        </a></label>
                      </div>
                      {checkboxError && !checkbox && <small className="text-danger">{t("ACCEPT_POLICY_ERROR")}</small>}
                    </div>
                  </div>
                  <div>
                    <button disabled={isFormSubmitting} type="submit" className="btn btn-primary w-100 mb-0 mt-4" style={{ borderRadius: "10px" }}>
                      <div className="row" style={{ height: "30px" }}>
                        <div className="col" style={{ textAlign: "left", marginTop: "2px" }}>
                          {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                          <span style={{ fontSize: "15px", fontWeight: "normal" }}> {t("Kreiraj novi račun")}</span>
                        </div>
                        <div className="col-auto" style={{ marginTop: "2px" }}>
                          <i className="bi bi-chevron-right"></i>
                        </div>
                      </div>
                    </button>
                    <p className="mb-0 mt-3" style={{ textAlign: "center", fontSize: "14px" }}>
                      {t("ALREADY_HAVE_ACCOUNT")}
                      <a style={{ fontWeight: "bold" }} className="purple cursor-pointer" onClick={() => navigate("/sign-in")}>
                        &nbsp; {t("SIGNIN")}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <img src="assets/images/registracija-footer.png" className="cursor-pointer bg-light  fade-in" alt=""
                style={{ objectFit: "cover", maxHeight: "100%", marginTop: "-6px" }} />
            </div>
          </div>
        }
      </main>
    </>
  );
}