import _debounce from 'debounce';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getAppPagingSettings from 'config/paging/pagingConsts';
import { UsersService } from 'data/services';
import { toastify } from 'utils/toastify';
import { PagingComponent } from 'components/layout/components';
import { ConfirmationDialog } from 'components/modules/public/dialogs';
import Select from 'react-select';
import { httpSmartClient } from 'config/httpClient';
import globalStore from 'data/stores/GlobalStore';
import moment from 'moment';
import { UpwardIcon } from 'components/layout/components/Icons';
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite';

const UsersPage = observer(() => {
    let pagingSettings = getAppPagingSettings();
    const [users, setUsers] = useState();
    const [pagingData, setPagingData] = useState(pagingSettings);
    const [data, setData] = useState([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showConfirmationDialogDeactivate, setShowConfirmationDialogDeactivate] = useState(false);
    const [isDeactivate, setIsDeactivate] = useState(false);
    const [currentId, setCurrentId] = useState();
    const [searchParams, setSearchParams] = useState({});
    const [verificationStatus, setVerificationStatus] = useState()
    const [activityStatus, setActivityStatus] = useState()
    const [orderOne, setOrderOne] = useState(false);
    const [orderTwo, setOrderTwo] = useState(false);
    const [colorArrowOne, setColorArrowOne] = useState();
    const [colorArrowTwo, setColorArrowTwo] = useState();
    const [numberOfActiveRides, setNumberOfActiveRides] = useState();
    const [sortBirthDate, setSortBirthDate] = useState(null);
    const [counterChangeSortBirthDate, setCounterChangeSortBirthDate] = useState(0);
    const [listingOrderValue, setListingOrderValue] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const navigate = useNavigate();
    const activityStatusSelectRef = useRef();
    const verificationStatusSelectRef = useRef(null);
    const [reloadOptionsKey, setReloadOptionsKey] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const { t } = useTranslation();

    const fetchUsers = async (params) => {
        const usersList = await UsersService.getPagedUsersProcedure({ ...params, pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize });
        setUsers(usersList.data.items);
        setData(usersList.data);
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 850);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setVerificationStatus([
            {
                label: t("VERIFIED"),
                value: true
            },
            {
                label: t("UNVERIFIED"),
                value: false
            }
        ])

        setActivityStatus([
            {
                label: t("ACTIVE"),
                value: true
            },
            {
                label: t("INACTIVE"),
                value: false
            }
        ])
    }, [t])

    useEffect(() => {
        fetchUsers();
    }, [pagingData.pageNumber, pagingData.pageSize]);

    const reloadOptions = () => {
        setReloadOptionsKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        if (verificationStatusSelectRef.current?.props?.value) {
            searchParams.isVerified = verificationStatus.find(x => x.value === searchParams.isVerified).value;
            searchParams.isVerifiedLabel = verificationStatus.find(x => x.value === searchParams.isVerified).label;
        }
        if (activityStatusSelectRef.current?.props?.value) {
            searchParams.isActive = activityStatus.find(x => x.value === searchParams.isActive).value;
            searchParams.isActiveLabel = activityStatus.find(x => x.value === searchParams.isActive).label;
        }
        reloadOptions();
    }, [globalStore.lng]);

    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
    }

    const handleDelete = async (id) => {
        const response = await UsersService.getTotalActiveRidesByUserId(id, "Upcoming");
        setNumberOfActiveRides(response.data);
        setShowConfirmationDialog(true);
        setCurrentId(id);
    };

    const handleSetSortBirthDate = () => {
        if (counterChangeSortBirthDate === 2) {
            setSortBirthDate(null);
            setCounterChangeSortBirthDate(0);
            return;
        }
        setCounterChangeSortBirthDate(counterChangeSortBirthDate + 1);
        if (sortBirthDate === null || sortBirthDate === 1) {
            setSortBirthDate(0);
        }
        else if (sortBirthDate == 0) {
            setSortBirthDate(1);
        }
        listingOrder();
    }

    const handleDeactivate = async (user) => {
        setIsDeactivate(user.isActive);
        const response = await UsersService.getTotalActiveRidesByUserId(user.id, "Upcoming");
        setNumberOfActiveRides(response.data);
        setShowConfirmationDialogDeactivate(true);
        setCurrentId(user.id);
    };

    const searchQuery = (e) => {
        const params = { ...searchParams, query: e.target.value };
        setSearchParams(params);
        fetchUsers(params);
    };

    const searchActive = (data) => {
        const params = { ...searchParams, isActive: data.value, isActiveLabel: data.label };
        setSearchParams(params);
        fetchUsers(params);
    };
    const listingOrder = () => {
        const params = { ...searchParams, sortBirthDate: sortBirthDate, listingOrder: listingOrderValue, orderType: orderType == true ? "DESC" : "ASC" };
        setSearchParams(params);
        fetchUsers(params);
    };
    const searchVerified = (data) => {
        const params = { ...searchParams, isVerified: data.value, isVerifiedLabel: data.label };
        setSearchParams(params);
        fetchUsers(params);
    };
    const deleteUser = async (id) => {
        const deletedUser = await UsersService.delete(id);
        setShowConfirmationDialog(false);
        if (deletedUser.isSuccess) {
            fetchUsers();
            toastify.success(t("SUCCESSFULLY_DELETED_USER"));
        } else {
            toastify.error(t("ERROR_DELETING_USER"));
        }
    }

    const deactivateUser = async (id) => {
        const deactivatedUser = await UsersService.toggleStatus(id);
        if (deactivatedUser) {
            const Arr = users;
            const index = users.findIndex(x => x.id == id);
            Arr[index].isActive = Arr[index].isActive == true ? false : true;
            setUsers(Arr)
            Arr[index].isActive == true ? toastify.success(t("SUCCESS_ACTIVATE_USER")) : toastify.success(t("SUCCESS_DEACTIVATE_USER"));
            setShowConfirmationDialogDeactivate(false);
        } else {
            toastify.error(t("ERROR_USER"));
        }
    }

    const ClearFilters = () => {
        const params = {};
        let searchElement = document.getElementById("searchUsers")
        setOrderOne(false);
        setOrderTwo(false);
        setColorArrowOne(false);
        setColorArrowTwo(false);
        searchElement.value = ""
        setSearchParams(params);
        fetchUsers(params);

    }
    return (

        <>
            <div className="row">
                <div className="col-12 mb-2 mb-sm-4">
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <h1 className="h4 mb-3 mb-sm-0">{t("USERS")}</h1>
                        <div className="d-grid">
                            <a href="#" className="btn btn-primary-soft" onClick={() => navigate("create")}>
                                + {t("NEW_USER")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex">
                <div className="col-12">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-auto col-sm-12 mt-3">
                                    <div className="form-group ">
                                        <form className="rounded position-relative">
                                            <input style={{ height: "38px", borderRadius: "5px" }} id="searchUsers" className="form-control bg-transparent" type="text" placeholder={t("SEARCH")} aria-label="Search" onChange={_debounce(searchQuery, 500)} />
                                            <button className="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                                                <i className="fas fa-search fs-6"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-auto  col-sm-12 mt-3">
                                    <div className="form-group ">
                                        <Select
                                            key={reloadOptionsKey}
                                            ref={verificationStatusSelectRef}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                                }),

                                            }}
                                            className="basic-select"
                                            onChange={(e) => searchVerified(e)}
                                            placeholder={t("CHOOSE_VERIFICATION_STATUS")}
                                            defaultValue={t("CHOOSE_VERIFICATION_STATUS")}
                                            options={verificationStatus}
                                            value={searchParams.isVerifiedLabel ? { label: searchParams.isVerifiedLabel, value: searchParams.isVerified } : null}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-auto  col-sm-12 mt-3">
                                    <div className="form-group ">
                                        <Select
                                            key={reloadOptionsKey}
                                            ref={activityStatusSelectRef}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    backgroundColor: globalStore.theme === 'dark' ? 'dark' : "white",
                                                })
                                            }}
                                            className="basic-select"
                                            onChange={(e) => searchActive(e)}
                                            placeholder={t("CHOOSE_ACTIVITY_STATUS")}
                                            options={activityStatus}
                                            value={searchParams.isActiveLabel ? { label: searchParams.isActiveLabel, value: searchParams.isActive } : null}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-md-auto  col-sm-12 mt-3">
                                    <div className="form-group ">
                                        <div className="d-flex align-items-center gap-1" style={{ zIndex: 100 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                <path d="M6.38201 14.5209L2.82617 10.9722" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.38281 2.47925V14.5209" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.6172 2.47925L14.173 6.028" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.6172 14.5209V2.47925" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <Select
                                                styles={reactSelectCustomStyle}
                                                value={sortBy.filter(s => s.value === sortBySelect)}
                                                options={sortBy}
                                                placeholder={t("CHOOSE")}
                                                className="basic react-select-price"
                                                components={{ SingleValue: ({ children, ...props }) => (<components.SingleValue {...props}>{children}</components.SingleValue>) }}
                                                onChange={(e) => handleSorting(e)}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className='col-md col-sm-12 pt-3  ps-0 d-flex justify-content-end mt-1 mt-md-0'>
                                    <button onClick={() => ClearFilters()} className="btn d-flex  justify-content-center w-sm-100 ms-3 align-items-center btn-sm btn-neutral-soft border border-danger " >
                                        <i className="bi bi-x-lg"></i>
                                    </button>
                                </div>

                            </div>
                            <div className="bg-light rounded p-3 d-none d-lg-block text-center">
                                <div className="row  text-center">
                                    <div className="col-lg-3 col-sm-12 col-md-4">
                                        <h6 className="float-start mb-0">{t("USER_INFORMATION")}</h6>
                                    </div>
                                    <div className="col-lg col-md-4 col-sm-6" >
                                        <h6 className="mb-0 ">{t("GENDER")}</h6>
                                    </div>
                                    <div onClick={() => { handleSetSortBirthDate(); }} className="col-lg cursor-pointer col-md-4 col-sm-6 ">
                                        <h6 className="mb-0">{t("BIRTHDATE")}
                                            <UpwardIcon size={"18px"} color={sortBirthDate !== null && sortBirthDate === 0 ? "#900fc8" : "grey"} styles={{ marginLeft: "5px", transform: "rotate(180deg)" }}></UpwardIcon>
                                            <UpwardIcon size={"18px"} color={sortBirthDate !== null && sortBirthDate === 1 ? "#900fc8" : "grey"}></UpwardIcon>

                                        </h6>
                                    </div>
                                    <div onClick={() => { setOrderOne(!orderOne); setOrderTwo(false); setColorArrowOne(true); setColorArrowTwo(false); setListingOrderValue(0); setOrderType(!orderOne); listingOrder() }} className="col-lg cursor-pointer col-md-4 col-sm-6 ">
                                        <h6 className="mb-0">{t("CANCELLED_VS_TOTAL_DRIVER")}
                                            {(orderOne == null || orderOne == true) && <UpwardIcon size={"18px"} color={colorArrowOne == true && orderOne != null ? "#900fc8" : "grey"} styles={{ marginLeft: "5px", transform: "rotate(180deg)" }}></UpwardIcon>}
                                            {orderOne == false && <UpwardIcon size={"18px"} color={colorArrowOne == true ? "#900fc8" : "grey"} styles={{ marginLeft: "5px" }}></UpwardIcon>}

                                        </h6>
                                    </div>
                                    <div onClick={() => { setOrderTwo(!orderTwo); setOrderOne(false); setColorArrowOne(false); setColorArrowTwo(true); setListingOrderValue(1); setOrderType(!orderTwo); listingOrder() }} className="col-lg cursor-pointer col-md-4 col-sm-6 ">
                                        <h6 className="mb-0">{t("CANCELLED_VS_TOTAL_PASSENGER")}
                                            {(orderTwo == null || orderTwo == true) && <UpwardIcon size={"18px"} color={colorArrowTwo == true && orderTwo != null ? "#900fc8" : "grey"} styles={{ marginLeft: "5px", transform: "rotate(180deg)" }}></UpwardIcon>}
                                            {orderTwo == false && <UpwardIcon size={"18px"} color={colorArrowTwo == true ? "#900fc8" : "grey"} styles={{ marginLeft: "5px" }}></UpwardIcon>}

                                        </h6>
                                    </div>
                                    <div className="col-lg col-md-4 col-sm-6">
                                        <h6 className="float-end mb-0">{t("ACTION")}</h6>
                                    </div>
                                </div>
                            </div>
                            {users && users.map((user, index) => (
                                <div key={index} className="row align-items-center border-bottom px-2 py-3 d-flex">
                                    <div className="col-lg-3 col-sm-12 col-md-4 d-flex mt-sm-3 mt-md-0 mb-sm-0 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: "60px", height: "60px", borderRadius: "50%", overflow: "hidden", float: "left", display: "flex", alignItems: "center" }}>
                                                <img src={user.profilePhotoId != null ? httpSmartClient.getPhotoUrl(user.profilePhotoId) : "/assets/images/avatar/01.jpg"}
                                                    className="card-img-top"
                                                    alt="Profile Photo"
                                                    style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center" }} />
                                            </div>
                                        </div>
                                        <div className="ms-3 d-flex flex-column">
                                            <div className='d-flex flex-column'>
                                                <h6 className="mb-0 fw-normal" style={{ color: "black", fontWeight: "bold" }}>{user.firstName} {user.lastName}</h6>
                                                <h6 className="mb-0 small" style={{ color: "gray", fontWeight: "normal" }}>{user.email}</h6>
                                            </div>
                                            <div className='d-flex mt-2'>
                                                <div className={`badge bg-opacity-10 ${user.isActive ? "bg-success text-success me-2" : "bg-danger text-danger me-2"}`}>{user.isActive ? "Active" : "Inactive"}</div>
                                                <div className={`badge bg-opacity-10 ${user.isVerifiedPhoneNumber ? "bg-success text-success" : "bg-danger text-danger"}`}>{user.isVerifiedPhoneNumber ? "Verified" : "Unverified"}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg  mb-2 mb-sm-0 d-sm-block d-inline-flex col-md-4 col-sm-6 col-md mt-sm-3 mt-md-0 text-center" >
                                        <small className="d-block d-lg-none mb-1">{t("GENDER")}:</small>
                                        <h6 className="mb-0 ms-2" style={{ color: "black", fontWeight: "normal" }}>{user.gender}</h6>
                                    </div>
                                    <div className="col-lg mb-2 mb-sm-0 d-sm-block d-inline-flex col-md-4 col-sm-6 mt-sm-3 mt-md-0 text-center" >
                                        <small className="d-block mb-1 d-lg-none">{t("BIRTHDATE")}:</small>
                                        <h6 className="mb-0 ms-2" style={{ color: "black", fontWeight: "normal" }}>{moment(new Date(user.birthDate)).format("DD.MM.YYYY")}</h6>
                                    </div>
                                    <Tooltip style={{ width: "25%", zIndex: 1 }} id={`tooltip-criticalUserDriver${user.id}`} ></Tooltip>
                                    <div className="col-lg  mb-2 mb-sm-0 col-md-4  d-sm-block d-inline-flex cursor-pointer col-sm-6 mt-sm-3 mt-md-0 text-center" >
                                        <small className="d-block mb-1 d-lg-none">{t("CANCELLED_VS_TOTAL_DRIVER")}:</small>
                                        <h6 data-tooltip-id={user.isCriticalDriver == true ? 'tooltip-criticalUserDriver' + user.id : ''} data-tooltip-content={t("CRITICAL_USER_WARNING")} className="mb-0 ms-2" style={{ color: "black", fontWeight: "normal" }}>{user.cancelledRidesAsDriver}/{user.totalRidesAsDriver}
                                            {user.isCriticalDriver && <img className='ms-2 mb-1' src='/assets/images/warning.png'></img>}
                                        </h6>
                                    </div>
                                    <Tooltip style={{ width: "25%", zIndex: 1 }} id={`tooltip-criticalUserPassenger${user.id}`} ></Tooltip>
                                    <div className="col-lg  mb-2 col-md-4  d-sm-block d-inline-flex cursor-pointer col-sm-6 mt-sm-3 mt-md-0 text-center" >
                                        <small className="d-block mb-1 d-lg-none">{t("CANCELLED_VS_TOTAL_PASSENGER")}:</small>
                                        <h6 data-tooltip-id={user.isCriticalPassenger == true ? 'tooltip-criticalUserPassenger' + user.id : ''} data-tooltip-content={t("CRITICAL_USER_WARNING")} className="mb-0 ms-2" style={{ color: "black", fontWeight: "normal" }}>{user.cancelledRidesAsPassenger}/{user.totalRidesAsPassenger}
                                            {user.isCriticalPassenger && <img className='ms-2 mb-1' src='/assets/images/warning.png'></img>}
                                        </h6>
                                    </div>
                                    <div className="col-md-4 col-lg  col-sm-12 mt-sm-3 mt-md-0 text-end text-sm-center">
                                        <small className="d-block d-lg-none mb-1 ">{t("ACTION")}:</small>
                                        <div className='d-inline'>
                                            <button className="btn  btn-sm btn-primary-soft mb-0 me-2" onClick={() => navigate(`edit/${user.id}`)}>
                                                <i className="bi bi-pencil-square"></i>
                                            </button>
                                            <button className={`btn btn-sm ${user.isActive ? 'btn-danger-soft border border-danger' : 'btn-primary-soft border border-primary'}  mb-0 me-2`} onClick={() => handleDeactivate(user)}>
                                                {user.isActive ? <i className='fas fa-user-slash'></i> : <i className='fas fa-user'></i>}
                                            </button>
                                            <button className="btn btn-sm btn-danger-soft mb-0 border border-danger " onClick={() => handleDelete(user.id)}>
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {data && <PagingComponent
                            className="pagination-bar"
                            currentPage={data.pageNumber ?? 0}
                            totalCount={data.totalCount ?? 0}
                            pageSize={data.pageSize ?? 0}
                            onPageChange={onPageChange}
                            pageCount={data.pageCount}
                        />}
                    </div>
                </div>
            </div>
            {showConfirmationDialogDeactivate && <ConfirmationDialog
                onDiscard={() => setShowConfirmationDialogDeactivate(false)}
                title={`${isDeactivate ? t("DEACTIVATE") : t("ACTIVATION")} ${t("USER")}`}
                description={`${t("PART_1")} ${isDeactivate ? t("DEACTIVATE_CONFIRM") : t("ACTIVATE")} ${t("THIS_USER")}?`}
                confirmBtnLabel={`${isDeactivate ? t("DEACTIVATE") : t("ACTIVATION")} `}
                onConfirm={() => deactivateUser(currentId)}
                confirmationDialogWidth={isMobile? "90%": "50%"}
                btnType={`${isDeactivate ? null : "btn-primary" }`}
                note= {isDeactivate ? t("DEACTIVATE_USER_NOTE", {numberOfActiveRides}): null}
            ></ConfirmationDialog>}
            {showConfirmationDialog && <ConfirmationDialog
                onDiscard={() => setShowConfirmationDialog(false)}
                title={t("DELETE_USER_ITEM")}
                description={t("DELETE_USER")}
                confirmBtnLabel={t("DELETE")}
                note= {t("DEACTIVATE_USER_NOTE", {numberOfActiveRides})}
                onConfirm={() => deleteUser(currentId)}
                confirmationDialogWidth={isMobile? "90%": "50%"}

            ></ConfirmationDialog>}
        </>
    )
});

export default UsersPage;