import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import MyProfileNavbar from "./MyProfileNavbar";
import loggedStore from "data/stores/LoggedStore";
import LoginFillIcon from "./Icons/LoginFillIcon";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcherMobile from "./LanguageSwitcherMobile";
import MenuIcon from "./Icons/MenuIcon";
import "./../common.css"
import Notifications from "./Notifications";
import ChatsNotification from "../../modules/public/chat/ChatsNotification";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { httpSmartClient } from "config/httpClient";
import notificationsStore from "data/stores/NotificationsStore";
import moment from "moment";
import { useWindowSize } from "@uidotdev/usehooks";
import Modal from "react-responsive-modal";
import VehiclesModal from "components/modules/public/rides/VehiclesModal";
import globalStore from "data/stores/GlobalStore";
import chatStore from "data/stores/ChatStore";
import userProfileStore from "data/stores/UserProfileStore";
import { UnFinalizedRideModal } from "components/modules/public/rides";
import { RidesService, NotificationsService, ChatsService, UserVehiclesService, AppSettingsService, UsersService, AuthService } from "data/services";

const Header = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [IsTablet, setIsTablet] = useState(window.innerWidth <= 1200);
  const [noVehicleFounded, setNoVehicleFounded] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [unFinalizedRideModalOpen, setUnFinalizedRideModalOpen] = useState(false);
  const [notVerifiedModalOpen, setNotVerifiedModalOpen] = useState(false);
  const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);
  const [currentPaymentType, setCurrentPaymentType] = useState();
  const [unfinalizedRides, setUnfinalizedRides] = useState(false);
  const [openedNotif, setOpenedNotif] = useState(false);
  const [unread, setUnread] = useState();
  const currentPath = window.location.pathname;
  var user = userProfileStore.user;
  const confirmationDialogWidth = "500px";
  const size = useWindowSize();
  const chat = chatStore;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (window.location.pathname === "/ServerError") {
      return;
    }
    const fetchAndSetIsCashAllowed = async () => {
      const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 })
      if (response.isSuccess) {
        setCurrentPaymentType(response.data.items[0].isCashAllowed);
      }
    }
    fetchAndSetIsCashAllowed();
  }, []);
  const signOut = () => {
    userProfileStore.setUser(null);
    user = null;
    AuthService.signOut();
    navigate("/sign-in");
  }

  const handleCreditCardConfirm = () => {
    setShowConfirmationDialog(false);
    navigate("/my-profile/my-payment-details")
  }


  const addVehicles = () => {
    setMilestoneModalOpen(false);
    setNoVehicleFounded(false);
    navigate("/my-profile/my-vehicles");
  }

  const onUnfinalizedConfirmClick = () => {
    setUnFinalizedRideModalOpen(false);
    setUnfinalizedRides(false);
    navigate("/my-profile/my-rides");
  }



  const onPublishRideClick = async () => {
    const userVehicles = await UserVehiclesService.getCountForLoggedUser();
    const LoggUser = await UsersService.getById(user.id);
    const id = LoggUser.data.id;
    const driverHasUnfinalizedRides = await RidesService.getUnfinalizedCountByDriverid(id);
    if (!LoggUser.data.isVerifiedPhoneNumber) {
      setNotVerifiedModalOpen(true);
      return;
    }
    if (userVehicles.data === 0) {
      setMilestoneModalOpen(true);
      setNoVehicleFounded(true);
    } else if (driverHasUnfinalizedRides.data >= 2) {
      setUnFinalizedRideModalOpen(true);
      setUnfinalizedRides(true);
    } else {
      setNoVehicleFounded(false);
      setUnfinalizedRides(false);
      navigate("/rides/create");
    }
  }
  useEffect(() => {
    if (window.location.pathname === "/ServerError" || window.location.pathname == "/sign-in") {
      return;
    }
    if (user == null)
      return;
    const toggleSeen = async () => {
      if (user == null)
        return;
      const unread = await ChatsService.getUnreadChats();
      if (unread && unread.data != null)
        setUnread(unread.data);
    }
    toggleSeen();
  }, [chat.toggleSeen, chat.messageSent])
  const handleNavigateMobile = (navigation) => {
    navigate(navigation);
    if (currentPath != navigation) {
      document.body.style.overflow = null;
      document.body.style.padding = null;
    }
  }
  const toggleNotifCanvas = (open) => {
    const MarkAllRead = async () => {
      notificationsStore.markAllRead();
      await NotificationsService.toggleIsReadByUserId();
    }
    MarkAllRead();
    setOpenedNotif(prev => !prev)
    if (open === true) {
      const elements = document.getElementsByClassName("sidebar-notif-offcanvas-inactive");
      const element = document.getElementsByClassName("sidebar-offcanvas-active");
      Array.from(element).forEach(x => {
        x.className = "d-none sidebar-offcanvas-inactive"
      })
      Array.from(elements).forEach(x => {
        x.classList.remove("d-none")
      })
    } else {
      const elements = document.getElementsByClassName("sidebar-notif-offcanvas-inactive");
      const element = document.getElementsByClassName("sidebar-offcanvas-inactive");
      Array.from(element).forEach(x => {
        x.classList.remove("d-none")
        x.className = "card bg-white h-100 w-100 sidebar-offcanvas-active"
      })
      Array.from(elements).forEach(x => {
        x.className = "d-none sidebar-notif-offcanvas-inactive"
      })
    }
  }
  const onClickFunction = async (notify, isClickedFromCanvas) => {
    if ((notify.isReview == false || notify.isReview == null) && notify.isReviewOnUser == null) {
      navigate(`/rides/${notify.rideId}/${notify.rideMilestoneFromId}/${notify.rideMilestoneToId}`);
    } else {
      if (notify.userModel !== -1) {
        navigate(`/user/${notify.userModel}/ratings`, { state: { navigateFromDialog: true } })
      } else if (notify.isReviewOnUser == true) {
        navigate(`/user/${notify.userModelReviewOnUserId}/ratings`, { state: { navigateFromDialog: true } })
      }
      else {
        navigate(`/user/${notify.userId}/ratings`, { state: { navigateFromDialog: true } })
      }
    }
  }
  useEffect(() => {
    if (size.width > 1199) {
      setOpenedNotif(false);
      const elements = document.getElementsByClassName("sidebar-notif-offcanvas-inactive");
      const element = document.getElementsByClassName("sidebar-offcanvas-active");
      Array.from(element).forEach(x => {
        x.className = "card bg-white h-100 w-100 d-none sidebar-offcanvas-active"
      })
      Array.from(elements).forEach(x => {
        x.className = "d-none sidebar-notif-offcanvas-inactive"
      })
    } else {
      const elements = document.getElementsByClassName("sidebar-offcanvas-inactive");
      Array.from(elements).forEach(x => {
        x.classList.remove("d-none")
        x.className = "card bg-white h-100 w-100 sidebar-offcanvas-active"
      })
    }
  }, [size.width])
  return (
    <>
      {(location.pathname === "/" && size.width > 1060) && <svg className="blob " viewBox="0 0 200 100">
        <path className="path" d="M0 100 V60 Q30 45 60 60 Q90 75 120 60 Q150 45 180 60 Q210 75 240 60 Q270 45 300 60 V100 Z" fill="#900fc8" stroke="none" />
      </svg>}
      <div style={{ position: "fixed", width: "100%", zIndex: 1100 }} className="bg-purple mw-100 mh-auto" id="header">
        <section className="container p-0 " id="section-header">
          <div className="row g-4 g-lg-5 justify-content-center p-0" style={{ marginLeft: IsTablet ? "0px" : "-50px", marginRight: IsTablet ? "0px" : "-40px" }} >
            <div className=" justify-content-center position-relative mw-90 mh-100 mt-0 p-0">
              <header className="navbar-light mt-5 mb-2" id="header-height">
                <div className="d-flex mb-0 d-xl-none w-100 justify-content-between align-items-center">
                  <img className="img-header mt-3" src="/assets/images/logo-light.svg" onClick={() => handleNavigateMobile('/')} />
                  <span className="mb-1  d-flex flex-column align-items-center navbar-toggler-animation mt-3 ms-1" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
                    <MenuIcon color="white" size="25"></MenuIcon>
                    <span className="d-none d-sm-inline-block small font white">{t("MENU")}</span>
                    {unread && unread > 0 ? <span className="notif-badge-custom animation-blink"></span> : ""}
                  </span>
                </div>
                <div className="col-lg-12 col-xl-12">
                  <div className="offcanvas-xl offcanvas-end h-100" tabIndex="-1" id="offcanvasSidebar">
                    <div className="offcanvas-header p-3 pb-0">
                      {openedNotif ?
                        <div className="d-flex gap-1">
                          {/* <svg fill="#000000" height="30px" width="30px" version="1.1" onClick={() => toggleNotifCanvas(false)} id="Capa_1" viewBox="0 0 26.676 26.676"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59 c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815 C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029 c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562 C26.18,21.891,26.141,21.891,26.105,21.891z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg> */}
                          <img src="/assets/images/back_notif.jpg" height="30px" width="30px" onClick={() => toggleNotifCanvas(false)} />
                          <h5 className="mb-0 ms-4 mt-1">{t("NOTIFICATIONS")}</h5>
                        </div>
                        :
                        <div className="ms-auto">
                          <svg data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18" fill="none">
                            <path d="M1 17L17 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17 17L1 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>}
                    </div>
                    {openedNotif && <hr className="mb-2 mt-2 w-90 ms-auto me-auto p-0" style={{ borderTop: "1px solid black" }} />}
                    <div className="offcanvas-body p-3 p-lg-0 pt-0">
                      <div className="card bg-purple w-100 sidebar-offcanvas">
                        <div className="card-body d-flex align-items-center justify-content-between">
                          <div className="ms-1">
                            <a className="logo cursor-pointer logo-header" href="#" onClick={() => navigate("/")}>
                              <img className="img-header" src="/assets/images/logo-light.svg" alt="logo" />
                            </a>
                          </div>
                          <ul className="nav nav-pills-primary-soft d-flex justify-content-end ul-header" >
                            <li className="d-flex align-items-center">
                              {" "}
                              <div className=" cursor-pointer ">
                                <a className="nav-link d-flex align-items-center white" onClick={() => navigate("/rides")}>
                                  <svg width="17px" height="17px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_202_1401)">
                                      <path d="M8.14812 15.1852C12.0346 15.1852 15.1852 12.0346 15.1852 8.14816C15.1852 4.26171 12.0346 1.11113 8.14812 1.11113C4.26167 1.11113 1.11108 4.26171 1.11108 8.14816C1.11108 12.0346 4.26167 15.1852 8.14812 15.1852Z" stroke="white" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M18.889 18.8889L13.5557 13.5555" stroke="white" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_202_1401">
                                        <rect width="20" height="20" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <span className="ms-2 white" style={{ fontSize: "16px", fontWeight: "500" }}>{t("SEARCH")}</span>
                                </a>{" "}
                              </div>
                            </li>
                            {noVehicleFounded &&
                              <>
                                <Modal
                                  open={milestoneModalOpen}
                                  onClose={() => setMilestoneModalOpen(false)}
                                  closeIcon={globalStore.closeIcon}
                                  center
                                  classNames={
                                    {
                                      modal: "",
                                      closeButton: "mt-2"
                                    }
                                  }
                                >
                                  <VehiclesModal
                                    onDiscard={() => setMilestoneModalOpen(false)}
                                    title={t("INFO")}
                                    description={t("ERROR_NO_VEHICLES")}
                                    confirmBtnLabel={t("MY_NEW_VEHICLE")}
                                    onConfirm={() => addVehicles()}
                                  ></VehiclesModal>
                                </Modal>
                              </>
                            }
                            {unfinalizedRides &&
                              <>
                                <Modal
                                  open={unFinalizedRideModalOpen}
                                  onClose={() => setUnFinalizedRideModalOpen(false)}
                                  closeIcon={globalStore.closeIcon}
                                  center
                                  classNames={
                                    { modal: "" }
                                  }
                                >
                                  <UnFinalizedRideModal
                                    onDiscard={() => setUnFinalizedRideModalOpen(false)}
                                    title={t("WARNING")}
                                    description={t("MY_UNFINALIZED_RIDES")}
                                    confirmBtnLabel={t("SHOW_MY_ACTIVE_RIDES")}
                                    onConfirm={() => onUnfinalizedConfirmClick()}
                                  ></UnFinalizedRideModal>
                                </Modal>
                              </>
                            }
                            <>
                              <Modal
                                open={notVerifiedModalOpen}
                                onClose={() => setNotVerifiedModalOpen(false)}
                                closeIcon={globalStore.closeIcon}
                                center
                                classNames={
                                  { modal: "" }
                                }
                              >
                                <UnFinalizedRideModal
                                  onDiscard={() => setNotVerifiedModalOpen(false)}
                                  title={t("INFO")}
                                  description={t("PHONE_NOT_VERIFIED_MESSAGE")}
                                  confirmBtnLabel={t("GO_VERIFY")}
                                  onConfirm={() => { setNotVerifiedModalOpen(false); navigate("my-profile") }}
                                ></UnFinalizedRideModal>
                              </Modal>
                            </>
                            {user && <li className="d-flex align-items-center">
                              {" "}
                              <div className="cursor-pointer" onClick={() => onPublishRideClick()}>
                                <a className="nav-link d-flex align-items-center white">
                                  <div className="icon-text-container">
                                    <svg width="20px" height="20px" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M9.76912 23.8333H16.2691C21.6858 23.8333 23.8525 21.6666 23.8525 16.25V9.74996C23.8525 4.33329 21.6858 2.16663 16.2691 2.16663H9.76912C4.35246 2.16663 2.18579 4.33329 2.18579 9.74996V16.25C2.18579 21.6666 4.35246 23.8333 9.76912 23.8333Z" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M8.68579 13H17.3525" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M13.019 17.3333V8.66663" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="ms-2 white">{t("POST_RIDE")}</span>
                                  </div>
                                </a>{" "}
                              </div>
                            </li>}
                            {(user === null && !loggedStore.isLogged || token === null) && <li className={`d-flex align-items-center`}>
                              {" "}
                              <div className="cursor-pointer">
                                <a className="nav-link d-flex align-items-center yellow " onClick={() => navigate("/sign-in")} href="#">
                                  <LoginFillIcon size="20" color="white"></LoginFillIcon>
                                  <span className="ms-2 yellow" style={{ fontSize: "16px", fontWeight: "500" }}>{t("SIGN_IN")}</span>
                                </a>{" "}
                              </div>
                            </li>}
                            <li className="d-flex align-items-center">
                              {user != null && <ChatsNotification text={"white"} />}
                            </li>
                            <li className=" mt-2">
                              {user != null && <MyProfileNavbar></MyProfileNavbar>}
                            </li>
                            <li className="m-2">
                              {user != null && <Notifications />}
                            </li>
                            <li className="m-2">
                              <LanguageSwitcher />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card d-none bg-white h-100 w-100 sidebar-offcanvas-active">
                        <div className="card-body p-0 d-flex flex-column align-items-center justify-content-between">
                          <div className="w-100 h-100">
                            {user != null ?
                              <div className="d-flex flex-column justify-content-center align-items-center" onClick={() => handleNavigateMobile(`/user/${user.id}`)}>

                                <img className={`avatar-img rounded-circle mb-1 ${user.isVerifiedPhoneNumber ? 'profile__photo__isVerified' : ''} border border-2 border-white`} src={user && user.profilePhotoId ? user.profilePhotoUrl : "/assets/images/avatar/01.jpg"} />
                                {user.isVerifiedPhoneNumber && <img style={{ height: "17px", width: "17px", position: "relative", top: "-30px", left: "38px" }} src='../../assets/images/verified.png'></img>}

                                <h6 className="m-0">{user && user.firstName + " " + user.lastName}</h6>
                                <p>{user.email}</p>
                                <hr className={`${user != null ? "mb-3 mt-3" : "d-none"}`} />
                              </div> :
                              <div className="d-flex flex-column align-items-start not-signed-in mt-2">
                                <div className="mb-1 h-20">
                                <a className="logo cursor-pointer logo-header" href="#" onClick={() => handleNavigateMobile("/")}>
                                  <img className="light-mode-item navbar-brand-item larger" style={{ borderRadius: "0" }} src="/assets/images/logo.svg" alt="logo" />
                                </a>
                                </div>
                                <hr className="mt-1" />
                                {(user === null && !loggedStore.isLogged || token === null) && <a className="mb-3 mt-5" onClick={() => handleNavigateMobile("/sign-in")} href="#">
                                  <LoginFillIcon size="20" ></LoginFillIcon>
                                  <span className="ms-2 black">{t("SIGN_IN")}</span>
                                </a>}
                                <div className="links-off " onClick={() => handleNavigateMobile("/rides")}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><g clipPath="url(#clip0_379_7677)"><path d="M6.92594 12.9074C10.2294 12.9074 12.9074 10.2294 12.9074 6.92592C12.9074 3.62244 10.2294 0.944443 6.92594 0.944443C3.62246 0.944443 0.944458 3.62244 0.944458 6.92592C0.944458 10.2294 3.62246 12.9074 6.92594 12.9074Z" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.0555 16.0555L11.5222 11.5222" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"></path></g><defs><clipPath id="clip0_379_7677"><rect width="17" height="17" fill="white"></rect></clipPath></defs></svg>
                                  <span className="ms-2 black">{t("SEARCH")}</span>
                                </div>
                              </div>
                            }
                            <div className="d-flex flex-column align-items-start w-100 p-2 gap-3" style={{ textAlign: "start" }}>
                              {user != null &&
                                <div className="links" onClick={() => handleNavigateMobile("/rides")} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <g clipPath="url(#clip0_379_7677)">
                                      <path d="M6.92594 12.9074C10.2294 12.9074 12.9074 10.2294 12.9074 6.92592C12.9074 3.62244 10.2294 0.944443 6.92594 0.944443C3.62246 0.944443 0.944458 3.62244 0.944458 6.92592C0.944458 10.2294 3.62246 12.9074 6.92594 12.9074Z" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M16.0555 16.0555L11.5222 11.5222" stroke="black" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_379_7677">
                                        <rect width="17" height="17" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <span className="ms-2 black">{t("SEARCH")}</span>
                                </div>}
                              {user != null && <div className="links" onClick={() => onPublishRideClick()} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 21 21" fill="none">
                                  <g clipPath="url(#clip0_379_7644)">
                                    <path d="M6.94998 9.50022H12.05" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.5 12.0502V6.9502" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.95 18H12.05C16.3 18 18 16.3 18 12.05V6.95C18 2.7 16.3 1 12.05 1H6.95C2.7 1 1 2.7 1 6.95V12.05C1 16.3 2.7 18 6.95 18Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_379_7644">
                                      <rect width="19" height="19" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span className="ms-2 black" onClick={() => onPublishRideClick()}>{t("POST_RIDE")}</span>
                              </div>}
                              {user != null &&
                                <div className="links" onClick={() => handleNavigateMobile("/chat/messages")} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                  <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" style={{ marginBottom: "4px" }} viewBox="0 0 21 21" fill="none">
                                      <path d="M19.25 5.46875V9.93123C19.25 11.0425 18.8825 11.9787 18.2263 12.6262C17.5788 13.2825 16.6425 13.65 15.5312 13.65V15.2337C15.5312 15.8287 14.8662 16.1875 14.3762 15.855L13.5275 15.295C13.6063 15.0237 13.6412 14.7262 13.6412 14.4112V10.85C13.6412 9.06502 12.4513 7.875 10.6663 7.875H4.72499C4.60249 7.875 4.48875 7.88377 4.375 7.89252V5.46875C4.375 3.2375 5.8625 1.75 8.09375 1.75H15.5312C17.7625 1.75 19.25 3.2375 19.25 5.46875Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M13.6412 10.85V14.4112C13.6412 14.7262 13.6063 15.0237 13.5275 15.295C13.2038 16.5812 12.1363 17.3862 10.6663 17.3862H8.28625L5.64375 19.145C5.25 19.4163 4.72499 19.1275 4.72499 18.655V17.3862C3.83249 17.3862 3.08875 17.0888 2.5725 16.5725C2.0475 16.0475 1.75 15.3037 1.75 14.4112V10.85C1.75 9.18752 2.7825 8.04127 4.375 7.89252C4.48875 7.88377 4.60249 7.875 4.72499 7.875H10.6663C12.4513 7.875 13.6412 9.06502 13.6412 10.85Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="ms-2 black" >{t("CHATS")}</span>
                                    {unread && unread > 0 ? <span className="badge kvadrat ms-2 bg-primary">{unread && unread}</span> : ""}
                                  </>
                                </div>}
                              {user != null &&
                                <div className="links" onClick={() => toggleNotifCanvas(true)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path d="M10.5168 2.54639C7.62053 2.54639 5.26678 4.90014 5.26678 7.79639V10.3251C5.26678 10.8589 5.03928 11.6726 4.76803 12.1276L3.76178 13.7989C3.14053 14.8314 3.56928 15.9776 4.70678 16.3626C8.47803 17.6226 12.5468 17.6226 16.318 16.3626C17.3768 16.0126 17.8405 14.7614 17.263 13.7989L16.2568 12.1276C15.9943 11.6726 15.7668 10.8589 15.7668 10.3251V7.79639C15.7668 4.90889 13.4043 2.54639 10.5168 2.54639Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                                    <path d="M12.1359 2.79977C11.8647 2.72102 11.5847 2.65977 11.2959 2.62477C10.4559 2.51977 9.65094 2.58102 8.89844 2.79977C9.15219 2.15227 9.78219 1.69727 10.5172 1.69727C11.2522 1.69727 11.8822 2.15227 12.1359 2.79977Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M13.1406 16.6777C13.1406 18.1215 11.9594 19.3027 10.5156 19.3027C9.79813 19.3027 9.13312 19.0052 8.66062 18.5327C8.18812 18.0602 7.89062 17.3952 7.89062 16.6777" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" />
                                  </svg>
                                  <span className="ms-2 black">{t("NOTIFICATIONS")}</span>
                                  {notificationsStore.notifications.some(x => x.isRead === false) && <span className="badge kvadrat bg-primary ms-2">{notificationsStore.notifications.filter(x => x.isRead === false).length}</span>}
                                </div>
                              }
                              {user != null && <div className="links" onClick={() => handleNavigateMobile("/my-profile")} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                  <path d="M10.6489 9.27273C10.5557 9.2634 10.4439 9.2634 10.3415 9.27273C8.12421 9.19811 6.36346 7.37942 6.36346 5.14103C6.36346 2.856 8.20806 1 10.4998 1C12.7823 1 14.6362 2.856 14.6362 5.14103C14.6269 7.37942 12.8661 9.19811 10.6489 9.27273Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M5.72984 12.9532C3.42339 14.5075 3.42339 17.0404 5.72984 18.5851C8.35081 20.3504 12.6492 20.3504 15.2702 18.5851C17.5766 17.0308 17.5766 14.4979 15.2702 12.9532C12.6587 11.1974 8.36034 11.1974 5.72984 12.9532Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="ms-2 black" onClick={() => navigate("my-profile")}>{t("MY_PROFILE")}</span>
                              </div>}
                              {user && user.role == "Administrator" &&
                                <div className="links" onClick={() => handleNavigateMobile("/admin")} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                  <i className="fa-solid fa-user-shield"></i>
                                  <span className="ms-2">{t("ADMIN")}</span>
                                </div>}
                              {user != null && <div className="links" onClick={() => handleNavigateMobile("/my-profile/my-vehicles")} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                  <path d="M13.5708 2.47626H7.42832C5.24957 2.47626 4.76832 3.56126 4.48832 4.89126L3.49957 9.62501H17.4996L16.5108 4.89126C16.2308 3.56126 15.7496 2.47626 13.5708 2.47626Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M19.2418 17.3425C19.338 18.3662 18.5155 19.25 17.4655 19.25H15.8205C14.8755 19.25 14.7443 18.8475 14.578 18.3487L14.403 17.8238C14.158 17.1063 14.0005 16.625 12.7405 16.625H8.2605C7.0005 16.625 6.81675 17.1675 6.598 17.8238L6.423 18.3487C6.25675 18.8475 6.1255 19.25 5.1805 19.25H3.5355C2.4855 19.25 1.663 18.3662 1.75925 17.3425L2.24925 12.0137C2.37175 10.7012 2.6255 9.625 4.918 9.625H16.083C18.3755 9.625 18.6293 10.7012 18.7518 12.0137L19.2418 17.3425Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M3.5 7H2.625" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M18.3754 7H17.5004" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M10.5 2.625V4.375" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M9.1875 4.375H11.8125" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M5.25 13.125H7.875" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M13.125 13.125H15.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="ms-2 black">{t("MY_VEHICLES")}</span>
                              </div>}
                              {user != null && <div className="links" onClick={() => handleNavigateMobile("/my-profile/my-rides")} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                  <path d="M12.7312 1.75H8.26874C6.69374 1.75 6.34374 2.53751 6.14249 3.50876L5.42499 6.93874H15.575L14.8575 3.50876C14.6562 2.53751 14.3062 1.75 12.7312 1.75Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M16.835 12.53C16.905 13.2738 16.31 13.9125 15.5487 13.9125H14.3587C13.6762 13.9125 13.58 13.6238 13.4575 13.2563L13.3262 12.88C13.1512 12.3637 13.0375 12.0137 12.1187 12.0137H8.87249C7.96249 12.0137 7.82248 12.4075 7.66498 12.88L7.53374 13.2563C7.41124 13.615 7.31499 13.9125 6.63249 13.9125H5.44249C4.68124 13.9125 4.08624 13.2738 4.15624 12.53L4.51499 8.66253C4.60249 7.70878 4.78623 6.92999 6.44873 6.92999H14.5425C16.205 6.92999 16.3887 7.70878 16.4762 8.66253L16.835 12.53Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M5.425 5.03125H4.78625" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M16.2138 5.03125H15.575" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M6.69373 9.47626H8.59249" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M12.4075 9.47626H14.3062" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M10.5 14.875V15.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M10.5 18.375V19.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M2.625 15.75L1.75 19.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M18.375 15.75L19.25 19.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="ms-2 black">{t("MY_RIDES")}</span>
                              </div>}
                              {user != null && <div className="links" onClick={() => handleNavigateMobile("/my-profile/my-travel-preference")} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                  <path d="M19.25 5.6875H14" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M5.25 5.6875H1.75" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M8.75 8.75C10.4414 8.75 11.8125 7.37887 11.8125 5.6875C11.8125 3.99613 10.4414 2.625 8.75 2.625C7.05863 2.625 5.6875 3.99613 5.6875 5.6875C5.6875 7.37887 7.05863 8.75 8.75 8.75Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M19.25 15.3125H15.75" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M7 15.3125H1.75" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M12.25 18.375C13.9414 18.375 15.3125 17.0039 15.3125 15.3125C15.3125 13.6211 13.9414 12.25 12.25 12.25C10.5586 12.25 9.1875 13.6211 9.1875 15.3125C9.1875 17.0039 10.5586 18.375 12.25 18.375Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="ms-2 black">{t("MY_TRAVEL_PREFERENCES")}</span>
                              </div>}
                            </div>
                          </div>
                          <div className="d-flex flex-column align-items-start w-100 p-2 ps-0 mb-3" style={{ textAlign: "start" }}>
                            {user != null && <div className="links" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close" onClick={() => signOut()}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M13.2122 14.385C12.9409 17.535 11.3222 18.8212 7.77841 18.8212L7.66466 18.8212C3.75341 18.8212 2.18716 17.255 2.18716 13.3437L2.18716 7.63873C2.18716 3.72748 3.75341 2.16123 7.66466 2.16123L7.77841 2.16123C11.2959 2.16123 12.9147 3.42998 13.2034 6.52748" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.8749 10.5L17.8324 10.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.8813 13.4312L18.8125 10.5L15.8813 7.56871" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="ms-2 black">{t("SIGN_OUT")}</span>
                            </div>}
                            <div className={`${user != null ? "links" : "links"}`}>
                              <LanguageSwitcherMobile sidebar={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card d-none bg-white h-100 w-100 sidebar-notif-offcanvas-inactive">
                        <div className="card-body p-0 d-flex flex-column align-items-center justify-content-between">
                          <div className="w-100 h-100">
                            <ul className="list-group list-group-flush list-unstyled p-2 overflow-auto scrollbar-primary">
                              {notificationsStore.notifications.length > 0 ? notificationsStore.notifications.map((notification, index) => {
                                return (<li key={index} className="cursor-pointer" onClick={() => onClickFunction(notification, true)} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close" style={{ height: "100px" }}>
                                  <a className={`list-group-item list-group-item-action bg-info-soft rounded border-0 pb-1 ps-1 p-3 pt-1  ${!notification.isRead ? "notif-unread" : ""}`}>
                                    <div className="d-flex align-items-center">
                                      <img className="image-custom" style={{ height: "35px", width: "40px" }} src={notification.sender && notification.sender.profilePhotoId ? httpSmartClient.getPhotoUrl(notification.sender.profilePhotoId) : notification.userCancellationId ?
                                        notification.userCancellation.profilePhotoId ? httpSmartClient.getPhotoUrl(notification.userCancellation.profilePhotoId) :
                                          "/assets/images/avatar/01.jpg" : "/assets/images/logo-icon-round.svg"} />
                                      <div className="row">
                                        <h6 className="mt-1 ms-2" style={{ fontSize: "14px" }}>{notification.sender && notification.sender.firstName + " " + notification.sender.lastName} </h6>
                                        <span className="mb-2 ms-2" style={{ fontSize: "12px", fontWeight: "600" }}>{moment(notification.createdAt).format("DD.MM.YYYY HH:mm")}</span>
                                      </div>
                                    </div>
                                    <p className="mb-0" style={{ fontSize: "14px" }}>{notification.senderId === 2 ? notification.userCancellationId ? notification.userCancellation.firstName + " " + notification.userCancellation.lastName + " " + `${t(notification.text)}` : `${t(notification.text)}` : notification.sender.firstName + " " + notification.sender.lastName + " " + `${t(notification.text)}`}</p>
                                  </a>
                                  {notificationsStore.notifications.length - 1 != index && <hr />}
                                </li>)
                              }) : <h6 className="p-2 m-auto">{t("NO_NOTIFICATIONS")}</h6>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div >
        </section >
        {showConfirmationDialog && currentPaymentType === false && <ConfirmationDialog
          onDiscard={() => setShowConfirmationDialog(false)}
          title={t("CREDIT_CARD_TITLE_MODAL")}
          description={t("CREDIT_CARD_WARNING_MODAL")}
          confirmBtnLabel={t("CONFIRM_PAYMENT")}
          onConfirm={() => handleCreditCardConfirm()}
          confirmationDialogWidth={confirmationDialogWidth}
          btnType={"btn-primary-soft"}
        ></ConfirmationDialog>}
      </div >
      <div style={{ height: "97.6px" }} className="bg-purple mw-100 mh-auto" id="header">
      </div>
    </>

  );
}
)

export default Header;