import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useOnclickOutside from "react-cool-onclickoutside";

export default function PlacesAutocomplete({ id, className, onPlaceSelected, defaultValue }) {
  const { t } = useTranslation()
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      fields: ["address_components", "geometry", "name"]
    },
    debounce: 500,
    cache: 86400
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  useEffect(() => {
    setValue(defaultValue, false)
  }, []);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const clearValue = () =>{
    setValue("");
    onPlaceSelected({name:"",address:"",postcode:"",locality:"",state:"",country:"",abbreviation:"",location:{lat:0,lng:0}});
  }

  const handleSelect =
    ({ description, structured_formatting: { main_text } }) =>
      () => {
        setValue(description, false);
        clearSuggestions();
        getGeocode({ address: description }).then((results) => {
          const place = results[0];
          let address, postcode, locality, state, country, abbreviation
          if (!place.address_components) return
          for (const component of place.address_components) {
            const componentType = component.types[0]
            switch (componentType) {
              case "route":
                address = component.short_name
                break
              case "postal_code":
                postcode = `${component.long_name}`
                break
              case "locality":
                locality = component.long_name
                break
              case "administrative_area_level_1":
                state = component.short_name
                break
              case "country":
                country = component.long_name
                abbreviation = component.short_name
                break
            }
          }
          onPlaceSelected({
            name: main_text,
            address,
            postcode,
            locality,
            state,
            country,
            abbreviation,
            location: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
          })
        });
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ul className="googlePlacesAutocompleteList" key={place_id} >
          <li className="mt-2 p-2 liElement" onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        </ul>
      );
    });

  return (
    <div className="col input-icons d-flex" ref={ref}>
      <img className="h-15px mb-1 ms-3 placeIcon2" src="/assets/images/from.svg" alt="from" />
      <input
        id={id}
        value={value}
        className={className}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t("GOOGLE_PLACES_PLACEHOLDER")}
        autoComplete="off"
        style={{paddingRight:"40px"}}
      />
        <div className="" style={{position:"absolute", padding:"8px", marginLeft:"82%", cursor:"pointer"}} onClick={() => clearValue()} aria-hidden="true">
          <svg height="20" width="20" fill="#cccccc" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-8mmkcg">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z">
            </path>
          </svg>
        </div>
      {status === "OK" &&
        <div className="card position-fixed z-100" style={{marginTop:"43px", marginRight:"30px"}}>
          <ul className="ulElement">{renderSuggestions()}</ul>
        </div>
      }
    </div>
  );
};
