


import React from 'react'

export default function UpwardIcon(props) {
    const { color, size, styles } = { color: props.color, size: props.size, styles: props.styles };

    return (

        <svg xmlns="http://www.w3.org/2000/svg" style={styles} fill={color} width={size} height={size} viewBox="0 -960 960 960" width="24"><path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z" /></svg>

    )
}