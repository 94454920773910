import { httpSmartClient } from "../../config/httpClient";

const baseName = "Languages";

const LanguagesService = {
  edit: async (languageCode) => {
    var response = await httpSmartClient.put(`/${baseName}/UpdateLanguageCode?languageCode=${languageCode}`);
    return response;
  },

};

export default LanguagesService;
