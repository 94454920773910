import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { httpSmartClient } from "config/httpClient";
import { toastify } from "utils/toastify";
import { useFieldArray } from "react-hook-form";
import { TravelPreferencesService } from "data/services";

export default function TravelPreferenceEditPage() {
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [defaultOptions, setDefaultOptions] = useState();
  const [apiErrors, setApiErrors] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    const fetchPreferenceAndSetForm = async () => {
      const preference = await TravelPreferencesService.getById(id);
      const iconId = preference.data.iconId;
      iconId ? setPreviewUrl(httpSmartClient.getPhotoUrl(iconId)) : setPreviewUrl("/assets/images/blankicon.jpg")

      if (preference) {
        reset(preference.data);
        const options = preference.data.travelPreferenceOptions;
        setDefaultOptions(options);
      } else {
        navigate("/admin/travelPreferences");
      }
    };
    fetchPreferenceAndSetForm();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    control
  } = useForm({
    defaultValues: {
      travelPreferenceOptions: defaultOptions
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "travelPreferenceOptions",
  });

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(file));
  }

  const onSubmit = async (data) => {
    setFormSubmitting(true);
    if (data.icon && data.icon.length > 0) {
      data.icon = data.icon[0];
    }
    const editedPreferenceResponse = await TravelPreferencesService.editTravelPreferences({ id: id, ...data });
    if (editedPreferenceResponse.statusCode === 409) {
      setError("travelPreferencesExist", 409)
      setFormSubmitting(false);
      return;
    }
    setFormSubmitting(false);
    if (editedPreferenceResponse.isSuccess) {
      navigate("/admin/travelPreferences");
      toastify.success(t("SUCCESSFULLY_EDITED_TRAVEL_PREFERENCE"),<img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png"/>);
    } else if (editedPreferenceResponse.isError) {
      toastify.error(t(editedPreferenceResponse.error));
    } else if (editedPreferenceResponse.isValidationError) {
      setApiErrors(editedPreferenceResponse.validationErrors);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-12 mb-4 mb-sm-5">
          <h1 className="h4 mb-0">{t("EDIT_TRAVEL_PREFERENCE")}</h1>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card shadow">
          <div className="card-header border-bottom">
            <h5 className="card-header-title">{t("TRAVEL_PREFERENCE_INFORMATION")}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="form-label">{t("PREFERENCE_NAME")}</label>
                <input
                  {...register("name", {
                    required: t("PREFERENCE_NAME_IS_REQUIRED"),
                  })}
                  type="text"
                  className="form-control"
                  placeholder={t("PREFERENCE_NAME")}
                  onChange={(e) => {
                    clearErrors();
                    setValue("name", e.target.value)
                  }}
                />
                {errors.name && <span className="text-danger">{t("PREFERENCE_NAME_IS_REQUIRED")}</span>}
                {errors.travelPreferencesExist && <span className="text-danger">{t("TRAVELPREFERENCES_EXIST")}</span>}
              </div>
              <div className="mb-3">
                <label className="form-label">{t("ICON")}</label>
                <div className="row">
                  <div className="col f-flex flex-column">
                    {previewUrl  && <img src={previewUrl} alt="Profile photo preview" style={{ height: "60px", width: "60px" }} className="photo-preview cursor-pointer" />}
                    <input
                      {...register("icon", { defaultValue: null })}
                      type="file"
                      className="form-control"
                      onChange={handlePhotoUpload}
                    />
                  </div>
                </div>
              </div>
              {errors.icon && <span className="text-danger">{t("PHOTO_IS_REQUIRED")}</span>}
              <br></br>
              {fields.length > 0 && <label className="form-label">{t("PREFERENCE_OPTION")}</label>}
              {fields.map((option, index) => (
                <div key={option.id} className="mb-3">
                  <div className="d-flex">
                    <input
                      {...register(`travelPreferenceOptions.${index}.name`, {
                        required: t("OPTION_IS_REQUIRED"),
                      })}
                      type="text"
                      className="form-control"
                      placeholder={`${index + 1}.${t("NEW_PREFERENCE_OPTION")}`}
                    />
                    <button onClick={() => remove(index)} className="btn d-flex mt-1 ms-2 align-items-center btn-sm btn-neutral-soft border border-danger " >
                      <i className="bi bi-x-lg"></i>
                    </button>
                  </div>
                  {errors.travelPreferenceOptions && <span className="text-danger">{t("OPTION_IS_REQUIRED")}</span>}
                </div>
              ))}
              <button className="btn btn-primary-soft border-0 me-2 mt-2" type="button" onClick={() => { append({ placeholder: t("NEW_PREFERENCE_OPTION") }); }}>
                <i className="fa fa-plus text-soft"></i>
              </button>
              <div className="d-flex justify-content-end mt-4">
                <button className="btn text-secondary border-0 me-2" onClick={() => navigate("/admin/travelPreferences")}>
                  {t("DISCARD")}
                </button>
                <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                  {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                  {t("SAVE")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
