import { Navigate } from "react-router";
import AuthService from "../../data/services/AuthService";
import userProfileStore from "../../data/stores/UserProfileStore";
import { useEffect, useState } from "react";
import UsersService from "../../data/services/UsersService";
import { httpSmartClient } from "../../config/httpClient";
import Spinner from "./Spinner/Spinner";
function ProtectedRoute({ roles, children }) {
  const currentUser = AuthService.getCurrentUser();
  const rolesArray = roles ? roles.split(",") : [];

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (window.location.pathname === "/ServerError") {
      setIsLoading(false);
      return;
    }
    const fetch = async () => {
      let currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
        setIsLoading(false);
        return;
      }

      let response = await UsersService.getById(currentUser.Id);
      if (response.isSuccess) {
        let user = response.data;
        if (user.profilePhoto) {
          user.profilePhotoUrl = httpSmartClient.getPhotoUrlObjectFromBase64(user.profilePhoto);
        }
        else if (user.profilePhotoId) {
          user.profilePhotoUrl = httpSmartClient.getPhotoUrl(user.profilePhotoId);
        }
        userProfileStore.setUser(user);
        setIsLoading(false);
      }
      else {
        console.log(response.error);
      }
    }
    fetch();
  }, [])

  if (isLoading) {
    return (
      <Spinner></Spinner>
    )
  }
  if (currentUser && currentUser.Role !== "Administrator") {
    return <Navigate to="/"></Navigate>
  }
  if (currentUser && (roles.length === 0 || rolesArray.includes(currentUser.Role))) {
    return children;
  } else {
    return <Navigate to="/sign-in#"></Navigate>
  }
}
export default ProtectedRoute;
