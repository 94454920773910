import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import UserPageMobile from "./UserPageMobile";
import UserPageDesktop from "./UserPageDesktop";

const UserPage = observer(() => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 850);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  return (
    <>
         <div style={{ display: isMobile ? "block" : "none" }} >
          <UserPageMobile></UserPageMobile>
          </div>
          <div style={{ display: !isMobile ? "block" : "none" }} >
          <UserPageDesktop></UserPageDesktop>
          </div>
    </>
  );
})

export default UserPage;