import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import globalStore from "data/stores/GlobalStore";
import { AuthService, LanguagesService } from "data/services";

export default function LanguageSwitcher(props) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const user = AuthService.getCurrentUser();
  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
    if (localStorage.getItem('Logged') === "true") {
      await LanguagesService.edit(lng);
    }
    globalStore.changeLng(lng);
  };
  const getCurrentLanguageIconPath = () => {
    switch (i18n.language) {
      case "en":
        return "/assets/images/flags/us.svg";
      case "de":
        return "/assets/images/flags/de.svg";
      case "ba":
        return "/assets/images/flags/ba.svg";
    }
  };


  return (
    <ul className="languageSwitcherList">
      <li aria-expanded="false" data-bs-toggle="dropdown" data-bs-display="static" className={`cursor-pointer nav-item dropdown ${location.pathname.includes('admin') || location.pathname.includes('sign-in') || location.pathname.includes('sign-up') || location.pathname.includes('forgot-password') || location.pathname.includes('forgot-password') ? "" : " rounded-circle"}  `}>
        <button className={`nav-notification lh-0 btn btn-light p-0 ${location.pathname.includes('admin') ? "" : "rounded-circle bg-transparent"} mb-0  border-0`} id="bd-theme" type="button" >
          <img className="m-0" src={getCurrentLanguageIconPath()} width="20" height="20" />
        </button>
        {!location.pathname.includes('admin') && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
          <path d="M14.1099 6.33972L9.49156 10.9581C8.94614 11.5035 8.05364 11.5035 7.50823 10.9581L2.88989 6.33972" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>}
        <ul className="dropdown-menu min-w-auto dropdown-menu-end" aria-labelledby="bd-theme">
          <li className="mb-1">
            <button onClick={() => changeLanguage("en")} type="button" className={`dropdown-item d-flex align-items-center me-3 ${i18n.language === "en" ? "active" : ""}`} data-bs-theme-value="light">
              <img src="/assets/images/flags/us.svg" width="16" className="me-2" />
              English
            </button>
          </li>
          <li className="mb-1">
            <button onClick={() => changeLanguage("de")} type="button" className={`dropdown-item d-flex align-items-center me-3 ${i18n.language === "de" ? "active" : ""}`} data-bs-theme-value="dark">
              <img src="/assets/images/flags/de.svg" width="16" className="me-2" />
              Deutsch
            </button>
          </li>
          <li className="mb-1">
            <button onClick={() => changeLanguage("ba")} type="button" className={`dropdown-item d-flex align-items-center me-3 ${i18n.language === "ba" ? "active" : ""}`} data-bs-theme-value="dark">
              <img src="/assets/images/flags/ba.svg" width="16" className="me-2" />
              Bosanski
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
}
