import { observer } from "mobx-react-lite";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import chatStore from "data/stores/ChatStore";
import { ChatsService } from "data/services";
import userProfileStore from "data/stores/UserProfileStore";
import { httpSmartClient } from "config/httpClient";

const Chats = observer((props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = userProfileStore.user;
    const chat = chatStore;
    const currentDate = new Date();
    const [unread, setUnread] = useState();
    useEffect(() => {

        const apiCall = async () => {
            if (window.location.pathname == "/sign-in")
                return;
            const lastFifteen = await ChatsService.getPagedChatsForUser({ UserOneId: user.id, pageNumber: 1, pageSize: 15 })
            if (lastFifteen.data) {
                chat.setChats(lastFifteen.data)
            }
        }
        apiCall();
    }, [])
    useEffect(() => {
        if (window.location.pathname == "/sign-in")
            return;
        const toggleSeen = async () => {
            const unread = await ChatsService.getUnreadChats();
            setUnread(unread.data)
        }
        toggleSeen();
    }, [chat.toggleSeen, chat.messageSent])
    return (
        <ul className="chatsNotificationsList">
            <li className={`nav-item dropdown  ${props.text === "white" ? "me-4" : "position-relative right-3"}`} data-bs-toggle="dropdown">
                <a className="btn d-flex align-items-center  btn-purple p-0 mb-0 nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                    {props.text === "white" ?
                        <svg width="20" className="cursor-pointer " height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="vuesax/linear/messages-3">
                                <g id="vuesax/linear/messages-3_2">
                                    <g id="messages-3">
                                        <path id="Vector" d="M15.5846 4.42692V8.0394C15.5846 8.93899 15.2871 9.69692 14.7559 10.2211C14.2317 10.7523 13.4738 11.0498 12.5742 11.0498V12.3319C12.5742 12.8136 12.0359 13.104 11.6392 12.8348L10.9521 12.3815C11.0159 12.1619 11.0442 11.9211 11.0442 11.6661V8.78319C11.0442 7.33819 10.0809 6.37484 8.63589 6.37484H3.8263C3.72713 6.37484 3.63505 6.38193 3.54297 6.38902V4.42692C3.54297 2.62067 4.74714 1.4165 6.55339 1.4165H12.5742C14.3805 1.4165 15.5846 2.62067 15.5846 4.42692Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path id="Vector_2" d="M11.0442 8.78335V11.6662C11.0442 11.9212 11.0159 12.1621 10.9521 12.3817C10.6901 13.4229 9.82589 14.0746 8.63589 14.0746H6.70922L4.57005 15.4983C4.2513 15.7179 3.8263 15.4842 3.8263 15.1017V14.0746C3.1038 14.0746 2.50172 13.8338 2.0838 13.4158C1.6588 12.9908 1.41797 12.3887 1.41797 11.6662V8.78335C1.41797 7.43752 2.2538 6.5096 3.54297 6.38918C3.63505 6.3821 3.72713 6.375 3.8263 6.375H8.63589C10.0809 6.375 11.0442 7.33835 11.0442 8.78335Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                </g>
                            </g>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M19.25 5.46875V9.93123C19.25 11.0425 18.8825 11.9787 18.2263 12.6262C17.5788 13.2825 16.6425 13.65 15.5312 13.65V15.2337C15.5312 15.8287 14.8662 16.1875 14.3762 15.855L13.5275 15.295C13.6063 15.0237 13.6412 14.7262 13.6412 14.4112V10.85C13.6412 9.06502 12.4513 7.875 10.6663 7.875H4.72499C4.60249 7.875 4.48875 7.88377 4.375 7.89252V5.46875C4.375 3.2375 5.8625 1.75 8.09375 1.75H15.5312C17.7625 1.75 19.25 3.2375 19.25 5.46875Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.6412 10.85V14.4112C13.6412 14.7262 13.6063 15.0237 13.5275 15.295C13.2038 16.5812 12.1363 17.3862 10.6663 17.3862H8.28625L5.64375 19.145C5.25 19.4163 4.72499 19.1275 4.72499 18.655V17.3862C3.83249 17.3862 3.08875 17.0888 2.5725 16.5725C2.0475 16.0475 1.75 15.3037 1.75 14.4112V10.85C1.75 9.18752 2.7825 8.04127 4.375 7.89252C4.48875 7.88377 4.60249 7.875 4.72499 7.875H10.6663C12.4513 7.875 13.6412 9.06502 13.6412 10.85Z" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    }
                <span className="cursor-pointer ms-2" style={{ color: props.text, fontSize: "16px", fontWeight: "500"}}>{t("CHATS_HEADER")}</span>
                </a>
                {chat.chats.items && (chat.chats.items.some(x => x.unread > 0) || unread > 0) && <span className="notif-badge-custom-chat animation-blink"></span>}
                <div className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0 z-index-master">
                    <div className="card bg-transparent">
                        <div className="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom">
                            <h6 className="m-0" style={{ color: "black" }}>
                                {t("CHATS")}
                            </h6>
                            <b className="m-0 fw-normal">{t("UNREAD")}<span className="badge bg-primary ms-2">{unread && unread}</span></b>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush list-unstyled p-2 overflow-auto max-height-300 scrollbar-primary" style={{scrollbarColor:"#8d0bf8 #f5f5f5"}} id="dropdown2">
                                {chat.chats.items && chat.chats.items.length > 0 ? chat.chats.items.map((x, index) => {
                                    const current = moment(currentDate);
                                    const lastMes = moment(x.lastMessageSentAt)
                                    const diffInHours = current.diff(lastMes, "hours")
                                    return (<li key={index} className="cursor-pointer" onClick={() => navigate(`chat/${x.guid}`)} aria-labelledby="dropdown2">
                                        <a className="list-group-item list-group-item-action bg-info-soft rounded border-0 p-3 ">
                                            <div className="d-flex align-items-center">
                                                <img className="image-custom-notif" src={x.userOneId != user.id ? x.userOne.profilePhotoId ? httpSmartClient.getPhotoUrl(x.userOne.profilePhotoId) : "/assets/images/avatar/01.jpg" : x.userTwoId != user.id ? x.userTwo.profilePhotoId ? httpSmartClient.getPhotoUrl(x.userTwo.profilePhotoId) : "/assets/images/avatar/01.jpg" : "/assets/images/avatar/01.jpg"} />
                                                <div className="d-flex w-100 flex-column">
                                                    <div className="d-flex mb-2 w-100 justify-content-between align-items-start">
                                                        <span className="ms-2 fs16 fw-700 darkgray">{x.userOneId != user.id ? x.userOne.firstName + " " + x.userOne.lastName : x.userTwo.firstName + " " + x.userTwo.lastName}</span>
                                                        <span className="fs12 fw-400 ms-2 gra mt-1">{diffInHours < 24 ? moment(x.lastMessageSentAt).format("HH:mm") : moment(x.lastMessageSentAt).format("DD.MM HH:mm")}</span>
                                                    </div>
                                                    <div className="d-flex ms-2  justify-content-between ">
                                                        {x.lastMessage && <span className=" fs14 d-block">{x.lastMessageSenderId === user.id ? t("YOU") : ""}{x.lastMessage ? x.lastMessage.substring(0, 20) + `${x.lastMessage.length > 20 ? "..." : ""}` : x.lastMessage.substring(0, 20) + `${x.lastMessage.length > 20 ? "..." : ""}`} <strong>·</strong> </span>}
                                                        {x.unread > 0 && <span className="badge bg-purple bg-opacity-10 text-white ms-2"> {x.unread}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        {chat.chats.items.length - 1 !== index && <hr className="m-1" />}
                                    </li>)
                                }) :
                                    <h6 className="p-2 m-auto">{t("NO_MESSAGES")}</h6>
                                }
                            </ul>
                        </div>
                        <div className="card-footer bg-transparent text-center border-top">
                            <a onClick={() => navigate('/chat/messages')} className="btn btn-sm btn-link mb-0 p-0">
                                {t("ALL_CHATS")}
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    );
});

export default Chats;
