import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TravelPreferencesService } from "data/services";

const AddUserTravelPreferenceOption = (props) => {
    const { t } = useTranslation();
    const [isDeleting, setIsDeleting] = useState(false);
    const [options, setOptions] = useState();
    const [selected, setSelected] = useState();
    const handleDiscard = () => {
        props.onDiscard();
    }

    const handleSave = () => {
        props.onConfirm(selected);
    }
    useEffect(() => {
        const api = async () => {
            const response = await TravelPreferencesService.getById(props.currentId)
            setOptions(response.data.travelPreferenceOptions);
            setSelected(props.currentUserTravelOption)
        }
        api()
    }, [])
    const handleChange = async (data) => {
        if (selected.id === data.id) {
            setSelected({ Id: data.id, delete: true, travelPreferenceId: data.travelPreferenceId })
        }
        else {
            setSelected(data)
        }
    }
    return (
        <>
            {!props.isMobile ?
                <div className="d-flex py-3 flex-column align-items-start justify-content-center gap-4 h-100 pick-user-travel-preference">
                    <div className="card-header ms-3">
                        <h6 className="card-header-title  pick-travel-preference-header ">{props.title + t(props.travelPreference.name)}</h6>
                    </div>
                    <div className="d-flex flex-column align-items-center pb-0 ms-3">
                        <div>
                            {options && options.map((x, index) => {
                                return (<div className="form-check mb-2" key={index}>
                                    <input className="form-check-input"
                                        type="radio"
                                        name={x.name}
                                        id={x.name}
                                        checked={selected && selected.id === x.id}
                                        onClick={() => { handleChange(x) }}
                                        readOnly
                                    />
                                    <label className="form-check-label" htmlFor={x.name}>
                                        {t(x.name)}
                                    </label>
                                </div>)
                            })}
                        </div>
                    </div>
                    <button disabled={isDeleting} type="submit" className="btn btn-primary button-add-user-travel-preference m-0" onClick={() => handleSave()}>
                        {isDeleting && <i className="fa fa-spin fa-spinner"></i>}
                        {t("PICK_PREFERENCE")}
                    </button>
                </div>
                :
                <>
                    <div className="card-header d-flex flex-column justify-content-around h-50">
                        <h6 className="card-header-title pick-travel-preference-header d-flex w-90">{props.title + props.travelPreference.name}</h6>
                        <div className="mt-3">
                            {options && options.map((x, index) => {
                                return (<div className="form-check mb-2" key={index}>
                                    <input className="form-check-input"
                                        type="radio"
                                        name={x.name}
                                        id={x.name}
                                        checked={selected && selected.id === x.id}
                                        onClick={() => { handleChange(x) }}
                                    />
                                    <label className="form-check-label" htmlFor={x.name}>
                                        {x.name}
                                    </label>
                                </div>)
                            })}
                        </div>
                    </div>
                    <button disabled={isDeleting} type="submit" className="btn btn-primary button-add-user-travel-preference me-1 w-100 " onClick={() => handleSave()}>
                        {isDeleting && <i className="fa fa-spin fa-spinner"></i>}
                        {t("PICK_PREFERENCE")}
                    </button>
                </>
            }
        </>
    );
};

export default AddUserTravelPreferenceOption;