import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthService } from "data/services";

export default function HowVoziMeWorks() {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const navigate = useNavigate();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const publishRide = () => {
        const user = AuthService.getCurrentUser();
        if (user != null) {
            navigate("/rides/create")
        }
        else {
            navigate("/sign-in")
        }
    }

    return (
        <>
            <div className="row" style={isMobile?{ height: "80px", backgroundColor: "#900fc8", marginTop: "-70px", marginBottom: "0px" }:
                { height: "60px", backgroundColor: "#900fc8", marginTop: "-70px", marginBottom: "40px" }}>
                <div className="col">
                </div>
                <div className="col-auto pt-1">
                    <h5 style={{ color: "white", fontWeight: "500", fontSize: isMobile ? "18px" : "22px", marginTop: "15px" }}>{t("HOW_VOZIME_WORKS")}</h5>
                </div>
                <div className="col">
                </div>
            </div>
            <div className={isMobile ?"bg-light":""} style={isMobile?{paddingTop:"40px",marginTop:"-20px", borderRadius:"1.5em"}:{}}>
                <div className={isMobile ? "container mb-6 p-0" : "container mb-6"} style={isMobile ? { width: "90%" } : {}}>
                    {!isMobile ? <div className="card p-4 bg-purple" style={{ borderRadius: "60px", height: "300px", boxShadow:"rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
                        <div className="row">
                            <div className="col-8 mt-5">
                                <h5 className="text-white ms-4" style={{ color: "black", fontSize: "25px", fontWeight: "500" }}>{t("HOW_VOZIME_WORKS")}</h5>
                                <p className="ms-4" style={{ lineHeight: "25px", fontSize: "16px", fontWeight: "500", lineHeight: "25px", color: "white", marginTop: "20px", width: "92%" }}>{t("ABOUT_VOZIME")}</p>
                            </div>
                            <div className="col-auto">
                                <div className="card shadow p-4 pb-3 mt-2 bg-white" style={{ position: "absolute", marginTop: "20px", width: "350px" }}>
                                    <h6 className="mb-3" style={{ fontWeight: "600", marginLeft: "5px", color: "black" }}>{t("IN_THIS_PAGE")} </h6>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref1)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("NEED_RIDE")}</p>
                                        <div style={{ alignSelf: "flex-end", paddingBottom: "2px" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref2)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("HAVE_AVAILABLE_SEATS")}</p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref3)} style={{ flexGrow: 1, marginRight: "5px", fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("HOW_WORKS_DETERMINING_PRICE")}</p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref4)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("HOW_CALCULATE_PRICE")}</p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref5)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("VOZIME_COMPENSATION")}</p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2">
                                        <p className="mb-1" onClick={() => handleClick(ref6)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("ACCOUNT_OPENING")}</p>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                        <div >
                            <div className="card bg-purple" style={{ borderRadius: "20px", height: "auto", marginBottom: "40px", marginTop: "-20px", boxShadow:"rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
                                <div className="row">
                                    <div className="col p-5">
                                        <h5 className="text-white" style={{ color: "black", fontSize: isMobile ? "22px" : "30px", fontWeight: "500" }}>{t("HOW_VOZIME_WORKS")}</h5>
                                        <p className="mt-2" style={{ lineHeight: "22px", fontSize: "15px", color: "white" }}>{t("ABOUT_VOZIME")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="card shadow p-4 bg-white" style={{ marginTop: "-15px" }}>
                                        <h6 className="mb-3" style={{ fontWeight: "600", color: "black" }}>{t("IN_THIS_PAGE")} </h6>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-1" onClick={() => handleClick(ref1)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("NEED_RIDE")}</p>
                                            <div style={{ alignSelf: "flex-end", paddingBottom: "2px" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-1" onClick={() => handleClick(ref2)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("HAVE_AVAILABLE_SEATS")}</p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-1" onClick={() => handleClick(ref3)} style={{ flexGrow: 1, marginRight: "5px", fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("HOW_WORKS_DETERMINING_PRICE")}</p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-1" onClick={() => handleClick(ref4)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("HOW_CALCULATE_PRICE")}</p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <p className="mb-1" onClick={() => handleClick(ref5)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("VOZIME_COMPENSATION")}</p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-1" onClick={() => handleClick(ref6)} style={{ flexGrow: 1, fontSize: "15px", color: "#900fc8", cursor: "pointer", marginLeft: "5px" }}>{t("ACCOUNT_OPENING")}</p>
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <svg style={{ marginBottom: "5px" }} width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="#900fc8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <div ref={ref1} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: isMobile ? "20px" : "70px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>  {t("NEED_RIDE")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P1")}
                                </p>
                                <p style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P2")}
                                </p>
                                <p className="mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P3")}
                                </p>
                                <div className="row d-flex flex-row mt-3" style={{ marginLeft: "-25px" }}>
                                    <div className="col d-flex flex-row">
                                        <i style={{ fontSize: "16px", marginLeft: "10px" }} className="bi bi-circle"></i>
                                        <div style={{ marginLeft: "-8px", height: isMobile ? "280px" : "200px", marginTop: "19px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                                        <div className="ms-3">
                                            <h6 style={{ fontWeight: "600", marginBottom: "15px", color: "black" }}>1. {t("FIND_RIDE")}</h6>
                                            <p style={{ lineHeight: "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P4")}</p>
                                            <li style={{ lineHeight: "25px", fontSize: "16px" }}> <b>{t("WHERE_FROM")}</b> {t("HOWVOZIMEWORKS_P5")}</li>
                                            <li style={{ lineHeight: "25px", fontSize: "16px" }}> <b>{t("WHERE")}</b> {t("HOWVOZIMEWORKS_P6")}</li>
                                            <li style={{ lineHeight: "25px", fontSize: "16px" }}> <b>{t("WHEN")}</b> {t("HOWVOZIMEWORKS_P7")}</li>
                                            <p style={{ lineHeight: isMobile ? "25px" : "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P8")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex flex-row" style={{ marginLeft: "-25px" }}>
                                    <div className="col d-flex flex-row">
                                        <i style={{ fontSize: "16px", marginLeft: "10px" }} className="bi bi-circle"></i>
                                        <div style={{ marginLeft: "-8px", height: isMobile ? "160px" : "100px", marginTop: "18px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                                        <div className="ms-3">
                                            <h6 style={{ fontWeight: "600", marginBottom: "20px", color: "black" }}>2. {t("RESERVED_ONLINE")}</h6>
                                            <p style={{ lineHeight: isMobile ? "25px" : "20px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P9")}</p>
                                            <p style={{ lineHeight: isMobile ? "25px" : "20px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P10")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex flex-row" style={{ marginLeft: "-25px" }}>
                                    <div className="col d-flex flex-row">
                                        <i style={{ fontSize: "16px", marginLeft: "10px" }} className="bi bi-circle"></i>
                                        <div className="ms-3">
                                            <h6 style={{ fontWeight: "600", marginBottom: "20px", color: "black" }}>3. {t("RIDE_TOGETHER")}</h6>
                                            <p style={{ lineHeight: "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P11")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => navigate("/rides")} className="btn btn-primary mt-3 ms-4" style={{ width: "280px" }}>
                                <div className="row">
                                    <div className="d-flex justify-content-between">
                                        <span style={{ fontWeight: "400", flexGrow: 1, textAlign: "start" }}>{t("FIND_RIDE")}</span>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div ref={ref2} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>  {t("HAVE_AVAILABLE_SEATS")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px", fontWeight: "bold" }}>
                                    {t("HOWVOZIMEWORKS_P12")}
                                </p>
                                <p className="mb-0" style={{ lineHeight: "25px" }}>
                                    {t("HOWVOZIMEWORKS_P13")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px" }}>
                                    {t("HOWVOZIMEWORKS_P14")}
                                </p>
                                <div className="row d-flex flex-row mt-3" style={{ marginLeft: "-25px" }}>
                                    <div className="col d-flex flex-row">
                                        <i style={{ fontSize: "16px", marginLeft: "10px" }} className="bi bi-circle"></i>
                                        <div style={{ marginLeft: "-8px", height: isMobile ? "280px" : "200px", marginTop: "19px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                                        <div className="ms-3">
                                            <h6 style={{ fontWeight: "600", marginBottom: "15px", color: "black" }}>1. {t("OFFER_RIDE")}</h6>
                                            <p style={{ lineHeight: "25px", fontSize: "16px", marginBottom: "20px" }}> {t("HOWVOZIMEWORKS_P15")}</p>
                                            <li style={{ lineHeight: "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P16")}</li>
                                            <li style={{ lineHeight: "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P17")}</li>
                                            <li style={{ lineHeight: "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P18")}</li>
                                            <li style={{ lineHeight: isMobile ? "25px" : "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P19")}</li>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex flex-row" style={{ marginLeft: "-25px" }}>
                                    <div className="col d-flex flex-row">
                                        <i style={{ fontSize: "16px", marginLeft: "10px" }} className="bi bi-circle"></i>
                                        <div style={{ marginLeft: "-8px", height: isMobile ? "100px" : "60px", marginTop: "18px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                                        <div className="ms-3">
                                            <h6 style={{ fontWeight: "600", marginBottom: "15px", color: "black" }}>2. {t("PASSENGERS_RESERVED_ONLINE")}</h6>
                                            <p style={{ lineHeight: isMobile ? "25px" : "10px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P20")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex flex-row" style={{ marginLeft: "-25px" }}>
                                    <div className="col d-flex flex-row">
                                        <i style={{ fontSize: "16px", marginLeft: "10px" }} className="bi bi-circle"></i>
                                        <div style={{ marginLeft: "-8px", height: isMobile ? "210px" : "110px", marginTop: "18px", border: "none", borderLeft: "2px dashed black" }} className="vr opacity-3"></div>
                                        <div className="ms-3">
                                            <h6 style={{ fontWeight: "600", marginBottom: "15px", color: "black" }}>3. {t("RIDE_TOGETHER")}</h6>
                                            <p style={{ lineHeight: isMobile ? "25px" : "25px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P21")}</p>
                                            <p style={{ lineHeight: isMobile ? "25px" : "10px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P22")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex flex-row " style={{ marginLeft: "-25px" }}>
                                    <div className="col d-flex flex-row">
                                        <i style={{ fontSize: "16px", marginLeft: "10px" }} className="bi bi-circle"></i>
                                        <div className="ms-2">
                                            <h6 style={{ fontWeight: "600", marginBottom: "15px", color: "black" }}>4. {t("PASSENGER_PAY_ONLINE")}</h6>
                                            <p style={{ lineHeight: isMobile ? "25px" : "20px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P23")}</p>
                                            <p style={{ lineHeight: isMobile ? "25px" : "20px", fontSize: "16px" }}> {t("HOWVOZIMEWORKS_P24")}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <button onClick={() => publishRide()} className="btn btn-primary mt-3 ms-4" style={{ width: "280px" }}>
                                <div className="row">
                                    <div className="d-flex justify-content-between">
                                        <span style={{ fontWeight: "400", flexGrow: 1, textAlign: "start" }}>{t("POST_RIDE")}</span>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <svg style={{ marginBottom: "5px" }} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div ref={ref3} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>  {t("HOW_WORKS_DETERMINING_PRICE")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P25")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P26")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={ref4} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>  {t("HOW_CALCULATE_PRICE")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P27")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P28")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={ref5} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>  {t("VOZIME_COMPENSATION")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P29")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P30")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={ref6} className="card bg-transparent border p-0" style={{ maxWidth: "1120px", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>
                        <div className="card-header bg-transparent border-bottom p-4">
                            <h5 className="mb-0" style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}>  {t("ACCOUNT_OPENING")}</h5>
                        </div>
                        <div className="card-body p-4 pt-0">
                            <div className="mt-4">
                                <p className="mb-0" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P31")}
                                </p>
                                <p className="mb-0 mt-3" style={{ lineHeight: "25px", fontSize: "16px" }}>
                                    {t("HOWVOZIMEWORKS_P32")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}