import { Outlet } from "react-router";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "../modules/public/home/hero/hero.css"
import userProfileStore from "data/stores/UserProfileStore";
import { useEffect, useState } from "react";
import { AuthService, UsersService } from "data/services";
import { httpSmartClient } from "config/httpClient";
import Spinner from "../common/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import {useNavigate} from 'react-router-dom';

const ClientLayout = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (window.location.pathname === "/ServerError") {
      setIsLoading(false);
      return;
    }
    const fetch = async () => {
      let currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
        setIsLoading(false);
        return;
      }
      let response = await UsersService.getById(parseInt(currentUser.Id));
      if (response.isSuccess) {
        let user = response.data;
        if (!user)
          return;
        if (user.profilePhoto) {
          user.profilePhotoUrl = httpSmartClient.getPhotoUrlObjectFromBase64(user.profilePhoto);
        }
        else if (user.profilePhotoId) {
          user.profilePhotoUrl = httpSmartClient.getPhotoUrl(user.profilePhotoId);
        }
        userProfileStore.setUser(user);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
      else {
        localStorage.setItem("Logged",false);
        localStorage.removeItem('token');
        userProfileStore.setUser(null);
        window.location.reload();
      }
    }
    fetch();
  }, [])

  if (isLoading) {
    return (
      <Spinner ></Spinner>
    )
  }

  return (
    <>
      <div className="client-layout">
        <Header></Header>
        <main>
          <div className="bg-light">
            <section className="section-client bg-light" id="section">
              <Outlet />
            </section>
          </div>
        </main>
        <Footer></Footer>
      </div>

    </>
  );
});
export default ClientLayout;