import userProfileStore from "./UserProfileStore";

const { makeObservable, observable, action, computed } = require("mobx");
class NotificationsStore {
  user = userProfileStore.user;
  notifications = [];
  constructor() {
    makeObservable(this, {
      notifications: observable,
      notify: action,
      setNotifications: action,
      unread: computed,
      markAllRead: action,
      resetNotifications: action,
    });
  }
  resetNotifications() {
    this.notifications = [];
  }
  setNotifications(notifications, userId) {
    const findIndex = notifications?.findIndex(n => n.senderId == userId);
    if (findIndex !== -1) {
      notifications.splice(findIndex, 1)
      this.notifications = notifications
    } else {
      this.notifications = notifications;
    }
  }

  notify(newNotification) {
    this.notifications.unshift({
      senderId: newNotification.senderId,
      sender: newNotification.sender,
      userId: newNotification.userId,
      user: newNotification.user,
      text: newNotification.text,
      rideId: newNotification.rideId,
      isRead: false,
      isReview: newNotification.isReview,
      rideMilestoneFromId: newNotification.rideMilestoneFromId,
      rideMilestoneToId: newNotification.rideMilestoneToId,
      userModel: newNotification.userModel,
      userModelReviewOnUserId: newNotification.userModelReviewOnUserId,
      isReviewOnUser: newNotification.isReviewOnUser,
      userCancellationId: newNotification.userCancellationId,
      userCancellation: newNotification.userCancellation,
      createdAt: newNotification.createdAt
    })
  }

  markAllRead() {
    this.notifications.forEach(x => {
      x.isRead = true;
    })
  }

  get unread() {
    return this.notifications.filter((x) => !x.isRead).length;
  }
}

const notificationsStore = new NotificationsStore();

export default notificationsStore;
