import { httpSmartClient } from "../../config/httpClient";

const baseName = "ReportTypes";

const ReportTypesService = {
  getById: async (id) => {
    var respone = await httpSmartClient.getById(`/${baseName}/` + id);
    return respone;
  },

  getByParams: async (params) => {
    var respone = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return respone;
  },

  add: async (type) => {
    var respone = await httpSmartClient.post(`/${baseName}`, type);
    return respone;
  },

  edit: async (type) => {
    var respone = await httpSmartClient.put(`/${baseName}`, type);
    return respone;
  },

  delete: async (id) => {
    var respone = await httpSmartClient.delete(`/${baseName}/` + id);
    return respone;
  },
};

export default ReportTypesService;
