import React from 'react'
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UsersService } from 'data/services';
import userProfileStore from 'data/stores/UserProfileStore';
import { toastify } from 'utils/toastify';
import { useTranslation } from 'react-i18next';

const MyChangePassword = observer(() => {
    const { t } = useTranslation();
    const user = userProfileStore.user;
    const [isFormSubmitting, setFormSubmitting] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const toggleVisibility = (e) => {
        setPasswordVisible((previousVisibility) => !previousVisibility)
    }

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
        clearErrors
    } = useForm();

    const onSubmit = async (data) => {
        if (data.password === data.password2) {
            setFormSubmitting(true);
            const response = await UsersService.edit({ ...user, oldPassword: data.oldPassword, password: data.password });
            setFormSubmitting(false);
            if (response.isSuccess) {
                toastify.success(t("SUCCESSFULLY_UPDATED_PASSWORD"));
                reset();
            } else if (response.error === "Current password is not valid") {
                const err = {
                    message: t("OLD_PASSWORD_NOT_SAME"),
                    id: 1
                }
                setError('oldPassword', err)
            } else if (response.error === "New password cannot be the same as current password") {
                const err = {
                    message: t("NEW_PASSWORD_SAME_AS_CURRENT"),
                    id: 2
                }
                setError('password', err)
                setError('password2', err)
            }
            else {
                toastify.error(t("PASSWORD_ERROR_UPDATE"));
            }
        }
        else {
            toastify.error(t("CONFIRM_PASSWORD_ERROR"));
            clearErrors('password2')
            setError('password2NotSame', t("CONFIRM_PASSWORD_NOT_SAME_AS_NEW_PASSWORD"))
        }
    }
    return (
        <>
            <div className="card border">
                <div className="card-header border-bottom">
                    <h4 className="card-header-title">{t("UPDATE_PASSWORD")}</h4>

                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="card-body">
                    <div className="mb-3">
                        <label className="form-label">{t("CURRENT_PASSWORD")}</label>
                        <div className="input-group">
                            <input {...register("oldPassword", { required: t("ENTER_CURRENT_PASSWORD") })} className="form-control" type={passwordVisible ? "text" : "password"} placeholder={t("ENTER_CURRENT_PASSWORD")} />
                        </div>
                        {errors.oldPassword != undefined && <span className="text-danger">{errors.oldPassword.id ? t("OLD_PASSWORD_NOT_SAME") : t("ENTER_CURRENT_PASSWORD")}</span>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t("NEW_PASSWORD")}</label>
                        <div className="input-group">
                            <input
                                {...register("password", {
                                    required: t("PASSWORD_IS_REQUIRED"),
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!.%*?&])[A-Za-z\d@$!.%*?&]{8,}$/
                                        ,
                                    }
                                })}
                                className="form-control fakepassword"
                                placeholder={t("ENTER_NEW_PASSWORD")}
                                type={passwordVisible ? "text" : "password"} id="psw-input" />
                            <span className=" p-0 bg-transparent"  >
                                <i className={`fakepasswordicon fas ${passwordVisible ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2`} onClick={toggleVisibility}></i>
                            </span>
                        </div>
                        {errors.password && <span className="text-danger">{errors.password.id ? t("NEW_PASSWORD_SAME_AS_CURRENT") : t("ERROR_PASSWORD")}</span>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t("CONFIRM_PASSWORD")}</label>
                        <div className="input-group">
                            <input {...register("password2", {
                                required: t("ENTER_CONFIRM_PASSWORD"),
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!.%*?&])[A-Za-z\d@$!.%*?&]{8,}$/
                                }
                            })}
                                className="form-control"
                                type={passwordVisible ? "text" : "password"}
                                placeholder={t("CONFIRM_PASSWORD2")} />
                        </div>
                        {errors.password2 && <span className="text-danger">{errors.password2.id ? t("NEW_PASSWORD_SAME_AS_CURRENT") : t("ENTER_CONFIRM_PASSWORD")}</span>}
                        {errors.password2NotSame && <span className="text-danger">{("CONFIRM_PASSWORD_NOT_SAME_AS_NEW_PASSWORD")}</span>}
                    </div>

                    <div className="text-end">
                        <button disabled={isFormSubmitting} type="submit" className="btn btn-primary">
                            {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                            {t("UPDATE_PASSWORD")}
                        </button>
                    </div>
                </form>
            </div>
        </>

    )
});

export default MyChangePassword;