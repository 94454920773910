import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { httpSmartClient } from "../../../../config/httpClient.js";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import GoogleMapSubmitMobile from "./GoogleMapSubmitMobile.js";
import AuthService from "data/services/AuthService.js";
import RidesService from "data/services/RidesService.js";
import toastify from "utils/toastify/toastify.js";
import AppSettingsService from "data/services/AppSettingsService.js";
import Modal from "react-responsive-modal";
import ConfirmationDialog from "../user/userChunks/modals/ConfirmationDialog.js";
import globalStore from "data/stores/GlobalStore.js";
import InformationModal from "./InformationModal.js";

export default function SubmitRideMobile({ formData, prevStep }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = AuthService.getCurrentUser();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [descriptionConfirmDialog, setDescriptionConfirmDialog] = useState(null);
    const [showModalMinHoursBeforeRide, setShowModalMinHoursBeforeRide] = useState(false);
    const [descriptionMinHoursRide, setDescriptionMinHoursRide] = useState("");
    const mapWidth = "100%";
    const mapHeight = "250px";

    const previousStep = () => {
        prevStep();
    }

    const openConfirmDialog = async () => {
        const oldStartDate = formData.rows[0].dateTime;
        const appSettings = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
        const minHoursRide = appSettings.data.items[0].minHoursBeforeRide;
        const currentRowDateTime = moment.utc(oldStartDate);
        if (Math.ceil(Math.abs(moment().diff(currentRowDateTime, 'minutes', true))) < minHoursRide * 60) {
            //toastify.error(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
            setDescriptionMinHoursRide(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
            setShowModalMinHoursBeforeRide(true);
            return;
        }
        let startDestination;
        let lastDestionation;
        {
            formData.rows && formData.rows.map((row) => {
                if (row.IsStartMilestone) {
                    startDestination = row;
                }
                else if (row.IsLastMilestone) {
                    lastDestionation = row;
                }
            })
        }
        setDescriptionConfirmDialog(
            <div>
                {t("POST_RIDE_CONFIRM_1")}
                <span className="darkgray fw-600">{startDestination.cityName}</span> -&gt;
                <span className="darkgray fw-600"> {lastDestionation.cityName}</span>
                {t("RIDE_SCHEDULED_FOR")}
                <span className="darkgray fw-600">{(moment(formData.rows[0].dateTime)).format("DD.MM.yyyy")}{t("YEAR")} </span>
                {t("IN")}
                <span className="darkgray fw-600"> {(moment(formData.rows[0].dateTime)).format("HH:mm")} h</span>
                {t("BOOK_RIDE_CONFIRM_3")}
                <span className="darkgray fw-600">{formData.price} BAM</span>?
            </div>)
        setConfirmDialog(true);
    }

    const onSubmit = async (formData) => {
        const oldStartDate = formData.rows[0].dateTime;
        let payload = {
            maxPassengersCount: formData.maxPassengersCount,
            price: formData.price,
            driverVehicleId: formData.selectedDriverVehicle.id,
            selectedRouteIndex: formData.selectedRouteIndex,
            routeKilometers: formData.routeKilometers,
            womenOnly: formData.womenOnly,
            summary: formData.summary,
            rows: formData.rows.map(r => ({ ...r, dateTime: moment(r.dateTime).format("DD.MM.YYYY HH:mm").toString() })),
            conditionIds: formData.conditions && formData.conditions.filter((c) => c.checked).map((condition) => condition.id),
        }
        setIsFormSubmitting(true)
        const newRide = await RidesService.add(payload)
        setIsFormSubmitting(false)
        if (newRide.data && newRide.isSuccess) {
            toastify.success(t("SUCCESSFULLY_ADDED_BASIC_RIDE_INFORMATION"))
            navigate("/my-profile/my-rides")
        } else {
            const appSettings = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
            const minHoursRide = appSettings.data.items[0].minHoursBeforeRide;
            const currentRowDateTime = moment.utc(oldStartDate);
            if (Math.ceil(Math.abs(moment().diff(currentRowDateTime, 'minutes', true))) < minHoursRide * 60) {
                //toastify.error(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
                setDescriptionMinHoursRide(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
                setShowModalMinHoursBeforeRide(true);
                setConfirmDialog(false);
            }
            else {
                toastify.error(t("ERROR_ADDING_BASIC_RIDE_INFORMATION"));
            }
        }
    }

    return (
        <div className="container d-inline">
            <div className="drivingOverviewCard">
                <span className="d-flex justify-content-center drivingOverviewTitle textWhite">{t("DRIVING_OVERVIEW")}</span>
                <span className="d-flex justify-content-center drivingOverviewDescription textWhite">{t("DRIVING_DETAILS_OVERVIEW")}</span>
            </div>
            <div className="card bg-light travelOverviewCard">
                <div className="card shadow submitRideCard ms-3 me-3">
                    <p className="mt-3 ms-4 fw-600 fs-14 black">{t("TRAVEL_OVERVIEW")}</p>

                    {formData.rows && formData.rows.map((row, index) => {
                        if (row.IsStartMilestone) {
                            const toDate = moment.utc(new Date(formData.rows[index + 1].dateTime));
                            const fromDate = moment.utc(new Date(formData.rows[0].dateTime));
                            var minutes = toDate.diff(fromDate, 'minutes');
                            var min = minutes % 60;
                            var hours = toDate.diff(fromDate, 'hours');
                            row.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                        }
                        return (
                            row.IsStartMilestone && (
                                <>
                                    <div className="row">
                                        <button className="mt-3 startMilestone">
                                            <p className="mb-2 ms-2 milestoneParagraph textDark">
                                                <img className="mb-1 me-2" src="/assets/images/Group 1.svg" alt="group1" />
                                                {t("FROM3") + " "}
                                                {moment(row.dateTime).format("DD.MM.YYYY") + " "}
                                                {t("IN") + " "}
                                                {moment(row.dateTime).format("HH:mm")}h
                                            </p>
                                        </button>
                                        <div className="row d-flex flex-row mt-3 milestoneStop">
                                            <div className="col d-flex flex-row">
                                                <p className="me-2 milestoneDateStartMilestone">{moment(row.dateTime).format("HH:mm")}h</p>
                                                <img className="circle3MobileStart" src="/assets/images/Ellipse 1.svg" alt="ellipse" />
                                                <div className="vr opacity-3 sideLineSubmitStartMilestone"></div>

                                                <div style={{ marginTop: "-4px" }}>
                                                    <h6 className="ms-3 fs16 fw-500 black me-2">{row.cityName}</h6>
                                                    <p className="ms-3 me-2 fs12 gray mt-0">{row.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3 ms-3">
                                            <button className="startMilestoneDate">
                                                <p className="milestoneDate textDark">
                                                    {row.dateTimeFormatted}
                                                </p>
                                            </button>
                                            <div className="me-4 col-auto ms-auto">
                                                <img className="mb-1 dollarPadding me-0" src="/assets/images/Character_1.svg" alt="dollar" />
                                                <p className="d-inline mb-0 ms-2 fs16 purple fw-500">{formData.rows[index + 1] && formData.rows[index + 1].price} </p>
                                                <p className="d-inline mb-0  fs16 gray fw-500"> {t("VALUTE")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        )
                    })}
                    {formData.rows &&
                        formData.rows.map((row, index) => {
                            if (row.IsStopMilestone) {
                                const toDate = moment.utc(new Date(formData.rows[index + 1].dateTime));
                                const fromDate = moment.utc(new Date(formData.rows[index].dateTime));
                                var minutes = toDate.diff(fromDate, 'minutes');
                                var min = minutes % 60;
                                var hours = toDate.diff(fromDate, 'hours');
                                row.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                            }
                            return (
                                row.IsStopMilestone && (
                                    <>
                                        <div className="row milestoneStop">
                                            <div className="col d-flex flex-row">
                                                <p className="milestoneParagraph" style={{ marginTop: "15px" }}>{moment(row.dateTime).format("HH:mm")}h</p>
                                                <div className="vr opacity-3 sideLineStop textDark"></div>
                                                <img className="circle3Mobile mt-3" src="/assets/images/Ellipse 1.svg" alt="ellipse" />
                                                <div className="vr opacity-3 sideLineSubmitStop textDark"></div>
                                                <div style={{ marginTop: "11px" }}>
                                                    <h6 className="ms-3 fs16 fw-500 black me-2" >{row.cityName}</h6>
                                                    <p className="ms-3 me-2 fs12 gray mt-0">{row.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3 ms-3">
                                            <button className="milestoneDateTime">
                                                <p className="milestoneDate textDark">
                                                    {row.dateTimeFormatted}
                                                </p>
                                            </button>
                                            <div className="me-4 col-auto ms-auto">
                                                <img className="mb-1 dollarPadding me-0" src="/assets/images/Character_1.svg" alt="dollar" />
                                                <p className="d-inline mb-0 ms-2 fs16 purple fw-500">{formData.rows[index + 1] && formData.rows[index + 1].price} </p>
                                                <p className="d-inline mb-0  fs16 gray fw-500"> {t("VALUTE")}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            )
                        })}

                    {formData.rows &&
                        formData.rows.map(
                            (row, index) =>
                                row.IsLastMilestone && (
                                    <div key={index}>
                                        <div className="row ms-4">
                                            <div className="col d-flex flex-row">
                                                <p className="lastMilestoneParagraph me-2" style={{ marginTop: "15px" }}>{moment(row.dateTime).format("HH:mm")}h</p>
                                                <div className="vr opacity-3 sideLineDestinationMobile textDark"></div>
                                                <img className="circle3Mobile mt-3" src="/assets/images/Ellipse 1.svg" alt="ellipse" />
                                                <div className="ms-2 " style={{ marginTop: "11px" }}>
                                                    <h6 className="ms-0 fs16 fw-500 black me-2">{row.cityName}</h6>
                                                    <p className="ms-0 me-2 fs12 gray mt-0">{row.address}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <button className="mt-3 detailsToSubmitRide">
                                                <p className="mb-2 ms-2 lastMilestoneParagraph textDark">
                                                    <img className="mb-1 me-2" src="/assets/images/Group 3.svg" alt="group3" />
                                                    {t("DETAILS_TO_SUBMIT_RIDE") + " "}
                                                    {moment(row.dateTime).format("DD.MM.YYYY") + " "}
                                                    {t("IN") + " "}
                                                    {moment(row.dateTime).format("HH:mm")}h
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                )
                        )}

                    <div className="row d-flex flex-row mt-3" style={{paddingLeft:"15px"}}>
                        <hr className="lineWidth" />
                        <div className="col mt-1">
                            <p className="fs14 mb-4 ms-1">{t("TOTAL_PRICE_PER_PASSENGER")}</p>
                        </div>
                        <div className="col-auto cardWeight priceSize" style={{marginRight:"15px"}}>
                            <p className="priceOnSubmit fs18 fw-600 d-inline  me-1  ">
                                <NumericFormat
                                    className="fw-normal  mb-0 ms-2 valute fs18 fw-600"
                                    displayType="text"
                                    value={formData.price}
                                    thousandSeparator=","
                                />
                            </p>
                            <span className="fw-600 fs18 gray">{t("VALUTE")}</span>
                        </div>
                    </div>
                </div >
            </div>

            <GoogleMapSubmitMobile width={mapWidth} height={mapHeight} formData={formData}></GoogleMapSubmitMobile>

            <div className="card shadow mt-3 mb-3 ms-3 me-3 me-3 cardWidth">
                <div className="card-body d-flex align-items-center justify-content-between cardWeight textDark">
                    <p className="textDark d-inline mb-0 fw-600 fs16 ms-2">{t("MAX_PASSENGERS_COUNT")}</p>
                    <p className="float-end fs20 mb-0 mt-0 me-2">{formData.maxPassengersCount}</p>
                </div>
            </div>

            {user.Gender === "Female" && formData.womenOnly &&
                <div className="card shadow mt-3 mb-3 ms-3 me-3 me-3 cardWidth">
                    <div className="card-body cardWeight textDark">
                        <p className="float-start fw-600 fs16 ms-2">{t("WOMEN_ONLY")}</p>
                        <p className="float-end fw-600 fs16 me-2 mt-0">
                            <input
                                id={`checkbox_womenOnly`}
                                type="checkbox"
                                defaultChecked={formData.womenOnly}
                                className="form-check-input inputCheckColor py-0 mt-0"
                                readOnly
                                style={{ width: "18px", height: "18px" }}
                            />
                        </p>
                    </div>
                </div>
            }
            <div className="card shadow mt-3 ms-3 me-3 cardWidth">
                <div className="card-header d-flex p-3 ms-1">
                    <p className="textDark fw-600 fs16 ms-2">{t("CONDITIONS")}</p>
                </div>
                <div className="card-body pt-0" style={{ paddingBottom: "10px" }}>
                    <div className="fw-normal submitRideStep">
                        {formData.conditions && formData.conditions.map((condition, index) => {
                            return (
                                <div key={index} className="form-check ms-2 p-0">
                                    <img
                                        className="inputCheckColor mb-2 mt-1"
                                        src={condition.checked ? httpSmartClient.getPhotoUrl(condition.noIconId) : httpSmartClient.getPhotoUrl(condition.iconId)}
                                        alt=""
                                    ></img>
                                    <label className={`${condition.checked ? "form-check-label ps-2 condition no-opacity labelColor" : "form-check-label ps-2 condition no-opacity"} `}>
                                        <h6 className={`mb-0 ${condition.checked ? 'purple' : ''} fw-normal me-2`}>{condition.name}</h6>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="card shadow mt-3 mb-3 ms-3 me-3 cardWidth">
                <div className="card-body textDark cardWeight">
                    <p className="float-start d-inline fs16 fw-600 mt-2 ms-2">{t("VEHICLE")}</p>
                    <div className="float-end">
                        <div className="d-flex align-items-center">
                            {formData && formData.selectedDriverVehicle && <h6 className="mb-0 fw-normal me-2 ms-auto">{formData.selectedDriverVehicle.vehicleModel.vehicleBrand.name + " - " + formData.selectedDriverVehicle.vehicleModel.name}</h6>}
                            {formData && formData.selectedDriverVehicle && <div className='vehicle-color-circle mr-2 col-4' style={{ backgroundColor: formData.selectedDriverVehicle.color }}></div>}
                        </div>
                        <div className="float-end">
                            <ul className="nav nav-divider textGray">
                                {formData && formData.selectedDriverVehicle && <li className="nav-item">{formData.selectedDriverVehicle.licensePlateNumber}</li>}
                                {formData && formData.selectedDriverVehicle && <li className="nav-item"> {formData.selectedDriverVehicle.manufactureYear}</li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ms-3 cursor-pointer btnStep2Mobile me-3 cardWidth">
                <div className="d-flex ms-0">
                    <button className="btn btnSoft btn-md center-block me-2 btnStepDesktop d-flex justify-content-between btnHeightOther" onClick={() => previousStep()}>
                        <img className="mt-2" src="/assets/images/arrow-right2.svg" alt="arrow-right" />
                        <span className="fontWeight500 marginTop">{t("GO_BACK")}</span>
                    </button>
                    <button className="btn btnPrimary align-items-center btn-md center-block btnStepDesktop d-flex justify-content-between btnHeightOther" disabled={isFormSubmitting} onClick={() => openConfirmDialog(true)}>
                        <span className="fontWeight500  ">
                            {isFormSubmitting && <i className="fa fa-spin fa-spinner px-2"></i>}

                            {t("POST_RIDE")}</span>
                        <img className="mt-0" src="/assets/images/arrow-right.svg" alt="arrow-right" />
                    </button>
                </div>
            </div>
            <Modal
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
                closeIcon={globalStore.closeIcon}
                classNames={{
                    modal: "modal-no-padding",
                    modalContainer: 'modal-container-animation-none',
                    closeButton: "closeButtonConfirmCancel"

                }}
                center
            >
                <ConfirmationDialog
                    onDiscard={() => setConfirmDialog(false)}
                    title={t("POST_RIDE")}
                    description={descriptionConfirmDialog}
                    confirmBtnLabel={t("POST_RIDE")}
                    btnType={"mb-0 btn-primary"}
                    onConfirm={() => onSubmit(formData)}
                >
                </ConfirmationDialog>
            </Modal>
            <Modal
                open={showModalMinHoursBeforeRide}
                onClose={() => setShowModalMinHoursBeforeRide(false)}
                closeIcon={globalStore.closeIcon}
                center
                classNames={
                    {
                        modal: "",
                        closeButton: "mt-2 me-2"
                    }
                }
            >
                <InformationModal
                    onDiscard={() => setShowModalMinHoursBeforeRide(false)}
                    title={t("WARNING")}
                    description={descriptionMinHoursRide}
                ></InformationModal>
            </Modal>
        </div>
    );
};
