import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toastify } from "utils/toastify";
import { CirclePicker } from "react-color";
import Select from "react-select";
import { VehicleModelsService,UserVehiclesService,VehicleBrandsService } from "data/services";
import userProfileStore from "data/stores/UserProfileStore";
import vehicleStore from "data/stores/VehiclesStore";
import moment from "moment";

export default function MyVehicleAdd(props) {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    clearErrors,
    setError
  } = useForm();
  const [hex, setHex] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [vehicleModels, setVehicleModels] = useState();
  const [vehicleBrands, setVehicleBrands] = useState();
  const [filters, setFilters] = useState();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAndSet = async () => {
      const vehcileBrandsList = await VehicleBrandsService.getAll();
      setVehicleBrands(() => {
        return vehcileBrandsList.data.map(x => {
          return {
            value: x.id,
            label: x.name
          }
        })
      });
    };
    fetchAndSet();

  }, []);
  const handleBrandChange = async (data) => {
    setValue("vehicleBrandId", data)
    setFilters(null);
    const filteredVehicleModels = await VehicleModelsService.getAllById(data);
    setVehicleModels(() => {
      return filteredVehicleModels.data.map(x => {
        return {
          value: x.id,
          label: x.name
        }
      })
    })
    clearErrors('vehicleBrandId')
  }
  const onSubmit = async (data) => {
    if (data.vehiclePhoto && data.vehiclePhoto.length > 0) {
      data.vehiclePhoto = data.vehiclePhoto[0];
    }
    if (!data.vehicleModelId) {
      setError("vehicleModelId", "error")
      return;
    }
    setFormSubmitting(true);
    data.vehicleType = parseInt(data.vehicleType);
    data.userId = userProfileStore.user.id;
    const newUserVehicle = await UserVehiclesService.addVehicle(data);
    setFormSubmitting(false);
    if (newUserVehicle.isSuccess) {
      props.onSuccess();
      toastify.success(t("SUCCESSFULLY_ADDED_NEW_VEHICLE"));
    } else {
      toastify.error(t("ERROR_ADDING_NEW_VEHICLE"));
    }
    vehicleStore.setShowNewVehicleDialog(false)
  };
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(file));
    setValue("vehiclePhoto", file)
  }
  return (
    <>
      <div className="bg-white z-index-master">
        <div className="col-12 p-2 d-flex justify-content-between ms-2 fs18">
          <span className="headers">{t("MY_NEW_VEHICLE")}</span>
        </div>
        <div className="col-12 card bg-transparent">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-12 d-flex justify-content-center">
                <div>
                  <label htmlFor="uploadfile-2" title="Replace this pic" className="mb-2">
                    <img className='car-image cursor-pointer' src={previewUrl ? previewUrl : "/assets/images/default-car.jpg"} />
                    <svg className="pencil-vehicle cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#900FC8" />
                      <path d="M6.84026 13.2821C6.65272 13.4696 6.54736 13.724 6.54736 13.9892V14.4252C6.54736 14.9774 6.99508 15.4252 7.54736 15.4252H7.98335C8.24857 15.4252 8.50292 15.3198 8.69046 15.1323L13.8563 9.96641L12.0061 8.11621L6.84026 13.2821Z" fill="white" />
                      <path d="M15.2813 7.84209L14.1296 6.68984C14.0369 6.59765 13.9114 6.5459 13.7807 6.5459C13.6499 6.5459 13.5244 6.59765 13.4317 6.68984L12.5286 7.59074L14.3788 9.44094L15.2813 8.53839C15.373 8.44572 15.4244 8.32061 15.4244 8.19024C15.4244 8.05987 15.373 7.93477 15.2813 7.84209Z" fill="white" />
                    </svg>
                    <input
                      id="uploadfile-2"
                      className="form-control d-none"
                      onChange={handlePhotoUpload}
                      type="file"
                      accept="image/png, image/jpeg"
                    />
                  </label>
                <p className="mb-2 paragraphs-purple mt-2 text-center me-3 cursor-pointer ms-2 ps-1" htmlFor="uploadfile-2">{t("SELECT_VEHICLE_PHOTO")}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12 mb-1">
                  <label className="labels mb-2">{t("VEHICLE_BRANDS")}:</label>
                  <Select
                    {...register("vehicleBrandId", {
                      required: t("VEHICLE_MODEL_IS_REQUIRED"),
                    })}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#F3F3F3',
                        border: 'none',
                      }),
                      // placeholder: (styles) => ({
                      //   ...styles,
                      //   fontFamily: "Inter",
                      //   fontSize: "16px",
                      //   fontStyle: "normal",
                      //   fontWeight: "500",
                      //   lineHeight: "normal"
                      // })
                    }}
                    className="basic-select"
                    onChange={(e) => handleBrandChange(e.value)}
                    placeholder={t("CHOOSE_VEHICLE_BRAND")}
                    options={vehicleBrands}
                  />
                  {errors.vehicleBrandId && <span className="text-danger">{t("VEHICLE_BRAND_IS_REQUIRED")}</span>}
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label className="labels mb-2">{t("VEHICLE_MODEL")}:</label>
                  <Select
                    {...register("vehicleModelId", {
                      required: t("VEHICLE_MODEL_IS_REQUIRED"),
                    })}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#F3F3F3',
                        border: 'none',
                      }),
                      // placeholder: (styles) => ({
                      //   ...styles,
                      //   fontFamily: "Inter",
                      //   fontSize: "16px",
                      //   fontStyle: "normal",
                      //   fontWeight: "500",
                      //   lineHeight: "normal"
                      // })
                    }}
                    className="basic"
                    onChange={(e) => {
                      clearErrors("vehicleModelId")
                      setValue("vehicleModelId", e.value)
                      setFilters({ value: e.value, label: e.label })
                    }}
                    placeholder={t("CHOOSE_VEHICLE_MODEL")}
                    options={vehicleModels}
                    value={filters && filters.label ? { label: filters.label, value: filters.value } : null}
                  />
                  {errors.vehicleModelId && <span className="text-danger">{t("VEHICLE_MODEL_IS_REQUIRED")}</span>}
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label className="labels mb-2">{t("LICENSE_PLATE_NUMBER")}:</label>
                  <input
                    {...register("licensePlateNumber", {
                      required: t("LICENSE_PLATE_NUMBER_IS_REQUIRED"),
                    })}
                    type="text"
                    className="form-control b-none inputs"
                    placeholder={t("LICENSE_PLATE_NUMBER")}
                  />
                  {errors.licensePlateNumber && <span className="text-danger">{t("LICENSE_PLATE_NUMBER_IS_REQUIRED")}</span>}
                </div>
                <div className="col-md-6 col-sm-12 mb-1">
                  <label className="labels mb-2">{t("MANUFACTURE_YEAR")}:</label>
                  <input
                    {...register("manufactureYear", {
                      required: t("MANUFACTURE_YEAR_IS_REQUIRED"),
                      valueAsNumber: true,
                      pattern: {
                        value: "/^(0|[1-9]\d*)(\.\d+)?$/"
                      },
                      validate: (value) => value >= 1960 && value <= new Date().getFullYear()
                    })}
                    type="number"
                    className="form-control b-none inputs"
                    placeholder={t("MANUFACTURE_YEAR")}
                  />
                  {errors.manufactureYear && <span className="text-danger">{errors.manufactureYear.type === "validate" ? t("MIN_MAX_MANUFACTURE_YEAR",{year: moment().year()}) : t("MANUFACTURE_YEAR_IS_REQUIRED")}</span>}
                </div>
                <div className="col-md-12 col-sm-12 mb-1">
                  <label className="labels mb-2">{t("COLOR")}:</label>
                  <CirclePicker
                    color={hex}
                    circleSize={22}
                    {...register("color", {
                      required: t("COLOR_IS_REQUIRED"),
                    })}

                    width="100%"
                    required={true}
                    colors={[ "#F3BA00","#86F3C0","#D9D9D9", "#EC2222", "#29BCEB", "#972ACA", "#000", "#4C3FE1","#007a8a", "#40690c", "#565656", "#F9E31F", "#00FF00", "#FFA500","#7a0600","#57160e", "#F5F5DC", "#A020F0", "#FFD700"]}
                    className="form-control mb-1 bg-body-secondary justify-content-center pb-0 h-50"
                    onChange={(color) => {
                      setHex(color.hex);
                      setValue("color", color.hex);
                      clearErrors('color')
                    }}
                  />
                  {errors.color && <span className="text-danger pt-2">{t("COLOR_IS_REQUIRED")}</span>}
                </div>
                <div className=" col-md-12 col-sm-12">
                  <label className="labels mb-2">{t("NOTES")}:</label>
                  <textarea
                    {...register("notes")}
                    type="text"
                    className="form-control b-none inputs"
                    placeholder={t("NOTES")}
                    maxLength={500}
                  ></textarea>
                  {errors.notes && <span className="text-danger">{errors.notes.message}</span>}
                </div>
                <button disabled={isFormSubmitting} type="submit" className="btn col-6 m-auto btn-font mt-4 btn-size btn-primary" style={isMobile ? {width:"92%"} : {width:"96%"}}>
                  {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                  {t("ADD_NEW_VEHICLE")}
                </button>
              </div>
            </form>
          </div>
        </div >
      </div >
    </>

  );
}
