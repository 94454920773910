import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastify } from 'utils/toastify';
import { useTranslation } from 'react-i18next';
import { Rating } from '@mui/material';
import { AuthService, UserReviewsService, NotificationsService, ConnectionsService } from 'data/services';

function ReviewDriverModal(props) {
    const {
        formState: { errors },
        setValue,
        handleSubmit,
        clearErrors,
        setError
    } = useForm();
    const navigate = useNavigate();
    const [starValue, setStarValue] = useState(0);
    const user = AuthService.getCurrentUser();
    const { t } = useTranslation();
    const onSubmit = async (data) => {
        if (data.reviewRating == null) {
            setError("reviewRating", t("REVIEW_RATING_ERROR"))
            return;
        }
        if (data.reviewComment == null || data.reviewComment.trim()==='') {
            setError("reviewComment", t("REVIEW_COMMENT_ERROR"));
            return;
        }
        const response = await UserReviewsService.add({ UserReviewerId: user.Id, UserId: props.data.driverId, UserRideId: props.data.userRideId, ...data })
        if (response.isSuccess) {
            var notificationToUser = {
                userId: response.data.userId,
                senderId: user.Id,
                text: 'Review',
                rideId: response.data.userRide.rideId,
                cityNameFrom: response.data.userRide.rideMilestoneFrom.cityName,
                cityNameTo: response.data.userRide.rideMilestoneTo.cityName,
                userModelReviewOnUserId: null

            };
            const toUser = await NotificationsService.add(notificationToUser);
            if (toUser.isSuccess) {
                await ConnectionsService.InvokeNewReview({ ...toUser.data, isReview: true })
            }
            navigate(`/user/${props.data.driverId}/ratings`, { state: { navigateFromDialog: true } })
            toastify.success(t("SUCCESS_REVIEW"))
        } else {
            toastify.error(t("ERROR_REVIEW"))
        }
    }
    return (
        <div className=' d-flex flex-column p-2 h-100'>
            <h6 className='leave-review-to-passenger'>{t("LEAVE_A_REVIEW")}{t("TO_DRIVER")}</h6>
            <p className='labels mt-2'>{t("LEAVE_A_REVIEW")}{t("TO_DRIVER")}<span style={{color:"#900FC8",fontWeight:"bold"}}> {props.data.driver.firstName + " " + props.data.driver.lastName}</span> </p>
            <div className='rating-div h-100'>
                <form onSubmit={handleSubmit(onSubmit)} className='h-100'>
                    <div className='col-md-12 col-sm-12 d-flex flex-column mb-2 rating-div-number-stars justify-content-center align-items-center'>
                        <p className='numbers-rating'>{starValue}.0</p>
                        <Rating
                            name="simple-controlled"
                            value={starValue}
                            size="large"
                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                <path d="M20.5234 2.52731L23.603 8.73753C24.023 9.60202 25.1429 10.4312 26.0877 10.59L31.6696 11.5251C35.2392 12.1249 36.0791 14.736 33.5069 17.3119L29.1674 21.6872C28.4325 22.4282 28.03 23.8573 28.2575 24.8806L29.4999 30.2969C30.4798 34.584 28.2225 36.2425 24.4604 34.0018L19.2285 30.8791C18.2836 30.3145 16.7263 30.3145 15.7639 30.8791L10.532 34.0018C6.78738 36.2425 4.51263 34.5664 5.49253 30.2969L6.73489 24.8806C6.96236 23.8573 6.55991 22.4282 5.82499 21.6872L1.48547 17.3119C-1.06925 14.736 -0.24684 12.1249 3.32277 11.5251L8.90465 10.59C9.83205 10.4312 10.9519 9.60202 11.3719 8.73753L14.4515 2.52731C16.1313 -0.842437 18.861 -0.842437 20.5234 2.52731Z" fill="#F7C32E" />
                            </svg>}
                            onChange={(event, newValue) => {
                                clearErrors("reviewRating");
                                setStarValue(newValue);
                                setValue("reviewRating", newValue)
                            }}
                        />
                        {errors.reviewRating && <span className="text-danger d-block mb-2">{t("REVIEW_RATING_ERROR")}</span>}
                    </div>
                    <div className='col-md-12 col-sm-12 h-25 mb-7'>
                        <label>{t("DESCRIPTION")}:</label>
                        <textarea
                            type='text'
                            className='textarea-placeholder'
                            placeholder={t("LEAVE_COMMENT")}
                            onChange={(e) => {
                                clearErrors("reviewComment");
                                setValue("reviewComment", e.target.value)
                            }}
                            maxLength={500}
                        ></textarea>
                        {errors.reviewComment && <span className="text-danger d-block">{t("REVIEW_COMMENT_ERROR")}</span>}
                    </div>
                    <div className="mt-4 w-100">
                        <button type="submit" className="w-100 button-review" >
                            {t("LEAVE_A_REVIEW")}
                        </button>
                    </div>
                </form>
            </div>
            <div>

            </div>
        </div>
    )
}

export default ReviewDriverModal
