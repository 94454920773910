import React, { useEffect, useState } from "react";
import "./../../../layout/common.css"
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "../../../../data/services/AuthService";
import { useTranslation } from "react-i18next";
import VerifySuccess from '../../../../assets/images/verify-success.svg'

export default function VerifyEmailPage() {
    const [searchParams, setSearchParams] = useSearchParams("");
    const [resultMessage, setResultMessage] = useState();
    const [counter, setCounter] = useState(5);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const token = searchParams.get("token").replace(/ /g, '+');
    useEffect(() => {
        const CheckToken = async () => {
            if (token) {
                var result = await AuthService.verifyEmail(token);
                if (result.data)
                    setResultMessage(t("VERIFICATION_MESSAGE"));
                else
                    setResultMessage(t("VERIFICATION_MESSAGE_ERROR"));
                var i = 5;
                const interval = setInterval(() => {
                    setCounter(e => e - 1);
                    i--;
                    if (i === 0)
                        clearInterval(interval);
                }, 1000);
            }
            else
                navigate('/sign-in')
        };
        CheckToken();
        const timeout = setTimeout(() => {
            navigate('/sign-in');
        }, 5000);

    }, []);

    return (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center ">
            <div className="verify-div rounded d-flex flex-column align-items-center justify-content-center bg-purple " >
                <img src={VerifySuccess} className="verify-success-icon mb-4" />
                <h4 className="p-4 white">{resultMessage}</h4>
                <p className="mt-3 white">{t("REDIRECTING_IN", {counter})}</p>
            </div>
        </div>);
}