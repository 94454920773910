import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { toastify } from "utils/toastify";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { Tooltip } from 'react-tooltip'
import { AppSettingsService } from "data/services";


export default function PaymenType({...props}) {
    const { t } = useTranslation();
    const [currentPaymentType, setCurrentPaymentType] = useState();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [settings,setSettings]=useState();

    const {
        register,
        formState: { errors },
        reset,
        getValues,
    } = useForm();

    useEffect(() => {
        reset(props.settings)
        setSettings(props.settings);
    }, []);

    const onSubmit = async () => {
        const params= getValues("isCashAllowed");
        setIsFormSubmitting(true);
        const response = await AppSettingsService.edit({...settings, isCashAllowed:params})
        setIsFormSubmitting(false);
        if (response.isSuccess) {
            toastify.success(t("SUCCESS_UPDATE_PAYMENT_TYPE"),  <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png"/>)
            setShowConfirmationDialog(false);
        } else {
            toastify.error(t("ERROR_UPDATING_PAYMENT_TYPE"))
        }
    }

    const SaveChanges=()=>{
        setShowConfirmationDialog(true);
    }

    const onDiscardFunction=()=>{
        setShowConfirmationDialog(false);
        reset();
    }
    return (
        <>
            <div className='input col-md ms-3 col-sm-12 card shadow p-3 mb-3 ' >
                <div className="row">
                    <div className="col-12 mb-4 mb-sm-4">
                        <div className="d-sm-flex justify-content-between align-items-center">
                            <h4 className="black mb-3 mb-sm-0">{t("SAVE_PAYMENT_TYPE")}</h4>
                            <Tooltip style={{ width: "100%", zIndex: 1 }} id="tooltip-paymentType" ></Tooltip>
                            <span data-tooltip-id="tooltip-paymentType" data-tooltip-content={t("TOOLTIP_PAYMENTTYPE")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='grey' height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="form-check form-switch form-check-md mb-0">
                    <form >
                        <div className="row">
                            <label className="form-label">{t("PAYMENT_TYPE_ALLOWED")}</label>
                            <div className="col">
                                <input {...register("isCashAllowed")} className="form-check-input" style={{ width: "60px", height: "30px" }} type="checkbox" />
                            </div>
                            <div className="col-auto">
                                <a className='btn btn-primary mb-0 ms-1 overflow-visible' onClick={()=>SaveChanges()}>
                                    {t("SAVE")}</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {showConfirmationDialog && <ConfirmationDialog
                onDiscard={() => onDiscardFunction()}
                title={t("SAVE_PAYMENT_TYPE")}
                description={t("SAVE_PAYEMENT_DETAILS1")}
                description2={t("SAVE_PAYEMENT_DETAILS2")}
                confirmBtnLabel={t("SAVE")}
                onConfirm={() => onSubmit()}
            ></ConfirmationDialog>}
        </>
    );
}