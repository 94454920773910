import React, { useEffect, useState } from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import toHoursAndMinutes from "../../../../utils/timeFormatter/TimeFormatter"

export default function GoogleRoutesDesktop({
    width,
    height,
    formData,
    setFormData,
    selectedRouteIndex,
    setSelectedRouteIndex,
    selectedSummary,
    setSelectedSummary,
    routeKilometers,
    setRouteKilometers,
    routeError,
    setRouteError,
    zeroResults,
    setZeroResults,
    rows,
    setRows,
    step,
    refreshRecommendedPrice,
    setRefreshRecommendedPrice,
    pricePerKilometers,
    priceError,
    totalPrice,
    recalculateTotalPrice
}) {
    const { t } = useTranslation();
    const [directions, setDirections] = useState(null);
    const [rerender, setRerender] = useState(0);
    const [currentIndex, setCurrentIndex] = useState();

    const containerStyle = {
        width: width,
        height: height,
        margin: 'auto',
        borderRadius: "20px",
        marginTop: "-90px",
        zIndex: "99"
    };

    useEffect(() => {
        setFormData((prevObj) => ({ ...prevObj, selectedRouteIndex: selectedRouteIndex, routeKilometers: routeKilometers, summary: selectedSummary, rows: rows, price: totalPrice }));
    }, [selectedRouteIndex, routeKilometers, selectedSummary, rows, totalPrice]);

    useEffect(() => {
        const newArr = []
        formData.rows.filter(r => r.isSubmitted).forEach((milestone, index) => {
            if (index !== 0 && index !== formData.rows.length - 1) {
                newArr.push({
                    location: { lat: milestone.lat, lng: milestone.lng }
                })
            }
        })

        const getDirections = async () => {
            try {
                const directionsOptions = {
                    origin: { lat: formData.rows[0].lat, lng: formData.rows[0].lng },
                    destination: { lat: formData.rows[formData.rows.length - 1].lat, lng: formData.rows[formData.rows.length - 1].lng },
                    waypoints: [...newArr],
                    travelMode: 'DRIVING',
                    provideRouteAlternatives: true,
                }

                const directionsService = new window.google.maps.DirectionsService();
                directionsService.route(directionsOptions, (result, status) => {
                    if (status === "ZERO_RESULTS") {
                        setZeroResults(true)
                        return;
                    } else if (status === 'OK') {
                        result.routes.map((route) => {
                            let totalDistance = 0, totalDuration = 0;
                            if (result.routes.length === 1) {
                                setSelectedRouteIndex(0);
                                setSelectedSummary(route.summary);
                                calculateLegsDistance(route, false, refreshRecommendedPrice);
                                recalculateTotalPrice();
                                setRefreshRecommendedPrice(false);
                            }
                            route.legs.map((leg) => {
                                totalDistance += leg.distance.value;
                                totalDuration += leg.duration.value;
                            });
                            route.totalDistance = (totalDistance / 1000).toFixed(2);
                            setRouteKilometers(route.totalDistance);
                            route.totalDuration = toHoursAndMinutes(totalDuration);
                        })
                        setDirections(result);
                    }
                });
            } catch (error) {
                throw error;
            }
        };
        getDirections();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCurrentIndex(selectedRouteIndex);
    }, [directions]);

    useEffect(() => {
        setRouteError(null);
    }, [selectedRouteIndex]);

    const calculateLegsDistance = (route, forceSet = false, refreshRecommendedPrice = false) => {
        let copy = [...rows];
        route.legs.map((leg, index) => {
            if (!copy[index + 1].price || forceSet || refreshRecommendedPrice) {
                if (pricePerKilometers === 0) {
                    copy[index + 1].price = 0;
                } else {
                    copy[index + 1].price = Math.round((leg.distance.value / 1000) * pricePerKilometers);
                }
            }
        });
        setRows(copy)
    }

    const onChangeRoute = (e, route, routeIndex) => {
        if (directions.routes.length > 1) {
            calculateLegsDistance(route, true);
            setRouteKilometers(route.totalDistance);
            recalculateTotalPrice();
            setSelectedRouteIndex(routeIndex);
            setSelectedSummary(route.summary);
            setCurrentIndex(routeIndex);
            setRerender(prev => prev + 1);
        }
    }

    const decrementCount = (index) => {
        if (rows) {
            const updatedRows = [...rows];
            if (updatedRows[index + 1].price > 0) {
                updatedRows[index + 1].price -= 1;
            }
            setRows(updatedRows);
        }
        recalculateTotalPrice();
    }

    const incrementCount = (index) => {
        if (rows) {
            const updatedRows = [...rows];
            updatedRows[index + 1].price += 1;
            setRows(updatedRows);
        }
        recalculateTotalPrice();
    }

    return (
        <>
            {!zeroResults ?
                <>
                    <div className="card-body googleRoutesBody">
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            className="card"
                            zoom={8}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                            }}
                        >
                            <div key={rerender}>
                                {directions && directions.routes && directions.routes.map((route, index) => (
                                    <DirectionsRenderer
                                        key={index}
                                        directions={directions}
                                        routeIndex={index}
                                        options={{
                                            polylineOptions: {
                                                strokeColor: selectedRouteIndex === index ? "#039be5" : "grey",
                                                strokeOpacity: selectedRouteIndex === index ? 1.0 : 0.6,
                                                strokeWeight: selectedRouteIndex === index ? 5 : 3,
                                            },
                                        }}
                                    />)
                                )}
                            </div>
                        </GoogleMap>
                    </div>

                    {step === 2 && <>
                        <div className="fade show card shadow googleRoutesDesktop ms-4">
                            <span className="px-3 py-2 mt-5 ms-3 routesTitle fw-600 fs18">{t("FOUND_ROUTES")}</span>
                            {directions && directions.routes.map((route, index) => {
                                return (
                                    <div className="ms-1" onClick={(e) => onChangeRoute(e, route, index)}>
                                        <div className="form-check cursor-pointer mt-2 mb-3 ms-1">
                                            <input
                                                className="form-check-input directionChecked my-3 me-3 ms-0"
                                                type="radio"
                                                checked={currentIndex === index ? `radio_${index}` : ""}
                                                name="googleRouteDesktop"
                                                id={`radio_${index}`}
                                                readOnly
                                            />
                                            <p className={`form-check-label mb-0 fs14 d-inline fw-500 route ${currentIndex === index ? 'checkedRoute' : ""}`} htmlFor={`radio_${index}`}>
                                                {t("ROUTE")} {route.summary}
                                            </p>
                                            <label className="colorGray d-block cursor-pointer" htmlFor={`radio_${index}`}>
                                                <ul className="nav nav-divider">
                                                    <li className="nav-item fs12 gray">{route.totalDuration} </li>
                                                    <li className="nav-item fs12 gray">{route.totalDistance} {t("KILOMETERS")} </li>
                                                </ul>
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                            {routeError && <div className="alert alert-danger me-4 ms-4 mt-2 text-danger" role="alert"><i className="fa-solid fa-circle-exclamation me-2 dangerIcon"></i>{t("ROUTE_ERROR")}</div>}
                        </div>
                    </>}

                    {step === 3 &&
                        <>
                            <div className="fade show card shadow googleRoutesDesktop ms-4">
                                <div className="scrollable-divDesktop">
                                    <div className="row col-10">
                                        <span className="mb-2 fs18 fw-600 mt-7 ms-4 routesTitle fontWeight500">{t("PRICE_FOR_MILESTONES")} {selectedSummary}</span>
                                    </div>
                                    {rows && rows.map((row, index) => {
                                        if (index !== rows.length - 1) {
                                            return (
                                                <>
                                                    <div className="row ms-4 mt-3 mb-3">
                                                        <div className="ps-0 d-flex">
                                                            <div className="col d-flex mt-2">
                                                                <div className="d-grid mb-auto">
                                                                    <span className="fs16 fw-600 textDark ">{rows[index].cityName}</span>
                                                                    <span className="fs12 fw-500 gray ">{rows[index].address}</span>
                                                                </div>
                                                                <svg className="ms-3 mt-2" xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                                                                    <path d="M14.8594 1.85938L22.0003 9.00034L14.8594 16.1413" stroke="#7E7E7E" strokeWidth="2.139" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M2 9.00098H21.7994" stroke="#7E7E7E" strokeWidth="2.139" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="d-grid mb-auto">
                                                                    <span className="ms-3 fs16 fw-600 textDark ">{rows[index + 1].cityName}</span>
                                                                    <span className="ms-3 gray fs12 fw-500">{rows[index + 1].address}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-auto me-4">
                                                                <div className="maxPassengersCount card-body maxPassengersCountBody mt-0">
                                                                    <span
                                                                        type="button"
                                                                        className="minus me-3"
                                                                        onClick={() => decrementCount(index)}
                                                                    ><i className="fa-solid fa-minus passengersCount d-flex justify-content-evenly"></i></span>
                                                                    <div className="d-flex w-50 justify-content-center">
                                                                        <span className="textDark w-25 d-flex justify-content-end fs16 d-inline-block px-1"><b>{rows && rows[index + 1].price && rows[index + 1].price}</b> </span>
                                                                        <span className="fw-500 gray">{t("VALUTE")}</span>
                                                                    </div>
                                                                    <span
                                                                        type="button"
                                                                        className="plus ms-3"
                                                                        onClick={() => incrementCount(index)}
                                                                    ><i className="fa-solid fa-plus passengersCount d-flex justify-content-evenly"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {index + 1 !== rows.length - 1 && <hr className="mt-3 hrStyle me-4 ms-4" />}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                                {priceError && <div className="alert alert-danger mt-2 text-danger ms-4 me-4" role="alert"><i className="fa-solid fa-circle-exclamation me-2 dangerIcon"></i>{t("PRICE_ERROR")}</div>}
                                <hr className="my-2" style={{ border: "1px solid black", width: "95%", marginLeft: "auto", marginRight: "auto" }} />

                                <div className=" d-flex justify-content-md-between align-items-center pb-3 px-3 m-2 ">
                                    <span className="textGray fontSize18 fw-500 col-auto  ">{t("TOTAL_PRICE_PER_PASSENGER")}</span>
                                    <div className=" priceSize ">
                                        <span className="  fs22 fw-600 valute">{totalPrice}</span>
                                        <span className="fw-600 gray fs22"> {t("VALUTE")}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
                : (
                    <div>
                        <div className="flex justify-content-center mb-6 mt-6">
                            <img src="/assets/images/relation_not_found.svg" width="500" className="me-2" />
                        </div>
                        <h5 className="routeError">{t("ZERO_ROUTE_RESULTS1")}</h5>
                        <div className="text-wrapper" style={{ width: "100%", textAlign: "center" }}>{t("ZERO_ROUTE_RESULTS2")}</div>
                    </div>
                )
            }
        </>
    );
};
