import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import userProfileStore from "data/stores/UserProfileStore";
import "./../common.css"
import { AuthService, ConnectionsService } from "data/services";
import { useState } from "react";

const MyProfileNavbar = observer((props) => {
  const navigate = useNavigate();
  const user = userProfileStore.user;
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const signOut = () => {
    userProfileStore.setUser(null);
    AuthService.signOut();
    ConnectionsService.connectionEnd();
    navigate("/sign-in");
  };
  return (
    <ul className="myProfileNavbarList">
      <li className="nav-item ms-0  mt-2 dropdown">
        <a className=" avatar-sm p-0 d-flex justify-content-end" onClick={() => setShowDropdown(true)} id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
          <img className="avatar-img rounded-5" style={{ width: "28px", height: "28px" }} src={user && user.profilePhotoId ? user.profilePhotoUrl : "/assets/images/avatar/01.jpg"} alt="avatar" />
        </a>
        {/* {user.role!="Administrator" && <span id="profileDropdown"  className="cursor-pointer" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" style={{ color:location.pathname.includes('admin') ? "black":"white", fontSize: "15px" }}>{user && user.firstName + " " + user.lastName}</span>} */}
        {/* <svg className="ms-2 mb-1" xmlns="http://www.w3.org/2000/svg" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" width="17" height="17" viewBox="0 0 17 17" fill={`${window.location.pathname=="/admin" ? 'purple' : ''}`}>
        <path d="M14.1099 6.33972L9.49156 10.9581C8.94614 11.5035 8.05364 11.5035 7.50823 10.9581L2.88989 6.33972" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </svg> */}
        <ul className={`dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3 ${showDropdown ? "show" : ""}`} id="dropdown" style={{ borderRadius: "20px" }} aria-labelledby="profileDropdown">
          <li className="px-3 mb-3">
            <div className="d-flex align-items-center cursor-pointer" onClick={() => { setShowDropdown(false); navigate(`/user/${user.id}`) }}>
              <div className="avatar me-3">
                <img className="avatar-img rounded-circle shadow" src={user && user.profilePhotoId ? user.profilePhotoUrl : "/assets/images/avatar/01.jpg"} alt="avatar" />
              </div>
              <div>
                <a className="h6 mt-2 mt-sm-0">
                  {user && user.firstName} {user && user.lastName}
                </a>
                <p className="small m-0">{user && user.email}</p>
              </div>
            </div>
          </li>
          <li className="nav-item mb-2 cursor-pointer">
            <a className="dropdown-item bg-info-soft-hover " onClick={() => { setShowDropdown(false); navigate("/my-profile"); }}>
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6489 9.27273C10.5558 9.2634 10.444 9.2634 10.3415 9.27273C8.12427 9.19811 6.36353 7.37942 6.36353 5.14103C6.36353 2.856 8.20812 1 10.4999 1C12.7823 1 14.6363 2.856 14.6363 5.14103C14.6269 7.37942 12.8662 9.19811 10.6489 9.27273Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.72996 12.9531C3.42351 14.5073 3.42351 17.0402 5.72996 18.5849C8.35093 20.3503 12.6493 20.3503 15.2703 18.5849C17.5767 17.0307 17.5767 14.4977 15.2703 12.9531C12.6588 11.1973 8.36046 11.1973 5.72996 12.9531Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="ms-2" style={{ color: "black" }}>{t("PROFILE2")}</span>
            </a>
          </li>
          <li className="nav-item mb-2 cursor-pointer">
            <a className="dropdown-item bg-info-soft-hover " onClick={() => { setShowDropdown(false); navigate("/my-profile/my-rides") }}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 20.8274C16.4274 20.8274 20.8272 16.4276 20.8272 11.0001C20.8272 5.57266 16.4274 1.17285 11 1.17285C5.57254 1.17285 1.17273 5.57266 1.17273 11.0001C1.17273 16.4275 5.57254 20.8274 11 20.8274Z" stroke="black" strokeMiterlimit="10" />
                <path d="M11.0001 12.8435C12.0182 12.8435 12.8435 12.0182 12.8435 11.0001C12.8435 9.98205 12.0182 9.15674 11.0001 9.15674C9.98205 9.15674 9.15674 9.98205 9.15674 11.0001C9.15674 12.0182 9.98205 12.8435 11.0001 12.8435Z" stroke="black" strokeMiterlimit="10" />
                <path d="M11.0001 18.4655C15.1232 18.4655 18.4657 15.123 18.4657 10.9998C18.4657 6.87666 15.1232 3.53418 11.0001 3.53418C6.87691 3.53418 3.53442 6.87666 3.53442 10.9998C3.53442 15.123 6.87691 18.4655 11.0001 18.4655Z" stroke="black" strokeMiterlimit="10" />
                <path d="M3.53442 12.5605L6.96283 12.5605C7.62809 12.5605 8.27723 12.7905 8.77819 13.2277C9.12483 13.5302 9.41773 13.9429 9.43807 14.4799C9.48913 15.8362 9.43807 18.3018 9.43807 18.3018" stroke="black" strokeMiterlimit="10" />
                <path d="M18.3024 12.5605L14.874 12.5605C14.2088 12.5605 13.5596 12.7905 13.0587 13.2277C12.712 13.5302 12.4191 13.9429 12.3988 14.4799C12.3477 15.8362 12.3988 18.3018 12.3988 18.3018" stroke="black" strokeMiterlimit="10" />
                <path d="M3.74414 9.82229C3.74414 9.82229 5.63781 9.89907 7.95412 8.81154C10.2704 7.72402 12.1127 7.74935 14.1219 8.81154C16.1311 9.87373 18.3733 9.82344 18.3733 9.82344" stroke="black" strokeMiterlimit="10" />
              </svg>
              <span className="ms-2" style={{ color: "black" }}>{t("MY_RIDES")}</span>
            </a>
          </li>
          <li className="nav-item mb-2 cursor-pointer">
            <a className="dropdown-item bg-info-soft-hover" onClick={() => { setShowDropdown(false); navigate("/my-profile/my-vehicles") }}>
              <svg width="22" height="22" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6529 2.00464H6.68042C4.91667 2.00464 4.52708 2.88297 4.30042 3.95964L3.5 7.79172H14.8333L14.0329 3.95964C13.8063 2.88297 13.4167 2.00464 11.6529 2.00464Z" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.2429 14.0391C16.3208 14.8679 15.655 15.5833 14.805 15.5833H13.4733C12.7083 15.5833 12.6021 15.2575 12.4675 14.8537L12.3258 14.4287C12.1275 13.8479 12 13.4583 10.98 13.4583H7.35335C6.33335 13.4583 6.1846 13.8975 6.00752 14.4287L5.86585 14.8537C5.73127 15.2575 5.62502 15.5833 4.86002 15.5833H3.52835C2.67835 15.5833 2.01252 14.8679 2.09043 14.0391L2.4871 9.72538C2.58627 8.66288 2.79168 7.79163 4.64752 7.79163H13.6858C15.5417 7.79163 15.7471 8.66288 15.8463 9.72538L16.2429 14.0391Z" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.50008 5.66663H2.79175" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.5418 5.66663H14.8335" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.16675 2.125V3.54167" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.10425 3.54163H10.2292" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.91675 10.625H7.04175" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.2917 10.625H13.4167" stroke="#292D32" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="ms-2" style={{ color: "black" }}>{t("VEHICLES")}</span>
            </a>
          </li>
          <li className="nav-item mb-2 cursor-pointer">
            <a className="dropdown-item bg-info-soft-hover " onClick={() => { setShowDropdown(false); navigate("/my-profile/my-travel-preference") }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path d="M19.25 5.6875H14" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.25 5.6875H1.75" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.75 8.75C10.4414 8.75 11.8125 7.37887 11.8125 5.6875C11.8125 3.99613 10.4414 2.625 8.75 2.625C7.05863 2.625 5.6875 3.99613 5.6875 5.6875C5.6875 7.37887 7.05863 8.75 8.75 8.75Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.25 15.3125H15.75" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 15.3125H1.75" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.25 18.375C13.9414 18.375 15.3125 17.0039 15.3125 15.3125C15.3125 13.6211 13.9414 12.25 12.25 12.25C10.5586 12.25 9.1875 13.6211 9.1875 15.3125C9.1875 17.0039 10.5586 18.375 12.25 18.375Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="ms-2" style={{ color: "black" }}>{t("TRAVEL_PREFERENCES")}</span>
            </a>
          </li>
          {user && user.role == "Administrator" &&
            <li className="nav-item mb-2 cursor-pointer">
              <hr className="dropdown-divider" />
              <a className="dropdown-item bg-info-soft-hover ms-1" onClick={() =>{setShowDropdown(false); navigate("/admin")}}>
                <i className="fa-solid fa-user-shield"></i>
                <span className="ms-2" style={{ color: "black" }}>{t("ADMIN")}</span>
              </a>
            </li>}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item bg-danger-soft-hover ms-1 cursor-pointer" onClick={signOut}>
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2123 14.385C12.941 17.535 11.3223 18.8213 7.77853 18.8213L7.66478 18.8213C3.75353 18.8213 2.18728 17.255 2.18728 13.3438L2.18728 7.63879C2.18728 3.72754 3.75353 2.16129 7.66478 2.16129L7.77853 2.16129C11.296 2.16129 12.9148 3.43004 13.2035 6.52754" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.87502 10.5L17.8325 10.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.8814 13.4312L18.8126 10.4999L15.8814 7.56865" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="ms-2" style={{ color: "black" }}> {t("SIGN_OUT")}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
});
export default MyProfileNavbar;