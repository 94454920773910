import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {

    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className="row" style={isMobile ? { height: "80px", backgroundColor: "#900fc8", marginTop: "-70px", marginBottom: "0px" } :
                { height: "60px", backgroundColor: "#900fc8", marginTop: "-70px", marginBottom: "40px" }}>
                <div className="col">
                </div>
                <div className="col-auto pt-1">
                    <h5 style={{ color: "white", fontWeight: "600", fontSize: isMobile ? "18px" : "22px", marginTop: "15px" }}>{t("PRIVACY_POLICY")}</h5>
                </div>
                <div className="col">
                </div>
            </div>
            <div className={isMobile ? "bg-light" : ""} style={isMobile ? { paddingTop: "40px", marginTop: "-20px", borderRadius: "1.5em" } : {}}>
                <div className="card bg-transparent border p-0 mb-6" style={isMobile ? { maxWidth: "1120px", width: "90%", marginLeft: "auto", marginRight: "auto" } : { maxWidth: "1120px", marginLeft: "auto", marginRight: "auto" }}>
                    <div className="card-header bg-transparent border-bottom p-4">
                        <h5 className="mb-0" style={{ fontWeight: "600", fontSize: "20px", color: "black" }}>  {t("PRIVACY_POLICY_HEADER")}</h5>
                    </div>
                    <div className="card-body p-4 pt-0">
                        <div className="mt-4">
                            <h6 className="mb-2" style={{ fontWeight: "600", color: "black" }}>{t("PRIVACY_POLICY_UPPER")}</h6>
                            <p className="mb-0" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P1")}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h6 className="mb-2" style={{ fontWeight: "600", color: "black" }}>{t("USERS_VOZIME")}</h6>
                            <p className="mb-0" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P2")}
                            </p>
                            <p className="mb-0 mt-3" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P3")}
                            </p>
                            <p className="mb-0 mt-3" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P4")}
                            </p>
                            <p className="mb-0 mt-3" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P5")}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h6 className="mb-2" style={{ fontWeight: "600", color: "black" }}>{t("RESRVATION_UPPER")} </h6>
                            <p className="mb-0" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P6")}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h6 className="mb-2" style={{ fontWeight: "600", color: "black" }}>{t("SAVE_DATA")} </h6>
                            <p className="mb-0" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P7")}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h6 className="mb-2" style={{ fontWeight: "600", color: "black" }}>{t("SUGGESTIONS")} </h6>
                            <p className="mb-0" style={{ lineHeight: "25px" }}>
                                {t("PRIVACY_POLICY_P8")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}