import { t } from "i18next";
import { useState } from "react";

const InformationModal = (props) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const handleDiscard = () => {
        props.onDiscard();
    }

    return (
        <>
            <div className="mb-3 ">
                <span className="cardModalTitle">{props.title}</span>
            </div>
            <div className="card-body ">
                <div className="mb-3">
                    <label className="form-label">{props.description}</label><br />
                </div>
                <div className="d-flex justify-content-end">
                <div className="align-items-end d-flex justify-content-end pt-2 w-100">
                        <button className="btn btn-primary mb-0 border-0 me-1" onClick={() => handleDiscard()}>
                            {t("OK")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default InformationModal;