import { httpSmartClient } from "../../config/httpClient";

const baseName = "ChatMessages";

const ChatMessagesService = {
    getById: async (id) => {
        var response = await httpSmartClient.getById(`/${baseName}/` + id);
        return response;
    },
    getAll: async (params) => {
        var response = await httpSmartClient.get(`/${baseName}/GetAll`, { params });
        return response;
    },
    toggleSeen: async (guid) => {
        var response = await httpSmartClient.put(`/${baseName}/ToggleSeen`, { Guid: guid });
        return response;
    },
    getPaged: async (params) => {
        var response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
        return response;
    },
    add: async (params) => {
        var response = await httpSmartClient.post(`/${baseName}/`, params);
        return response;
    },
    edit: async (params) => {
        var response = await httpSmartClient.put(`/${baseName}`, params);
        return response;
    },
    delete: async (id) => {
        var response = await httpSmartClient.delete(`/${baseName}/` + id);
        return response;
    },

};

export default ChatMessagesService;
