import React from 'react'
import { useTranslation } from 'react-i18next'
import { httpSmartClient } from 'config/httpClient';
import vehicleStore from 'data/stores/VehiclesStore';

function Vehicle(props) {
    const { t } = useTranslation();
    const vehicleFullName = props.vehicle.vehicleModel.vehicleBrand.name + " - " + props.vehicle.vehicleModel.name;
    const triggerDialog = (prop) => {
        props.triggerDialog({ vehicleId: prop.id });
    }
    const handleEditDialog = (e) => {
        vehicleStore.setShowEditVehicleDialog(true);
        props.editDialog({ vehicleId: props.vehicle.id, targetId: e.target.id })
    }
    return (
        <div className={`col-sm-12 col-md-6 mb-3`}>
            <div className='card shadow p-3 pt-1 cursor-pointer'>
                <div className='d-flex gap-3 mt-2 bg-image hover-zoom' onClick={(e) => handleEditDialog(e)}>
                    <img className='car-image mt-1' alt="" src={`${props.vehicle.optimizedVehiclePhoto ? httpSmartClient.getPhotoUrlObjectFromBase64(props.vehicle.optimizedVehiclePhoto) : props.vehicle.optimizedVehiclePhotoId ? httpSmartClient.getPhotoUrl(props.vehicle.optimizedVehiclePhotoId) :  "/assets/images/default-car.jpg"}`} />
                    <div className=''>
                        <span className='paragraphs-small'>{t("VEHICLE")}:</span>
                        <p className='paragraphs-medium mb-1'>{vehicleFullName && vehicleFullName.length < 18 ? vehicleFullName : vehicleFullName.substring(0, 16) + "..."} <i style={{ color: props.vehicle.color, fontSize: "14px" }} className='fas fa-circle'></i></p>
                        <span className='paragraphs-small'>{t("LICENSE_PLATE_NUMBER")}:</span>
                        <p className='paragraphs-medium mb-1'>{props.vehicle.licensePlateNumber}</p>
                        <span className='paragraphs-small'>{t("MANUFACTURE_YEAR")}:</span>
                        <p className='paragraphs-medium'>{props.vehicle.manufactureYear}</p>
                    </div>
                </div>
                <div className='row  mt-2 d-flex justify-content-between' style={{marginLeft:"-6px"}}>
                    <div onClick={(e) => handleEditDialog(e)} className='col' style={{paddingLeft:"5px"}}>
                        <span className='paragraphs-small'>{t("NOTES")}:</span>
                        <p className='paragraphs-medium  mb-0'>{props.vehicle.notes && props.vehicle.notes.toString().length < 20 ? props.vehicle.notes : props.vehicle.notes ? props.vehicle.notes.substring(0, 15) + "..." : t("NO_NOTES")}</p>
                    </div>
                    <div className='col row justify-content-end pe-3 align-items-end'>
                        <div onClick={() => triggerDialog(props.vehicle)} className="cursor-pointer align-items-center d-flex justify-content-end gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none"><path d="M7.6416 12.3583L12.3583 7.6416" stroke="#E76868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.3583 12.3583L7.6416 7.6416" stroke="#E76868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke="#E76868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            <span className="delete-small ">{t("DEACTIVATE_VEHICLE")}</span></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Vehicle
