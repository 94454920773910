import { makeObservable, observable, action, computed, makeAutoObservable } from "mobx";

class CatalogStore {
  filteredData = null;

  constructor() {
    makeObservable(this, {
      filteredData: observable,
      setFilters: action
    });
  }

  setFilters(filter) {
    this.filteredData = filter;
  }

}

const catalogStore = new CatalogStore();
export default catalogStore;