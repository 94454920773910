import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { httpSmartClient } from "config/httpClient";
import { useNavigate } from "react-router";
import ChartIncome from "./ChartIncome";
import ChartStatusBar from "./ChartStatusBar";
import { NumericFormat } from "react-number-format";
import AccountToCreditCardTransactions from "data/services/A2CTransactionsService";
import { AuthService, ChatsService, AppSettingsService, RidesService, UsersService, ReportsService } from "data/services";

export default function DashboardPage() {
  const [totalNewReports, setTotalNewReports] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [totalActiveRides, setTotalActiveRides] = useState();
  const [totalIncome, setTotalIncome] = useState();
  const [topUsers, setTopUsers] = useState();
  const [topUsersByIncome, setTopUsersByIncome] = useState();
  const [incomes, SetIncomes] = useState();
  const [ridesCount, setRidesCount] = useState();
  const [currentPaymentType, setCurrentPaymentType] = useState();
  const [currentFee, setCurremtFee] = useState();
  const loggedUser = AuthService.getCurrentUser();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchAndSetTotalNewReports = async () => {
      const response = await ReportsService.getCount("WaitingForReview");
      setTotalNewReports(response.data.count);
    }
    const fetchAndSetTotalUsers = async () => {
      const response = await UsersService.getTotalUsers();
      setTotalUsers(response.data.count);
    }
    const fetchAndSetTotalActiveRides = async () => {
      const response = await RidesService.getCount(new Date());
      setTotalActiveRides(response.data.count);
    }
    const fetchAndSetTotalIncome = async () => {
      const response = await AccountToCreditCardTransactions.getTotalIncome();
      setTotalIncome(response.data.count);
    }
    const fetchAndSetTopUsers = async () => {
      const response = await UsersService.getTopUsers(5);
      setTopUsers(response.data);
    }
    const fetchAndSetTopUsersByIncome = async () => {
      const response = await UsersService.getTopUsersByIncome(5);
      setTopUsersByIncome(response.data);
    }
    const fetchAndSetMonthlyIncomes = async () => {
      const response = await AccountToCreditCardTransactions.getIncomesPerMonths(1, 12);
      SetIncomes(response.data);
    }
    const fetchAndSetRidesCount = async () => {
      const response = await RidesService.GetCountByRideStatus(1, 12);
      setRidesCount(response.data);
    }
    const fetchAndSetIsCashAllowed = async () => {
      const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 })
      setCurrentPaymentType(response.data.items[0].isCashAllowed);
      setCurremtFee(response.data.items[0].rideFee);
    }

    fetchAndSetTotalNewReports();
    fetchAndSetTotalUsers();
    fetchAndSetTotalActiveRides();
    fetchAndSetTotalIncome();
    fetchAndSetTopUsers();
    fetchAndSetTopUsersByIncome();
    fetchAndSetMonthlyIncomes();
    fetchAndSetIsCashAllowed();
    fetchAndSetRidesCount();
  }, [])
  const handleChatNavigate = async (userId) => {
    const response = await ChatsService.getChatGuidBetweenUsers({ UserOneId: userId, UserTwoId: loggedUser.Id });
    navigate(`/chat/${response.data}`)
  }
  const { t } = useTranslation();
  return (
    <>
      {currentPaymentType == true && <div className="alert alert-danger" role="alert">
        {t("CASH_ALLOWED")}
      </div>}
      {currentFee == 0 && <div className="alert alert-danger" role="alert">
        {t("FEE_IS_NULL")}
      </div>}
      <h1 className="h3 mb-3 mb-sm-4">{t("DASHBOARD")}</h1>
      <div className="row justify-content-between g-4 mb-4">
        <div className="col-md-6 col-xxl-3 cursor-pointer" onClick={() => navigate('/admin/users')}>
          <div className="card card-body bg-warning bg-opacity-10 border border-warning border-opacity-25 p-4 h-100  ms-md-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <h4><NumericFormat displayType="text" value={totalUsers} thousandSeparator="," /></h4>
                <span className="h6 fw-light mb-0" style={{ marginTop: "-5px" }}>{t("TOTAL_USERS")}</span>
              </div>
              <div className="icon-lg rounded-circle bg-warning text-white mb-0"><i className="fa-solid fa-users fa-fw"></i></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card card-body bg-success bg-opacity-10 border border-success border-opacity-25 p-4 h-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <h4><NumericFormat displayType="text" value={totalIncome} thousandSeparator="," /></h4>
                <span className="h6 fw-light mb-0" style={{ marginTop: "-5px" }}>{t("TOTAL_INCOMES")}</span>
              </div>
              <div className="icon-lg rounded-circle bg-success text-white mb-0"><i className="bi bi-cash "></i></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card card-body bg-primary bg-opacity-10 border border-primary border-opacity-25 p-4 h-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <h4><NumericFormat displayType="text" value={totalActiveRides} thousandSeparator="," /></h4>
                <span className="h6 fw-light mb-0" style={{ marginTop: "-5px" }}>{t("TOTAL_ACTIVE_RIDES")}</span>
              </div>
              <div className="icon-lg rounded-circle bg-primary text-white mb-0"><i className="fa-solid fa-car fa-fw"></i></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xxl-3">
          <div className="card card-body bg-info bg-opacity-10 border border-info border-opacity-25 p-4 h-100  me-md-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <h4><NumericFormat displayType="text" value={totalNewReports} thousandSeparator="," /></h4>
                <span className="h6 fw-light mb-0" style={{ marginTop: "-5px" }}>{t("TOTAL_NEW_REPORTS")}</span>
              </div>
              <div className="icon-lg rounded-circle bg-info text-white mb-0"><i className="fa-solid fa-user fa-fw"></i></div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-between">
        {incomes && <ChartIncome props={incomes}></ChartIncome>}
        {ridesCount && <ChartStatusBar props={ridesCount}></ChartStatusBar>}
      </div>

      <div className="row  justify-content-between ">
        <div className="col-lg-6 col-xxl-6 mb-4 " >
          <div className="card ms-1 shadow p-3 h-100">
            <div className="card-header border-bottom d-flex justify-content-between align-items-center p-3">
              <h5 className="card-header-title">{t("TOP_USERS_BY_INCOME")} ({t(`MONTHS.${new Date().getMonth() + 1}`)})</h5>
            </div>
            <div className="card-body p-3">
              {topUsersByIncome && topUsersByIncome.map((user, index) => (
                <>
                  <div id={index} className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center mb-1 mb-sm-0">
                      <div className="avatar avatar-md flex-shrink-0">
                        <img className="avatar-img rounded-circle" src={user.profilePhotoId != null ? httpSmartClient.getPhotoUrl(user.profilePhotoId) : "/assets/images/avatar/01.jpg"} alt="profile picture" />
                      </div>
                      <div className="d-inline-flex flex-column">
                        <div className="ms-2 d-inline-flex  align-items-ceneter ">
                          <h6 className="mb-0 fw-600 fs16">{user.firstName} {user.lastName}</h6>
                          {loggedUser.Id != user.id && <div className="col ps-2 d-flex flex-row  align-items-center" onClick={() => handleChatNavigate(user.id)} style={{ color: "#9400D3" }}>
                            <svg width="17" className="contact-driver cursor-pointer" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="vuesax/linear/messages-3">
                                <g id="vuesax/linear/messages-3_2">
                                  <g id="messages-3">
                                    <path id="Vector" d="M15.5846 4.42692V8.0394C15.5846 8.93899 15.2871 9.69692 14.7559 10.2211C14.2317 10.7523 13.4738 11.0498 12.5742 11.0498V12.3319C12.5742 12.8136 12.0359 13.104 11.6392 12.8348L10.9521 12.3815C11.0159 12.1619 11.0442 11.9211 11.0442 11.6661V8.78319C11.0442 7.33819 10.0809 6.37484 8.63589 6.37484H3.8263C3.72713 6.37484 3.63505 6.38193 3.54297 6.38902V4.42692C3.54297 2.62067 4.74714 1.4165 6.55339 1.4165H12.5742C14.3805 1.4165 15.5846 2.62067 15.5846 4.42692Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path id="Vector_2" d="M11.0442 8.78335V11.6662C11.0442 11.9212 11.0159 12.1621 10.9521 12.3817C10.6901 13.4229 9.82589 14.0746 8.63589 14.0746H6.70922L4.57005 15.4983C4.2513 15.7179 3.8263 15.4842 3.8263 15.1017V14.0746C3.1038 14.0746 2.50172 13.8338 2.0838 13.4158C1.6588 12.9908 1.41797 12.3887 1.41797 11.6662V8.78335C1.41797 7.43752 2.2538 6.5096 3.54297 6.38918C3.63505 6.3821 3.72713 6.375 3.8263 6.375H8.63589C10.0809 6.375 11.0442 7.33835 11.0442 8.78335Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>}
                        </div>
                        <ul className="ms-2  nav">
                          <li key={index} className="nav-item fs12 gray">{user.incomePerMonth} BAM</li>
                        </ul>
                      </div>
                    </div>
                    <a onClick={() => navigate(`/user/` + user.id)} className="btn btn-sm btn-light flex-shrink-0 mb-0 ms-3">{t("VIEW")}</a>
                  </div>
                  <hr />
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xxl-6 mb-4 " >
          <div className="card shadow p-3 h-100">
            <div className="card-header border-bottom d-flex justify-content-between align-items-center p-3">
              <h5 className="card-header-title">{t("TOP_USERS")}</h5>
            </div>
            <div className="card-body p-3">
              {topUsers && topUsers.map((user, index) => (
                <>
                  <div key={index} className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center mb-1 mb-sm-0">
                      <div className="avatar avatar-md flex-shrink-0">
                        <img className="avatar-img rounded-circle" src={user.profilePhotoId != null ? httpSmartClient.getPhotoUrl(user.profilePhotoId) : "/assets/images/avatar/01.jpg"} alt="profile picture" />
                      </div>
                      <div className=" d-inline-flex  flex-column">
                        <div className="ms-2 d-inline-flex  align-items-ceneter mt-2 mt-sm-0">
                          <h6 className="mb-0 fw-600 fs16">{user.firstName} {user.lastName}</h6>
                          {loggedUser.Id != user.id && <div className="col ps-2 d-flex flex-row  align-items-center" onClick={() => handleChatNavigate(user.id)} style={{ color: "#9400D3" }}>
                            <svg width="17" className="contact-driver cursor-pointer" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="vuesax/linear/messages-3">
                                <g id="vuesax/linear/messages-3_2">
                                  <g id="messages-3">
                                    <path id="Vector" d="M15.5846 4.42692V8.0394C15.5846 8.93899 15.2871 9.69692 14.7559 10.2211C14.2317 10.7523 13.4738 11.0498 12.5742 11.0498V12.3319C12.5742 12.8136 12.0359 13.104 11.6392 12.8348L10.9521 12.3815C11.0159 12.1619 11.0442 11.9211 11.0442 11.6661V8.78319C11.0442 7.33819 10.0809 6.37484 8.63589 6.37484H3.8263C3.72713 6.37484 3.63505 6.38193 3.54297 6.38902V4.42692C3.54297 2.62067 4.74714 1.4165 6.55339 1.4165H12.5742C14.3805 1.4165 15.5846 2.62067 15.5846 4.42692Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path id="Vector_2" d="M11.0442 8.78335V11.6662C11.0442 11.9212 11.0159 12.1621 10.9521 12.3817C10.6901 13.4229 9.82589 14.0746 8.63589 14.0746H6.70922L4.57005 15.4983C4.2513 15.7179 3.8263 15.4842 3.8263 15.1017V14.0746C3.1038 14.0746 2.50172 13.8338 2.0838 13.4158C1.6588 12.9908 1.41797 12.3887 1.41797 11.6662V8.78335C1.41797 7.43752 2.2538 6.5096 3.54297 6.38918C3.63505 6.3821 3.72713 6.375 3.8263 6.375H8.63589C10.0809 6.375 11.0442 7.33835 11.0442 8.78335Z" stroke="#900FC8" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>}
                        </div>
                        <ul className=" ms-2 list-inline smaller mb-0" key={`rating_${index}`}>
                          {[...Array(Math.floor(user.ridesAverageRating))].map((x, i) => (
                            <li key={i} className="list-inline-item me-0"><i className="fas fa-star text-warning"></i></li>
                          ))}
                          {(user.ridesAverageRating - Math.floor(user.ridesAverageRating)) >= 0.5 && [...Array(1)].map((x, i) => (
                            <li key={i} className="list-inline-item me-0"><i className="fas fa-star-half-stroke text-warning"></i></li>
                          ))}
                          {(user.ridesAverageRating - Math.floor(user.ridesAverageRating)) == 0.5 ? [...Array(Math.floor(5 - user.ridesAverageRating))].map((x, i) => (
                            <li key={i} className="list-inline-item me-0"><i className="far fa-star text-warning"></i></li>
                          )) :
                            [...Array(Math.round(5 - user.ridesAverageRating))].map((x, i) => (
                              <li key={i} className="list-inline-item me-0"><i className="far fa-star text-warning"></i></li>
                            ))}&nbsp;
                          <li key={index} className="list-inline-item me-0">({user.ridesRatingCount})</li>
                        </ul>
                      </div>
                    </div>
                    <a onClick={() => navigate(`/user/` + user.id)} className="btn btn-sm btn-light flex-shrink-0 mb-0 ms-3">{t("VIEW")}</a>
                  </div>
                  <hr />
                </>
              ))}
              {topUsers && topUsers.length == 0 && <p>{t("NO_INFORMATION_TOP_USERS")}.</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
