import { makeObservable, observable, action, computed, makeAutoObservable } from "mobx";

class ChatStore {
    chatMessages = [];
    chats = [];
    render = false;
    messageSent = false;
    hasNextPage = false;
    toggleSeen = false;
    constructor() {
        makeObservable(this, {
            chatMessages: observable,
            chats: observable,
            messageSent: observable,
            hasNextPage: observable,
            toggleSeen: observable,
            render: observable,
            setChatMessages: action,
            addMessageIntoChat: action,
            addChatMessages: action,
            pageNumberUpdate: action,
            pageNumberReset: action,
            clear: action,
            setChats: action,
            changeRender: action,
            changeUnread: action,
            changeHasNextPage: action,
            changeToggleSeen: action,
            loadNewMessages: action,
            chatNotifUpdate: action,
            loadNewChats: action,
            resetChatStore: action,
            changeMessageSent: action,
            updateProfilePhotoId: action
        });
    }
    updateProfilePhotoId(id, userId) {
        this.chatMessages = this.chatMessages.map(x => {
            return {
                ...x,
                chatMessages: {
                    ...x.chatMessages,
                    items: x.chatMessages.items.map(x => {
                        if (x.senderId == userId) {
                            return {
                                ...x,
                                sender: {
                                    ...x.sender,
                                    profilePhotoId: id
                                }
                            }
                        } else {
                            return { ...x }
                        }
                    })
                }
            }
        })
    }
    resetChatStore() {
        this.chats = []
        this.chatMessages = []
    }
    changeUnread(chatIndex) {
        this.chats.items[chatIndex].unread = 0;
    }
    changeToggleSeen() {
        this.toggleSeen = !this.toggleSeen
    }
    chatNotifUpdate(message) {
        const chatIndex = this.chats.items.findIndex(x => x.guid === message.chatGuidId)
        if (chatIndex !== -1) {
            this.chats.items[chatIndex].unread += 1
            this.chats.items[chatIndex].lastMessage = message.message
            this.chats.items[chatIndex].lastMessageSentAt = message.lastMessageSentAt
            this.chats.items.sort((a,b) =>  new Date( b.lastMessageSentAt) -  new Date(a.lastMessageSentAt));
        } else {
            const newMessage = {
                guid: message.chatGuidId,
                unread: 1,
                userOneId: message.recipientId,
                userOne: message.recipient,
                userTwoId: message.senderId,
                userTwo: message.sender,
                lastMessage: message.message,
                lastMessageSenderId: message.lastMessageSenderId,
                lastMessageSentAt: message.lastMessageSentAt,
                chatMessages: {
                    items: [message]
                }
            }
            this.chats.items.unshift({ ...newMessage })
        }
    }
    setChatMessages(chatMessages) {
        this.chatMessages = [chatMessages]
    }
    setChats(chats) {
        chats.pageNumber += 1
        this.chats = chats
    }
    changeRender() {
        this.render = !this.render
    }
    changeHasNextPage(data) {
        this.hasNextPage = data;
    }

    addChatMessages(chat) {
        chat.chatMessages.pageNumber = chat.chatMessages.pageNumber + 1;
        this.chatMessages.push(chat)
    }
    loadNewMessages(messages) {
        const index = this.chatMessages.findIndex(x => x.chatGuidId === messages.chatGuidId)
        this.chatMessages[index].chatMessages.items.unshift(...messages.chatMessages.items)
        this.chatMessages[index].chatMessages.pageNumber = messages.chatMessages.pageNumber + 1
        this.chatMessages[index].chatMessages.hasNextPage = messages.chatMessages.hasNextPage
        this.render = !this.render;
    }
    loadNewChats(chats) {
        if (this.chats.length === 0) {
            this.chats = { items: [] }
        }
        this.chats.items.push(...chats.items)
        this.chats.pageNumber = chats.pageNumber + 1;
        this.chats.hasNextPage = chats.hasNextPage
    }
    changeMessageSent() {
        this.messageSent = !this.messageSent
    }
    addMessageIntoChat(message, recipient) {
        const index = this.chatMessages.findIndex(x => x.chatGuidId === message.chatGuidId)
        if (index !== -1) {
            this.chatMessages[index].chatMessages.items.push(message)
        } else {
            const obj = {
                chatGuidId: message.chatGuidId,
                chatMessages: {
                    items: [message]
                }
            }
            this.chatMessages.push(obj);
        }
        const chatIndex = this.chats.items.findIndex(x => x.guid === message.chatGuidId)
        if (chatIndex !== -1) {
            this.chats.items[chatIndex].lastMessage = message.message
            this.chats.items[chatIndex].lastMessageSenderId = message.senderId
            this.chats.items[chatIndex].lastMessageSentAt = message.createdAt
            var element = this.chats.items.splice(chatIndex, 1)[0];
            this.chats.items.unshift(element);
        } else {
            const model = {
                userOneId: message.senderId,
                userOne: message.sender,
                userTwoId: recipient.id,
                userTwo: recipient,
                unread: 0,
                lastMessage: message.message,
                lastMessageSentAt: message.createdAt,
                lastMessageSenderId: message.senderId,
                guid: message.chatGuidId

            }
            this.chats.items.unshift(model);
        }
    }
    clear() {
        this.chat = [];
    }
    pageNumberUpdate(id) {
        const index = this.chatMessages.findIndex(x => x.chatGuidId == id)
        this.chatMessages[index].chatMessages.pageNumber++
        this.messageSent = !this.messageSent;
    }
    pageNumberReset(id) {
        const index = this.chat.findIndex(x => x.chatGuidId == id)
        this.chatMessages[index].chatMessages.pageNumber = 1
    }

}

const chatStore = new ChatStore();
export default chatStore;