import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import notificationsStore from "../stores/NotificationsStore";
import chatMessagesStore from "../stores/ChatStore";
import AuthService from "./AuthService";
import globalStore from "../stores/GlobalStore";
const chat = chatMessagesStore;
var connection = null;
const ConnectionsService = {
    connectionStart: async () => {
        try {
            var token = localStorage.getItem('token');
            connection = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_API}/base`, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => token,
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();
            await connection.start();
            return "Success";
        }
        catch (e) {
            console.log(e);
        }
    },
    connectionEnd: async () => {
        try {
            return await connection.stop();
        }
        catch (e) {
            console.log(e);
        }
    },
    InvokeDeleteMyProfile: async (id) => {
        try {
            return await connection
                .invoke("DeleteMyProfile", id)
                .then(function () {
                })
                .catch(function (err) {
                    return console.error(err.toString());
                });
        }
        catch (e) {
            console.log(e);
        }
    },
    OnDeleteMyProfile: () => {
        try {
            connection.on("OnDeleteMyProfile", (data) => {
                if (data) {
                    AuthService.signOut();
                    window.location.reload(true);
                }
            })
            return;
        }
        catch (e) {
            return false;
        }
    },
    InvokeNewReview: async (data) => {
        try {
            return await connection.invoke("NewReview", {
                Text: data.text,
                UserId: data.userId,
                SenderId: data.senderId,
                Id: data.id
            }).then(function () { })
                .catch(function (err) {
                    return console.error(err.toString());
                });;
        }
        catch (e) {
            console.log(e);
        }
    },
    OnNewReview: () => {
        try {
            if (connection === null)
                return;
            return connection.on("NewReview", (data) => {
                notificationsStore.notify(data)
            })
        }
        catch (e) {
            console.log(e);
        }
    },
    InvokeNewMessage: async (data) => {
        var recipientId = parseInt(data.RecipientId)
        var index = chat.chatMessages.findIndex(x => x.chatGuidId === data.chatGuidId)
        if (index === -1) {
            chat.addChatMessages({
                chatGuidId: data.chatGuidId,
                chatMessages: {
                    hasNextPage: true,
                    hasPreviousPage: false,
                    isFirstPage: true,
                    isLastPage: false,
                    items: [{
                        senderId: data.SenderId,
                        sender: data.Sender,
                        recipientId: recipientId,
                        recipient: data.Recipient,
                        message: data.Message
                    }],
                    pageCount: 5,
                    pageNumber: 1,
                    pageSize: 1,
                    totalCount: 65
                }
            })
        }
        await connection.invoke("NewMessage", {
            RecipientId: recipientId,
            Message: data.Message,
            SenderId: data.SenderId,
            ChatGuidId: data.ChatGuidId,
            LastMessage: data.LastMessage,
            LastMessageSentAt: data.LastMessageSentAt,
            LastMessageSenderId: data.LastMessageSenderId,
            CreatedAt: data.LastMessageSentAt
        })
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err);
            });;

    },
    OnNewMessage: () => {
        try {
            connection.on("NewMessage", (newMessage) => {
                chat.changeMessageSent();
                if (chat.chatMessages.length > 0) {
                    var index = chat.chatMessages.findIndex(x => x.chatGuidId == newMessage.chatGuidId)
                    if (index !== -1) {
                        chat.addMessageIntoChat(newMessage);

                    }
                }
                chat.chatNotifUpdate(newMessage);
            })
        }
        catch (e) {
            console.log(e);
        }
    },
    InvokeReservedRide: async (data) => {
        try {
            await connection.invoke("ReservedRide", {
                UserId: data.userId,
                UserRideId: data.userRideId,
                NotificationId: data.notificationId
            })
                .then(function () { })
                .catch(function (err) {
                    return console.error(err.toString());
                });;
        }
        catch (e) {
            console.log(e);
        }
    },
    InvokeReservedRideDriver: async (data) => {
        try {
            await connection.invoke("ReservedRideDriver", {
                UserId: data.userId,
                UserRideId: data.userRideId,
                NotificationId: data.notificationId
            })
                .then(function () { })
                .catch(function (err) {
                    return console.error(err.toString());
                });;
        }
        catch (e) {
            console.log(e);
        }
    },
    OnReservedRide: () => {
        try {
            return connection.on("ReservedRide", (notification) => {
                globalStore.changeTranCode(notification.tranCode);
                globalStore.changeRender(!globalStore.render);
                notificationsStore.notify(notification);
            });
        }
        catch (e) {
            console.log(e);
        }
    },
    OnFinalizedRide: () => {
        try {
            return connection.on("FinalizedRide", (notification) => {
                globalStore.changeTranCode(notification.tranCode);
                //globalStore.changeRender(!globalStore.render);
                globalStore.changeLastOrderIdRequestFinalizeRide(notification.lastOrderIdRequestFinalizeRide);
            });
        }
        catch (e) {
            console.log(e);
        }
    },
    OnReservedRideDriver: () => {
        try {
            return connection.on("ReservedRideDriver", (notification) => {
                notificationsStore.notify(notification);
            });
        }
        catch (e) {
            console.log(e);
        }
    },
    InvokeCancelRide: async (data) => {
        try {
            await connection.invoke("CancelRide", {
                UserId: data?.userId,
                NotificationId: data?.notificationId
            })
                .then(function () { })
                .catch(function (err) {
                    return console.error(err.toString());
                });;
        }
        catch (e) {
            console.log(e);
        }
    },
    OnCancelRide: () => {
        try {
            return connection.on("CancelRide", (notification) => {
                notificationsStore.notify(notification);
            });
        }
        catch (e) {
            console.log(e);
        }
    }

}
export default ConnectionsService;