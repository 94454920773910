import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import userProfileStore from "data/stores/UserProfileStore";
import Modal from "react-responsive-modal";
import globalStore from "data/stores/GlobalStore";
import VehiclesModal from "components/modules/public/rides/VehiclesModal";
import { UnFinalizedRideModal } from "components/modules/public/rides";
import { RidesService, UserVehiclesService, UsersService } from "data/services";

export default function DriverInformation() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
	const [unFinalizedRideModalOpen, setUnFinalizedRideModalOpen] = useState(false);
	const [notVerifiedModalOpen, setNotVerifiedModalOpen] = useState(false);
	const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);
	const [noVehicleFounded, setNoVehicleFounded] = useState(false);
	const [unfinalizedRides, setUnfinalizedRides] = useState(false);
	var user = userProfileStore.user;

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 1200);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const addVehicles = () => {
		setMilestoneModalOpen(false);
		setNoVehicleFounded(false);
		navigate("/my-profile/my-vehicles");
	}

	const onUnfinalizedConfirmClick = () => {
		setUnFinalizedRideModalOpen(false);
		setUnfinalizedRides(false);
		navigate("/my-profile/my-rides");
	}

	const NavigateToPostARide = async () => {
		if (JSON.parse(localStorage.getItem('Logged')) == true) {
			const userVehicles = await UserVehiclesService.getCountForLoggedUser();
			const LoggUser = await UsersService.getById(user.id);
			const id = LoggUser.data.id;
			const driverHasUnfinalizedRides = await RidesService.getUnfinalizedCountByDriverid(id);
			if (!LoggUser.data.isVerifiedPhoneNumber) {
				setNotVerifiedModalOpen(true);
				return;
			}
			if (userVehicles.data === 0) {
				setMilestoneModalOpen(true);
				setNoVehicleFounded(true);
			} else if (driverHasUnfinalizedRides.data >= 2) {
				setUnFinalizedRideModalOpen(true);
				setUnfinalizedRides(true);
			} else {
				setNoVehicleFounded(false);
				setUnfinalizedRides(false);
				navigate("/rides/create");
			}
		}
		else
			navigate('/sign-in');
	}

	return (
		<>
			{!isMobile ? <div className="container mt-5">
				<div className="row">
					<div className="col-3"></div>
					<div className="col-lg rounded-3 me-3 ms-3 position-relative">
						<img src="assets/images/myImages/woman.png" className="rounded-3 " alt="" style={{ objectFit: "cover", height: "500px", width: "900px" }} />
						<div className="col-8 col-md-6 col-lg-4 col-xl-10 position-lg-middle ms-lg-8 ms-xl-7">
							<div className="card pb-0 mt-n7 mt-sm-n8 mt-lg-0" style={{ width: "530px", boxShadow: "rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
								<div className="card-header" style={{ backgroundColor: "white", borderRadius: '15px', boxShadow: "rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
									<div className="card card-body" style={{ backgroundColor: "white", padding: "0px" }}>
										<div className="col">
											<h4 className="text mb-0" style={{ fontSize: "22px", fontWeight: "bold", color: "black" }}>{t("DRIVE_WHERE")}</h4>
										</div>
										<div className="row mt-3">
											<div className="col-auto" style={{ marginTop: "70px" }}>
												<h5 style={{ fontSize: "20px", fontWeight: "bold", color: "black" }}>{t("CITY_A")}</h5>
											</div>
											<div className="col">
												<img src="assets/images/car_home_page.png" className="rounded-3" alt="" />
											</div>
											<div className="col-auto" style={{ marginTop: "70px" }}>
												<h5 style={{ fontSize: "20px", fontWeight: "bold", color: "black" }}>{t("CITY_B")}</h5>
											</div>
										</div>
										<div className="col">
											<div className="d-flex align-items-center">
												<p className="mt-4" style={{ fontSize: "15px" }}>{t("BECOME_DRIVER_DESCRIPTION")}</p>
											</div>
										</div>
										<div className="row mt-4">
											<div className="col"></div>
											<div className="col-auto">
												<button className="btn btn-primary" style={{ width: "280px" }} onClick={() => NavigateToPostARide()}>
													<div className="row">
														<div className="col-1">
															<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g id="vuesax/linear/driving">
																	<g id="driving">
																		<g id="Group">
																			<path id="Vector" d="M14.5502 2H9.4502C7.6502 2 7.2502 2.90001 7.0202 4.01001L6.2002 7.92999H17.8002L16.9802 4.01001C16.7502 2.90001 16.3502 2 14.5502 2Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			<g id="Group_2">
																				<path id="Vector_2" d="M19.2401 14.3199C19.3201 15.1699 18.6401 15.9 17.7701 15.9H16.4101C15.6301 15.9 15.5201 15.57 15.3801 15.15L15.23 14.7199C15.03 14.1299 14.9001 13.7299 13.8501 13.7299H10.1401C9.10005 13.7299 8.94005 14.1799 8.76005 14.7199L8.61005 15.15C8.47005 15.56 8.36006 15.9 7.58006 15.9H6.22005C5.35005 15.9 4.67005 15.1699 4.75005 14.3199L5.16006 9.89996C5.26006 8.80996 5.47005 7.91992 7.37005 7.91992H16.62C18.52 7.91992 18.7301 8.80996 18.8301 9.89996L19.2401 14.3199Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			</g>
																			<path id="Vector_3" d="M6.20021 5.75H5.47021" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			<path id="Vector_4" d="M18.5303 5.75H17.8003" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			<path id="Vector_5" d="M7.6499 10.8301H9.81992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			<path id="Vector_6" d="M14.1802 10.8301H16.3502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																		</g>
																		<path id="Vector_7" d="M12 17V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																		<path id="Vector_8" d="M12 21V22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																		<path id="Vector_9" d="M3 18L2 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																		<path id="Vector_10" d="M21 18L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																	</g>
																</g>
															</svg>
														</div>
														<div className="col-auto">
															<span style={{ fontWeight: "400" }}>{t("POST_RIDE")}</span>
														</div>
														<div className="col" style={{ marginRight: "-50px", marginTop: "-2px" }}>
															<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
															</svg>
														</div>
													</div>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> :
				<div className="container mt-5">
					<div className="row">
						<div className="col-lg rounded-3 position-relative" style={isMobile ? { paddingLeft: "18.5px", paddingRight: "18.5px" } : {}}>
							<img src="assets/images/myImages/woman.png" className="rounded-3" alt="" style={{ objectFit: "cover" }} />
							<div className="col" style={{ marginTop: "-30px" }}>
								<div className="card shadow " >
									<div className="card-header" style={{ backgroundColor: "white", borderRadius: '25px', boxShadow: "rgba(29, 58, 83, 0.1) 0px 10px 20px" }}>
										<div className="card card-body" style={{ backgroundColor: "white", padding: "0px" }}>
											<div className="col">
												<h4 className="text mb-0" style={{ fontSize: "22px", fontWeight: "bold", color: "black" }}>{t("DRIVE_WHERE")}</h4>
											</div>
											<div className="row mt-3">
												<div className="col-auto" style={{ marginTop: "70px" }}>
													<h5 style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>{t("CITY_A")}</h5>
												</div>
												<div className="col">
													<img src="assets/images/car_home_page.png" className="rounded-3" alt="" />
												</div>
												<div className="col-auto" style={{ marginTop: "70px" }}>
													<h5 style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>{t("CITY_B")}</h5>
												</div>
											</div>
											<div className="col">
												<div className="d-flex align-items-center">
													<p className="mt-4" style={{ fontSize: "15px" }}>{t("BECOME_DRIVER_DESCRIPTION")}</p>
												</div>
											</div>
											<div className="row mt-4">
												<div className="col-auto m-auto">
													<button className="btn btn-primary" style={{ width: "320px" }} onClick={() => NavigateToPostARide()}>
														<div className="row">
															<div className="col-1">
																<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<g id="vuesax/linear/driving">
																		<g id="driving">
																			<g id="Group">
																				<path id="Vector" d="M14.5502 2H9.4502C7.6502 2 7.2502 2.90001 7.0202 4.01001L6.2002 7.92999H17.8002L16.9802 4.01001C16.7502 2.90001 16.3502 2 14.5502 2Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																				<g id="Group_2">
																					<path id="Vector_2" d="M19.2401 14.3199C19.3201 15.1699 18.6401 15.9 17.7701 15.9H16.4101C15.6301 15.9 15.5201 15.57 15.3801 15.15L15.23 14.7199C15.03 14.1299 14.9001 13.7299 13.8501 13.7299H10.1401C9.10005 13.7299 8.94005 14.1799 8.76005 14.7199L8.61005 15.15C8.47005 15.56 8.36006 15.9 7.58006 15.9H6.22005C5.35005 15.9 4.67005 15.1699 4.75005 14.3199L5.16006 9.89996C5.26006 8.80996 5.47005 7.91992 7.37005 7.91992H16.62C18.52 7.91992 18.7301 8.80996 18.8301 9.89996L19.2401 14.3199Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																				</g>
																				<path id="Vector_3" d="M6.20021 5.75H5.47021" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																				<path id="Vector_4" d="M18.5303 5.75H17.8003" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																				<path id="Vector_5" d="M7.6499 10.8301H9.81992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																				<path id="Vector_6" d="M14.1802 10.8301H16.3502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			</g>
																			<path id="Vector_7" d="M12 17V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			<path id="Vector_8" d="M12 21V22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			<path id="Vector_9" d="M3 18L2 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																			<path id="Vector_10" d="M21 18L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																		</g>
																	</g>
																</svg>
															</div>
															<div className="col-auto">
																<span style={{ fontWeight: "400" }}>{t("POST_RIDE")}</span>
															</div>
															<div className="col" style={{ marginRight: "-90px", marginTop: "-2px" }}>
																<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M8.91016 19.92L15.4302 13.4C16.2002 12.63 16.2002 11.37 15.4302 10.6L8.91016 4.07996" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
																</svg>
															</div>
														</div>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			{noVehicleFounded &&
				<>
					<Modal
						open={milestoneModalOpen}
						onClose={() => setMilestoneModalOpen(false)}
						closeIcon={globalStore.closeIcon}
						center
						classNames={
							{
								modal: "",
								closeButton: "mt-2"
							}
						}
					>
						<VehiclesModal
							onDiscard={() => setMilestoneModalOpen(false)}
							title={t("INFO")}
							description={t("ERROR_NO_VEHICLES")}
							confirmBtnLabel={t("MY_NEW_VEHICLE")}
							onConfirm={() => addVehicles()}
						></VehiclesModal>
					</Modal>
				</>
			}
			{unfinalizedRides &&
				<>
					<Modal
						open={unFinalizedRideModalOpen}
						onClose={() => setUnFinalizedRideModalOpen(false)}
						closeIcon={globalStore.closeIcon}
						center
						classNames={
							{
								modal: "",
								closeButton: "mt-2 me-1"
							}
						}
					>
						<UnFinalizedRideModal
							onDiscard={() => setUnFinalizedRideModalOpen(false)}
							title={t("WARNING")}
							description={t("MY_UNFINALIZED_RIDES")}
							confirmBtnLabel={t("SHOW_MY_ACTIVE_RIDES")}
							onConfirm={() => onUnfinalizedConfirmClick()}
						></UnFinalizedRideModal>
					</Modal>
				</>
			}
			<>
				<Modal
					open={notVerifiedModalOpen}
					onClose={() => setNotVerifiedModalOpen(false)}
					closeIcon={globalStore.closeIcon}
					center
					classNames={
						{
							modal: "",
							closeButton: "mt-2 me-1"
						}
					}
				>
					<UnFinalizedRideModal
						onDiscard={() => setNotVerifiedModalOpen(false)}
						title={t("INFO")}
						description={t("PHONE_NOT_VERIFIED_MESSAGE")}
						confirmBtnLabel={t("GO_VERIFY")}
						onConfirm={() => { setNotVerifiedModalOpen(false); navigate("my-profile") }}
					></UnFinalizedRideModal>
				</Modal>
			</>
		</>
	);
}