import { useEffect, useState } from "react";
import { VehicleBrandsService } from "data/services";
import { toastify } from "utils/toastify";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "components/modules/public/dialogs";
import { getAppPagingSettings } from "config/paging";
import { PagingComponent } from "components/layout/components";
import VehicleBrandAddModal from "./modals/VehicleBrandAddModal";
import VehicleBrandEditModal from "./modals/VehicleBrandEditModal";

export default function VehicleBrandsPage() {
  let pagingSettings = getAppPagingSettings();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [pagingData, setPagingData] = useState(pagingSettings);
  const [filters, setFilters] = useState();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fetchVehicleBrands, setFetchVehicleBrands] = useState(false);

  const fetchAndSetState = async () => {
    const vehicleBrandsList = await VehicleBrandsService.getByParams({ pageNumber: pagingData.pageNumber, pageSize: pagingData.pageSize, name: filters && filters.brandName ? filters.brandName : null });
    setData(vehicleBrandsList.data);
  };

  useEffect(() => {
    fetchAndSetState();
  }, [pagingData.pageNumber, pagingData.pageSize, filters, fetchVehicleBrands]);

  const onPageChange = (page) => {
    pagingData.pageNumber = page;
    setPagingData({ ...pagingData });
  }

  const handleDelete = (id) => {
    setShowConfirmationDialog(true);
    setCurrentId(id);
  };
  const deleteVehicleBrand = async (id) => {
    const deleted = await VehicleBrandsService.delete(id);
    if (deleted.isSuccess) {
      setFetchVehicleBrands(!fetchVehicleBrands);
      toastify.success(t("SUCCESSFULLY_DELETED_VEHICLE_BRAND"));
      setShowConfirmationDialog(false);
    } else {
      toastify.error(t("ERROR_DELETING_VEHICLE_BRAND"));
    }
  };

  const onSuccess = (isEdit) => {
    if (isEdit) {
      setShowEditModal(false);
    }
    else {
      setShowAddModal(false);
    }
    setFetchVehicleBrands(!fetchVehicleBrands);
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mb-4 mb-sm-4">
          <div className="d-sm-flex justify-content-between align-items-center">
            <h1 className="h4 mb-3 mb-sm-0">{t("VEHICLE_BRANDS")}</h1>
            <div className="d-grid">
              <a className="btn btn-primary-soft" onClick={() => setShowAddModal(true)}>
                + {t("NEW_VEHICLE_BRAND")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-body pb-0 mb-3">
          <div className="mb-4 col col-md-4 col-sm-12">
            <form className="rounded position-relative">
              <input style={{ height: "38px", borderRadius: "5px" }} className="form-control bg-transparent" type="text" placeholder={t("SEARCH")} aria-label="Search" onChange={(e) => setFilters({ ...filters, brandName: e.target.value })} />
              <button className="bg-transparent p-2 position-absolute top-50 end-0 translate-middle-y border-0 text-primary-hover text-reset" type="submit">
                <i className="fas fa-search fs-6"></i>
              </button>
            </form>
          </div>
          <div className="bg-light rounded p-3 d-none d-lg-block">
            <div className="row row-cols-7 g-4">
              <div className="col">
                <h6 className="mb-0">{t("NAME")}</h6>
              </div>
              <div className="col">
                <h6 className="float-end mb-0">{t("ACTION")}</h6>
              </div>
            </div>
          </div>
          {(!data.items || data.items.length === 0) && (
            <div className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col text-center">{t("NO_RESULTS")}</div>
            </div>
          )}
          {data && data.items && data.items.map((vehicleBrand, index) => (
            <div key={index} className="row row-cols-xl-7 align-items-lg-center border-bottom g-4 px-2 py-4">
              <div className="col">
                <small className="d-block d-lg-none">{t("NAME")}:</small>
                <h6 className="mb-0 fw-normal">{vehicleBrand.name}</h6>
              </div>

              <div className="col-auto">
                <button className="btn btn-sm btn-primary-soft me-2" onClick={() => { setCurrentId(vehicleBrand.id); setShowEditModal(true); }}>
                  <i className="bi bi-pencil-square"></i>
                </button>
                <button className="btn btn-sm btn-danger-soft border border-danger" onClick={() => handleDelete(vehicleBrand.id)}>
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
        <PagingComponent
          className="pagination-bar"
          currentPage={data.pageNumber ?? 0}
          totalCount={data.totalCount ?? 0}
          pageSize={data.pageSize ?? 0}
          onPageChange={onPageChange}
        />
      </div>
      {showConfirmationDialog && <ConfirmationDialog
        onDiscard={() => setShowConfirmationDialog(false)}
        title={t("DELETE_VEHICLE_BRAND_ITEM")}
        description={t("DELETE_VEHICLE_BRAND")}
        confirmBtnLabel={t("DELETE")}
        onConfirm={() => deleteVehicleBrand(currentId)}
      ></ConfirmationDialog>}

      {showAddModal && <VehicleBrandAddModal
        onDiscard={() => setShowAddModal(false)}
        title={t("NEW_VEHICLE_BRAND")}
        description={t("VEHICLE_BRAND_INFORMATION")}
        confirmBtnLabel={t("SAVE")}
        onSuccess={() => { onSuccess() }}
      ></VehicleBrandAddModal>}

      {showEditModal && <VehicleBrandEditModal
        onDiscard={() => setShowEditModal(false)}
        title={t("EDIT_VEHICLE_BRAND")}
        description={t("VEHICLE_BRAND_INFORMATION")}
        confirmBtnLabel={t("SAVE")}
        onSuccess={() => { onSuccess(true) }}
        vehicleBrandId={currentId}
      ></VehicleBrandEditModal>}
    </>
  );
}