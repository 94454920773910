import React, { useEffect, useState } from "react"
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api"
import { useTranslation } from "react-i18next"
import toHoursAndMinutes from "utils/timeFormatter/TimeFormatter"

export default function GoogleRoutesMobile({
  width,
  height,
  formData,
  setFormData,
  selectedRouteIndex,
  setSelectedRouteIndex,
  selectedSummary,
  setSelectedSummary,
  routeKilometers,
  setRouteKilometers,
  routeError,
  setRouteError,
  zeroResults,
  setZeroResults,
  rows,
  setRows,
  step,
  refreshRecommendedPrice,
  setRefreshRecommendedPrice,
  pricePerKilometers,
  priceError,
  totalPrice,
  recalculateTotalPrice
}) {
  const { t } = useTranslation()
  const [directions, setDirections] = useState(null);
  const [rerender, setRerender] = useState(0);
  const [currentIndex, setCurrentIndex] = useState();
  const [screenSize, setScreenSize] = useState(null);

  const containerStyle = {
    width: width,
    height: height,
    borderRadius: "20px",
    zIndex: "9",
    margin: "-50px 0 auto 0"
  }

  let totalDistance = 0;
  let totalDuration = 0;

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 450) {
        setScreenSize('small');
      } else {
        setScreenSize('large');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setFormData((prevObj) => ({ ...prevObj, selectedRouteIndex: selectedRouteIndex, routeKilometers: routeKilometers, summary: selectedSummary, rows: rows, price: totalPrice }))
  }, [selectedRouteIndex, routeKilometers, selectedSummary, rows, totalPrice])

  useEffect(() => {
    const newArr = []
    recalculateTotalPrice();
    formData.rows.filter(r => r.isSubmitted).forEach((milestone, index) => {
      if (index !== 0 && index !== formData.rows.length - 1) {
        newArr.push({
          location: { lat: milestone.lat, lng: milestone.lng },
        })
      }
    })

    const getDirections = async () => {
      try {
        const directionsOptions = {
          origin: { lat: formData.rows[0].lat, lng: formData.rows[0].lng },
          destination: { lat: formData.rows[formData.rows.length - 1].lat, lng: formData.rows[formData.rows.length - 1].lng },
          waypoints: [...newArr],
          travelMode: "DRIVING",
          provideRouteAlternatives: true,
        }

        const directionsService = new window.google.maps.DirectionsService()
        directionsService.route(directionsOptions, (result, status) => {
          if (status === "ZERO_RESULTS") {
            setZeroResults(true)
            return
          } else if (status === "OK") {
            result.routes.map((route) => {
              if (result.routes.length === 1) {
                setSelectedRouteIndex(0);
                setSelectedSummary(route.summary);
                calculateLegsDistance(route, false, refreshRecommendedPrice);
                recalculateTotalPrice();
                setRefreshRecommendedPrice(false);
              }
              route.legs.map((leg) => {
                if (route.legs.length === 1) {
                  totalDistance = leg.distance.value;
                } else {
                  totalDistance += leg.distance.value;
                }
                totalDuration += leg.duration.value;
              })
              route.totalDistance = (totalDistance / 1000).toFixed(2);
              setRouteKilometers(route.totalDistance);
              route.totalDuration = toHoursAndMinutes(totalDuration);
            })
            setDirections(result)
          }
        })
      } catch (error) {
        throw error
      }
    }
    getDirections()
    window.scrollTo(0, 0)
  }, []);

  const calculateLegsDistance = (route, forceSet = false, refreshRecommendedPrice = false) => {
    let copy = [...rows];
    route.legs.map((leg, index) => {
      if (!copy[index + 1].price || forceSet || refreshRecommendedPrice) {
        if (pricePerKilometers === 0) {
          copy[index + 1].price = 0;
        } else {
          copy[index + 1].price = Math.round((leg.distance.value / 1000) * pricePerKilometers);
        }
      }
    });
    setRows(copy);

  }

  useEffect(() => {
    setCurrentIndex(selectedRouteIndex)
  }, [directions])

  useEffect(() => {
    setRouteError(null)
  }, [selectedRouteIndex])

  const onChangeRoute = (e, route, routeIndex) => {
    calculateLegsDistance(route, true);
    recalculateTotalPrice();
    setSelectedRouteIndex(routeIndex);
    setRouteKilometers(route.totalDistance);
    setSelectedSummary(route.summary);
    setCurrentIndex(routeIndex);
    setRerender((prev) => prev + 1);
  }


  const decrementCount = (index) => {
    if (rows) {
      const updatedRows = [...rows];
      if (updatedRows[index + 1].price > 0) {
        updatedRows[index + 1].price -= 1;
      }
      setRows(updatedRows);
    }
    recalculateTotalPrice();
  }

  const incrementCount = (index) => {
    if (rows) {
      const updatedRows = [...rows];
      updatedRows[index + 1].price += 1;
      setRows(updatedRows);
    }
    recalculateTotalPrice();
  }

  return (
    <>
      {!zeroResults ? (
        <>
          <div className="card bg-light d-block backgroundCardMobile">
            <div className="card-body z-index-master googleRoutesCardMobile">
              <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={8}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                <div key={rerender}>
                  {directions &&
                    directions.routes &&
                    directions.routes.map((route, index) => (
                      <DirectionsRenderer
                        key={index}
                        directions={directions}
                        routeIndex={index}
                        options={{
                          polylineOptions: {
                            strokeColor: selectedRouteIndex === index ? "#039be5" : "grey",
                            strokeOpacity: selectedRouteIndex === index ? 1.0 : 0.6,
                            strokeWeight: selectedRouteIndex === index ? 5 : 3,
                          },
                        }}
                      />
                    ))}
                </div>
              </GoogleMap>
            </div>

            <div className="fade show card shadow googleRoutesMobileCard">
              {step === 2 && <>
                <p className="px-3 pt-3 py-1 mt-4 ms-2  fw-500 fs14 black">{t("FOUND_ROUTES")}</p>
                {directions && directions.routes.map((route, index) => {
                  return (
                    <div onClick={(e) => onChangeRoute(e, route, index)}>
                      <div className="form-check cursor-pointer my-2 ms-1">
                        <input
                          className="form-check-input directionChecked my-3 me-3 ms-0"
                          type="radio"
                          checked={currentIndex === index ? `radioBtn_${index}` : ""}
                          name="googleRouteMobile"
                          id={`radioBtn_${index}`}
                          readOnly
                        />
                        <p className={`form-check-label d-inline fs14 fw-500 route ${currentIndex === index ? 'checkedRoute' : ""}`} htmlFor={`radioBtn_${index}`}>
                          {t("ROUTE")} {route.summary}
                        </p>
                        <label className="colorGray  d-block cursor-pointer" htmlFor={`radioBtn_${index}`}>
                          <ul className="nav nav-divider">
                            <li className="nav-item fs12 gray">{route.totalDuration} </li>
                            <li className="nav-item fs12 gray">{route.totalDistance} {t("KILOMETERS")} </li>
                          </ul>
                        </label>
                      </div>
                    </div>
                  )
                })}
                {routeError && <div className="alert alert-danger me-4 ms-4 text-danger" role="alert"><i className="fa-solid fa-circle-exclamation me-2 dangerIcon"></i>{t("ROUTE_ERROR")}</div>}
              </>}
            </div>

            {step === 3 &&
              <>
                <div className="fade show card shadow googleRoutesMobileCard mt-5">
                  <div className="scrollable-div">
                    <div className="row col-12">
                      <p className="mb-4 mt-5 ms-4 fs14 fw-500 black">{t("PRICE_FOR_MILESTONES")} {selectedSummary}</p>
                    </div>
                    {rows && rows.map((row, index) => {
                      if (index !== rows.length - 1) {
                        return (
                          <div>
                            <div className="mx-4">
                              <div className={`  row  mb-3`}>
                                <div className="col-6 d-flex align-items-center mt-1">
                                  <div className="d-grid">
                                    <p className="fs14 black fw-500">{rows[index].cityName}</p>
                                  </div>
                                  <img width={"13px"} height={"9px"} className=" mx-2 " src="/assets/images/arrowRight.svg" alt="arrowRight" />
                                  <div className="d-grid">
                                    <p className=" fs14 black fw-500">{rows[index + 1].cityName}</p>
                                  </div>
                                </div>
                                <div className={` col-6 d-flex align-items-center pe-0 justify-content-end  `} >
                                  <span
                                    type="button"
                                    className="minus  mx-2"
                                    onClick={() => decrementCount(index)}
                                  ><i className="fa-solid fa-minus passengersCount d-flex justify-content-evenly"></i></span>
                                  <p className="textDark d-inline-block d-flex justify-content-end mb-0 fs14 me-1 valuteWidthMobile">{rows && rows[index + 1].price && rows[index + 1].price}</p>
                                  <p className="fw-500 mt-0 fs14 gray">{t("VALUTE")}</p>
                                  <span
                                    type="button"
                                    className="plus mx-2"
                                    onClick={() => incrementCount(index)}
                                  ><i className="fa-solid fa-plus passengersCount d-flex justify-content-evenly"></i></span>
                                </div>
                              </div>
                            </div>
                            {index + 1 !== rows.length - 1 && <hr className="mt-3 hrStyle me-4 ms-4" />}
                          </div>
                        )
                      }
                    })}
                  </div>
                  {priceError && <div className="alert alert-danger mt-2 text-danger ms-4 me-4" role="alert"><i className="fa-solid fa-circle-exclamation me-2 dangerIcon"></i>{t("PRICE_ERROR")}</div>}

                  <div className="mb-3 d-flex justify-content-md-between line-at-top">
                    <p className="gray fs14 col-auto me-auto ms-4  marginTopTotalPriceSpan fw-500">{t("TOTAL_PRICE_PER_PASSENGER")}</p>
                    <div className="me-4 priceSize mt-3">
                      <p className=" me-2 fs18 d-inline fw-600 purple">{totalPrice}</p>
                      <p className="fw-600 fs18 d-inline gray">{t("VALUTE")}</p>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-content-center mb-4 mt-6">
            <img src="/assets/images/relation_not_found.svg" width="300" />
          </div>
          <h5 className="routeError">{t("ZERO_ROUTE_RESULTS1")}</h5>
          <div className="text-wrapper" style={{ width: "100%", textAlign: "center" }}>{t("ZERO_ROUTE_RESULTS2")}</div>
        </>
      )}
    </>
  )
}
