import React, { useEffect, useState, useTransition } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import globalStore from "../../../../data/stores/GlobalStore";
import { observer } from "mobx-react-lite";

const ApexChart = observer(({ props }) => {
    const [chartInfo, SetChartInfo] = useState();
    const [theme, setTheme] = useState();
    let months;
    const { t } = useTranslation();
    const setMonths = () => {
        months = [];
        props.months.forEach(element => {
            months.push(t(`MONTHS.${element}`).substring(0, 3))
        });
    }
    useEffect(() => {
        setTheme(localStorage.getItem('theme'))
    }, [globalStore.theme])
    useEffect(() => {
        setMonths();
        SetChartInfo(
            {
                series: [{
                    name: t("INCOME"),
                    data: props.income
                }],
                options: {
                    chart: {
                        type: 'area',
                        height: 350,
                        zoom: {
                            enabled: false
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },

                    stroke: {
                        curve: 'smooth'
                    },
                    colors: ['#9400D3', '#E91E63', '#9C27B0'],
                    title: {
                        text: t("YEARLY_INCOME"),
                        align: 'left',
                        style: {
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "25px",
                            color: theme == 'dark' ? "white" : "black"
                        }
                    },
                    subtitle: {
                        text: t("PRICE_MOVEMENTS"),
                        align: 'left'
                    },
                    labels: months,
                    xaxis: {
                        type: 'dateTime',
                    },
                    yaxis: {
                        opposite: true
                    },
                    legend: {
                        horizontalAlign: 'left'
                    }
                },
            })
    }, [localStorage.getItem('i18nextLng'), theme]);

    return (

        <div className="col-lg-6 col-xxl-6 mb-4 " id="chart">
            <div className="card ms-1 shadow p-4 h-100">
            {chartInfo && <ReactApexChart className={theme === 'dark' ? "text-white" : "text-dark"} options={chartInfo.options} series={chartInfo.series} type="area" height={350} />}
            </div>
        </div>

    );
})
export default ApexChart