import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import DriverCompleteProfile from './driverChunks/DriverCompleteProfile';
import DriverPersonalInfo from './driverChunks/DriverPersonalInfo';
import driverProfileStore from 'data/stores/DriverProfileStore';
import { UsersService } from 'data/services';
import { httpSmartClient } from 'config/httpClient';

export default function UserProfilePage() {
	const navigate = useNavigate();
	const { driverId } = useParams();
	const [driver, setDriver] = useState();
	useEffect(() => {
		const fetch = async () => {
			let response = await UsersService.getById(driverId);
			if (response.isSuccess) {
				let dri = response.data;
				if (dri.profilePhoto) {
					dri.profilePhotoUrl = httpSmartClient.getPhotoUrlObjectFromBase64(dri.profilePhoto);
				}
				else if (dri.profilePhotoId) {
					dri.profilePhotoUrl = httpSmartClient.getPhotoUrl(dri.profilePhotoId);
				}
				setDriver(dri);
			}
			else {
				navigate("/sign-in");
			}
		}
		fetch();
	}, []);
	useEffect(() => {
		driverProfileStore.getDriverData(driver);
	}, [driver]);

	return (
		<>
			<main>
				<section className="pt-3">
					<div className="container">
						<div className="row">
							<div className="d-grid mb-0 d-lg-none w-100">
								<button className="btn btn-primary mb-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
									<i className="fas fa-sliders-h"></i> Menu
								</button>
							</div>
							<div className="vstack gap-4">
								<DriverCompleteProfile />
								<DriverPersonalInfo />
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	)
}
