import React from 'react'
import { observer } from 'mobx-react-lite';
import './myprofile.css'
import "./../../../layout/common.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import userProfileStore from 'data/stores/UserProfileStore';
import { ConnectionsService, AuthService } from 'data/services';

const MyProfileSidebar = observer(() => {
	const { t } = useTranslation();
	const user = userProfileStore.user;
	const navigate = useNavigate();
	const location = useLocation();
	const signOut = () => {
		AuthService.signOut();
		ConnectionsService.connectionEnd();
		navigate("/sign-in");
	}
	return (
		<div className="profile-sidebar">
			<div style={{maxHeight:"700px", marginLeft:"24px"}} className="card h-100 w-100">
				<div className="card-body p-3">
					<div className="text-center mb-3">
						<div className="avatar avatar-xl mb-2">
							<img className={`avatar-img rounded-circle ${user.isVerifiedPhoneNumber ? 'profile__photo__isVerified' : ''} border border-2 border-white`} src={user.profilePhotoId != null ? user.profilePhotoUrl : "/assets/images/avatar/01.jpg"} />
							{user.isVerifiedPhoneNumber && <img style={{ height: "17px", position: "absolute", top: "60px", left: "60px" }} src='../../assets/images/verified.png'></img>}
						
						</div>
						<p className="mb-0 black fs16 fw-500">{user.firstName + " " + user.lastName} </p>
						<p  className="text-reset mt-0 fs12 gray ">{user.email}</p>
						<hr  style={{border: "0.5px solid #DDD"}}></hr>

					</div>

					<ul style={{height:"70%"}} className="nav nav-pills-primary-soft flex-column justify-content-between ">
						<li className='nav-item d-flex flex-column '>
							<div  className={`${location.pathname === '/my-profile' ? "active" : "routes "} py-2 mb-2 ps-2 link cursor-pointer`} onClick={() => navigate('/my-profile')}>
								<svg xmlns="http://www.w3.org/2000/svg" className='me-1' width="21" height="21" viewBox="0 0 21 21" fill="none">
									<path d="M10.6488 9.27273C10.5557 9.2634 10.4439 9.2634 10.3414 9.27273C8.12415 9.19811 6.3634 7.37942 6.3634 5.14103C6.3634 2.856 8.208 1 10.4998 1C12.7822 1 14.6361 2.856 14.6361 5.14103C14.6268 7.37942 12.8661 9.19811 10.6488 9.27273Z" stroke={`${location.pathname === '/my-profile' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M5.72984 12.9535C3.42339 14.5078 3.42339 17.0407 5.72984 18.5854C8.35081 20.3508 12.6492 20.3508 15.2702 18.5854C17.5766 17.0311 17.5766 14.4982 15.2702 12.9535C12.6587 11.1978 8.36034 11.1978 5.72984 12.9535Z" stroke={`${location.pathname === '/my-profile' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<span >{" " + t('MY_PROFILE')}</span>
							</div>
							<div  className={`${location.pathname === '/my-profile/my-rides' ? "active" : "routes"} py-2 ps-2  mb-2 link cursor-pointer`} onClick={() => navigate('/my-profile/my-rides')}>
								<svg xmlns="http://www.w3.org/2000/svg" className='me-1' width="21" height="21" viewBox="0 0 21 21" fill="none">
									<path d="M13.5709 2.47656H7.42838C5.24963 2.47656 4.76838 3.56156 4.48838 4.89156L3.49963 9.62531H17.4996L16.5109 4.89156C16.2309 3.56156 15.7496 2.47656 13.5709 2.47656Z" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M19.2418 17.3425C19.338 18.3662 18.5155 19.25 17.4655 19.25H15.8205C14.8755 19.25 14.7443 18.8475 14.578 18.3487L14.403 17.8238C14.158 17.1063 14.0005 16.625 12.7405 16.625H8.2605C7.0005 16.625 6.81675 17.1675 6.598 17.8238L6.423 18.3487C6.25675 18.8475 6.1255 19.25 5.1805 19.25H3.5355C2.4855 19.25 1.663 18.3662 1.75925 17.3425L2.24925 12.0137C2.37175 10.7012 2.6255 9.625 4.918 9.625H16.083C18.3755 9.625 18.6293 10.7012 18.7518 12.0137L19.2418 17.3425Z" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M3.5 7H2.625" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M18.3754 7H17.5004" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M10.5 2.625V4.375" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M9.1875 4.375H11.8125" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M5.25 13.125H7.875" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M13.125 13.125H15.75" stroke={`${location.pathname === '/my-profile/my-rides' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<span>{" " + t('MY_RIDES')}</span>
							</div>
							<div  className={` ${location.pathname === '/my-profile/my-vehicles' ? "active" : "routes"} py-2  ps-2 mb-2 link cursor-pointer`} onClick={() => navigate('/my-profile/my-vehicles')}>
								<svg xmlns="http://www.w3.org/2000/svg" className='me-1' width="21" height="21" viewBox="0 0 21 21" fill="none">
									<path d="M12.7313 1.75H8.2688C6.6938 1.75 6.34381 2.53751 6.14256 3.50876L5.42505 6.93874H15.5751L14.8575 3.50876C14.6563 2.53751 14.3063 1.75 12.7313 1.75Z" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M16.835 12.5297C16.905 13.2735 16.31 13.9122 15.5487 13.9122H14.3587C13.6762 13.9122 13.58 13.6235 13.4575 13.256L13.3262 12.8797C13.1512 12.3634 13.0375 12.0134 12.1187 12.0134H8.87249C7.96249 12.0134 7.82248 12.4072 7.66498 12.8797L7.53374 13.256C7.41124 13.6147 7.31499 13.9122 6.63249 13.9122H5.44249C4.68124 13.9122 4.08624 13.2735 4.15624 12.5297L4.51499 8.66222C4.60249 7.70847 4.78623 6.92969 6.44873 6.92969H14.5425C16.205 6.92969 16.3887 7.70847 16.4762 8.66222L16.835 12.5297Z" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M5.425 5.03125H4.78625" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M16.2137 5.03125H15.575" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M6.69373 9.47656H8.59249" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M12.4075 9.47656H14.3062" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M10.5 14.875V15.75" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M10.5 18.375V19.25" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M2.625 15.75L1.75 19.25" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M18.375 15.75L19.25 19.25" stroke={`${location.pathname === '/my-profile/my-vehicles' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<span>{" " + t('MY_VEHICLES')}</span>
							</div>
							<div  className={` ${location.pathname === '/my-profile/my-travel-preference' ? "active" : "routes"} py-2  ps-2 mb-2 link cursor-pointer`} onClick={() => navigate('/my-profile/my-travel-preference')}>
								<svg xmlns="http://www.w3.org/2000/svg" className='me-1' width="21" height="21" viewBox="0 0 21 21" fill="none">
									<path d="M19.25 5.6875H14" stroke={`${location.pathname === '/my-profile/my-travel-preference' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M5.25 5.6875H1.75" stroke={`${location.pathname === '/my-profile/my-travel-preference' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M8.75 8.75C10.4414 8.75 11.8125 7.37887 11.8125 5.6875C11.8125 3.99613 10.4414 2.625 8.75 2.625C7.05863 2.625 5.6875 3.99613 5.6875 5.6875C5.6875 7.37887 7.05863 8.75 8.75 8.75Z" stroke={`${location.pathname === '/my-profile/my-travel-preference' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M19.25 15.3125H15.75" stroke={`${location.pathname === '/my-profile/my-travel-preference' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M7 15.3125H1.75" stroke={`${location.pathname === '/my-profile/my-travel-preference' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M12.25 18.375C13.9414 18.375 15.3125 17.0039 15.3125 15.3125C15.3125 13.6211 13.9414 12.25 12.25 12.25C10.5586 12.25 9.1875 13.6211 9.1875 15.3125C9.1875 17.0039 10.5586 18.375 12.25 18.375Z" stroke={`${location.pathname === '/my-profile/my-travel-preference' ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<span>{" " + t('MY_TRAVEL_PREFERENCES')}</span>
							</div>
						</li>
						<li >
							<a onClick={signOut} className=" mb-3 ms-1 d-flex align-items-center cursor-pointerp ps-2" href='#'><svg xmlns="http://www.w3.org/2000/svg" className='me-1' width="21" height="21" viewBox="0 0 21 21" fill="none">
								<path d="M13.2122 14.385C12.9409 17.535 11.3222 18.8213 7.77841 18.8213L7.66466 18.8213C3.75341 18.8213 2.18716 17.255 2.18716 13.3438L2.18716 7.63879C2.18716 3.72754 3.75341 2.16129 7.66466 2.16129L7.77841 2.16129C11.2959 2.16129 12.9147 3.43004 13.2034 6.52754" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M7.8749 10.5L17.8324 10.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M15.8813 13.4316L18.8125 10.5004L15.8813 7.56914" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg> <span className='sign-out ms-1'>{t("SIGN_OUT")}</span></a>
							<a style={{alignItems:"center", display:"flex" }} className={` text-danger ps-2 ${location.pathname && location.pathname.includes('/my-profile/delete-profile') ? "active" : ""} cursor-pointer`} onClick={() => navigate('/my-profile/delete-profile')}>
								<svg xmlns="http://www.w3.org/2000/svg" className='me-1' width="21" height="21" viewBox="0 0 21 21" fill="none">
									<path d="M17.8368 5.07509C16.5561 4.94782 15.2754 4.85236 13.9868 4.78077V4.77282L13.8118 3.73872C13.6924 3.00691 13.5174 1.90918 11.656 1.90918H9.57195C7.71852 1.90918 7.54352 2.95918 7.41625 3.73077L7.2492 4.74895C6.50943 4.79668 5.76966 4.84441 5.02988 4.916L3.40716 5.07509C3.07307 5.10691 2.83443 5.40122 2.86625 5.72736C2.89807 6.0535 3.18443 6.29213 3.51852 6.26032L5.14125 6.10122C9.30945 5.68759 13.5095 5.84668 17.7254 6.26827C17.7493 6.26827 17.7652 6.26827 17.789 6.26827C18.0913 6.26827 18.3538 6.03759 18.3856 5.72736C18.4095 5.40122 18.1708 5.10691 17.8368 5.07509Z" fill="#E76868" />
									<path d="M16.3732 7.39031C16.1823 7.19144 15.9198 7.08008 15.6493 7.08008H5.5948C5.32434 7.08008 5.05389 7.19144 4.87093 7.39031C4.68798 7.58917 4.58457 7.85962 4.60048 8.13803L5.09366 16.2994C5.18116 17.5085 5.29253 19.0199 8.06866 19.0199H13.1755C15.9516 19.0199 16.063 17.5164 16.1505 16.2994L16.6437 8.14599C16.6596 7.85962 16.5562 7.58917 16.3732 7.39031ZM11.9425 15.0346H9.29362C8.96753 15.0346 8.69707 14.7642 8.69707 14.438C8.69707 14.1119 8.96753 13.8414 9.29362 13.8414H11.9425C12.2687 13.8414 12.5391 14.1119 12.5391 14.438C12.5391 14.7642 12.2687 15.0346 11.9425 15.0346ZM12.6107 11.8528H8.63343C8.3073 11.8528 8.03684 11.5824 8.03684 11.2562C8.03684 10.9301 8.3073 10.6596 8.63343 10.6596H12.6107C12.9368 10.6596 13.2073 10.9301 13.2073 11.2562C13.2073 11.5824 12.9368 11.8528 12.6107 11.8528Z" fill="#E76868" />
								</svg> <span className='delete ms-2'>{t('DEACTIVATE_PROFILE')}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

	)
});

export default MyProfileSidebar;
