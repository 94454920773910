import axios from "axios";
var qs = require('qs');
const baseURL = process.env.REACT_APP_API;
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API
});

httpClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Accept-Language"] = localStorage.getItem("i18nextLng");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


httpClient.interceptors.response.use(

  function (response) {
    return response;
  },
  function (error) {
   if(window.location.pathname=="/sign-in")
   return Promise.reject(error);

    if (error.response && error.response.status === 404) {
       window.location = "/*"
    }
    else if (error.response && error.response.status === 401) {
      window.location = "/Unauthorized";
    }
    else if (error.response.status === 500) {
      window.location = "/ServerError";
    }

    else if (error.response && error.response.status === 403) {
      window.location = "/sign-in";
      localStorage.clear('token');

    }
    // if(error.code="ERR_NETWORK")
    // {
    //   if(window.location.pathname!= "/ServerError")
    //   window.location = "/ServerError";

    // return Promise.reject(error);
    // }
    return Promise.reject(error);
  }
);


const handleApiResponse = function (httpResponse) {
  if (httpResponse.status >= 200 && httpResponse.status < 300) {
    return {
      isSuccess: true,
      isError: false,
      isValidationError: false,
      data: httpResponse.data,
      error: null,
      validationErrors: null
    }
  }
}


const handleExceptionApiResponse = function (axiosError) {
  if (axiosError.response && axiosError.response.status === 400) {
    return {
      statusCode: axiosError.response.status,
      isSuccess: false,
      isError: false,
      isValidationError: true,
      data: null,
      error: axiosError.request.response,
      validationErrors: axiosError.response.data.errors
    }
  }
  else if (axiosError.response ) {
    return {
      statusCode: axiosError.response.status,
      isSuccess: false,
      isError: true,
      isValidationError: false,
      data: null,
      error: "INTERNAL_SERVER_ERROR",
      validationErrors: null
    }
  }
}

const api = (axios) => {
  const baseUrl = baseURL;
  return {
    getPhotoUrl: (photoId, config = {}) => {
      return baseURL + "/Photos/" + photoId;
    },
    getPhotoUrlObjectFromBase64 : (photo, config = {}) =>{
      return `data:image/jpeg;base64,${photo?.data}`;
    },
    getById: async (url, config = {}) => {
      try {
        var response = await axios.get(baseUrl + url, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
    get: async (url, config = {}) => {
      try {
        var response = await axios.get(baseUrl + url, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
    delete: async (url, config = {}) => {
      try {
        var response = await axios.delete(baseUrl + url, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
    put: async (url, body, config = {}) => {
      try {
        var response = await axios.put(baseUrl + url, body, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
    putForm: async (url, body, config = {}) => {
      try {
        var response = await axios.putForm(baseUrl + url, body, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
    post: async (url, body, config = {}) => {
      try {
        var response = await axios.post(baseUrl + url, body, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
    postForm: async (url, body, config = {}) => {
      try {
        var response = await axios.postForm(baseUrl + url, body, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
    getPaged: async (url, searchObject = {}, config = {}) => {
      try {
        var response = await axios.get(baseUrl + url, searchObject, config);
        return handleApiResponse(response);
      } catch (e) {
        return handleExceptionApiResponse(e);
      }
    },
  };
};

export const httpSmartClient = api(httpClient);

export default httpClient;
