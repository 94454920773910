import { result } from "lodash";
import { httpSmartClient } from "../../config/httpClient";

const baseName = "Rides";

const RidesService = {
  getCount: async (dateTime) => {
    var response = await httpSmartClient.get(`/${baseName}/Count?dateTime=${dateTime}`);
    return response;
  },

  getById: async (id) => {
    var response = await httpSmartClient.getById(`/${baseName}/${id}`);
    return response;
  },
  getByDriverId: async (id) => {
    var response = await httpSmartClient.get(`/${baseName}/GetByDriverId`, { params: { DriverId: id } });
    return response;
  },
  getAllRides: async (params) => {
    var response = await httpSmartClient.get(`/${baseName}/GetAllRides?`, { params });
    return response;
  },
  getDrivers: async (name) => {
    var response = await httpSmartClient.get(`/${baseName}/GetDrivers?name=` + name);
    return response;
  },
  getByRideIdDetails: async (obj) => {
    var response = await httpSmartClient.get(`/${baseName}/GetByRideIdDetails?rideId=${obj.rideId}&rideMilestoneFromId=${obj.rideMilestoneFromId}&rideMilestoneToId=${obj.rideMilestoneToId}`);
    return response;
  },
  getMilestonesIds: async (params) => {
    var response = await httpSmartClient.get(`/${baseName}/GetMilestonesIDs`, { params });
    return response;
  },
  getByParams: async (params) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetPaged`, { params });
    return response.data;
  },
  getRidesByQuery: async (params) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetPagedRides`, { params });
    return response.data;
  },
  getRidesAndUserRidesByUser: async (params) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetRidesAndUserRidesByUser`, { params });
    return response;
  },
  GetRidesByDriverId: async (DriverId) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetRidesByDriverId?DriverId=${DriverId}`);
    return response.data;
  },
  GetRidesByUserId: async (params) => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetRidesByUserId`, { params });
    return response;
  },
  GetCountByRideStatus: async () => {
    var response = await httpSmartClient.getPaged(`/${baseName}/GetCountByRideStatus`);
    return response;
  },
  getSumOfAllPollution: async () => {
    var response = await httpSmartClient.get(`/${baseName}/GetPollutionForThisYear`);
    return response;
  },
  add: async (ride) => {
    var response = await httpSmartClient.post(`/${baseName}/add`, ride);
    return response;
  },
  edit: async (ride) => {
    var response = await httpSmartClient.put(`/${baseName}`, ride);
    return response;
  },
  changeRideStatus: async (rideId, status, creditCardNumber) => {
    var response = await httpSmartClient.put(`/${baseName}/UpdateRideStatus?rideId=${rideId}&status=${status}&creditCardNumber=${creditCardNumber}`);
    //var response = await httpSmartClient.post(`/AccountToCreditCardTransactions/MakeA2CTransfer?rideId=${rideId}`);
    return response;
  },
  delete: async (id) => {
    var response = await httpSmartClient.delete(`/${baseName}/${id}`);
    return response;
  },
  transactionsExist: async (rideId) => {
    var response = await httpSmartClient.get(`/${baseName}/TransactionsExist?rideId=${rideId}`);
    return response;
  },
  getUnfinalizedCountByDriverid: async (DriverId) => {
    var response = await httpSmartClient.get(`/${baseName}/GetUnfinalizedCountByDriverid?DriverId=${DriverId}`);
    return response;
  },
};

export default RidesService;
