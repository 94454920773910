import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import { useForm } from 'react-hook-form';
import { toastify } from 'utils/toastify';
import { AppSettingsService } from 'data/services';

export default function MinHoursBeforeRide({...props}) {
    const { t } = useTranslation();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [settings,setSettings]=useState();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();
    useEffect(() => {
        reset(props.settings)
        setSettings(props.settings);
    }, []);
    const onSubmit = async (data) => {
        if (data.minHoursBeforeRide === settings.minHoursBeforeRide) {
            return;
        }

        setIsFormSubmitting(true);
        const response = await AppSettingsService.edit({...settings, minHoursBeforeRide: data.minHoursBeforeRide})
        setIsFormSubmitting(false);
        if (response.isSuccess) {
            toastify.success(t("SUCCESS_EDITING_MIN_HOURS_RIDE"), <img className="Toastify__toast-icon--success" src="../assets/images/checkwhite.png"/>)
        } else {
            toastify.error(t("ERROR"))
        }
    }
    return (
        <>
            <div className='input col-md ms-3  card shadow p-3 mb-3'>
                <div className="row">
                    <div className="col-12 mb-4 mb-sm-4">
                        <div className="d-sm-flex justify-content-between align-items-center">
                            <h4 className="black mb-3 mb-sm-0">{t("MIN_HOURS_BEFORE_RIDE")}</h4>
                            <Tooltip style={{ width: "55%", zIndex: 1 }} id="tooltip-minHoursBeforeRide" ></Tooltip>
                            <span data-tooltip-id="tooltip-minHoursBeforeRide" data-tooltip-content={t("TOOLTIP_MIN_HOURS_BEFORE_RIDE")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='grey' height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                            </span>
                        </div>
                    </div>
                </div>
                <label className="form-label">{t("HOURSFULL")}</label>
                <form className='d-flex' onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type='text'
                        className='w-100 me-3 form-control'
                        {...register("minHoursBeforeRide",
                            {
                                required: t("REQUIRED_FIELD"),
                                validate: {
                                    matchPattern: (v) =>
                                        /^(?:\d{1,2}|120)$/.test(v) || t("HOURS_VALIDATION")
                                },
                            })}
                    />
                    <button className='btn btn-primary mb-0 ms-1 overflow-visible'>
                        {isFormSubmitting && <i className="fa fa-spin fa-spinner fa-1x"></i>}
                        {t("SAVE")}</button>
                </form>
                {errors.minHoursBeforeRide && errors.minHoursBeforeRide.type === "required" && <small className="text-danger">{errors.minHoursBeforeRide.message}</small>}
                {errors.minHoursBeforeRide && errors.minHoursBeforeRide.type === "matchPattern" && <small className="text-danger">{errors.minHoursBeforeRide.message}</small>}
            </div>

        </>
    )
}

