import { useSearchParams, useNavigate } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { AuthService } from "data/services";
import { toastify } from "utils/toastify";
import { LanguageSwitcher } from "components/layout/components";
import Header from "../../../layout/components/Header";

export default function ForgotPasswordPage() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams("");
    const [isResetPasswordActive, setIsResetPasswordActive] = useState(false);
    const token = searchParams.get("token");
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [IsTablet, setIsTablet] = useState(window.innerWidth <= 1200);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth <= 1500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setIsTablet(window.innerWidth <= 1200);
            setIsDesktop(window.innerWidth <= 1500);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const token = searchParams.get("token");
        if (token) {
            const fetchToken = async () => {
                const isTokenValid = await AuthService.isTokenvalid(token);
                if (isTokenValid === true) {
                    setError("");
                    setIsResetPasswordActive(true);
                } else {
                    setError(t("Token is not valid"));
                    toastify.error(t("INVALID_TOKEN"));
                    navigate("/sign-in");
                }
            }
            fetchToken();
        }
    }, []);

    return (
        <>
            {<main>
                {!isMobile ? <div className="row justify-content-center align-items-center">
                    <div className="col-12">
                        <div className="row g-1">
                            {!IsTablet ? <div className="col-5 mt-5" >
                                <img src="assets/images/element/forgotpass.svg" style={{ maxHeight: "100%", objectFit: "cover",marginTop:"80px",marginLeft:"40px" }} alt="" />
                            </div> : <div className="col mt-5" >
                                <img src="assets/images/element/forgotpass.svg" style={{ maxHeight: "100%", objectFit: "cover",marginTop:"80px" ,marginLeft:"40px"}} alt="" />
                            </div>}
                            <div className="col-lg order-1 ">
                                <div className="row" style={{ marginTop: "50px", marginBottom: "80px" }}>
                                    <div className="col"></div>
                                    <div className="col-auto d-flex flex-row">
                                        <svg width="16" onClick={() => navigate("/rides")} style={{ marginTop: "1px" }} className="cursor-pointer" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_202_1401)">
                                                <path d="M8.14812 15.1852C12.0346 15.1852 15.1852 12.0346 15.1852 8.14816C15.1852 4.26171 12.0346 1.11113 8.14812 1.11113C4.26167 1.11113 1.11108 4.26171 1.11108 8.14816C1.11108 12.0346 4.26167 15.1852 8.14812 15.1852Z" stroke="black" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M18.889 18.8889L13.5557 13.5555" stroke="black" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_202_1401">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p onClick={() => navigate("/rides")} className="cursor-pointer me-3" style={{ marginLeft: "10px", color: "black", fontSize: "14px" }}>{t("SEARCH")}</p>
                                        <ul className="nav" style={{ marginTop: "-10px" }}>
                                            <LanguageSwitcher/>
                                        </ul>
                                    </div>
                                    <div className="col-2"></div>
                                </div>
                                <div className="row mb-5" >
                                    {!isDesktop ? <div className="col"></div> : IsTablet ? <div className="col-2"></div> : <div className="col-3"></div>}
                                    <div className={!isDesktop ? "col-5" : "col"}>
                                        {!isResetPasswordActive && <h4 className="mb-2" style={{ fontWeight: "bold" }}>{t("FORGOT_PASSWORD")}</h4>}
                                        {!isResetPasswordActive && <p style={{ fontSize: "14px" }} className="mb-sm-0">{t("FORGOT_MESSAGE")}</p>}
                                        {!isResetPasswordActive && <ForgotPasswordForm></ForgotPasswordForm>}
                                        {isResetPasswordActive && <h4 className="mb-2" style={{ fontWeight: "bold" }}> {t("RESET_PASSWORD")}</h4>}
                                        {isResetPasswordActive && <p style={{ fontSize: "14px" }} className="mb-sm-0">{t("RESET_MESSAGE")}</p>}
                                        {isResetPasswordActive && <ResetPasswordForm token={token}></ResetPasswordForm>}
                                        {error && <small className="text-danger">{error}</small>}
                                    </div>
                                    {!isDesktop ? <div className="col"></div> : IsTablet ? <div className="col-2"></div> : <div className="col-3"></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className="card">
                        <div className="card bg-purple" style={{ height: "185px", top: "0", maxWidth: "100%", borderRadius: "0px", textAlign: "center" }}>
                            <Header></Header>
                            {!isResetPasswordActive && <span className="mb-1" style={{ color: "white", fontWeight: "500", fontSize: "17px" }}>{t("FORGOT_PASSWORD")}</span>}
                            {!isResetPasswordActive && <p style={{ fontSize: "13px", color: "white" }}>
                                {t("FORGOT_MESSAGE")}
                            </p>}
                            {isResetPasswordActive && <span className="mb-1" style={{ color: "white", fontWeight: "500", fontSize: "17px" }}>{t("RESET_PASSWORD")}</span>}
                            {isResetPasswordActive && <p style={{ fontSize: "13px", color: "white" }}>
                                {t("RESET_MESSAGE")}
                            </p>}
                        </div>
                        <div className="card bg-light" style={{ maxWidth: "100%", marginTop: "-10px", height: "570px" }}>
                            <div className="card p-4" style={{ maxWidth: "100%", marginTop: "-15px", marginLeft: "7px",borderRadius:"20px", marginRight: "7px" }}>
                                {!isResetPasswordActive && <ForgotPasswordForm></ForgotPasswordForm>}
                                {isResetPasswordActive && <ResetPasswordForm token={token}></ResetPasswordForm>}
                                {error && <small className="text-danger">{error}</small>}
                            </div>
                        </div>
                        <img src="assets/images/registracija-footer.png" className="cursor-pointer bg-light fade-in" alt=""
                            style={{ objectFit: "cover", maxHeight: "100%",marginTop:"-5px" }} />
                    </div>
                }
            </main>}
        </>
    );
}