import React, { useEffect, useState, useTransition } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import globalStore from "data/stores/GlobalStore";
import { observer } from "mobx-react-lite";

const ChartStatusBar = observer(({ props }) => {
    const [chartInfo, SetChartInfo] = useState();
    const [theme, setTheme] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        setTheme(localStorage.getItem('theme'));
    }, [globalStore.theme])

    useEffect(() => {
        SetChartInfo(
            {
                "annotations": {},
                options: {
                    chart: {
                      type: 'bar',
                      height: 350,
                    },
                },
                title: {
                    text: t("RIDE_STATUS"),
                    align: 'left',
                    style: {
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "25px",
                        color: theme == 'dark' ? "white" : "black"
                    },
                },
                subtitle: {
                    text: t("NUMBER_OF_RIDES"),
                    align: 'left'
                },
                responsive: [
                    {
                      breakpoint: 768,
                      options: {
                            chart: {
                                height: 275,
                            },
                            plotOptions: {
                                bar: {
                                  columnWidth: '70%',
                                }
                            },
                      },
                    },
                  ],
                "plotOptions": {
                    "bar": {
                        "columnWidth": "50%",
                        "borderRadius": 4,
                        "borderRadiusApplication": "end",
                        "borderRadiusWhenStacked": "all",
                        "hideZeroBarsWhenGrouped": false,
                        "isDumbbell": false,
                        "isFunnel": false,
                        "isFunnel3d": true,
                        "colors": {
                            "backgroundBarColors": [
                                "#f1f2f3"
                            ],
                            "backgroundBarOpacity": 0
                        },

                        "dataLabels": {
                            "total": {
                                "enabled": false,
                                "offsetX": 0,
                                "offsetY": 0,
                                "style": {
                                    "color": "#373d3f",
                                    "fontSize": "12px",
                                    "fontWeight": 600
                                },
                                formatter: function (val) {
                                    return Math.round(val); // Show the numerical value of the data instead of percentages
                                },
                            }
                        }
                    },
                  
                   
                },
                "dataLabels": {
                    "offsetY": 8,
                    "style": {
                        "fontWeight": 500,
                        "colors": [
                            "#f1f2f3"
                        ]
                    },
                    "background": {
                        "enabled": false,
                        "borderWidth": 0
                    }
                },
                "grid": {
                    "padding": {
                        "right": 25,
                        "left": 15,
                    }
                },
                "legend": {
                    "fontSize": 14,
                    "offsetY": 0,
                    "markers": {
                        "shape": "square",
                        "size": 8
                    },
                    "itemMargin": {
                        "vertical": 0
                    }
                },
                "series": [
                    {
                        "name": t("DRIVER"),
                        "data": [
                            {
                                "x": t("UPCOMING"),
                                "y": props[0].data[0].y
                            },
                            {
                                "x": t("COMPLETED"),
                                "y": props[0].data[1].y
                            },
                            {
                                "x": t("CANCELLED"),
                                "y": props[0].data[2].y
                            }
                        ]
                    },
                    {
                        "name": t("PASSENGER"),
                        "data": [
                            {
                                "x": t("UPCOMING"),
                                "y": props[1].data[0].y
                            },
                            {
                                "x": t("COMPLETED"),
                                "y": props[1].data[1].y
                            },
                            {
                                "x": t("CANCELLED"),
                                "y": props[1].data[2].y
                            }
                        ]
                    }
                ],
                "stroke": {
                    "fill": {
                        "type": "solid",
                        "opacity": 0.85,
                        "gradient": {
                            "shade": "dark",
                            "type": "horizontal",
                            "shadeIntensity": 0.5,
                            "inverseColors": true,
                            "opacityFrom": 1,
                            "opacityTo": 1,
                            "stops": [
                                0,
                                500,
                                100
                            ],
                            "colorStops": []
                        }
                    }
                },
                "tooltip": {
                    "shared": false,
                    "intersect": true
                },
                "xaxis": {
                    "labels": {
                        "trim": true,
                        "style": {}
                    },
                    "group": {
                        "groups": [],
                        "style": {
                            "colors": [],
                            "fontSize": "12px",
                            "fontWeight": 400,
                            "cssClass": ""
                        }
                    },
                    "tickPlacement": "between",

                    "tooltip": {
                        "enabled": false
                    }
                },
                "yaxis": {
                    "tickAmount": 3,
                    "labels": {
                        formatter: function (value) {
                            return Math.round(value); // Display whole numbers instead of decimals
                        },
                        "style": {
                            "colors": [
                                null,
                                null,
                                null,
                                null,
                                null
                            ]
                        }
                    },
                    "title": {
                        "style": {}
                    }
                },
                "colors": [
                    "#17A2B8",
                    "#9400D3",

                ],
            }
        )
    }, [localStorage.getItem('i18nextLng'), theme]);

    return (

        <div className="col-lg-6 col-xxl-6 mb-4 " id="chart">
            <div className="card shadow p-4 h-100">
                {chartInfo && <ReactApexChart options={chartInfo} className={theme === 'dark' ? "text-white" : "text-dark"} series={chartInfo.series} type="bar" />}
            </div>
        </div>

    );
})
export default ChartStatusBar;