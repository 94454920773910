import { useState } from "react";
import { useTranslation } from "react-i18next";

const BookingInProgressModal = (props) => {
    const { t } = useTranslation();
    const [isConfirming, setIsConfirming] = useState(false);

    const handleDiscard = () => {
        props.onDiscard();
    }
    const handleConfirm = () => {
        props.onConfirm();
    }
    return (
        <>
            <div className="m-2 pb-2 h-75">
                <div className="border-bottom mb-2 pb-2">
                    <h5 className="card-header-title">{props.title}</h5>
                </div>
                <div className="d-flex flex-column justify-content-between mt-4 w-100">
                    <div className="mb-3 w-100">
                        <label className="form-label text-wrap">{props.description}</label><br />
                    </div>
                    <div className="align-items-end d-flex justify-content-end pt-2 w-100">
                        <button className="btn btn-primary mb-0 border-0" onClick={() => handleDiscard()}>
                            {t("OK")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookingInProgressModal;