import { useState } from "react";

const UnFinalizedRideModal = (props) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const handleConfirm = () => {
        props.onConfirm();
    }

    return (
        <>
            <div className="mb-3 ">
                <span className="cardModalTitle">{props.title}</span>
            </div>
            <div className="card-body ">
                <div className="mb-3">
                    <label className="form-label">{props.description}</label><br />
                </div>
                <div className="d-flex justify-content-end">
                    <button disabled={isConfirming} type="submit" className={props.btnType ? `btn ${props.btnType}` : `btn btn-primary`} onClick={() => handleConfirm()}>
                        {isConfirming && <i className="fa fa-spin fa-spinner"></i>}
                        {props.confirmBtnLabel}
                    </button>

                </div>
            </div>
        </>
    )
};

export default UnFinalizedRideModal;