import { useState } from "react";
import { useTranslation } from "react-i18next";
const ConfirmationDialog = (props) => {
    const { t } = useTranslation();
    const [isConfirming, setIsConfirming] = useState(false);
    const [creditCardNumber, setCreditCardNumber] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const handleDiscard = () => {
        props.onDiscard();
    }
    const handleConfirm = () => {
        if (props.showCCInput != undefined && props.showCCInput == true) {
            if (!/^(?:\d{16}|\d{4}(?:\s?\d{4}){3})$/.test(creditCardNumber)) {
                setErrorMessage("Invalid credit card format.");
                return;
            }
            else setErrorMessage(null);
            setIsConfirming(true);
        }
        props.onConfirm(creditCardNumber);
    }
    return (
        <>
            <div className="position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="card shadow mw-90" style={{ width: props.confirmationDialogWidth }}>
                        <div className="card-header border-bottom">
                            <h5 className="card-header-title">{props.title}</h5>
                        </div>
                        <div className="card-body">
                            <div className="mb-3">
                                <label className="form-label">{props.description}</label><br />
                                {props.note && <><span className="darkgray fw-600">{t("NOTE")}: </span> {props.note} </>}
                                {props.showCCInput == false || props.showCCInput == undefined && <label className="form-label">{props.description2}</label>}
                                {props.showCCInput && (
                                    <div>
                                        <hr></hr>
                                        <label className="form-label mb-1">Please type in your credit card number</label>
                                        <input onChange={(e) => setCreditCardNumber(e.target.value)} type="text" className="mb-1 form-control" placeholder="1234 5678 1234 5678" />
                                    </div>
                                )}
                                {errorMessage != null && <span className="text-danger ">{errorMessage}</span>}
                            </div>
                            <div className="d-flex justify-content-end mt-4">
                                <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                                    {t("DISCARD")}
                                </button>
                                <button disabled={isConfirming} type="submit" className={props.btnType ? `btn ${props.btnType}` : `btn btn-danger`} onClick={() => handleConfirm()}>
                                    {isConfirming && <i className="fa fa-spin fa-spinner"></i>}
                                    {props.confirmBtnLabel}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};



export default ConfirmationDialog;