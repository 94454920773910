import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { httpSmartClient } from "../../../../config/httpClient.js";
import moment from "moment/moment";
import GoogleMapSubmitDesktop from "./GoogleMapSubmitDesktop.js";
import AuthService from "../../../../data/services/AuthService.js";
import RidesService from "../../../../data/services/RidesService.js";
import toastify from "../../../../utils/toastify/toastify.js";
import AppSettingsService from "data/services/AppSettingsService.js";
import Modal from "react-responsive-modal";
import ConfirmationDialog from "../user/userChunks/modals/ConfirmationDialog.js";
import globalStore from "data/stores/GlobalStore.js";
import InformationModal from "./InformationModal.js";

export default function SubmitRideStep({ formData, prevStep, rows }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = AuthService.getCurrentUser();
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [minHoursRide, setMinHoursRide] = useState(0);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [showModalMinHoursBeforeRide, setShowModalMinHoursBeforeRide] = useState(false);
    const [descriptionMinHoursRide, setDescriptionMinHoursRide] = useState("");
    const [descriptionConfirmDialog, setDescriptionConfirmDialog] = useState(null);
    const mapWidth = "48.5%";
    const mapHeight = "280px";

    const previousStep = () => {
        prevStep();
    }

    const openConfirmDialog = async () => {
        const oldStartDate = formData.rows[0].dateTime;
        const appSettings = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
        const minHoursRide = appSettings.data.items[0].minHoursBeforeRide;
        const currentRowDateTime = moment.utc(oldStartDate);
        if (Math.ceil(Math.abs(moment().diff(currentRowDateTime, 'minutes', true))) < minHoursRide * 60) {
            //toastify.error(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
            setDescriptionMinHoursRide(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
            setShowModalMinHoursBeforeRide(true);
            return;
        }
        let startDestination;
        let lastDestionation;
        {
            formData.rows && formData.rows.map((row) => {
                if (row.IsStartMilestone) {
                    startDestination = row;
                }
                else if (row.IsLastMilestone) {
                    lastDestionation = row;
                }
            })
        }
        setDescriptionConfirmDialog(
            <div>
                {t("POST_RIDE_CONFIRM_1")}
                <span className="darkgray fw-600">{startDestination.cityName}</span> -&gt;
                <span className="darkgray fw-600"> {lastDestionation.cityName}</span>
                {t("RIDE_SCHEDULED_FOR")}
                <span className="darkgray fw-600">{(moment(formData.rows[0].dateTime)).format("DD.MM.yyyy")}{t("YEAR")} </span>
                {t("IN")}
                <span className="darkgray fw-600"> {(moment(formData.rows[0].dateTime)).format("HH:mm")} h</span>
                {t("BOOK_RIDE_CONFIRM_3")}
                <span className="darkgray fw-600">{formData.price} BAM</span>?
            </div>)
        setConfirmDialog(true);
    }

    const onSubmit = async (formData) => {
        const oldStartDate = formData.rows[0].dateTime;
        let payload = {
            maxPassengersCount: formData.maxPassengersCount,
            price: formData.price,
            driverVehicleId: formData.selectedDriverVehicle.id,
            selectedRouteIndex: formData.selectedRouteIndex,
            routeKilometers: formData.routeKilometers,
            womenOnly: formData.womenOnly,
            summary: formData.summary,
            rows: formData.rows.map(r => ({ ...r, dateTime: moment(r.dateTime).format("DD.MM.YYYY HH:mm").toString() })),
            conditionIds: formData.conditions && formData.conditions.filter(c => c.checked).map(condition => condition.id)
        };
        setIsFormSubmitting(true);
        const newRide = await RidesService.add(payload);
        setIsFormSubmitting(false);
        if (newRide.data && newRide.isSuccess) {
            toastify.success(t("SUCCESSFULLY_ADDED_BASIC_RIDE_INFORMATION"));
            navigate("/my-profile/my-rides");
        } else {
            const appSettings = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
            const minHoursRide = appSettings.data.items[0].minHoursBeforeRide;
            const currentRowDateTime = moment.utc(oldStartDate);
            if (Math.ceil(Math.abs(moment().diff(currentRowDateTime, 'minutes', true))) < minHoursRide * 60) {
                //toastify.error(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
                setDescriptionMinHoursRide(t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE", { minHoursRide }));
                setShowModalMinHoursBeforeRide(true);
                setConfirmDialog(false);
            }
            else {
                toastify.error(t("ERROR_ADDING_BASIC_RIDE_INFORMATION"));
            }
        }
    }

    return (
        <>

            <div className="container ">
                <div style={{ marginRight: "24px", marginLeft: "24px" }}>
                    <GoogleMapSubmitDesktop width={mapWidth} height={mapHeight} formData={formData}></GoogleMapSubmitDesktop>
                    <div className="d-flex justify-content-center">
                        <div className="col-auto w-50">
                            <div className="card shadow mt-3 mb-3 me-3">
                                <div className="card-body textDark cardWeight">
                                    <div className="row">
                                        <span className="float-start black fs16 fw-600 mb-3">{t("VEHICLE")}</span>
                                        <div className="col mb-1">
                                            <label className="fs12 fw-400 gray">{t("CAR")}:</label>
                                            {formData && formData.selectedDriverVehicle && <h6 className="mb-0 fw-normal fs14 mb-2">{formData.selectedDriverVehicle.vehicleModel.vehicleBrand.name + " - " + formData.selectedDriverVehicle.vehicleModel.name}</h6>}
                                            <label className="fs12 fw-400 gray">{t("LICENSE_PLATE_NUMBER")}:</label>
                                            {formData && formData.selectedDriverVehicle && <p className="fs14 fw-normal">{formData.selectedDriverVehicle.licensePlateNumber}</p>}
                                        </div>

                                        <div className="col-auto">
                                            {formData && formData.selectedDriverVehicle &&
                                                <img
                                                    className="imageStyleVehicleSubmit"
                                                    src={formData.selectedDriverVehicle.optimizedVehiclePhotoId == null ? "/assets/images/default-car.jpg" : httpSmartClient.getPhotoUrl(formData.selectedDriverVehicle.optimizedVehiclePhotoId)}
                                                />}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card shadow mt-3 me-3">
                                <div className="card-header d-flex p-3 ms-1">
                                    <span className="card-header-title fw-600 fs16 black">{t("CONDITIONS")}</span>
                                </div>
                                <div className="card-body pt-0" style={{ paddingBottom: "10px" }}>
                                    <div className="fw-normal submitRideStep">
                                        {formData.conditions && formData.conditions.map((condition, index) => {
                                            return (
                                                <div key={index} className="form-check p-0">
                                                    <img width={"20px"}
                                                        className="inputCheckColor mb-2 mt-1"
                                                        src={condition.checked ? httpSmartClient.getPhotoUrl(condition.noIconId) : httpSmartClient.getPhotoUrl(condition.iconId)}
                                                        alt=""
                                                    ></img>
                                                    <label className={`${condition.checked ? "form-check-label ps-2 condition no-opacity labelColor" : "form-check-label ps-2 condition no-opacity"} `}>
                                                        <h6 className={`mb-0 fs14 ${condition.checked? 'purple':''} fw-normal me-2`}>{t(condition.name)}</h6>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="card shadow mt-3 mb-3 me-3">
                                <div className="card-body cardWeight textDark">
                                    <span className="float-start fw-600 fs16 black">{t("MAX_PASSENGERS_COUNT")}</span>
                                    <span className="float-end fw-600 fs16 passengersCountSpan me-2">{formData.maxPassengersCount}</span>
                                </div>
                            </div>
                            {user.Gender === "Female" && formData.womenOnly &&
                                <div className="card shadow mt-3 mb-3 me-3">
                                    <div className="card-body cardWeight textDark">
                                        <span className="float-start fw-600 fs16">{t("WOMEN_ONLY")}</span>
                                        <span className="float-end fw-600 fs16 me-2 mt-0"><input
                                            id={`checkbox_womenOnly`}
                                            type="checkbox"
                                            defaultChecked={formData.womenOnly}
                                            className="form-check-input inputCheckColor py-0 "
                                            readOnly
                                            style={{ width: "18px", height: "18px" }}
                                        />
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="w-50">
                            <div className="card shadow mainCardDesktopSubmit">
                                <span className="mt-2 ms-4 mt-4 black fw-600 fs18">{t("TRAVEL_OVERVIEW")}</span>

                                {formData.rows && formData.rows.map((row, index) => {
                                    if (row.IsStartMilestone) {
                                        const toDate = moment.utc(new Date(formData.rows[index + 1].dateTime));
                                        const fromDate = moment.utc(new Date(formData.rows[0].dateTime));
                                        let minutes = toDate.diff(fromDate, 'minutes');
                                        var min = minutes % 60;
                                        var hours = toDate.diff(fromDate, 'hours');
                                        row.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                                    }
                                    return (
                                        row.IsStartMilestone && (
                                            <>
                                                <div className="row" key={index}>
                                                    <div className="mt-3 startMilestone">
                                                        <p className="mb-2 ms-2 milestoneParagraph textDark">
                                                            <img className="mb-1 me-2" src="/assets/images/Group 1.svg" alt="group1" />
                                                            {t("FROM3") + " "}
                                                            {moment(row.dateTime).format("DD.MM.YYYY") + " "}
                                                            {t("IN") + " "}
                                                            {moment(row.dateTime).format("HH:mm")}h
                                                        </p>
                                                    </div>
                                                    <div className="row d-flex flex-row mt-3 milestone">
                                                        <div className="col d-flex flex-row">
                                                            <p className="me-3 milestoneDate textDark">
                                                                {moment(row.dateTime).format("HH:mm")}h
                                                            </p>
                                                            <img className="circleSubmitRide circleStartMilestone" src="/assets/images/Ellipse 1.svg" alt="ellipse" />
                                                            <div className="vr opacity-3 sideLineSubmitStartMilestone"></div>

                                                            <div>
                                                                <h6 className="ms-3 black me-2">{row.cityName}</h6>
                                                                <div className="ms-3 me-2 fs12 gray locationName mt-0">{row.address}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ms-3 mb-3">
                                                        <div className="startMilestoneDate col-auto" style={{ textAlign: "center" }}>
                                                            <p className="milestoneDate textDark mt-1">
                                                                {row.dateTimeFormatted}
                                                            </p>
                                                        </div>
                                                        <div className="me-4 col-auto ms-auto">
                                                            <img className="mb-1 dollarPadding me-2" src="/assets/images/Character_1.svg" alt="dollar" />
                                                            <span className="fw-600 fs18 mb-0 valute priceSize">{formData.rows[index + 1] && formData.rows[index + 1].price} </span>
                                                            <span className="priceSize gray">{t("VALUTE")}</span>
                                                        </div>
                                                    </div>
                                                </div>


                                            </>
                                        )
                                    )
                                })}
                                {formData.rows &&
                                    formData.rows.map((row, index) => {
                                        if (row.IsStopMilestone) {
                                            const toDate = moment.utc(new Date(formData.rows[index + 1].dateTime));
                                            const fromDate = moment.utc(new Date(formData.rows[index].dateTime));
                                            var minutes = toDate.diff(fromDate, 'minutes');
                                            var min = minutes % 60;
                                            var hours = toDate.diff(fromDate, 'hours');
                                            row.dateTimeFormatted = minutes >= 60 ? hours > 99 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                                        }
                                        return (
                                            row.IsStopMilestone && (
                                                <>
                                                    <div className="row milestoneStopDesktop">
                                                        <div className="col d-flex flex-row">
                                                            <p className="milestoneParagraph" style={{ marginTop: "15px" }}>{moment(row.dateTime).format("HH:mm")}h</p>
                                                            <div className="vr opacity-3 sideLineStop textDark"></div>
                                                            <img className="circleSubmitRide mt-3" src="/assets/images/Ellipse 1.svg" alt="ellipse" />
                                                            <div className="vr opacity-3 sideLineSubmitStop textDark"></div>
                                                            <div>
                                                                <h6 className="ms-3 me-2 black" style={{ marginTop: "11px" }}>{row.cityName}</h6>
                                                                <div className="ms-3 me-2 fs12 gray locationName mt-0">{row.address}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ms-3 mb-3">
                                                        <div className="milestoneDateTime col-auto" style={{ textAlign: "center" }}>
                                                            <p className="milestoneDate textDark mt-1">
                                                                {row.dateTimeFormatted}
                                                            </p>
                                                        </div>
                                                        <div className="me-4 col-auto ms-auto">
                                                            <img className="mb-1 dollarPadding me-2" src="/assets/images/Character_1.svg" alt="dollar" />
                                                            <span className=" fw-600 fs18 me-2 mb-0 valute priceSize">{formData.rows[index + 1] && formData.rows[index + 1].price}</span>
                                                            <span className="priceSize">{t("VALUTE")}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        )
                                    })}

                                {formData.rows &&
                                    formData.rows.map(
                                        (row, index) =>
                                            row.IsLastMilestone && (
                                                <>
                                                    <div className="row milestone">
                                                        <div className="col d-flex flex-row lastMilestoneDesktop">
                                                            <p className="lastMilestoneParagraph" style={{ marginTop: "15px" }}>{moment(row.dateTime).format("HH:mm")}h</p>
                                                            <div className="vr opacity-3 sideLineDestination textDark"></div>
                                                            <img className="circleSubmitRide mt-3" src="/assets/images/Ellipse 1.svg" alt="ellipse" />
                                                            <div>
                                                                <h6 className="black me-2" style={{ marginTop: "11px", marginLeft: "0.6rem" }}>{row.cityName}</h6>
                                                                <div className="me-2 fs12 gray locationName mt-0" style={{ marginLeft: "0.6rem" }}>{row.address}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row lastMilestoneSubmit">
                                                        <div className="mt-3 detailsToSubmitRide">
                                                            <p className="mb-2 ms-2 lastMilestoneParagraph mt-2 textDark">
                                                                <img className="mb-1 me-2" src="/assets/images/Group 3.svg" alt="group3" />
                                                                {t("DETAILS_TO_SUBMIT_RIDE") + " "}
                                                                {moment(row.dateTime).format("DD.MM.YYYY") + " "}
                                                                {t("IN") + " "}
                                                                {moment(row.dateTime).format("HH:mm")}h
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                    )}
                                <hr className="mt-4" style={{ border: "1px solid black", width: "94%", marginLeft: "auto", marginRight: "auto" }} />

                                <div className="row align-items-center mb-4 me-2">
                                    <div className="col" style={{marginLeft:"15px"}}>
                                        <p className="fontSize16">{t("TOTAL_PRICE_PER_PASSENGER")}</p>
                                    </div>
                                    <div className="col-auto cardWeight priceSize" style={{paddingRight:"8px"}}>
                                        <span className="priceOnSubmit fs22 fw-600  me-1 submitRideStep">
                                            <NumericFormat
                                                className="valute"
                                                displayType="text"
                                                decimalScale={2}
                                                value={formData.price}
                                                thousandSeparator=","
                                            />
                                        </span>
                                        <span className="fs22 fw-600 gray">{t("VALUTE")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 cursor-pointer btnStep2Desktop">
                                <div className="d-flex">
                                    <button className="btn btnSoft btn-md align-items-center center-block me-2 btnStepDesktop d-flex justify-content-between btnHeightOther" onClick={() => previousStep()}>
                                        <img className="me-7" src="/assets/images/arrow-right2.svg" alt="arrow-right" />
                                        <span className="fontWeight500  ">{t("GO_BACK")}</span>
                                    </button>
                                    <button className="btn btnPrimary align-items-center btn-md center-block btnStepDesktop d-flex justify-content-between btnHeightOther" disabled={isFormSubmitting} onClick={() => openConfirmDialog(true)}>
                                        <span className="fontWeight500  ">
                                            {isFormSubmitting && <i className="fa fa-spin fa-spinner px-2"></i>}

                                            {t("POST_RIDE")}</span>
                                        <img className="ms-7 " src="/assets/images/arrow-right.svg" alt="arrow-right" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={confirmDialog}
                    onClose={() => setConfirmDialog(false)}
                    closeIcon={globalStore.closeIcon}
                    classNames={{
                        modal: "modal-no-padding",
                        modalContainer: 'modal-container-animation-none',
                        closeButton: "closeButtonConfirmCancel"

                    }}
                    center
                >
                    <ConfirmationDialog
                        onDiscard={() => setConfirmDialog(false)}
                        title={t("POST_RIDE")}
                        description={descriptionConfirmDialog}
                        confirmBtnLabel={t("POST_RIDE")}
                        btnType={"mb-0 btn-primary"}
                        onConfirm={() => onSubmit(formData)}
                    >
                    </ConfirmationDialog>
                </Modal>
                <Modal
                    open={showModalMinHoursBeforeRide}
                    onClose={() => setShowModalMinHoursBeforeRide(false)}
                    closeIcon={globalStore.closeIcon}
                    center
                    classNames={
                        {
                            modal: "",
                            closeButton: "mt-2 me-2"
                        }
                    }
                >
                    <InformationModal
                        onDiscard={() => setShowModalMinHoursBeforeRide(false)}
                        title={t("WARNING")}
                        description={descriptionMinHoursRide}
                    ></InformationModal>
                </Modal>
            </div>
        </>
    );
};
