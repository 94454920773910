import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { useTranslation } from 'react-i18next';
import { toastify } from 'utils/toastify';
import { CreditCardsService, AuthService } from 'data/services';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'

function AddCreditCardDialog(props) {
    const user = AuthService.getCurrentUser();
    const {
        formState: { errors },
        setValue,
        handleSubmit,
        setError,
        clearErrors,
        reset
    } = useForm({ mode: "onChange" });
    const { t } = useTranslation();
    const [state, setState] = useState({
        cardNumber: '',
        expiryDate: '',
        name: '',
        focus: '',
    });
    const {
        meta,
        getCardNumberProps,
        getCardImageProps,
        getExpiryDateProps,
    } = usePaymentInputs({
        errorMessages: {
            emptyCardNumber: t("EMPTY_CARD_NUMBER"),
            invalidCardNumber: t("INVALID_CARD_NUMBER"),
            emptyExpiryDate: t("EMPTY_EXPIRY_DATE"),
            yearOutOfRange: t("YEAR_OUT_OF_RANGE"),
            dateOutOfRange: t("DATE_OUT_OF_RANGE"),
            invalidExpiryDate: t("INVALID_EXPIRY_DATE"),
        }
    });
    useEffect(() => {
        clearErrors();
    }, [meta.error])
    const onSubmit = async (data) => {
        if (meta.error) {
            setError("globalError", { message: meta.error })
            return;
        }
        const response = await CreditCardsService.add(data);
        if (response.isSuccess) {
            props.onDiscard();
            props.ReRender();
            toastify.success(t("SUCCESSFULLY_ADDED_CARD"))
        }
        else if (response.statusCode === 409) {
            setError("globalError", { message: t("INVALID_CARD_NUMBER") })
            return;

        } else {
            props.onDiscard();
            toastify.error(t("ERROR_ADDING_CARD"))
        }
        reset();
    }
    const handleChangeCardNumber = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
        setValue("cardNumber", e.target.value)
    }

    const handleChangeExpiryDate = (e) => {
        const { name, value } = e.target;
        let obj = value.toString();
        let model = obj;
        if (obj.includes('/')) {
            model = obj.replace('/', '')
            setValue("expiryDate", value)
        }
        setState((prev) => ({ ...prev, [name]: model }));
        if (!obj.includes('/')) {
            setValue("expiryDate", value)
        }
    }
    return (
        <div className="d-flex justify-content-center align-items-center position-fixed z-index-master top-0 start-0 bottom-0 end-0 w-100 bg-dark bg-lighten-lg bg-opacity-50">
            <div className="border card Card">
                <div className="card-header pb-0">
                    <h5 className="card-header-title">{t("ADD_NEW_CARD")}</h5>
                </div>
                <div className='p-2'>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Cards
                                number={state.cardNumber}
                                expiry={state.expiryDate}
                                name={user.FirstName + " " + user.LastName}
                                focused={state.focus}
                            />
                            <PaymentInputsWrapper className='mt-3 ms-4 card-input'>
                                <input name='number' {...getCardNumberProps({ onChange: handleChangeCardNumber, required: false })} />
                                <input name='expiry' {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} />
                            </PaymentInputsWrapper>
                            {errors.globalError && <span className="text-danger d-block ms-4">{errors.globalError.message}</span>}
                            <button className="btn btn-primary mb-0 float-end mt-2 me-4" type='submit'>{t("SAVE")}</button>
                            <button className="btn text-secondary border-0 float-end mt-2" type='submit' onClick={() => props.onDiscard()}>{t("DISCARD")}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCreditCardDialog
