import { useEffect, useState } from "react";
import RideDetailMobile from "./RideDetailMobile";
import RideDetail from "./RideDetail";
import { RidesService } from "data/services";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "components/common/Spinner";

export default function RideDetailChange() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [ride, setRide] = useState();
  const parameters = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchAndSetDetail = async () => {
      const response = await RidesService.getByRideIdDetails(parameters);
      if (response.isSuccess) {
        setRide(response.data);
        setIsLoading(false);
      }
      else {
        navigate("*");
      }
    }
    fetchAndSetDetail();
    window.scrollTo(0, 0);
  }, [parameters]);

  if (isLoading) {
    return (
      <Spinner />
    )
  }

  return (
    <>
      {isMobile ?
        <div>
          {ride != null && <RideDetailMobile ride={ride}></RideDetailMobile>}
        </div>
        :
        <div>
          {ride != null && <RideDetail ride={ride}></RideDetail>}
        </div>}
    </>
  );
}