import React, { useEffect, useRef, useState } from "react";
import "./../../../../layout/common.css"
import { useTranslation } from "react-i18next";
import { toastify } from "utils/toastify";
import moment from "moment/moment";
import { RidesService, UserRidesService, NotificationsService, ConnectionsService, EPayService } from "data/services";
import { useNavigate, useSearchParams } from "react-router-dom";
import userProfileStore from "data/stores/UserProfileStore";
import { httpSmartClient } from "config/httpClient";
import { useWindowSize } from "@uidotdev/usehooks";
import Modal from "react-responsive-modal";
import globalStore from "data/stores/GlobalStore";
import ReviewModal from "./modals/ReviewModal";
import ReviewDriverModal from "./modals/ReviewDriverModal";
import Select, { components } from 'react-select';
import FinalizationDialog from "./modals/FinalizationDialog";
import { ConfirmationDialog } from "./modals";
import { BeatLoader } from 'react-spinners';
import SuccessInfo from "./modals/SuccessInfo";
import SuccessInfoFinalize from "./modals/SuccessInfoFinalize";
import CancelRideDialog from "./modals/CancelRideDialog";
import { PagingComponent } from "components/layout/components";
import { reactSelectCustomStyle } from "assets/styles/react-select-customstyle";
import ConfirmFinalizationCash from "./modals/ConfirmFinalizationCash";
import { AuthService } from "data/services";
import { useIsFirstRender } from "../../chat/hooks/useIsFirstRender";
import { merchantID, paymentCSSUrl, paymentJSUrl, paymentLocale, paymentUrl, terminalID, versionPayment } from "config/config";
import { observer } from "mobx-react-lite";

const MyRides = observer(() => {
    const [rides, setRides] = useState([]);
    const [data, setData] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [confirmCashFinalizationDialog, setconfirmCashFinalizationDialog] = useState(false);
    const [isCancellation, setIsCancellation] = useState(false);
    const [selectedRide, setSelectedRide] = useState();
    const [ride, setRide] = useState([]);
    const user = userProfileStore.user
    const [showReviewDialog, setShowReviewDialog] = useState();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams("");
    const [showCCInput, setShowCCInput] = useState(false);
    var tranCode = searchParams.get("TranCode");
    const [notificationSeen, setNotificationSeen] = useState(false);
    const size = useWindowSize();
    const [tab, setTab] = useState(0);
    const [ridesStatusCount, setRidesStatusCount] = useState();
    const activeRef = useRef();
    const cancelledRef = useRef();
    const completedRef = useRef();
    const [isMobile, setIsMobile] = useState();
    const [showDriverReviewModal, setShowDriverReviewModal] = useState();
    const [activeFilter, setActiveFilter] = useState();
    const [dataTotalCount, setDataTotalCount] = useState();
    const [availableRides, setAvailableRides] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [finalizationDialog, setFinalizationDialog] = useState();
    const [sortBySelect, setSortBySelect] = useState();
    const [IsTablet, setIsTablet] = useState();
    const [pagingData, setPagingData] = useState({ pageNumber: 1, pageSize: 5, filter: null, sort: null });
    const [showSuccessInfo, setShowSuccesInfo] = useState();
    const [showSuccessInfoReserved, setShowSuccesInfoReserved] = useState(false);
    const [showCancelRideDialog, setShowCancelRideDialog] = useState(false);
    const [transactionsExist, setTransactionsExist] = useState(false);
    const [filter, setFilter] = useState();
    const [sort, setSort] = useState();
    const isFirstRender = useIsFirstRender();
    var logged_user = AuthService.getCurrentUser();

    const sortBy = [
        { value: "Newest", label: t("NEWEST") },
        { value: "Oldest", label: t("OLDEST") },
        { value: "Highest", label: t("HIGHEST") },
        { value: "Lowest", label: t("LOWEST") }
    ]

    const RideDetail = (ride) => {
        navigate({ pathname: `/rides/${ride.rideId}/${ride.rideMilestoneFromId}/${ride.rideMilestoneToId}` });
    }

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = paymentJSUrl;
    //     script.async = true;

    //     const link = document.createElement('link');
    //     link.rel = 'stylesheet';
    //     link.href = paymentCSSUrl;

    //     document.body.appendChild(script);
    //     document.head.appendChild(link);
    // }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            const link = document.querySelector('link[rel="shortcut icon"]');
            const originalFavicon = '/assets/images/favicon.ico';
            if (document.hidden && tranCode == "000" && notificationSeen == false) {
                document.title = `(1) ` + t("NEW_NOTIFICATION");
                const img = document.createElement('img')
                img.src = originalFavicon;
                img.onload = () => {
                    const canvas = document.createElement('canvas')
                    canvas.width = img.width
                    canvas.height = img.height
                    const context = canvas.getContext('2d')
                    context.drawImage(img, 0, 0, img.width, img.height)
                    context.beginPath()
                    context.arc(img.width - img.width / 5, img.height / 5,
                        img.width / 5, 0, 2 * Math.PI)
                    context.fillStyle = '#f00000'
                    context.fill();
                    const notificationFavicon = canvas.toDataURL('image/png');
                    link.href = notificationFavicon;
                }
                setNotificationSeen(false);
            } else {
                document.title = 'VoziMe.ba';
                setNotificationSeen(true);
                link.href = originalFavicon;
            }
        };
        handleVisibilityChange();
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [notificationSeen]);

    useEffect(() => {
        fetchAndSetData();
    }, [tab, filter, sort])

    useEffect(() => {
        if (tranCode == "000") {
            setShowSuccesInfoReserved(true);
            // const closeButtonPaymentModal = document.getElementsByClassName("upc-close");
            // if (closeButtonPaymentModal[0]) {
            //     closeButtonPaymentModal[0].click();
            // }
            window.history.replaceState(null, null, "/my-profile/my-rides");
        }
        else if (tranCode != null && tranCode != "000") {
            toastify.error(t("ERROR_WHILE_RESERVING"));
        }
    }, [])

    const fetchAndSetData = async () => {
        setIsLoading(true);
        let result = await RidesService.getRidesAndUserRidesByUser({ ...pagingData, driverId: parseInt(logged_user.Id), rideStatus: tab, filter: filter, sort: sort });
        if (result.isSuccess && result.data.items) {
            setPagingData(prev => {
                return {
                    ...prev,
                    totalCount: result.data.items.length > 0 ? result.data.items[0].totalCount : 0
                }
            })
            setRidesStatusCount(result.data.items.length > 0 ? result.data.items[0].totalCount : 0)
            if (filter == null)
                setAvailableRides(result.data.items.length)
            setRides(result.data.items);
            setData(result.data.items);
            setDataTotalCount(result.data);
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
        window.scrollTo(0, 0);

    };
    const HandleFinalization = async (ride) => {
        if (transactionsExist) {
            setFinalizationDialog(true);
            setSelectedRide(ride);
            setconfirmCashFinalizationDialog(true);
            //await initUPCPayment(ride);
        }
        else {
            FinalizeBooking(ride);
        }
    }

    // useEffect(() => {
    //     if (!isFirstRender) {
    //         if (globalStore.lastOrderIdFinalizeRide === globalStore.lastOrderIdRequestFinalizeRide) {
    //             if (globalStore.tranCode === "000") {
    //                 setShowSuccesInfo(true);
    //                 const closeButtonPaymentModal = document.getElementsByClassName("upc-close");
    //                 if (closeButtonPaymentModal[0]) {
    //                     closeButtonPaymentModal[0].click();
    //                 }
    //                 changeTabs(completedRef, 2);
    //                 onPageChange(1);
    //                 setFinalizationDialog(false);
    //             }
    //             else {
    //                 const closeButtonPaymentModal = document.getElementsByClassName("upc-close");
    //                 if (closeButtonPaymentModal[0]) {
    //                     closeButtonPaymentModal[0].click();
    //                 }
    //                 toastify.error(t("ERROR_FINALIZATION"))
    //             }
    //         }
    //     }
    // }, [globalStore.tranCode]);

    const FinalizeBooking = async (e, creditCardNumber = null) => {
        setconfirmCashFinalizationDialog(false);
        setFinalizationDialog(false);
        const finalize = async () => {
            const response = e.isDriver == true ? await RidesService.changeRideStatus(e.rideId, "Completed", creditCardNumber) : null;
            if (response.isSuccess) {
                setShowSuccesInfo(true);
                changeTabs(completedRef, 2);
                onPageChange(1);
                setFinalizationDialog(false);
            } else {
                toastify.error(t("ERROR_FINALIZATION"))
            }
            setConfirmDialog(false);
            setShowCCInput(false);
        }
        finalize();
    }

    const initUPCPayment = async (data) => {
        let object = {
            currency: 977,
            amount: '0',
            time: moment(new Date()).format("yyMMDDHHmmss").slice(2),
            orderId: `${data.rideId}-${data.driverId}-${new Date().getTime()}`,
            description: t("DESCRIPTION_FINALIZE_RIDE"),
            delay: 0
        }

        const getSignature = async () => {
            let response = await EPayService.getSignature({ ...object });
            if (response.data) {
                // const requestBody = {
                //   orderId: object.orderId.toString(),
                //   userId: user.Id,
                //   rideId: parameters.rideId,
                //   price: ride.price,
                //   rideMilestoneFromId: ride.milestonesForUser[0].id,
                //   rideMilestoneToId: ride.milestonesForUser[1].id,
                // };
                // const request = await CreditCardPaymentRequestsService.add(requestBody);
                window.UpcPayment({
                    url: paymentUrl,
                    payment: {
                        Version: versionPayment,
                        MerchantID: merchantID,
                        TerminalID: terminalID,
                        locale: paymentLocale,
                        Signature: response.data,
                        Currency: object.currency,
                        TotalAmount: object.amount,
                        PurchaseTime: object.time,
                        OrderID: object.orderId,
                        PurchaseDesc: object.description,
                        Delay: 0
                    },
                    options: {
                        modal: true,
                        modalHeight: "600px"
                    }
                });
                const modal = document.getElementsByClassName("upc-modal");
                if (modal[0]) {
                    if (isMobile) {
                        modal[0].style.width = "94%";
                    }
                    else {
                        modal[0].style.width = "40%";
                    }
                    globalStore.changeLastOrderIdFinalizeRide(object.orderId);
                }
            }
        }
        getSignature();
    }
    const onPageChange = (page) => {
        pagingData.pageNumber = page;
        setPagingData({ ...pagingData });
        fetchAndSetData();
    }
    const CancelBooking = async (e) => {
        const finalize = async () => {
            if (moment(e.startDateTime) < moment(new Date())) {
                setConfirmDialog(false);
                toastify.error(t("ERROR_RIDE_STARTING_CANCELLED"));
                await fetchAndSetData();
                return;
            }
            const response = e.isDriver == true ? await RidesService.changeRideStatus(e.rideId, "Cancelled") : await UserRidesService.changeRideStatus(e.userRideId, "Cancelled");
            if (response.isSuccess) {
                setShowCancelRideDialog(true);
                if (response.data.passengers && response.data.passengers.length > 0) {
                    response.data.passengers.forEach(x => {
                        const apiResponse = async () => {
                            const notifResponse = await NotificationsService.add({ rideMilestoneFromId: x.rideMilstoneFromId, rideMilestoneToId: x.rideMilestoneToId, userId: x.userId, senderId: 2, text: 'RideCancelled', rideId: x.rideId })
                            await ConnectionsService.InvokeCancelRide({ userId: x.userId, notificationId: notifResponse.data.id });
                        }
                        apiResponse();
                    })
                } else {
                    if (e.isDriver == false) {
                        const apiResponse = async () => {
                            const rideModel = response.data.rideId ? await RidesService.getById(response.data.rideId) : await RidesService.getById(response.data.id)
                            const notifResponse = await NotificationsService.add({ rideMilestoneFromId: rideModel.data.milestones[0].id, rideMilestoneToId: rideModel.data.milestones[rideModel.data.milestonesCount - 1].id, userId: rideModel.data.driverId, senderId: 2, text: 'UserRideCancelled', rideId: response.data.rideId ? response.data.rideId : response.data.id, userCancellationId: response.data.userId })
                            await ConnectionsService.InvokeCancelRide({ userId: rideModel.data.driverId, notificationId: notifResponse.data.id });
                        }
                        apiResponse();
                    }
                }
                fetchAndSetData();
            } else {
                if (response.error === "RIDE_ALREADY_CANCELLED") {
                    toastify.error(t("RIDE_ALREADY_CANCELLED"));
                    fetchAndSetData();
                }
                else if (response.error === "RIDE_ALREADY_UPCOMING") {
                    toastify.error(t("RIDE_ALREADY_UPCOMING"));
                    fetchAndSetData();
                }
                else if (response.error === "RIDE_ALREADY_COMPLETED") {
                    toastify.error(t("RIDE_ALREADY_COMPLETED"));
                    fetchAndSetData();
                }
                else {
                    toastify.error(t("ERROR_RIDE_CANCELLED"));
                }
            }
            setConfirmDialog(false);
        }
        finalize();
    }
    const handleShowReviewModal = async (ride) => {
        const response = await UserRidesService.GetAllPassengersByRideId(ride.rideId)
        ride.userRides = response.data;
        setRide(ride)
        if (ride.isDriver) {
            setShowReviewDialog(prev => !prev);
        }
        else {
            setShowDriverReviewModal(true);
        }
    }

    const handleOpenConfirmCashFinalizationDialog = async (ride) => {
        const response = await RidesService.transactionsExist(ride.rideId);
        if (response) {
            setTransactionsExist(response.data);
        }
        setSelectedRide(ride);
        setconfirmCashFinalizationDialog(true);
    }
    useEffect(() => {
        if (size.width > 522) {
            setIsTablet(true)
        } else {
            setIsTablet(false)
        }
        if (size.width < 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        if (size.width >= 1400) {
            const outlet = document.getElementById("outlet");
            outlet.classList.remove("w-50")
            outlet.classList.remove("outlet-test")
            outlet.className = "w-75 col-md"
        }
        else {
            const outlet = document.getElementById("outlet");
            outlet.classList.remove("w-75")
            outlet.classList.remove("outlet-test")
            outlet.className = "width-70"
        }
        if (size.width >= 1200) {
            const div = document.createElement('div')
            const header = document.getElementById("profileHeader")
            header.classList.remove("d-none")
            header.className = "profileHeader"
            const element = document.createElement("h6");
            const spanElement = document.createElement("span");
            spanElement.innerText = t("MY_RIDES_DETAILS")
            spanElement.className = 'span-header mt-0 pb-2'
            element.innerText = t("MY_RIDES")
            element.className = 'header-profile mb-1';
            // div.className = "pt-2"
            div.appendChild(element)
            div.appendChild(spanElement)
            header.appendChild(div)
            return () => header.removeChild(div);
        }
        else {
            const header = document.getElementById("profileHeader");
            header.classList.remove("profileHeader")
        }
        if (window.innerWidth < 767) {
            const outlet = document.getElementById("outlet");
            outlet.style.marginBottom = "-20px";
        }
    }, [size])
    const changeTabs = (ref, tab) => {
        setPagingData({ pageNumber: 1, pageSize: 5 });
        setTab(tab);
        setFilter(null);
        setSort(null);
        setSortBySelect();
        setActiveFilter();
        window.scrollTo(0, 0);
        const tabPosition = ref.current.offsetLeft;
        const tabWidth = ref.current.offsetWidth;
        const parentWidth = ref.current.parentElement.offsetWidth;
        const scrollTo = tabPosition - (parentWidth / 2) + (tabWidth / 2);
        ref.current.parentElement.scrollLeft = scrollTo;
    }

    const handleFilter = (e, obj) => {
        setPagingData(prev => {
            return {
                pageNumber: 1,
                pageSize: 5,
                filter: null,
                sort: null
            }
        })
        if (obj.clearAll === true) {
            setRides(data)
            setActiveFilter();
            setFilter(null)
            setRidesStatusCount(data.length)
            return;
        }
        if (e.target.id == "Driver") {
            setFilter(0)
            setActiveFilter({ driver: true })
        } else {
            setFilter(1)
            setActiveFilter({ passenger: true })
        }
    }
    const handleSorting = (e) => {
        setRides([])
        setSortBySelect(e.value)
        setPagingData(prev => {
            return {
                pageNumber: 1,
                pageSize: 5
            }
        })
        if (e.value === "Newest") {
            setSort(0);

        } else if (e.value === "Oldest") {
            setSort(1);
        } else if (e.value === "Highest") {
            setSort(2);
        } else if (e.value === "Lowest") {
            setSort(3);
        }
        else {
            setRides(rides)
        }
    }
    useEffect(() => {
        if (size.width < 767) {
            const div = document.getElementById("my-rides-div")
            div.className = "user-info-mobile-background"
            return () => div.classList.remove("user-info-mobile-background")
        }
    }, [])
    return (

        <div className="user-info-mobile-background" id="my-rides-div">
            {isMobile && <div className="d-flex w-100  pt-1 pb-4 bg-purple flex-column text-center">
                <p className='fs18 white  fw-600'>{t("MY_RIDES")}</p>
                <p className='fs12 white  fw-600'>{t("MY_RIDES_DETAILS")}</p>
            </div>}
            {isMobile && <div style={{ position: "absolute", marginTop: "-3px" }} className="d-flex w-100  pt-1 pb-4 bg-purple flex-column text-center">
            </div>}
            <div className={isMobile ? "col my-rides mt-3 mt-md-5 pt-3" : "col my-rides mt-3 mt-md-5 p-3"} style={isMobile ? { minHeight: "0px", borderRadius: '1.5em' } : { minHeight: "600px" }} >
                <div className={"card-body pt-2 pt-md-0 p-0"} style={isMobile ? { width: "90%", margin: "auto" } : {}}>
                    <div className={`tabs-div mb-4 mt-0 gap-4 d-flex`}>
                        <a className={`mb-0 line-height text-nowrap cursor-pointer tabs-normal ${tab === 0 ? "active tab-active" : ""}`} onClick={(e) => changeTabs(activeRef, 0)} ref={activeRef}>
                            <div className="d-flex align-items-center">
                                <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke={`${tab === 0 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.58301 10.0002V8.7669C7.58301 7.17524 8.70801 6.53357 10.083 7.32524L11.1497 7.9419L12.2163 8.55857C13.5913 9.35024 13.5913 10.6502 12.2163 11.4419L11.1497 12.0586L10.083 12.6752C8.70801 13.4669 7.58301 12.8169 7.58301 11.2336V10.0002Z" stroke={`${tab === 0 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {t("UPCOMING_RIDES")} {tab === 0 && !isLoading ? `(${ridesStatusCount})` : ""}
                            </div>
                        </a>
                        <a className={`line-height text-nowrap cursor-pointer tabs-normal ${tab === 1 ? "active tab-active" : ""}`} onClick={() => changeTabs(cancelledRef, 1)} ref={cancelledRef}>
                            <div className="d-flex align-items-center">
                                <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7.6416 12.3583L12.3583 7.6416" stroke={`${tab === 1 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.3583 12.3583L7.6416 7.6416" stroke={`${tab === 1 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke={`${tab === 1 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {t("CANCELLED_RIDES")} {tab === 1 && !isLoading ? `(${ridesStatusCount})` : ""}
                            </div>
                        </a>
                        <a className={`line-height text-nowrap cursor-pointer tabs-normal ${tab === 2 ? "active tab-active" : ""}`} onClick={() => changeTabs(completedRef, 2)} ref={completedRef}>
                            <div className="d-flex align-items-center">
                                <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke={`${tab === 2 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.4585 9.99993L8.81683 12.3583L13.5418 7.6416" stroke={`${tab === 2 ? "#900FC8" : "#7E7E7E"}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {t("COMPLETED_RIDES")} {tab === 2 && !isLoading ? `(${ridesStatusCount})` : ""}
                            </div>
                        </a>
                    </div>
                    {availableRides > 0 && <div className="d-flex justify-content-between sorting-filter mb-2">
                        <div className="d-flex align-items-center gap-1 mb-1">
                            <div id="Driver" className={`filter-driver-passenger gray ${activeFilter && activeFilter.driver ? "filter-driver-passenger-active" : ""} cursor-pointer`} onClick={(e) => handleFilter(e, { driverFilter: true })}>
                                {t("DRIVER")}
                            </div>
                            <div id="Passenger" className={`filter-driver-passenger gray ${activeFilter && activeFilter.passenger ? "filter-driver-passenger-active" : ""} cursor-pointer `} onClick={(e) => handleFilter(e, { driverFilter: false })}>
                                {t("PASSENGER")}
                            </div>
                            {activeFilter &&
                                <div className="clear-filter-driver-passenger  cursor-pointer" onClick={(e) => handleFilter(e, { clearAll: true })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 13" fill="none">
                                        <path d="M0.857422 12.1417L12.1408 0.858398" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.1408 12.1417L0.857422 0.858398" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>}
                        </div>
                        <div className="d-flex align-items-center gap-1" style={{ zIndex: 100 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M6.38201 14.5209L2.82617 10.9722" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.38281 2.47925V14.5209" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.6172 2.47925L14.173 6.028" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.6172 14.5209V2.47925" stroke="#7E7E7E" strokeWidth="1.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <Select
                                styles={reactSelectCustomStyle}
                                value={sortBy.filter(s => s.value === sortBySelect)}
                                options={sortBy}
                                placeholder={t("CHOOSE")}
                                className="basic react-select-price"
                                components={{ SingleValue: ({ children, ...props }) => (<components.SingleValue {...props}>{children}</components.SingleValue>) }}
                                onChange={(e) => handleSorting(e)}
                            />

                        </div>
                    </div>}
                    {isLoading && <BeatLoader color="#900fc8" size={10} className='text-center' />}
                    {rides && !isLoading && tab === 0 && rides.map((ride, index) => {
                        const toDate = moment.utc(new Date(ride.startDateTime))
                        const fromDate = moment.utc(new Date(ride.endDateTime))
                        var minutes = fromDate.diff(toDate, "minutes")
                        var hours = fromDate.diff(toDate, "hours");
                        var min = minutes % 60;
                        ride.dateTimeFormatted = minutes >= 60 ? hours.toString().length > 2 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                        return (
                            ride.rideStatus == 'Upcoming' &&
                            <div onClick={() => RideDetail(ride)} className={`card p-3 shadow mb-3 bg-white cursor-pointer pt-2 pb-2 ${ride.milestonesCount > 2 ? 'pt-3' : ''}`} key={index}>
                                {logged_user.Id != ride.driverId && <div className="d-flex justify-content-between ps-2 mb-2 pt-3" >
                                    <div className={`d-flex`} onClick={(e) => { e.stopPropagation(); navigate(`/user/${ride.driverId}`) }}>
                                        <div className="pe-2" >
                                            <img className="my-rides-image" src={ride.driver.profilePhotoId ? httpSmartClient.getPhotoUrl(ride.driver.profilePhotoId) : "/assets/images/avatar/01.jpg"} />
                                        </div>
                                        <div className={`d-flex flex-column ${ride.driversAverageRating != 0 ? "" : "justify-content-center"}`} >
                                            <h6 className="driver" style={{ color: "black", marginTop: "-12px" }}>{ride.driver.firstName + " " + ride.driver.lastName}</h6>
                                            <div className="d-flex align-items-center rating-div">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                                    <path d="M6.4502 0.794298L7.41809 2.74608C7.55008 3.01778 7.90204 3.27839 8.19901 3.32829L9.95331 3.62217C11.0752 3.81069 11.3392 4.63133 10.5307 5.44087L9.1669 6.81599C8.93592 7.04888 8.80944 7.49801 8.88093 7.81961L9.27139 9.52187C9.57935 10.8693 8.86993 11.3905 7.68756 10.6863L6.04324 9.70485C5.74628 9.52742 5.25683 9.52742 4.95437 9.70485L3.31005 10.6863C2.13318 11.3905 1.41826 10.8637 1.72622 9.52187L2.11668 7.81961C2.18817 7.49801 2.06169 7.04888 1.83071 6.81599L0.466861 5.44087C-0.33605 4.63133 -0.0775784 3.81069 1.0443 3.62217L2.7986 3.32829C3.09007 3.27839 3.44203 3.01778 3.57402 2.74608L4.54191 0.794298C5.06985 -0.264766 5.92776 -0.264766 6.4502 0.794298Z" fill="#F7C32E" />
                                                </svg>
                                                <p className="mb-0 ms-1 rating" >{ride.driversAverageRating ? ride.driversAverageRating.toFixed(1) : "0.0"}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="me-2">
                                        <div className="d-flex align-items-center gap-1">
                                            <h5 className="paragraphs-purple-XX-large mb-0" style={{ marginTop: "3px" }}>{ride.passengersPrice} </h5><span className="ms-1 BAM-big">{t("VALUTE")}</span>
                                        </div>
                                    </div>
                                </div>}
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    zIndex: "1"
                                }}>
                                    <button className="position-absolute" style={{ marginTop: ride.milestonesCount > 2 ? "-4px" : "18px", opacity: ride.currentPassengersCount == 0 ? "0.6" : "none", borderRadius: "30px", height: "22px", width: "80px", border: "1px solid grey", backgroundColor: "white" }}>
                                        <p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{ride.dateTimeFormatted}</p>
                                    </button>
                                    <div className="position-absolute row justify-content-center">
                                        {ride.milestonesCount - 2 <= 3 && [...Array(ride.milestonesCount - 2)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                        {ride.milestonesCount - 2 > 3 && [...Array(1)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {<p className="position-absolute" style={{ fontSize: "12px", marginLeft: "-2px", color: "#e61870", fontWeight: "bold", marginTop: "42px" }}>
                                        {ride.milestonesCount <= 2 ? t("NO_STOP") : `${t("STOP")} (${ride.milestonesCount - 2})`}
                                    </p>}
                                </div>
                                <div className="row px-2" >
                                    <div className="col-auto pe-0 text-start" style={isMobile ? { maxWidth: "95px", overflowWrap: "break-word" } : { maxWidth: "150px" }}>
                                        <h6 className={`mt-3 pt-05 fw-500 ${isMobile ? "fs14" : "fs16"} black`}>{ride.cityFrom}</h6>
                                    </div>
                                    <div className="col px-0">
                                        <div className="row mt-3">
                                            <div className="col-auto text-end zi10 pe-0">
                                                <svg className="p-1 pe-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                                    <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                                                </svg>
                                                <p className="" style={{ fontSize: "13px", marginRight: "-10px", color: "black" }}>{moment.utc(ride.startDateTime).format("HH:mm")} </p>
                                            </div>
                                            <div className="col px-0" style={{ textAlign: "center", marginTop: "-14px" }}>
                                                <div className=" text-center">
                                                    <div className="position-relative my-4 mb-6" style={{ opacity: ride.currentPassengersCount == 0 ? "0.6" : "none" }}>
                                                        <hr style={{ border: "none", borderTop: "2px dashed grey" }} className="opacity-5 position-relative" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto zi10 ps-0 text-start" >
                                                <svg className="p-1 ps-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                                    <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                                                </svg>
                                                <p style={{ fontSize: "13px", marginLeft: "-10px", color: "black" }}>{moment.utc(ride.endDateTime).format("HH:mm")} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto ps-1" style={isMobile ? { maxWidth: "95px", overflowWrap: "break-word" } : { maxWidth: "150px" }}>
                                        <h6 className={`mt-3 pt-05 fw-500 ${isMobile ? "fs14" : "fs16"} black`}>{ride.cityTo}</h6>
                                    </div>
                                </div>
                                <div className={`d-flex gap-3 justify-content-sm-start justify-content-between align-items-center px-2`} onClick={() => RideDetail(ride)}>
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M10.9859 2.00488H6.01342C4.24967 2.00488 3.86009 2.88322 3.63342 3.95988L2.83301 7.79197H14.1663L13.3659 3.95988C13.1393 2.88322 12.7497 2.00488 10.9859 2.00488Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.5767 14.0395C15.6546 14.8682 14.9888 15.5837 14.1388 15.5837H12.8071C12.0421 15.5837 11.9358 15.2578 11.8013 14.8541L11.6596 14.4291C11.4613 13.8482 11.3338 13.4587 10.3138 13.4587H6.68709C5.66709 13.4587 5.51834 13.8978 5.34126 14.4291L5.19959 14.8541C5.06501 15.2578 4.95876 15.5837 4.19376 15.5837H2.86209C2.01209 15.5837 1.34626 14.8682 1.42417 14.0395L1.82084 9.72574C1.92001 8.66324 2.12542 7.79199 3.98126 7.79199H13.0196C14.8754 7.79199 15.0808 8.66324 15.18 9.72574L15.5767 14.0395Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.83333 5.66699H2.125" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M14.8753 5.66699H14.167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.5 2.125V3.54167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.4375 3.54199H9.5625" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.25 10.625H6.375" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.625 10.625H12.75" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className="labels-small ms-1" style={{ marginTop: "2px", wordBreak:"break-word", maxWidth:"89%" }}> {ride.driverVehicle.vehicleModel.name}</span>
                                    </div>
                                    {/* <div className="d-flex align-items-center">
                                        {ride.countPassengers > 0 ? Array.from({ length: ride.countPassengers }, (_, index) => (
                                            <svg key={index} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                                <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        )) : (<><svg key={index} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                            <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                            <span className="labels-small ms-1"> {ride.countPassengers}</span>
                                            <span className="labels-small ms-1"> {t("PASSENGERS_2")}</span></>
                                        )}
                                    </div> */}
                                    <div className="d-flex" style={isMobile ? {} : { position: "absolute", marginLeft: "200px" }}>
                                        {[...Array(ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        )}
                                        {[...Array(ride.maxPassengersCount - ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        )}
                                    </div>
                                    {logged_user.Id == ride.driverId && <div className="d-flex" style={isMobile ? {} : { position: "absolute", marginLeft: "350px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23 22" fill="none">
                                            <g clipPath="url(#clip0_1529_6528)">
                                                <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1529_6528">
                                                    <rect width="22.47" height="21.09" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span className="BAM ms-1"> {ride.totalPrice} </span><span className="labels-small ms-1">{t("VALUTE")}</span>
                                    </div>}
                                </div>
                                <div style={{ borderBottom: "2px solid lightgrey" }} className="text-center my-3 mx-1">
                                </div>
                                <div className="d-flex px-2 time-cancel-ride-mobile ">
                                    <div className={`d-flex w-100 justify-content-between align-items-between ride-card-below h-100`}>
                                        <div className="d-flex gap-6 ride-card-below-time" onClick={() => RideDetail(ride)}>
                                            <div className="d-flex flex-column">
                                                <span className="labels-small">{t("DEPARTURE_TIME")}:</span>
                                                <div className="d-flex align-items-center gap-1 mt-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                                        <g clipPath="url(#clip0_503_15992)">
                                                            <path d="M11.5303 2.4082L15.9597 6.83766L11.5303 11.2671" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M3.55371 6.83789H15.8351" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M1 1L1 11.9459" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_503_15992">
                                                                <rect width="16" height="13" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span className="date-time-rides text-nowrap">{moment(ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="labels-small">{t("TIME_END")}:</span>
                                                <div className="d-flex align-items-center gap-1 mt-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                                                        <path d="M8.97607 2.43555L13.4055 6.86501L8.97607 11.2945" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 6.86523H13.2814" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.9595 1.02734L15.9595 11.9733" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className="date-time-rides text-nowrap">{moment(ride.endDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                                                </div>
                                            </div>
                                        </div>
                                        {ride.canFinalize == false ?
                                            moment(ride.startDateTime) > moment(new Date()) ?
                                                <div className=" mt-md-0 mt-4 cursor-pointer align-items-center d-flex justify-content-center gap-1" onClick={(e) => { e.stopPropagation(); setSelectedRide(ride); setIsCancellation(true); setConfirmDialog(true) }}>
                                                    <svg className="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M7.6416 12.3583L12.3583 7.6416" stroke="#E76868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.3583 12.3583L7.6416 7.6416" stroke="#E76868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z" stroke="#E76868" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className="delete-small">{t("CANCEL_BOOKING")}</span>
                                                </div>
                                                :
                                                ""
                                            :
                                            ride.isDriver &&
                                            <div className="d-flex mt-sm-0 mt-2 justify-content-between align-items-center w-100">
                                                {window.innerWidth < 520 && ride.totalEarningsForDriver > 0 &&
                                                    <div className="d-flex col-6 flex-column justify-content-end gap-1 me-2">
                                                        <span className="labels-small">{t("TOTAL_INCOME")}:</span>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23 22" fill="none">
                                                                <g clipPath="url(#clip0_1529_6528)">
                                                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_1529_6528">
                                                                        <rect width="22.47" height="21.09" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            <p className="purple my-0 fs18 fw-500">{ride.totalEarningsForDriver > 0 ? ride.totalEarningsForDriver : "0"} </p>
                                                            <p className="gray my-0 fs18 fw-500">{t("VALUTE")}</p>
                                                        </div>
                                                    </div>}
                                                <div className={`w-100  d-flex justify-content-end`}>
                                                    <button onClick={async (e) => { e.stopPropagation(); await handleOpenConfirmCashFinalizationDialog(ride); }} className="finalize-button text-white">
                                                        {t("FINALIZE")}</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {!isLoading && tab === 0 && rides.filter(ride => ride.rideStatus === 'Upcoming').length === 0 &&
                        <div className='d-flex justify-content-between mb-4 pe-4'>
                            <div className="w-100 text-center"><span className="labels">{t("NO_ACTIVE_RIDES")}</span></div>
                        </div>}
                    {rides && !isLoading && tab === 1 && rides.map((ride, index) => {
                        const toDate = moment.utc(new Date(ride.startDateTime))
                        const fromDate = moment.utc(new Date(ride.endDateTime))
                        var minutes = fromDate.diff(toDate, "minutes")
                        var hours = fromDate.diff(toDate, "hours");
                        var dateStart = moment.utc(ride.startDateTime).format("DD.MM.yyyy");
                        var dateEnd = moment.utc(ride.endDateTime).format("DD.MM.yyyy");
                        var min = minutes % 60;
                        ride.dateTimeFormatted = minutes >= 60 ? hours.toString().length > 2 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`
                        return (
                            ride.rideStatus == 'Cancelled' &&
                            <div onClick={() => RideDetail(ride)} className={`card p-3 shadow mb-3 bg-white cursor-pointer pt-2 pb-2 ${ride.milestonesCount > 2 ? 'pt-3' : ''}`} key={index}>
                                {logged_user.Id != ride.driverId && <div className="d-flex justify-content-between ps-2 mb-2 pt-3" >
                                    <div className="d-flex" onClick={(e) => { e.stopPropagation(); navigate(`/user/${ride.driverId}`) }}>
                                        <div className="pe-2" >
                                            <img className="my-rides-image" src={ride.driver.profilePhotoId ? httpSmartClient.getPhotoUrl(ride.driver.profilePhotoId) : "/assets/images/avatar/01.jpg"} />
                                        </div>
                                        <div className={`d-flex flex-column ${ride.driversAverageRating != 0 ? "" : "justify-content-center"}`} >
                                            <h6 className="driver" style={{ color: "black", marginTop: "-12px" }}>{ride.driver.firstName + " " + ride.driver.lastName}</h6>
                                            <div className="d-flex align-items-center rating-div">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                                    <path d="M6.4502 0.794298L7.41809 2.74608C7.55008 3.01778 7.90204 3.27839 8.19901 3.32829L9.95331 3.62217C11.0752 3.81069 11.3392 4.63133 10.5307 5.44087L9.1669 6.81599C8.93592 7.04888 8.80944 7.49801 8.88093 7.81961L9.27139 9.52187C9.57935 10.8693 8.86993 11.3905 7.68756 10.6863L6.04324 9.70485C5.74628 9.52742 5.25683 9.52742 4.95437 9.70485L3.31005 10.6863C2.13318 11.3905 1.41826 10.8637 1.72622 9.52187L2.11668 7.81961C2.18817 7.49801 2.06169 7.04888 1.83071 6.81599L0.466861 5.44087C-0.33605 4.63133 -0.0775784 3.81069 1.0443 3.62217L2.7986 3.32829C3.09007 3.27839 3.44203 3.01778 3.57402 2.74608L4.54191 0.794298C5.06985 -0.264766 5.92776 -0.264766 6.4502 0.794298Z" fill="#F7C32E" />
                                                </svg>
                                                <p className="mb-0 ms-1 rating" >{ride.driversAverageRating ? ride.driversAverageRating.toFixed(1) : "0.0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="me-2">
                                        <div className="d-flex align-items-center gap-1">
                                            <h5 className="paragraphs-purple-XX-large mb-0" style={{ marginTop: "3px" }}>{ride.passengersPrice} </h5><span className="ms-1 BAM-big">{t("VALUTE")}</span>
                                        </div>
                                    </div>
                                </div>}
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    zIndex: "1"
                                }}>
                                    <button className="position-absolute" style={{ marginTop: ride.milestonesCount > 2 ? "-4px" : "18px", opacity: ride.currentPassengersCount == 0 ? "0.6" : "none", borderRadius: "30px", height: "22px", width: "80px", border: "1px solid grey", backgroundColor: "white" }}>
                                        <p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{ride.dateTimeFormatted}</p>
                                    </button>
                                    <div className="position-absolute row justify-content-center">
                                        {ride.milestonesCount - 2 <= 3 && [...Array(ride.milestonesCount - 2)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                        {ride.milestonesCount - 2 > 3 && [...Array(1)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {<p className="position-absolute" style={{ fontSize: "12px", marginLeft: "-2px", color: "#e61870", fontWeight: "bold", marginTop: "42px" }}>
                                        {ride.milestonesCount <= 2 ? t("NO_STOP") : `${t("STOP")} (${ride.milestonesCount - 2})`}
                                    </p>}
                                </div>
                                <div className="row px-2" >
                                    <div className="col-auto pe-0 text-start" style={isMobile ? { maxWidth: "95px", overflowWrap: "break-word" } : { maxWidth: "150px" }}>
                                        <h6 className={`mt-3 pt-05 fw-500 ${isMobile ? "fs14" : "fs16"} black`}>{ride.cityFrom}</h6>
                                    </div>
                                    <div className="col px-0">
                                        <div className="row mt-3">
                                            <div className="col-auto text-end zi10 pe-0">
                                                <svg className="p-1 pe-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                                    <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                                                </svg>
                                                <p className="" style={{ fontSize: "13px", marginRight: "-10px", color: "black" }}>{moment.utc(ride.startDateTime).format("HH:mm")} </p>
                                            </div>
                                            <div className="col px-0" style={{ textAlign: "center", marginTop: "-14px" }}>
                                                <div className=" text-center">
                                                    <div className="position-relative my-4 mb-6" style={{ opacity: ride.currentPassengersCount == 0 ? "0.6" : "none" }}>
                                                        <hr style={{ border: "none", borderTop: "2px dashed grey" }} className="opacity-5 position-relative" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto zi10 ps-0 text-start" >
                                                {/* <i style={{ fontSize: "10px" }} className="bi bi-circle"></i> */}
                                                <svg className="p-1 ps-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                                    <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                                                </svg>
                                                <p style={{ fontSize: "13px", marginLeft: "-10px", color: "black" }}>{moment.utc(ride.endDateTime).format("HH:mm")} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto ps-1" style={isMobile ? { maxWidth: "95px", overflowWrap: "break-word" } : { maxWidth: "150px" }}>
                                        <h6 className={`pt-05 mt-3 fw-500 ${isMobile ? "fs14" : "fs16"} black`}>{ride.cityTo}</h6>
                                    </div>
                                </div>
                                <div className={`d-flex gap-3 justify-content-sm-start justify-content-between align-items-center px-2`} onClick={() => RideDetail(ride)}>
                                    <div className="d-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M10.9859 2.00488H6.01342C4.24967 2.00488 3.86009 2.88322 3.63342 3.95988L2.83301 7.79197H14.1663L13.3659 3.95988C13.1393 2.88322 12.7497 2.00488 10.9859 2.00488Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.5767 14.0395C15.6546 14.8682 14.9888 15.5837 14.1388 15.5837H12.8071C12.0421 15.5837 11.9358 15.2578 11.8013 14.8541L11.6596 14.4291C11.4613 13.8482 11.3338 13.4587 10.3138 13.4587H6.68709C5.66709 13.4587 5.51834 13.8978 5.34126 14.4291L5.19959 14.8541C5.06501 15.2578 4.95876 15.5837 4.19376 15.5837H2.86209C2.01209 15.5837 1.34626 14.8682 1.42417 14.0395L1.82084 9.72574C1.92001 8.66324 2.12542 7.79199 3.98126 7.79199H13.0196C14.8754 7.79199 15.0808 8.66324 15.18 9.72574L15.5767 14.0395Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.83333 5.66699H2.125" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M14.8753 5.66699H14.167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.5 2.125V3.54167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.4375 3.54199H9.5625" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.25 10.625H6.375" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.625 10.625H12.75" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <span className="labels-small ms-1" style={{maxWidth:"89%", wordBreak:"break-word"}}> {ride.driverVehicle.vehicleModel.name}</span>
                                    </div>
                                    {/* <div className="d-flex align-items-center">
                                        {ride.countPassengers > 0 ? Array.from({ length: ride.countPassengers }, (_, index) => (
                                            <svg key={index} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                                <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        )) : (<><svg key={index} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                            <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                            <span className="labels-small ms-1"> {ride.countPassengers}</span>
                                            <span className="labels-small ms-1"> {t("PASSENGERS_2")}</span></>
                                        )}
                                    </div> */}
                                    <div className="d-flex" style={isMobile ?{}:{ position: "absolute", marginLeft: "200px" }}>
                                        {[...Array(ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        )}
                                        {[...Array(ride.maxPassengersCount - ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        )}
                                    </div>
                                    {logged_user.Id == ride.driverId && <div className="d-flex" style={isMobile ? {} : { position: "absolute", marginLeft: "350px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23 22" fill="none">
                                            <g clipPath="url(#clip0_1529_6528)">
                                                <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1529_6528">
                                                    <rect width="22.47" height="21.09" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span className="BAM ms-1"> {ride.totalPrice} </span><span className="labels-small ms-1">{t("VALUTE")}</span>
                                    </div>}
                                </div>
                                <div style={{ borderBottom: "2px solid lightgrey" }} className="text-center my-3 mx-1"></div>
                                <div className="d-flex px-2 time-cancel-ride-mobile ">
                                    <div className={`d-flex w-100 justify-content-between align-items-between ride-card-below h-100`}>
                                        <div className="d-flex gap-6 ride-card-below-time" onClick={() => RideDetail(ride)}>
                                            <div className="d-flex flex-column">
                                                <span className="labels-small">{t("DEPARTURE_TIME")}:</span>
                                                <div className="d-flex align-items-center gap-1 mt-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                                        <g clipPath="url(#clip0_503_15992)">
                                                            <path d="M11.5303 2.4082L15.9597 6.83766L11.5303 11.2671" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M3.55371 6.83789H15.8351" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M1 1L1 11.9459" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_503_15992">
                                                                <rect width="16" height="13" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span className="date-time-rides text-nowrap">{moment(ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="labels-small">{t("TIME_END")}:</span>
                                                <div className="d-flex align-items-center gap-1 mt-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                                                        <path d="M8.97607 2.43555L13.4055 6.86501L8.97607 11.2945" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 6.86523H13.2814" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.9595 1.02734L15.9595 11.9733" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className="date-time-rides text-nowrap">{moment(ride.endDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )

                    })}
                    {!isLoading && tab === 1 && rides.filter(ride => ride.rideStatus === 'Cancelled').length === 0 &&
                        <div className='d-flex justify-content-between mb-4 pe-4'>
                            <div className="w-100 text-center"><span className="labels">{t("NO_CANCELLED_RIDES")}</span></div>
                        </div>}
                    {rides && !isLoading && tab === 2 && rides.map((ride, index) => {
                        const toDate = moment.utc(new Date(ride.startDateTime))
                        const fromDate = moment.utc(new Date(ride.endDateTime))
                        var minutes = fromDate.diff(toDate, "minutes")
                        var hours = fromDate.diff(toDate, "hours");
                        var dateStart = moment.utc(ride.startDateTime).format("DD.MM.yyyy");
                        var dateEnd = moment.utc(ride.endDateTime).format("DD.MM.yyyy");
                        var min = minutes % 60;
                        ride.dateTimeFormatted = minutes >= 60 ? hours.toString().length > 2 ? `${hours}h` : `${hours}h ${min}m` : `${min}m`;
                        return (
                            ride.rideStatus == 'Completed' &&
                            <div onClick={() => RideDetail(ride)} className={`card  p-3 shadow mb-3 bg-white cursor-pointer pt-2 pb-2 ${ride.milestonesCount > 2 ? 'pt-3' : ''}`} key={index}>
                                {logged_user.Id != ride.driverId && <div className="d-flex justify-content-between ps-2 pt-3 mb-2">
                                    <div className="d-flex" onClick={(e) => { e.stopPropagation(); navigate(`/user/${ride.driverId}`) }}>
                                        <div className="pe-2" >
                                            <img className="my-rides-image" src={ride.driver.profilePhotoId ? httpSmartClient.getPhotoUrl(ride.driver.profilePhotoId) : "/assets/images/avatar/01.jpg"} />
                                        </div>
                                        <div className={`d-flex flex-column ${ride.driversAverageRating != 0 ? "" : "justify-content-center"}`} >
                                            <h6 className="driver" style={{ color: "black", marginTop: "-12px" }}>{ride.driver.firstName + " " + ride.driver.lastName}</h6>
                                            <div className="d-flex align-items-center rating-div">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                                    <path d="M6.4502 0.794298L7.41809 2.74608C7.55008 3.01778 7.90204 3.27839 8.19901 3.32829L9.95331 3.62217C11.0752 3.81069 11.3392 4.63133 10.5307 5.44087L9.1669 6.81599C8.93592 7.04888 8.80944 7.49801 8.88093 7.81961L9.27139 9.52187C9.57935 10.8693 8.86993 11.3905 7.68756 10.6863L6.04324 9.70485C5.74628 9.52742 5.25683 9.52742 4.95437 9.70485L3.31005 10.6863C2.13318 11.3905 1.41826 10.8637 1.72622 9.52187L2.11668 7.81961C2.18817 7.49801 2.06169 7.04888 1.83071 6.81599L0.466861 5.44087C-0.33605 4.63133 -0.0775784 3.81069 1.0443 3.62217L2.7986 3.32829C3.09007 3.27839 3.44203 3.01778 3.57402 2.74608L4.54191 0.794298C5.06985 -0.264766 5.92776 -0.264766 6.4502 0.794298Z" fill="#F7C32E" />
                                                </svg>
                                                <p className="mb-0 ms-1 rating" >{ride.driversAverageRating ? ride.driversAverageRating.toFixed(1) : "0.0"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {ride.isDriver ?
                                        ride.isFinalized &&
                                        <div className="me-2">
                                            <div className="d-flex align-items-center gap-1">
                                                <h5 className="paragraphs-purple-XX-large mb-0" style={{ marginTop: "3px" }}>{ride.totalEarningsForDriver > 0 ? ride.totalEarningsForDriver : "0"} </h5><span className="ms-1 BAM-big">{t("VALUTE")}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className="me-2">
                                            <div className="d-flex align-items-center gap-1">
                                                <p className="fs18 fw-600 purple mb-0">{ride.passengersPrice} </p>
                                                <p className="fs18 fw-600  mb-0 mt-0 gray">{t("VALUTE")}</p>
                                            </div>
                                        </div>
                                    }
                                </div>}
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    zIndex: "1"
                                }}>
                                    <button className="position-absolute" style={{ marginTop: ride.milestonesCount > 2 ? "-4px" : "18px", opacity: ride.currentPassengersCount == 0 ? "0.6" : "none", borderRadius: "30px", height: "22px", width: "80px", border: "1px solid grey", backgroundColor: "white" }}>
                                        <p style={{ fontSize: "12px", color: "black", fontWeight: "bold", marginTop: "1px" }}>{ride.dateTimeFormatted}</p>
                                    </button>
                                    <div className="position-absolute row justify-content-center">
                                        {ride.milestonesCount - 2 <= 3 && [...Array(ride.milestonesCount - 2)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                        {ride.milestonesCount - 2 > 3 && [...Array(1)].map((e, i) =>
                                            <>
                                                <div className="col-auto" >
                                                    <i style={{ fontSize: "10px", color: "#e61870", marginTop: "29px" }} className="bi bi-circle-fill position-absolute  translate-middle"></i>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {<p className="position-absolute" style={{ fontSize: "12px", marginLeft: "-2px", color: "#e61870", fontWeight: "bold", marginTop: "42px" }}>
                                        {ride.milestonesCount <= 2 ? t("NO_STOP") : `${t("STOP")} (${ride.milestonesCount - 2})`}
                                    </p>}
                                </div>
                                <div className="row px-2" >
                                    <div className="col-auto pe-0 text-start" style={isMobile ? { maxWidth: "95px", overflowWrap: "break-word" } : { maxWidth: "150px" }}>
                                        <h6 className={`mt-3 pt-05 fw-500 ${isMobile ? "fs14" : "fs16"} black`}>{ride.cityFrom}</h6>
                                    </div>
                                    <div className="col px-0">
                                        <div className="row mt-3">
                                            <div className="col-auto text-end zi10 pe-0">
                                                <svg className="p-1 pe-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                                    <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                                                </svg>
                                                <p className="" style={{ fontSize: "13px", marginRight: "-10px", color: "black" }}>{moment.utc(ride.startDateTime).format("HH:mm")} </p>
                                            </div>
                                            <div className="col px-0" style={{ textAlign: "center", marginTop: "-14px" }}>
                                                <div className=" text-center">
                                                    <div className="position-relative my-4 mb-6" style={{ opacity: ride.currentPassengersCount == 0 ? "0.6" : "none" }}>
                                                        <hr style={{ border: "none", borderTop: "2px dashed grey" }} className="opacity-5 position-relative" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto zi10 ps-0 text-start" >
                                                {/* <i style={{ fontSize: "10px" }} className="bi bi-circle"></i> */}
                                                <svg className="p-1 ps-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11" fill="none">
                                                    <circle cx="5.5" cy="5.5" r="5" fill="white" stroke="#7E7E7E" />
                                                </svg>
                                                <p style={{ fontSize: "13px", marginLeft: "-10px", color: "black" }}>{moment.utc(ride.endDateTime).format("HH:mm")} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto ps-1" style={isMobile ? { maxWidth: "95px", overflowWrap: "break-word" } : { maxWidth: "150px" }}>
                                        <h6 className={`pt-05 mt-3 fw-500 ${isMobile ? "fs14" : "fs16"} black`}>{ride.cityTo}</h6>
                                    </div>
                                </div>
                                {ride.isDriver ?
                                    ride.isFinalized && <div className={`d-flex gap-3 justify-content-sm-start justify-content-between align-items-center px-2`} onClick={() => RideDetail(ride)}>
                                        <div className="d-flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                <path d="M10.9859 2.00488H6.01342C4.24967 2.00488 3.86009 2.88322 3.63342 3.95988L2.83301 7.79197H14.1663L13.3659 3.95988C13.1393 2.88322 12.7497 2.00488 10.9859 2.00488Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5767 14.0395C15.6546 14.8682 14.9888 15.5837 14.1388 15.5837H12.8071C12.0421 15.5837 11.9358 15.2578 11.8013 14.8541L11.6596 14.4291C11.4613 13.8482 11.3338 13.4587 10.3138 13.4587H6.68709C5.66709 13.4587 5.51834 13.8978 5.34126 14.4291L5.19959 14.8541C5.06501 15.2578 4.95876 15.5837 4.19376 15.5837H2.86209C2.01209 15.5837 1.34626 14.8682 1.42417 14.0395L1.82084 9.72574C1.92001 8.66324 2.12542 7.79199 3.98126 7.79199H13.0196C14.8754 7.79199 15.0808 8.66324 15.18 9.72574L15.5767 14.0395Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.83333 5.66699H2.125" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.8753 5.66699H14.167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.5 2.125V3.54167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.4375 3.54199H9.5625" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.25 10.625H6.375" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.625 10.625H12.75" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span className="labels-small ms-1" style={{maxWidth:"89%", wordBreak:"break-word"}}> {ride.driverVehicle.vehicleModel.name}</span>
                                        </div>
                                        <div className="d-flex" style={isMobile ? {} : { position: "absolute", marginLeft: "200px" }}>
                                            {[...Array(ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            )}
                                            {[...Array(ride.maxPassengersCount - ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            )}
                                        </div>
                                        {logged_user.Id == ride.driverId && <div className="d-flex" style={isMobile ? {} : { position: "absolute", marginLeft: "350px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23 22" fill="none">
                                                <g clipPath="url(#clip0_1529_6528)">
                                                    <path d="M21.2501 12.17L7.04008 20.37C6.74008 20.54 6.37008 20.52 6.10008 20.32L0.950081 16.5C0.450081 16.13 0.500081 15.38 1.03008 15.07L15.2401 6.87C15.5401 6.7 15.9101 6.72 16.1801 6.92L21.3301 10.74C21.8301 11.11 21.7801 11.86 21.2501 12.17Z" fill="white" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" fill="white" />
                                                    <path d="M12.93 14.01L7.23001 17.3C6.93001 17.47 6.56001 17.45 6.29001 17.25L1.13001 13.43C0.630013 13.06 0.680013 12.31 1.21001 12L15.43 3.78999C15.73 3.61999 16.1 3.63999 16.37 3.83999L21.52 7.65999C22.02 8.02999 21.97 8.77999 21.44 9.08999L16.67 11.84" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" fill="white" />
                                                    <path d="M12.96 10.92L7.23001 14.23C6.93001 14.4 6.56001 14.38 6.29001 14.18L1.13001 10.35C0.630013 9.98 0.680013 9.23 1.21001 8.92L15.43 0.720002C15.73 0.550002 16.1 0.570001 16.37 0.770001L21.52 4.59C22.02 4.96 21.97 5.71 21.44 6.02L16.81 8.69" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" fill="white" />
                                                    <path d="M12.03 5.04L15.63 2.95C15.77 2.87 15.94 2.88 16.07 2.98L18.47 4.76C18.7 4.93 18.68 5.28 18.43 5.43L14.98 7.4" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" fill="white" />
                                                    <path d="M11.33 9.52L7.49997 11.75C7.35997 11.83 7.18997 11.82 7.05997 11.72L4.65997 9.94C4.42997 9.77 4.44997 9.42 4.69997 9.27L8.20997 7.25" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.55005 5.84L13.12 10.7V16.87" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.01 3.84L16.67 8.77V14.81" stroke="#656565" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M6.73999 10.11L8.72999 8.95" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.3401 5.74001L16.3301 4.57001" stroke="#656565" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1529_6528">
                                                        <rect width="22.47" height="21.09" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <span className="BAM ms-1"> {ride.totalPrice} </span><span className="labels-small ms-1">{t("VALUTE")}</span>
                                        </div>}
                                    </div>
                                    :
                                    <div className={`d-flex gap-3 justify-content-sm-start justify-content-between align-items-center px-2`} onClick={() => RideDetail(ride)}>
                                        <div className="d-flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                <path d="M10.9859 2.00488H6.01342C4.24967 2.00488 3.86009 2.88322 3.63342 3.95988L2.83301 7.79197H14.1663L13.3659 3.95988C13.1393 2.88322 12.7497 2.00488 10.9859 2.00488Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.5767 14.0395C15.6546 14.8682 14.9888 15.5837 14.1388 15.5837H12.8071C12.0421 15.5837 11.9358 15.2578 11.8013 14.8541L11.6596 14.4291C11.4613 13.8482 11.3338 13.4587 10.3138 13.4587H6.68709C5.66709 13.4587 5.51834 13.8978 5.34126 14.4291L5.19959 14.8541C5.06501 15.2578 4.95876 15.5837 4.19376 15.5837H2.86209C2.01209 15.5837 1.34626 14.8682 1.42417 14.0395L1.82084 9.72574C1.92001 8.66324 2.12542 7.79199 3.98126 7.79199H13.0196C14.8754 7.79199 15.0808 8.66324 15.18 9.72574L15.5767 14.0395Z" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.83333 5.66699H2.125" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.8753 5.66699H14.167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.5 2.125V3.54167" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.4375 3.54199H9.5625" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.25 10.625H6.375" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.625 10.625H12.75" stroke="#7E7E7E" strokeWidth="1.0625" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <span className="labels-small ms-1" style={{maxWidth:"89%", wordBreak:"break-word"}}> {ride.driverVehicle.vehicleModel.name}</span>
                                        </div>
                                        <div className="d-flex" style={isMobile ? {} : { position: "absolute", marginLeft: "200px" }}>
                                            {/* {ride.countPassengers > 0 ? Array.from({ length: ride.countPassengers }, (_, index) => (
                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                                    <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            )) : (<><svg key={index} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
                                                <path d="M5.82767 7.69991C5.75684 7.69283 5.67184 7.69283 5.59392 7.69991C3.90809 7.64324 2.56934 6.26199 2.56934 4.56199C2.56934 2.82658 3.97184 1.41699 5.71434 1.41699C7.44975 1.41699 8.85934 2.82658 8.85934 4.56199C8.85225 6.26199 7.5135 7.64324 5.82767 7.69991Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.28563 10.314C0.571458 11.4615 0.571458 13.3315 2.28563 14.4719C4.23354 15.7752 7.42813 15.7752 9.37604 14.4719C11.0902 13.3244 11.0902 11.4544 9.37604 10.314C7.43521 9.01773 4.24062 9.01773 2.28563 10.314Z" stroke="#900fc8" strokeWidth="1.06" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                                <span className="labels-small ms-1"> {ride.countPassengers}</span>
                                                <span className="labels-small ms-1"> {t("PASSENGERS_2")}</span></>
                                            )} */}

                                            {[...Array(ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            )}
                                            {[...Array(ride.maxPassengersCount - ride.countPassengers)].map((e, i) => <svg width="19" style={{ marginLeft: "-3px" }} height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1535 10.417C12.0576 10.4075 11.9426 10.4075 11.8372 10.417C9.55637 10.3404 7.74512 8.47163 7.74512 6.17163C7.74512 3.82371 9.64262 1.91663 12.0001 1.91663C14.348 1.91663 16.2551 3.82371 16.2551 6.17163C16.2455 8.47163 14.4343 10.3404 12.1535 10.417Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.36169 13.9534C5.04252 15.5059 5.04252 18.0359 7.36169 19.5788C9.99711 21.3421 14.3192 21.3421 16.9546 19.5788C19.2738 18.0263 19.2738 15.4963 16.9546 13.9534C14.3288 12.1996 10.0067 12.1996 7.36169 13.9534Z" stroke="#900fc8" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            )}
                                        </div>

                                    </div>
                                }
                                <div style={{ borderBottom: "2px solid lightgrey" }} className="text-center my-3 mx-1"></div>

                                <div className="d-flex px-2 time-cancel-ride-mobile ">
                                    <div className={`d-flex w-100 justify-content-between align-items-between ride-card-below h-100`}>
                                        <div className="d-flex gap-6  ride-card-below-time">
                                            <div className="d-flex flex-column" onClick={() => RideDetail(ride)}>
                                                <span className="labels-small">{t("DEPARTURE_TIME")}:</span>
                                                <div className="d-flex align-items-center gap-1 mt-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                                        <g clipPath="url(#clip0_503_15992)">
                                                            <path d="M11.5303 2.4082L15.9597 6.83766L11.5303 11.2671" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M3.55371 6.83789H15.8351" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M1 1L1 11.9459" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_503_15992">
                                                                <rect width="16" height="13" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span className="date-time-rides">{moment(ride.startDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column" onClick={() => RideDetail(ride)}>
                                                <span className="labels-small">{t("TIME_END")}:</span>
                                                <div className="d-flex align-items-center gap-1 mt-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                                                        <path d="M8.97607 2.43555L13.4055 6.86501L8.97607 11.2945" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 6.86523H13.2814" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.9595 1.02734L15.9595 11.9733" stroke="#7E7E7E" strokeWidth="1.09459" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className="date-time-rides">{moment(ride.endDateTime).format("DD.MM.YYYY, HH:mm")}h</span>
                                                </div>
                                            </div>
                                        </div>
                                        {(ride.isDriver) ?
                                            ride.countPassengers > 0 && ride.sumOfDriverReviews != ride.countPassengers && ride.countPassengers > ride.sumOfDriverReviews ?
                                                <div className=" mt-md-0 mt-4 cursor-pointer align-items-center d-flex justify-content-center gap-1 " onClick={(e) => { e.stopPropagation(); handleShowReviewModal(ride) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M8.86059 1.35241C9.48464 0.818729 10.5066 0.818729 11.1397 1.35241L12.5687 2.58267C12.8401 2.81785 13.3465 3.00778 13.7083 3.00778H15.2458C16.2045 3.00778 16.9913 3.79466 16.9913 4.75347V6.29125C16.9913 6.64402 17.1813 7.15961 17.4164 7.43097L18.6465 8.8601C19.1801 9.48423 19.1801 10.5064 18.6465 11.1395L17.4164 12.5687C17.1813 12.84 16.9913 13.3466 16.9913 13.7084V15.2462C16.9913 16.205 16.2045 16.9919 15.2458 16.9919H13.7083C13.3556 16.9919 12.8401 17.1818 12.5687 17.417L11.1397 18.6471C10.5157 19.1808 9.49369 19.1808 8.86059 18.6471L7.43161 17.417C7.16029 17.1818 6.6538 16.9919 6.29203 16.9919H4.72737C3.76868 16.9919 2.98184 16.205 2.98184 15.2462V13.6993C2.98184 13.3466 2.79192 12.84 2.56582 12.5687L1.34484 11.1305C0.820275 10.5064 0.820275 9.49328 1.34484 8.86915L2.56582 7.43097C2.79192 7.15961 2.98184 6.65307 2.98184 6.3003V4.75347C2.98184 3.79466 3.76868 3.00778 4.72737 3.00778H6.29203C6.64476 3.00778 7.16029 2.81785 7.43161 2.58267L8.86059 1.35241Z" stroke="#900FC8" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8.24202 12.3497L9.45034 13.283C9.60868 13.4413 9.95869 13.5163 10.192 13.5163H11.667C12.1337 13.5163 12.642 13.1664 12.7587 12.6997L13.692 9.84966C13.8837 9.308 13.5337 8.83302 12.9504 8.83302H11.392C11.1587 8.83302 10.967 8.64141 11.0004 8.36641L11.192 7.11641C11.267 6.76641 11.0337 6.37471 10.6837 6.25805C10.3754 6.14138 9.98369 6.29968 9.82535 6.53301L8.22534 8.90799" stroke="#900FC8" strokeWidth="1.3125" strokeMiterlimit="10" />
                                                        <path d="M6.25 12.3493V8.53269C6.25 7.98269 6.48334 7.79102 7.03334 7.79102H7.425C7.96667 7.79102 8.20834 7.98269 8.20834 8.53269V12.3493C8.20834 12.891 7.975 13.091 7.425 13.091H7.03334C6.48334 13.091 6.25 12.8993 6.25 12.3493Z" stroke="#900FC8" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className="paragraphs-purple ms-2">{t("LEAVE_A_REVIEW")}</span>
                                                </div>
                                                :
                                                ride.countPassengers === 0 ?
                                                    ""
                                                    :
                                                    <div className="mt-md-0 mt-4 cursor-pointer align-items-center d-flex justify-content-center gap-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                            <path d="M9.38211 1.65534L10.79 4.4943C10.9819 4.8895 11.4939 5.26856 11.9258 5.34115L14.4775 5.7686C16.1094 6.04282 16.4933 7.23647 15.3175 8.414L13.3337 10.4142C12.9977 10.7529 12.8137 11.4062 12.9177 11.874L13.4857 14.35C13.9336 16.3098 12.9017 17.068 11.1819 16.0437L8.79017 14.6162C8.35822 14.3581 7.6463 14.3581 7.20635 14.6162L4.81461 16.0437C3.1028 17.068 2.06292 16.3018 2.51087 14.35L3.07881 11.874C3.18279 11.4062 2.99881 10.7529 2.66285 10.4142L0.679071 8.414C-0.4888 7.23647 -0.112841 6.04282 1.51898 5.7686L4.0707 5.34115C4.49465 5.26856 5.00659 4.8895 5.19857 4.4943L6.60642 1.65534C7.37433 0.114886 8.62219 0.114886 9.38211 1.65534Z" fill="#F7C32E" />
                                                        </svg>
                                                        <span className="routes">{t("LEFT_EVERY_PASSENGER_REVIEW")}</span>
                                                    </div>
                                            :
                                            !ride.isDriver && ride.hasReview == false ?
                                                ride.isFinalized &&
                                                <div className=" mt-md-0 mt-4 cursor-pointer align-items-center d-flex justify-content-center gap-1" onClick={(e) => { e.stopPropagation(); handleShowReviewModal(ride) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M8.86059 1.35241C9.48464 0.818729 10.5066 0.818729 11.1397 1.35241L12.5687 2.58267C12.8401 2.81785 13.3465 3.00778 13.7083 3.00778H15.2458C16.2045 3.00778 16.9913 3.79466 16.9913 4.75347V6.29125C16.9913 6.64402 17.1813 7.15961 17.4164 7.43097L18.6465 8.8601C19.1801 9.48423 19.1801 10.5064 18.6465 11.1395L17.4164 12.5687C17.1813 12.84 16.9913 13.3466 16.9913 13.7084V15.2462C16.9913 16.205 16.2045 16.9919 15.2458 16.9919H13.7083C13.3556 16.9919 12.8401 17.1818 12.5687 17.417L11.1397 18.6471C10.5157 19.1808 9.49369 19.1808 8.86059 18.6471L7.43161 17.417C7.16029 17.1818 6.6538 16.9919 6.29203 16.9919H4.72737C3.76868 16.9919 2.98184 16.205 2.98184 15.2462V13.6993C2.98184 13.3466 2.79192 12.84 2.56582 12.5687L1.34484 11.1305C0.820275 10.5064 0.820275 9.49328 1.34484 8.86915L2.56582 7.43097C2.79192 7.15961 2.98184 6.65307 2.98184 6.3003V4.75347C2.98184 3.79466 3.76868 3.00778 4.72737 3.00778H6.29203C6.64476 3.00778 7.16029 2.81785 7.43161 2.58267L8.86059 1.35241Z" stroke="#900FC8" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8.24202 12.3497L9.45034 13.283C9.60868 13.4413 9.95869 13.5163 10.192 13.5163H11.667C12.1337 13.5163 12.642 13.1664 12.7587 12.6997L13.692 9.84966C13.8837 9.308 13.5337 8.83302 12.9504 8.83302H11.392C11.1587 8.83302 10.967 8.64141 11.0004 8.36641L11.192 7.11641C11.267 6.76641 11.0337 6.37471 10.6837 6.25805C10.3754 6.14138 9.98369 6.29968 9.82535 6.53301L8.22534 8.90799" stroke="#900FC8" strokeWidth="1.3125" strokeMiterlimit="10" />
                                                        <path d="M6.25 12.3493V8.53269C6.25 7.98269 6.48334 7.79102 7.03334 7.79102H7.425C7.96667 7.79102 8.20834 7.98269 8.20834 8.53269V12.3493C8.20834 12.891 7.975 13.091 7.425 13.091H7.03334C6.48334 13.091 6.25 12.8993 6.25 12.3493Z" stroke="#900FC8" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className="paragraphs-purple ms-2">{t("LEAVE_A_REVIEW")}</span>
                                                </div>
                                                :
                                                !isMobile && <div className='d-flex align-items-center gap-1 me-4'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                        <path d="M8.20934 1.51092L9.44121 3.99501C9.60919 4.34081 10.0571 4.67249 10.4351 4.736L12.6679 5.11003C14.0957 5.34997 14.4317 6.39442 13.4028 7.42475L11.667 9.1749C11.373 9.4713 11.212 10.0429 11.303 10.4522L11.7999 12.6187C12.1919 14.3336 11.289 14.997 9.78417 14.1007L7.6914 12.8516C7.31344 12.6258 6.69051 12.6258 6.30556 12.8516L4.21279 14.1007C2.71495 14.997 1.80505 14.3266 2.19701 12.6187L2.69395 10.4522C2.78494 10.0429 2.62396 9.4713 2.33 9.1749L0.594187 7.42475C-0.4277 6.39442 -0.0987362 5.34997 1.32911 5.11003L3.56186 4.736C3.93282 4.67249 4.38077 4.34081 4.54875 3.99501L5.78061 1.51092C6.45254 0.163025 7.54442 0.163025 8.20934 1.51092Z" fill="#F7C32E" />
                                                    </svg>
                                                    <span className="routes">{t("HAS_REVIEW_RATING")}{ride.passengersReviewRatingOnDriver}.0</span>
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        )

                    })}
                    {!isLoading && tab === 2 && rides.filter(ride => ride.rideStatus === 'Completed').length === 0 &&
                        <div className='d-flex justify-content-between mb-4 pe-4'>
                            <div className="w-100 text-center"><span className="labels">{t("NO_COMPLETED_RIDES")}</span></div>
                        </div>}
                    <PagingComponent
                        className="pagination-bar"
                        currentPage={pagingData.pageNumber ?? 0}
                        totalCount={pagingData.totalCount ?? 0}
                        pageSize={pagingData.pageSize ?? 0}
                        onPageChange={onPageChange}
                    />
                </div>

                <Modal
                    open={finalizationDialog}
                    onClose={() => { setFinalizationDialog(false) }}
                    closeIcon={globalStore.closeIcon}
                    modalId="updateModals"
                    classNames={{
                        modal: 'finalization-dialog t-30 finalize-mobile',
                        closeButton: "finalization-dialog-close-button me-2"
                    }}
                    center
                >
                    <FinalizationDialog
                        onConfirm={(e) => FinalizeBooking(selectedRide, e)}
                        onDiscard={() => setFinalizationDialog(false)}
                        ride={selectedRide}
                    ></FinalizationDialog>
                </Modal>
                <Modal
                    open={confirmDialog}
                    onClose={() => setConfirmDialog(false)}
                    closeIcon={globalStore.closeIcon}
                    classNames={{
                        modal: "modal-no-padding",
                        modalContainer: 'modal-container-animation-none',
                        closeButton: "closeButtonConfirmCancel mt-2"

                    }}
                    center
                >
                    {selectedRide && <ConfirmationDialog
                        onDiscard={() => setConfirmDialog(false)}
                        title={t("CANCEL_RIDE")}
                        description={
                            <div>
                                {t("CANCEL_RIDE_CONFIRM_1")}
                                <span className="darkgray fw-600"> {selectedRide.cityFrom} -&gt; </span>
                                <span className="darkgray fw-600">{selectedRide.cityTo} </span>
                                {t("BOOK_RIDE_CONFIRM_2")}
                                <span className="darkgray fw-600">{moment(selectedRide.firstMilestoneDateTime).format("DD.MM.yyy")}{t("YEAR")} </span>
                                {t("IN")}
                                <span className="darkgray fw-600"> {moment(selectedRide.firstMilestoneDateTime).format("HH:mm")} h </span>
                                {t("BOOK_RIDE_CONFIRM_3")}
                                <span className="darkgray fw-600">{selectedRide.totalPrice} BAM</span>?
                                <br /> <br />
                                <span className="darkgray fw-600">{selectedRide.hasUserAlreadyBookedOnce ? t("NOTE1") : null}</span>
                                <span className="italic">{selectedRide.hasUserAlreadyBookedOnce ? t("CANCEL_RIDE_CONFIRM_2") : null}</span>
                            </div>
                        }
                        confirmBtnLabel={t("CANCEL")}
                        onConfirm={() => CancelBooking(selectedRide)}
                    >
                    </ConfirmationDialog>}
                </Modal>

                <Modal
                    open={confirmCashFinalizationDialog}
                    onClose={() => setconfirmCashFinalizationDialog(false)}
                    closeIcon={globalStore.closeIcon}
                    classNames={{
                        modal: "modal-no-padding",
                        modalContainer: 'modal-container-animation-none',
                        closeButton: "closeButtonConfirmCancel mt-2"

                    }}
                    center
                >
                    {selectedRide &&
                        <ConfirmFinalizationCash
                            onDiscard={() => setconfirmCashFinalizationDialog(false)}
                            setConfirmDialog={setconfirmCashFinalizationDialog}
                            title={t("FINALIZE_RIDE")}
                            description={
                                <div>
                                    {t("FINALIZE_RIDE_CONFIRM_1")}
                                    <span className="darkgray fw-600"> {selectedRide.cityFrom} -&gt; </span>
                                    <span className="darkgray fw-600">{selectedRide.cityTo} </span>
                                    {t("FINALIZE_RIDE_CONFIRM_2")}
                                    <span className="darkgray fw-600">{moment(selectedRide.firstMilestoneDateTime).format("DD.MM.yyy")}{t("YEAR")} </span>
                                    {t("IN")}
                                    <span className="darkgray fw-600"> {moment(selectedRide.firstMilestoneDateTime).format("HH:mm")} h</span>
                                    ?
                                    <br />
                                    {transactionsExist && <><span className="darkgray fw-600">{t("NOTE")}: </span> {t("FINALIZE_RIDE_CONFIRM_NOTE")}</>}
                                </div>
                            }
                            confirmBtnLabel={t("FINALIZE")}
                            onConfirm={() => HandleFinalization(selectedRide)}
                        >
                        </ConfirmFinalizationCash>}
                </Modal>
                <Modal
                    open={showReviewDialog}
                    onClose={() => setShowReviewDialog(false)}
                    closeIcon={globalStore.closeIcon}
                    modalId='reviewModals'
                    classNames={{
                        modal: 'reviewModal',
                    }}
                    center
                >
                    <ReviewModal
                        ride={ride}
                    >
                    </ReviewModal>
                </Modal>
                <Modal
                    open={showDriverReviewModal}
                    onClose={() => setShowDriverReviewModal(false)}
                    closeIcon={globalStore.closeIcon}
                    modalId='reviewModals'
                    classNames={{
                        modal: 'reviewUserModal',
                    }}
                    center
                >
                    <ReviewDriverModal
                        data={ride}
                    >
                    </ReviewDriverModal>
                </Modal>
                <Modal
                    open={showSuccessInfo}
                    showCloseIcon={true}
                    onClose={() => setShowSuccesInfo(false)}
                    classNames={{
                        modal: 'successInfoFinalize successInfoFinalizeMobile',
                        modalContainer: 'modal-container-animation-none',
                        closeButton: "mt-1"
                    }}
                    center
                >
                    <SuccessInfoFinalize
                        title={t("FINALIZE_RIDE_SUCCESS")}
                        description={t("SUCCESS_FINALIZATION")}
                        confirmBtnLabel={t("CLOSE")}
                        onConfirm={() => setShowSuccesInfo(false)}
                    >
                    </SuccessInfoFinalize>
                </Modal>

                <Modal
                    open={showSuccessInfoReserved}
                    showCloseIcon={true}
                    classNames={{
                        modal: isMobile ? 'confirm-success-dialog-mobile ms-2' : 'confirm-success-dialog ms-2',
                        modalContainer: 'modal-container-animation-none',
                    }}
                    center
                >
                    <SuccessInfo
                        title={t("BOOK_A_RIDE")}
                        description={t("SUCCESSFULLY_RESERVED")}
                        confirmBtnLabel={t("CLOSE")}
                        onConfirm={() => setShowSuccesInfoReserved(false)}
                    ></SuccessInfo>
                </Modal>

                <Modal
                    open={showCancelRideDialog}
                    showCloseIcon={true}
                    onClose={() => setShowCancelRideDialog(false)}
                    classNames={{
                        modal: 'successInfoFinalize successInfoFinalizeMobile',
                        modalContainer: 'modal-container-animation-none',
                        closeButton: "mt-1"
                    }}
                    center
                >
                    <CancelRideDialog
                        title={t("CANCEL_BOOKING")}
                        description={t("SUCCESS_RIDE_CANCELLED")}
                        confirmBtnLabel={t("CLOSE")}
                        onConfirm={() => setShowCancelRideDialog(false)}
                    ></CancelRideDialog>
                </Modal>
            </div >

        </div>)
});

export default MyRides;