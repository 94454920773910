import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import ActionRoute from "./ActionRoute";
import NestedActionRoute from "./NestedActionRoute";
import globalStore from "data/stores/GlobalStore";
import { AuthService } from "data/services";

const AdminSidebar = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [theme, setTheme] = useState();
  const signOut = () => {
    AuthService.signOut();
    navigate("/sign-in");
  };

  const routes = [
    { route: '/admin', labelKey: 'DASHBOARD' },
    { route: '/admin/users', labelKey: t('USERS') },
    { route: '/admin/rides', labelKey: t('RIDES') },
    { route: '/admin/transaction', labelKey: t('TRANSACTION') },
    { route: '/admin/viberMessages', labelKey: t('VIBER_MESSAGES') },

    {
      sectionKey: 'SETTINGS',
      links: [
        { route: '/admin/appSettings', labelKey: t('APP_SETTINGS') },
        { route: '/admin/vehicleBrands', labelKey: t('VEHICLE_BRANDS') },
        { route: '/admin/vehicleModels', labelKey: t('VEHICLE_MODELS') },
        { route: '/admin/conditions', labelKey: t('CONDITIONS') },
        // { route: '/admin/reportTypes', labelKey: t('REPORT_TYPES') },
        // { route: '/admin/reports', labelKey: t('REPORTS') },
        { route: '/admin/travelPreferences', labelKey: t('TRAVEL_PREFERENCES') }
      ]
    },
  ];

  useEffect(() => {
    routes.forEach((r) => {
      if (location.pathname.startsWith(r.route)) {
        setIsExpanded(true);
        return;
      }
    });
  }, []);
  useEffect(() => {
    const model = localStorage.getItem('theme')
    setTheme(model)
  }, [globalStore.theme])

  return (
    <nav className="navbar sidebar navbar-expand-xl navbar-light">
      <div className="d-flex align-items-center">
        <a className="navbar-brand cursor-pointer" onClick={() => navigate("/")}>
          <img className=" navbar-brand-item larger" src={theme === 'dark' ? "/assets/images/logo-light.svg" : "/assets/images/logo.svg"} alt="logo" />
        </a>
      </div>

      <div className="offcanvas offcanvas-start flex-row custom-scrollbar h-100" data-bs-backdrop="true" tabndex="-1" id="offcanvasSidebar">
        <div className="offcanvas-body sidebar-content d-flex flex-column pt-4">
          <ul className="navbar-nav flex-column" id="navbar-sidebar">
            {
              routes.map((route) => {
                if (!route.sectionKey) {
                  return <ActionRoute route={route.route} labelKey={route.labelKey} key={route.route}></ActionRoute>
                }
                else {
                  return <NestedActionRoute key={route.sectionKey} sectionKey={route.sectionKey} links={route.links} isExpanded={isExpanded}></NestedActionRoute>
                }
              })
            }
          </ul>
          <div className="d-flex align-items-center justify-content-between text-primary-hover mt-auto p-3">
            <a className="h6 fw-light mb-0 text-body" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Sign out" onClick={signOut}>
              <i className="fa-solid fa-arrow-right-from-bracket me-2"></i>{t("LOGOUT")}
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
})
export default AdminSidebar;
