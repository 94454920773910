import "./App.css"
import { Routes, Route } from "react-router-dom"
import { useEffect } from "react"
import ClientLayout from "./components/layout/ClientLayout"
import ProtectedRoute from "./components/common/ProtectedRoute"
import AdminLayout from "./components/layout/AdminLayout"
import SignUpPage from "./components/modules/public/signUp/SignUpPage"
import SignInPage from "./components/modules/public/signIn/SignInPage"
import DashboardPage from "./components/modules/admin/dashboard/DashboardPage"
import VehicleBrandsPage from "./components/modules/admin/vehicleBrands/VehicleBrandsPage"
import ConditionsPage from "./components/modules/admin/conditions/ConditionsPage"
import ReportTypesPage from "./components/modules/admin/reportTypes/ReportTypesPage"
import ReportTypeAddPage from "./components/modules/admin/reportTypes/ReportTypeAddPage"
import ReportTypeEditPage from "./components/modules/admin/reportTypes/ReportTypeEditPage"
import VehicleModelsPage from "./components/modules/admin/vehiclemodels/VehicleModelsPage"
import TravelPreferencesPage from "./components/modules/admin/travelPreferences/TravelPreferencesPage"
import TravelPreferenceAddPage from "./components/modules/admin/travelPreferences/TravelPreferenceAddPage"
import TravelPreferenceEditPage from "./components/modules/admin/travelPreferences/TravelPreferenceEditPage"
import CatalogFilter from "./components/modules/public/catalogFilter/CatalogFilter"
import ReportsPage from "./components/modules/admin/reports/ReportsPage"
import ReportAddPage from "./components/modules/admin/reports/ReportAddPage"
import HomePage from "./components/modules/public/home/HomePage"
import UsersPage from "./components/modules/admin/users/UsersPage"
import UserAddPage from "./components/modules/admin/users/UserAddPage"
import UserEditPage from "./components/modules/admin/users/UserEditPage"
import UserProfilePage from "./components/modules/public/driver/UserProfilePage"
import UserPage from "./components/modules/public/userInfo/UserPage"
import MyProfilePage from "./components/modules/public/user/MyProfilePage"
import Page404 from "./components/layout/components/Page404"
import CatalogPageMainComponent from "./components/modules/public/catalogPage/CatalogPageMainComponent"
import MyChangeMail from "./components/modules/public/user/userChunks/MyChangeMail"
import MyChangePassword from "./components/modules/public/user/userChunks/MyChangePassword"
import MyVehicles from "./components/modules/public/user/userVehicle/MyVehicles"
import MyVehicleEdit from "./components/modules/public/user/userVehicle/MyVehicleEdit"
import MyPersonalInfo from "./components/modules/public/user/userChunks/MyPersonalInfo"
import MyVehicleAdd from "./components/modules/public/user/userVehicle/MyVehicleAdd"
import Page401 from "./components/layout/components/Page401"
import Page500 from "./components/layout/components/Page500"
import ForgotPasswordPage from "./components/modules/public/forgotPassword/ForgotPasswordPage"
import MyRides from "./components/modules/public/user/userChunks/MyRides"
import VerifyEmailPage from "./components/modules/public/verifyEmail/VerifyEmailPage"
import ConnectionsService from "./data/services/ConnectionsService"
import DeleteProfile from "./components/modules/public/user/DeleteProfile"
import Chat from "./components/modules/public/chat/Chat"
import AppSettingsPage from "./components/modules/admin/appSettings/AppSettingsPage"
import MyTravelPreference from "./components/modules/public/user/userChunks/MyTravelPreference"
import RidesPage from "./components/modules/admin/rides/RidesPage"
import PrivacyPolicy from "./components/modules/public/privacyPolicy/privacyPolicy"
import SecurityOfCreditCard from "./components/modules/public/privacyPolicy/securityOfCreditCard"
import TermsOfPayment from "./components/modules/public/privacyPolicy/termsOfPayment"
import RideDetailChange from "./components/modules/public/catalogPage/RideDetailChange"
import TransactionsPage from "./components/modules/admin/transactions/TransactionsPage"
import AboutUs from "./components/modules/public/aboutus/aboutus"
import UserReviewsPage from "./components/modules/public/userInfo/UserReviewsPage"
import globalStore from "./data/stores/GlobalStore"
import { observer } from "mobx-react-lite"
import AuthService from "./data/services/AuthService"
import HowVoziMeWorks from "./components/modules/public/privacyPolicy/howVoziMeWorks"
import TransportAtLowPrices from "./components/modules/public/privacyPolicy/transportAtLowPrices"
import SafetyRecommendations from "./components/modules/public/privacyPolicy/safetyRecommendations"
import PublishRideWrapper from "./components/modules/public/rides/PublishRideWrapper"
import ViberMessagesPage from "./components/modules/admin/viberMessages/ViberMessagesPage"

const App = observer(() => {
  useEffect(() => {
    const user = AuthService.getCurrentUser()
    if (user == null) return
    const initSignalR = async () => {
      const response = await ConnectionsService.connectionStart()
      if (response == "Success") {
        ConnectionsService.OnNewMessage()
        ConnectionsService.OnReservedRide()
        ConnectionsService.OnReservedRideDriver()
        ConnectionsService.OnFinalizedRide()
        ConnectionsService.OnNewReview()
        ConnectionsService.OnCancelRide()
        ConnectionsService.OnDeleteMyProfile()
        ConnectionsService.InvokeCancelRide()
      }
    }
    initSignalR()
  }, [globalStore.renderConnection])

  return (
    <Routes>
      <Route path="/"  element={<ClientLayout></ClientLayout>}>
        <Route index element={<HomePage />}></Route>
        <Route path="chat/:chatGuidId" element={<Chat />}></Route>
        <Route path="userProfile/:userId" element={<UserProfilePage />}></Route>
        <Route path="rides" element={<CatalogFilter />}></Route>
        <Route path="rides/:rideId/:rideMilestoneFromId/:rideMilestoneToId" element={<RideDetailChange />}></Route>
        <Route path="rides/catalogpagemain" element={<CatalogPageMainComponent />}></Route>
        <Route path="/rides/create" element={<PublishRideWrapper />}></Route>
        <Route path="user">
          <Route path=":userId" element={<UserPage />}>
            {" "}
          </Route>
          <Route path=":userId/ratings" element={<UserReviewsPage />}></Route>
        </Route>
        <Route path="*" element={<Page404></Page404>}></Route>
        <Route path="Unauthorized" element={<Page401></Page401>}></Route>
        <Route path="ServerError" element={<Page500></Page500>}></Route>
        <Route path="filter" element={<CatalogFilter />}></Route>
        <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="terms-of-payment" element={<TermsOfPayment />}></Route>
        <Route path="how-vozime-works" element={<HowVoziMeWorks />}></Route>
        <Route path="travel-by-low-prices" element={<TransportAtLowPrices />}></Route>
        <Route path="safety-recommendations" element={<SafetyRecommendations />}></Route>
        <Route path="security-of-credit-card-payment" element={<SecurityOfCreditCard />}></Route>
        <Route path="aboutus" element={<AboutUs />}></Route>
        <Route path="/my-profile" element={<MyProfilePage />}>
          <Route index element={<MyPersonalInfo />}></Route>
          <Route path="my-rides" element={<MyRides />}></Route>
          <Route path="changeEmail" element={<MyChangeMail />}></Route>
          <Route path="changePassword" element={<MyChangePassword />}></Route>
          <Route path="my-travel-preference" element={<MyTravelPreference />}></Route>
          <Route path="my-vehicles" element={<MyVehicles />}></Route>
          <Route path="my-vehicles/edit/:userVehicleId" element={<MyVehicleEdit />}></Route>
          <Route path="my-vehicles/add" element={<MyVehicleAdd />}></Route>
          <Route path="delete-profile" element={<DeleteProfile />}></Route>
        </Route>
      </Route>

      <Route
        path="/admin"
        element={
          <ProtectedRoute roles="Administrator,Passenger">
            <AdminLayout></AdminLayout>
          </ProtectedRoute>
        }
      >
        <Route index element={<DashboardPage />}></Route>
        <Route path="vehicleBrands" element={<VehicleBrandsPage />}></Route>
        <Route path="conditions" element={<ConditionsPage />}></Route>
        <Route path="reportTypes" element={<ReportTypesPage />}></Route>
        <Route path="transaction" element={<TransactionsPage />}></Route>
        <Route path="viberMessages" element={<ViberMessagesPage />}></Route>
        <Route path="reportTypes/create" element={<ReportTypeAddPage />}></Route>
        <Route path="reportTypes/edit/:id" element={<ReportTypeEditPage />}></Route>
        <Route path="vehicleModels" element={<VehicleModelsPage />}></Route>
        <Route path="travelPreferences" element={<TravelPreferencesPage />}></Route>
        <Route path="travelPreferences/create" element={<TravelPreferenceAddPage />}></Route>
        <Route path="travelPreferences/edit/:id" element={<TravelPreferenceEditPage />}></Route>
        <Route path="appSettings" element={<AppSettingsPage />}></Route>
        <Route path="users" element={<UsersPage />}></Route>
        <Route path="users/create" element={<UserAddPage />}></Route>
        <Route path="users/edit/:id" element={<UserEditPage />}></Route>
        <Route path="reports" element={<ReportsPage />}></Route>
        <Route path="reports/create" element={<ReportAddPage />}></Route>
        <Route path="rides" element={<RidesPage />}></Route>
      </Route>
      <Route path="/sign-in" element={<SignInPage></SignInPage>}></Route>
      <Route path="/verify-email" element={<VerifyEmailPage />}></Route>
      <Route path="/sign-up" element={<SignUpPage></SignUpPage>}></Route>
      <Route path="/forgot-password" element={<ForgotPasswordPage></ForgotPasswordPage>}></Route>
    </Routes>
  )
})

export default App
