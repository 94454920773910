import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function LoadMoreText({ text }) {
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const { t } = useTranslation();
  const isTooLong = text.length>300;

  const truncatedText = isFullTextVisible || !isTooLong ? text : text.substring(0, 300) + '...';

  const toggleTextVisibility = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  return (
    <div className='w-100'>
      <p style={{color:"#7e7e7e"}} className='fs14 fw-500 w-100'>{truncatedText}</p>
      <p className='gray text-end cursor-pointer fs12 fw14' onClick={toggleTextVisibility}>
       {isTooLong && <a>{isFullTextVisible ?  t("SHOW_LESS") : t("LOAD_MORE")}</a>}
      </p>
    </div>
  );
}

export default LoadMoreText;
