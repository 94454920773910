import { httpSmartClient } from "../../config/httpClient";

const baseName = "UserReviewComments";

const UserReviewCommentsService = {
  getByUserReviewId: async (userReviewId) => {
    var response = await httpSmartClient.get(`/${baseName}/GetByUserReviewId`, { params: { userReviewId } })
    return response
  },
  add: async (params) => {
    var response = await httpSmartClient.post(`/${baseName}/`, params);
    return response;
  },

  edit: async (comment) => {
    var response = await httpSmartClient.put(`/${baseName}`, comment);
    return response;
  },
  delete: async (id) => {
    var response = await httpSmartClient.delete(`/${baseName}/` + id);
    return response;
  },
}

export default UserReviewCommentsService;
