import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UsersService, ConnectionsService } from 'data/services';
import { toastify } from 'utils/toastify';
import { useNavigate } from 'react-router-dom';
import userProfileStore from 'data/stores/UserProfileStore';

function DeleteProfile() {
    const { t } = useTranslation();
    const [checkbox, setCheckbox] = useState(true);
    const user = userProfileStore.user;
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const deleteProfile = async () => {
        const response = await UsersService.deleteMyProfile(user.id);
        if (response.isSuccess) {
            navigate("/sign-in");
            await ConnectionsService.InvokeDeleteMyProfile(user.id)
            toastify.success(t("DEACTIVATED_PROFILE"))
        } else {
            toastify.error(t("ERROR_WHILE_DEACTIVATING_PROFILE"))
        }
    }
    const clickCheckbox = () => {
        setCheckbox(prev => !prev)
    }
    return (
        <div className="col-lg-12 col-xl-12 mt-3 p-3" style={{padding: isMobile ? "3px" : ""}}>
            <div className="card border">
                <div className="card-header border-bottom">
                    <h4 className="card-header-title"><i className="fa fa-warning text-danger warning-sign" ></i> {t("DEACTIVATE_PROFILE")}</h4>
                </div>

                <div className="card-body">
                    <ul className='p-0 m-0'>
                        <li className='li-custom'>{t("CONFIRM_DEACTIVATE_MESSAGE1")}</li>
                        <li className='li-custom'>{t("CONFIRM_DEACTIVATE_MESSAGE2")}</li>
                    </ul>
                    <div className="form-check form-check-md my-4">
                        <input className="form-check-input" type="checkbox" value="" onClick={clickCheckbox} id="deleteaccountCheck" />
                        <label className="form-check-label text-secondary" htmlFor="deleteaccountCheck">{t("YES_DEACTIVATE")}</label>
                    </div>
                    <button disabled={checkbox} onClick={deleteProfile} className="btn btn-danger btn-sm mb-0 float-end">{t("DEACTIVATE_MY_PROFILE")}</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteProfile;
