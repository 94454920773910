import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { AuthService } from "data/services";
import { toastify } from "utils/toastify";

export default function ForgotPasswordForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [isFormSubmitting, setFormSubmitting] = useState(false);

    const onSubmit = async (data) => {
        setFormSubmitting(true);
        await AuthService.sendEmail(data.email);
        if (data.email) {
            toastify.success(t("CHECK_MAIL"));
        } else {
            toastify.error(t("ERROR_MAIL"));
        }
        setFormSubmitting(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-0 mt-md-4 text-start">
                <div className="mb-3">
                    <label className="form-label" style={{ fontSize: "14px", marginLeft: "5px" }} >{t("EMAIL_INPUT")}:</label>
                    <div className="input-group bg-light" style={{ borderRadius: "10px" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{ border: "none", marginTop: "3px", marginRight: "-5px" }}>
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.2917 19.6457H6.70841C3.83341 19.6457 1.91675 18.2082 1.91675 14.854V8.14567C1.91675 4.7915 3.83341 3.354 6.70841 3.354H16.2917C19.1667 3.354 21.0834 4.7915 21.0834 8.14567V14.854C21.0834 18.2082 19.1667 19.6457 16.2917 19.6457Z" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.2916 8.625L13.292 11.0208C12.3049 11.8067 10.6853 11.8067 9.69825 11.0208L6.70825 8.625" stroke="#7E7E7E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                        <input placeholder={t("EMAIL")}
                            {...register("email", {
                                required: t("EMAIL_IS_REQUIRED"),
                                pattern: {
                                    value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                    message: t("INVALID_EMAIL"),
                                }
                            })}
                            style={{ border: "none", marginLeft: "-5px" }} className="form-control bg-light" />
                    </div>
                    {errors.email && <small className="text-danger">{errors.email.message}</small>}
                </div>
                <div className="mb-3">
                    <p className="mb-0 mt-3" style={{ fontSize: "14px" }}>
                        {t("BACK_TO")}
                        <a style={{ fontWeight: "bold" }} className="purple cursor-pointer" onClick={() => navigate("/sign-in")}>
                            &nbsp; {t("SIGN_IN_RESET")}
                        </a>
                    </p>
                </div>
                <div>
                    <button disabled={isFormSubmitting} type="submit" className="btn btn-primary w-100 mb-0 mt-4">
                        <div className="row" style={{ height: "30px" }}>
                            <div className="col" style={{ textAlign: "left", marginTop: "2px" }}>
                                {isFormSubmitting && <i className="fa fa-spin fa-spinner"></i>}
                                <span style={{ fontSize: "15px", fontWeight: "normal" }}>  {t("RESET_PASSWORD")}</span>
                            </div>
                            <div className="col-auto" style={{ marginTop: "2px" }}>
                                <i className="bi bi-chevron-right"></i>
                            </div>
                        </div>
                    </button>
                </div>
            </form>
        </>
    );
}