import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagingComponent.css';
import { useTranslation } from 'react-i18next';

const PaginationComponent = props => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    pageCount
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      {!isMobile ?
        <div className={`d-flex  flex-row ${props.class}`}>
          {currentPage == 1 ? <p className='ms-3'>{t("SHOWING")} {currentPage} {t("PAGE_TO")} {pageSize} {t("PAGE_OF")} {totalCount} {t("ENTRIES")}</p> :
            <p className='ms-3'>{t("SHOWING")} {(currentPage * 5 + 1) - 5} {t("PAGE_TO")} {currentPage == pageCount ? totalCount : (pageSize * (currentPage + 1)) - 5} {t("PAGE_OF")} {totalCount} {t("ENTRIES")}</p>}
          <ul
            className={classnames('pagination-container', { [className]: className })}
          >
            <li
              className={classnames('pagination-item', {
                disabled: currentPage === 1
              })}
              onClick={onPrevious}
            >
              <div className="arrow left" />
            </li>
            {paginationRange.map(pageNumber => {
              if (pageNumber === DOTS) {
                return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
              }

              return (
                <li
                  key={pageNumber}
                  className={classnames('pagination-item', {
                    selected: pageNumber === currentPage
                  })}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </li>
              );
            })}
            <li
              className={classnames('pagination-item ', {
                disabled: currentPage === lastPage
              })}
              onClick={onNext}
            >
              <div className="arrow right" />
            </li>
          </ul>
        </div> :
        <div className='row '>
          <ul
            className={classnames('pagination-container justify-content-end px-0 mt-2', { [className]: className })}
          >
            <li
              className={classnames('pagination-item', {
                disabled: currentPage === 1
              })}
              onClick={onPrevious}
            >
              <div className="arrow left" />
            </li>
            {paginationRange.map(pageNumber => {
              if (pageNumber === DOTS) {
                return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
              }

              return (
                <li
                  key={pageNumber}
                  className={classnames('pagination-item', {
                    selected: pageNumber === currentPage
                  })}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </li>
              );
            })}
            <li
              key={currentPage}
              className={classnames('pagination-item', {
                disabled: currentPage === lastPage
              })}
              onClick={onNext}
            >
              <div className="arrow right" />
            </li>
          </ul>
        </div>}
    </>
  );
};

export default PaginationComponent;
