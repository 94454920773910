import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

const DateTimeDifference = observer(({ prevMessage, data, myMessage, diffInMinutes }) => {
    const todayDate = new Date();
    const { t } = useTranslation();
    let dateProp = moment(data.createdAt);
    let yesterday = moment(todayDate.setDate(todayDate.getDate() - 1));
    if (myMessage) {
        return (
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <hr className={`${yesterday.format("DD MMM YYYY") == dateProp.format("DD MMM YYYY") ? "today-message-hr" : "w-35"}`} />
                    <span className='pb-1' style={{ fontSize: '80%' }}>{yesterday.format("DD MMM YYYY") == dateProp.format("DD MMM YYYY") ? t("YESTERDAY") : moment(data.createdAt).format("DD.MM.YYYY ")}</span>
                    <hr className={`${yesterday.format("DD MMM YYYY") == dateProp.format("DD MMM YYYY") ? "today-message-hr" : "w-35"}`} />
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <hr className={`${yesterday.format("DD MMM YYYY") == dateProp.format("DD MMM YYYY") ? "today-message-hr" : "w-35"}`} />
                    <span className='pb-1' style={{ fontSize: '80%' }}>{yesterday.format("DD MMM YYYY") == dateProp.format("DD MMM YYYY") ? t("YESTERDAY") : moment(data.createdAt).format("DD.MM.YYYY ")}</span>
                    <hr className={`${yesterday.format("DD MMM YYYY") == dateProp.format("DD MMM YYYY") ? "today-message-hr" : "w-35"}`} />
                </div>
                {prevMessage === undefined && <span className="message-data-time other-message-time">{data.sender && data.sender.firstName + ' ' + data.sender.lastName + ' ' + moment(data.createdAt).format("HH:mm")}</span>}
                {prevMessage && prevMessage.senderId !== data.senderId && <span className="message-data-time other-message-time">{data.sender && data.sender.firstName + ' ' + data.sender.lastName + ' ' + moment(data.createdAt).format("HH:mm")}</span>}
                {prevMessage && (prevMessage.senderId === data.senderId && diffInMinutes >= 60) && <span className="message-data-time other-message-time">{data.sender && data.sender.firstName + ' ' + data.sender.lastName + ' ' + moment(data.createdAt).format("HH:mm")}</span>}
            </>)
    }
})

export default DateTimeDifference
