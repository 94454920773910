import { useState } from "react";
import { useTranslation } from "react-i18next";

const VehiclesModal = (props) => {
    const { t } = useTranslation();
    const [isConfirming, setIsConfirming] = useState(false);

    const handleDiscard = () => {
        props.onDiscard();
    }

    const handleConfirm = () => {
        props.onConfirm();
    }

    return (
        <>
            <div className="mb-3">
                <span className="cardModalTitle">{props.title}</span>
            </div>
            <div className="card-body">
                <div className="mb-3">
                    <label className="form-label">{props.description}</label><br />
                </div>
                <div className="d-flex justify-content-end">
                    <button className="btn text-secondary border-0 me-2" onClick={() => handleDiscard()}>
                        {t("DISCARD")}
                    </button>
                    <button disabled={isConfirming} type="submit" className={props.btnType ? `btn ${props.btnType}` : `btn btn-primary`} onClick={() => handleConfirm()}>
                        {isConfirming && <i className="fa fa-spin fa-spinner"></i>}
                        {props.confirmBtnLabel}
                    </button>

                </div>
            </div>
        </>
    )
};

export default VehiclesModal;