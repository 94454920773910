import { makeObservable, observable, action } from "mobx";
import i18n from "i18next";

class GlobalStore {
    theme = '';
    lng = '';
    changeLanguage = false;
    render = false;
    renderConnection = false;
    firstLogin = false;
    tranCode = "";
    lastOrderIdRequestFinalizeRide = "";
    lastOrderIdFinalizeRide = "";
    closeIcon = <svg className='cursor-pointer mt-3' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M1 14L14 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 14L1 1" stroke="#7E7E7E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>;

    constructor() {
        makeObservable(this, {
            theme: observable,
            lng: observable,
            closeIcon: observable,
            changeTheme: action,
            changeLng: action,
            setChangeLanguage: action,
            changeLanguage: observable,
            changeRenderConnection: action,
            changeRender: action,
            render: observable,
            renderConnection: observable,
            tranCode: observable,
            changeTranCode: action,
            lastOrderIdRequestFinalizeRide: observable,
            lastOrderIdFinalizeRide: observable
        });
        const model = localStorage.getItem('theme');
        this.theme = model;
        this.lng = localStorage.getItem('i18nextLng');
        if(this.lng != "ba" && this.lng != "en" && this.lng != "de")
        {
            this.lng = "ba";
            i18n.changeLanguage(this.lng);
            localStorage.setItem('i18nextLng', this.lng);
        }
    }
    changeTheme(theme) {
        this.theme = theme;
    }
    changeFirstLogin(value){
        this.firstLogin = value;
    }
    changeRenderConnection() {
        this.renderConnection = !this.renderConnection;
    }
    changeRender(render) {
        this.render = render;
    }
    changeTranCode(code) {
        this.tranCode = code;
    }
    changeLastOrderIdRequestFinalizeRide(orderId) {
        this.lastOrderIdRequestFinalizeRide = orderId;
    }
    changeLastOrderIdFinalizeRide(orderId) {
        this.lastOrderIdFinalizeRide = orderId;
    }
    changeLng(lng) {
        this.lng = lng;
        this.setChangeLanguage(true);
    }
    setChangeLanguage(bool)
    {
        this.changeLanguage = bool;
    }

}

const globalStore = new GlobalStore();
export default globalStore;