import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Flatpickr from "react-flatpickr"
import moment from "moment/moment"
import React from "react"
import PlacesAutocomplete from "../../../common/googleAutocomplete/GooglePlacesAutocomplete"
import { AppSettingsService } from "data/services"

export default function MilestoneModal({
  editingIndex,
  setEditingIndex,
  rows,
  setRows,
  index,
  setIndex,
  setFormData,
  setMilestoneModalOpen,
  setRefreshRecommendedPrice,
  setIsModalClosing,
}) {
  const { t } = useTranslation();
  const [flatPickrDiv, setFlatPickrDiv] = useState(null);
  const [minHoursRide, setMinHoursRide] = useState(0);

  useEffect(() => {
    if (editingIndex !== -1) {
      setIndex(editingIndex);
    }
    const fetchAndSetMinHoursRide = async () => {
      const response = await AppSettingsService.getByParams({ pageSize: 999, pageNumber: 1 });
      setMinHoursRide(response.data.items[0].minHoursBeforeRide);
    }
    fetchAndSetMinHoursRide();
  }, [editingIndex]);

  const updateAddressValue = (index, event) => {
    rows[index]["address"] = event.target.value
    setRows([...rows]);
    rows[index].isAddressInvalid = !rows[index].address && "ERROR"
    return
  }

  useEffect(() => {
    setTimeout(() => {
      let el = document.getElementById(`${index}_autocomplete`);
      if (el) {
        el.focus();
      }
    }, 20);

  }, [index]);

  const updateDateTimeValue = (index, date) => {
    if (date != null) {
      rows[index]["dateTime"] = date;
      const currentRowDateTime = moment.utc(new Date(rows[index].dateTime));
    if (index != 0) {
      const previousRowDateTime = moment.utc(new Date(rows[index - 1].dateTime));
      rows[index].isCurrentDateTimeConflictedWithPrevious = currentRowDateTime.diff(previousRowDateTime, "minutes") === 0;
    }
    else
    {
      rows[index].rideDurationLessThanMinHours = Math.ceil(currentRowDateTime.diff(moment(), 'minutes',true)) < minHoursRide * 60;
    }
    const nextRowDateTime = rows[index + 1] && moment.utc(new Date(rows[index + 1].dateTime));
    rows[index].isCurrentDateTimeConflictedWithNext = nextRowDateTime && currentRowDateTime.diff(nextRowDateTime, "minutes") === 0;
    setRows([...rows])
    }
  }

  const validateRow = (row) => {
    row.isCityInvalid = !row.cityName && "ERROR"
    row.isAddressInvalid = !row.address && "ERROR"
    row.isDateTimeInvalid = !row.dateTime && "ERROR"
    const currentRowDateTime = moment.utc(new Date(row.dateTime));
    if (index != 0) {
      const previousRowDateTime = moment.utc(new Date(rows[index - 1].dateTime));
      row.isCurrentDateTimeConflictedWithPrevious = currentRowDateTime.diff(previousRowDateTime, "minutes") === 0;
    }
    else
    {
      row.rideDurationLessThanMinHours = Math.ceil(currentRowDateTime.diff(moment(), 'minutes',true)) < minHoursRide * 60;
    }
    const nextRowDateTime = rows[index + 1] && moment.utc(new Date(rows[index + 1].dateTime));
    row.isCurrentDateTimeConflictedWithNext = nextRowDateTime && currentRowDateTime.diff(nextRowDateTime, "minutes") === 0;
    row.isValid =
      !row.isCityInvalid &&
      !row.isAddressInvalid &&
      !row.isDateTimeInvalid &&
      !row.isCurrentDateTimeConflictedWithPrevious &&
      !row.isCurrentDateTimeConflictedWithNext &&
      !row.rideDurationLessThanMinHours
  }

  const addNewMilestone = (index) => {
    if (!rows[index]) return;

    validateRow(rows[index]);

    if (!rows[index].isValid) {
      setRows([...rows]);
      return;
    }

    setIsModalClosing(true);
    setIndex(index + 1);

    if (index === 0) {
      rows[index].IsStartMilestone = true;
    } else if (index === rows.length - 1) {
      rows[rows.length - 1].IsLastMilestone = true;
    } else {
      rows[index].IsStopMilestone = true;
    }

    rows[index].isSubmitted = true;

    setFormData((prevObj) => ({ ...prevObj, rows }));

    if (index > 0 && index < rows.length - 1) {
      rows[rows.length - 1].startPointLocationLabel = rows[index].address;
      rows[rows.length - 1].startPointCityLabel = rows[index].cityName;
    }

    if (rows.length >= 2) {
      setMilestoneModalOpen(false);

    }

    if (rows.length === 1) {
      rows.push({ isSubmitted: false, dateTime: rows[0] && rows[0].dateTime });
    }
    if (rows[index].oldValue && rows[index].lat !== rows[index].oldValue.lat && rows[index].lng !== rows[index].oldValue.lng) {
      setRefreshRecommendedPrice(true)
    }
    delete rows[index].oldValue;
    setRows(rows);
    setEditingIndex(-1);
  }


  const onPlaceSelected = (rowsData, index) => {
    const oldAddressValue = rows[index].address;
    if (!rows[index])
      return;
    const rowsCopy = [...rows]

    rowsCopy[index].cityName = rowsData.locality ?? rowsData.name
    rowsCopy[index].country = rowsData.country
    if (rowsData.address !== undefined || rowsData.address) {
      rowsCopy[index].address = rowsData.address;
    } else {
      rowsCopy[index].address = ""
    }
    rowsCopy[index].locationName = rowsData.name;
    rowsCopy[index].postalCode = rowsData.postcode;
    rowsCopy[index].countryCode = rowsData.abbreviation;
    rowsCopy[index].lat = rowsData.location.lat;
    rowsCopy[index].lng = rowsData.location.lng;
    rowsCopy[index].isChanged = !rowsCopy[index].isChanged;
    rowsCopy[index].isAddressDisabled = !!rowsCopy[index].address;
    if (rowsCopy[index].lat !== rowsData.location.lat || rowsCopy[index].lng !== rowsData.location.lng) {
      setRefreshRecommendedPrice(true);
    }
    setRows(rowsCopy);
    rows[index].isCityInvalid = !rows[index].cityName && "ERROR";
    if(oldAddressValue && oldAddressValue != "" && rowsData.address === "")
    {
      rows[index].isAddressInvalid = "ERROR";
    }
    else{
      rows[index].isAddressInvalid = false;
    }
  }

  useEffect(() => {
    setFormData((prevObj) => ({ ...prevObj, rows }));
  }, [rows,]);

  useEffect(() => {
    if (rows.length === 0) {
      rows.push({ isSubmitted: false, dateTime: new Date(new Date().getTime() + 27 * 60 * 60 * 1000) });
      setIndex(0);
    }
  }, [rows.length]);

  const getTitle = () => {
    if (index === 0) {
      return editingIndex !== -1 ? t("EDIT_DEPARTURE") : t("ADD_DEPARTURE")
    } else if (index < rows.length - 1) {
      return editingIndex !== -1 ? t("EDIT_STOPOVER") : t("ADD_STOPS")
    } else {
      return editingIndex !== -1 ? t("EDIT_DESTINATION") : t("ADD_DESTINATION")
    }
  }
  useEffect(() => {
    setTimeout(() => {
      const modal = document.getElementById(`flatPickrDiv_${index}`)
      setFlatPickrDiv(modal);
    }, 20)
  }, [])
  return (
    <>
    
      <style>
        {`
          .flatpickr-wrapper {
            width:100%!important;
          }
      @media (max-width: 575.98px)
      .flatpickr-calendar.hasTime {
          width: 100%!important;
          min-width: 100%!important;
          max-width: 100%!important;
      }
    `}
      </style>

      <React.Fragment key={`add_${index}`}>
        <div className="mb-3">
          <p className="mt-1 ms-1 cardModalTitle fw-600 fs18">{getTitle()}</p>
        </div>
        <div className="card-body ms-1">

          <div className="mb-3">
            {rows && rows[0] && !rows.length - 1 && (
              <>
              <label className="form-label fw-500 gray  fs14 mt-1">{t("DATE")}:</label>
                <div className="col" id={`flatPickrDiv_${index}`} >
                  <img className="marginLeftMilestoneModalIcons h-20px modalIcons" src="/assets/images/calendar.svg" alt="calendar" />
                  <Flatpickr
                    key={index}
                    onChange={(selectedDates) => {
                      updateDateTimeValue(index, selectedDates[0])
                    }}
                    onValueUpdate={(selectedDates) => {
                      if (selectedDates.length === 0) {
                        updateDateTimeValue(index, null)
                      }
                    }}
                    type="text"
                    className='form-control fs16  input-field input-address'
                    placeholder={t("SELECT_DATE_AND_TIME")}
                    options={{
                      minDate: index === 0 ? moment(new Date()).add(1, 'hour').startOf('hour').toDate() : rows.at(index - 1) ? rows[index - 1].dateTime : undefined,
                      maxDate: rows.at(index + 1) ? rows[index + 1].dateTime : undefined,
                      disableMobile: true,
                      enableTime: true,
                      time_24hr: true,
                      static: true,
                      dateFormat: "d.m.Y H:i",
                      //position: "above center",
                      defaultDate:
                        (rows[index] && rows[index].dateTime
                          ? rows[index].dateTime
                          : rows[index - 1] && rows[index - 1].dateTime
                            ? rows[index - 1].dateTime
                            : new Date()),
                    }}
                  />
                  {rows[index] && rows[index].isDateTimeInvalid && <div className="text-danger errors ms-2"> {t("CHOOSE_DATETIME")}</div>}
                  {rows[index] && rows[index].isCurrentDateTimeConflictedWithPrevious && (
                    <div className="text-danger errors ms-2 mt-0">
                      {t("ERROR_DATETIME_CONFLICTED_WITH_PREV")} {rows[index - 1] && moment(rows[index - 1].dateTime).format("DD.MM.YYYY HH:mm")}
                    </div>
                  )}
                  {rows[index] && rows[index].rideDurationLessThanMinHours && (
                    <div className="text-danger errors ms-2 mt-0">
                      {t("ERROR_DATETIME_LESS_MIN_HOURS_BEFORE_RIDE_MODAL",{minHoursRide})}
                    </div>
                  )}
                  {rows[index] && rows[index].isCurrentDateTimeConflictedWithNext && (
                    <div className="text-danger errors ms-2 mt-0">
                      {t("ERROR_DATETIME_CONFLICTED_WITH_NEXT")} {rows[index + 1] && moment(rows[index + 1].dateTime).format("DD.MM.YYYY HH:mm")}
                    </div>
                  )}

                </div>
              <label className="form-label fw-500 gray fs14  mt-1">{t("CITY_LABEL")}:</label>
                <PlacesAutocomplete
                  id={`${index}_autocomplete`}
                  className="input-address  input-field  fs16"
                  onPlaceSelected={(rowsData) => onPlaceSelected(rowsData, index)}
                  defaultValue={(rows[index] && rows[index].locationName && rows[index].cityName && `${rows[index].locationName}, ${rows[index].cityName}`) || ""}
                ></PlacesAutocomplete>
                {rows[index] && rows[index].isCityInvalid && <div className="text-danger errors ms-2">{t("CHOOSE_CITY")}</div>}

                <label className="form-label fw-500 gray fs14  mt-1">{t("ADDRESS")}:</label>
                <div className="col input-icons">
                  <img className="h-20px mb-1 marginLeftMilestoneModalIcons placeIcon2" src="/assets/images/location.svg" alt="location" />
                  <input
                    type="text"
                    className="form-control fw-500  input-address input-field"
                    value={(rows[index] && rows[index].address) || ""}
                    onInput={(event) => {
                      updateAddressValue(index, event)
                    }}
                    placeholder={t("INSERT_LOCATION_NAME")}
                    disabled={rows[index] && rows[index].isAddressDisabled}
                  />
                </div>
                {rows[index] && rows[index].isAddressInvalid && <div className="text-danger errors ms-2">{t("CHOOSE_ADDRESS")}</div>}
              </>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btnPrimary w-100 h-45 btnForAddingMilestones" onClick={() => addNewMilestone(index)}>
              {getTitle()}
            </button>
          </div>
        </div>
      </React.Fragment>
    </>
  )
}
